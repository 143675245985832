import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMG_BASE_URL } from '../Constant';
export default function AboutUs() {
  return (
    <>
      <div className=" mt-5 ">
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval={10000}>
              <img
                src={IMG_BASE_URL + "Banners/Banner.jpg"} //"https://drive.google.com/uc?id=1NGetLOw-ZLAKGVsaRzA7kNInvAb35q0o"
                className="d-block w-100"
                style={{ height: 300 }}
                alt="..."
              />

              {/* <div className="carousel-caption d-none d-md-block">
                <h5 className="text-light">First slide label</h5>
                <p className="text-light">
                  Some representative placeholder content for the first slide.
                </p>
              </div> */}
            </div>
            <div className="carousel-item" data-bs-interval={2000}>
              <img
                src={IMG_BASE_URL + "Banners/hospital7.jpeg"}
                className="d-block w-100"
                style={{ height: 300 }}
                alt="..."
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5 className="text-light">Second slide label</h5>
                <p className="text-light">
                  Some representative placeholder content for the second slide.
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                src={IMG_BASE_URL + "Banners/hospita4.jpeg"}
                className="d-block w-100"
                style={{ height: 300 }}
                alt="..."
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5 className="text-light">Third slide label</h5>
                <p className="text-light">
                  Some representative placeholder content for the third slide.
                </p>
              </div> */}
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon " aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next "
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon " aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <Aboutscroolcart />
      <CashLessPolicy />
      <Card />
      <HealthCare />
    </>
  );



   function HealthCare() {
  return (
    <div>
      <h2 className="text-center fw-bold mt-5">BEST HOSPITAL IN TOWN</h2>
      {/* <p className="text-center text-Secondary">When It Comes to Health Care</p> */}

      <div className="row g-0  px-3">
        <div className="col-md-6">
          <img
            src={IMG_BASE_URL + "Banners/Hospitalbanner.png"}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div className="col-md-6 text-start p-5 ">
          <div className="card-body">
            <h2 className="card-title fw-bold ">
              Quality Care for Comprehensive Treatment
            </h2>
            <br></br>
            <p className="card-text">
              Quality Care for Comprehensive Treatment Founded in 2010, Jivdani
              Hospital stands as a premier healthcare institution committed to a
              'Patient First' approach. Our ethos is rooted in providing
              exemplary tertiary and quaternary healthcare services. With
              strategically located hospitals in Dombivli and Pune, we adopt a
              Greenfield strategy to address the end-to-end healthcare needs of
              our patients
            </p>
            <br></br>
            <p className="card-text">
              Our flagship hospital, nestled near the Kalyan-Shilphata road in
              Dombivli, ensures easy connectivity for residents and the
              surrounding areas. Operating round the clock, we prioritize
              patient well-being and convenience
            </p>
            <br></br>
          </div>
        </div>
      </div>

      <div className="row g-0  px-3">
        <div className="col-md-6 text-start p-5 ">
          <div className="card-body">
            <h2 className="card-title fw-bold  text-center ">Mission</h2>
            <br></br>
            <p className="card-text p-5">
              Our mission is to create the best infrastructure, technology and
              support to put Patient First & foremost and be futuristic,
              innovative in delivery of healthcare.
            </p>
            <br></br>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={IMG_BASE_URL + "Banners/bannaer03.jpeg"}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
      </div>
      <div className="row g-0  p-3">
        <div className="col-md-6">
          <img
            src={IMG_BASE_URL + "socialmedia/Aboutus.jpeg"}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div className="col-md-6 text-start px-5 ">
          <div className="card-body">
            <h2 className="card-title fw-bold  text-center ">Vision</h2>
            <br></br>
            <p className="card-text p-5">
              Our vision is to be a full-service hospital, operating
              independently and serving the end-to-end healthcare needs of
              patients, right from diagnostics to surgery and rehabilitation.
            </p>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
  
  
 }



    function Aboutscroolcart() {

   
    const [numbar, setNumbar] = useState(1);
        const [numbar1, setNumbar1] = useState(2);
            const [numbar2, setNumbar2] = useState(10);


    setTimeout(() => {
 
if (numbar < 500) setNumbar(numbar + 1);
     
        if (numbar1 < 2500) setNumbar1(numbar1 + 2);
 
        if (numbar2 < 5000) setNumbar2(numbar2 + 10);
  
    }, 5);
      

      return (
        <div
          className="d-flex flex-row flex-nowrap overflow-auto gap-3 ps-3 pt-5 "
          style={{ backgroundColor: "#8320F9" }}
        >
          <div className="container text-center  px-4">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <FontAwesomeIcon
                    icon="fa-solid fa-user-nurse"
                    className="text-light"
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      height: "200px",
                    }}
                  />
                  <h3
                    className="h1 fw-bold text-light"
                    style={{ fontSize: "5vw" }}
                  >
                    {numbar + "+"}
                  </h3>
                </div>
              </div>
              <div className="col">
                <div className="text-center">
                  <FontAwesomeIcon
                    icon="fa-solid fa-users-viewfinder"
                    className="text-light"
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      height: "200px",
                    }}
                  />
                  <h3
                    className="h1 fw-bold text-light"
                    style={{ fontSize: "5vw" }}
                  >
                    {numbar1 + "+"}
                  </h3>
                </div>
              </div>
              <div className="col">
                <div className="text-center">
                  <FontAwesomeIcon icon="fa-regular fa-face-smile" 
                  className="text-light" style=
                  {{
                    width: "100%",
                    maxWidth: "200px",
                    height: "200px",
                  }}
                  />
                  <h3
                    className="h1 fw-bold text-light"
                    style={{ fontSize: "5vw" }}
                  >
                    {numbar2 + "+"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    
}



 const CashLessPolicy = ({  speed = 20000 }) => {
const images = [
  IMG_BASE_URL + "CashLesspolicy/Acko.png",
  IMG_BASE_URL + "CashLesspolicy/Bajaj.png",
  IMG_BASE_URL + "CashLesspolicy/Chola.png",
  IMG_BASE_URL + "CashLesspolicy/fhpl.png",
  IMG_BASE_URL + "CashLesspolicy/healthindia.png",
  IMG_BASE_URL + "CashLesspolicy/layout_set_logo.png",
  IMG_BASE_URL + "CashLesspolicy/logo.svg",
  IMG_BASE_URL + "CashLesspolicy/manipal.png",
  IMG_BASE_URL + "CashLesspolicy/mediassit.png",
  IMG_BASE_URL + "CashLesspolicy/naviLogo.svg",
].map((image) => ({
  id: Math.floor(100000 + Math.random() * 900000), //crypto.randomUUID(),
  image,
}));
  return (
    <>
      <h3 className="my-4 text-center fw-bold"> Cash Less Policy Available </h3>
      <div style={{ height: 140, textAlign: "center" }} className="inner my-5">
        <div className="wrapper">
          <section style={{ "--speed": `${speed}ms` }}>
            <div className="image me-2">
              <p
                className="bg-warning text-light fw-bold px-2 text-center"
                style={{ height: "8rem" ,alignItems:'center'}}
              >
                CashLessPolicy
              </p>
            </div>
            {images.map(({ id, image }) => (
              <div className="image me-2" key={id}>
                <img style={{ height: "8rem" }} src={image} alt={id} />
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            <div className="image me-2">
              <p
                className="bg-warning text-light fw-bold px-2 text-center"
                style={{ height: "8rem" }}
              >
                CashLessPolicy
              </p>
            </div>
            {images.map(({ id, image }) => (
              <>
                <div className="image me-2" key={id}>
                  <img style={{ height: "8rem" }} src={image} alt={id} />
                </div>
              </>
            ))}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            <div className="image me-2">
              <p
                className="bg-warning text-light fw-bold px-2 text-center"
                style={{ height: "8rem" }}
              >
                CashLessPolicy
              </p>
            </div>
            {images.map(({ id, image }) => (
              <>
                <div className="image me-2" key={id}>
                  <img style={{ height: "8rem" }} src={image} alt={id} />
                </div>
              </>
            ))}
          </section>
        </div>
      </div>
    </>
  );
};




const Card = () => {
  return (
    <div
      className="d-flex flex-row flex-nowrap overflow-auto gap-3 ps-3 pt-5 "
      style={{ height: 500, backgroundColor: "#14BDC0" }}
    >
      <div
        className="card p-3"
        style={{ width: 500, height: 370, minWidth: 370, minHeight: 400 }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-briefcase-medical"
          className="card-img-top text-primary "
          alt="..."
          style={{ width: 100, height: 100, alignSelf: "center" }}
        />
        <div className="card-body text-center">
          <h4> ADVANCED TECHNOLOGY </h4>
          <p className="card-text">
            At Jivdani Hospital, we believe in delivering healthcare that not
            only meets the highest standards of medical expertise but also
            harnesses the power of cutting-edge technology. Our commitment to
            your well-being extends beyond traditional practices, as we
            integrate advanced technology to redefine the healthcare experience.
          </p>
        </div>
      </div>
      <div
        className="card p-3"
        style={{ width: 500, height: 370, minWidth: 370, minHeight: 400 }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-user-doctor"
          className="card-img-top  text-primary"
          alt="..."
          style={{ width: 100, height: 100, alignSelf: "center" }}
        />
        <div className="card-body text-center">
          <h4>HEALTHCARE SOLUTIONS</h4>
          <p className="card-text">
            In an era of rapid advancements, Jivdani Hospital is at the
            forefront of delivering transformative healthcare solutions that
            prioritize your health and elevate your overall well-being. Our
            commitment to excellence extends to embracing cutting-edge
            technologies and forward-thinking approaches. Explore how our
            healthcare solutions are reshaping the landscape of medical care:
          </p>
        </div>
      </div>
      <div
        className="card p-3"
        style={{ width: 500, height: 370, minWidth: 370, minHeight: 400 }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-truck-medical"
          className="card-img-top text-primary "
          alt="..."
          style={{ width: 100, height: 100, alignSelf: "center" }}
        />
        <div className="card-body text-center">
          <h4> 24/7 AVAILABILITY </h4>
          <p className="card-text">
            At Jivdani Hospital, we understand that health concerns can arise at
            any hour, and your well-being should never be bound by the
            constraints of time. That's why we proudly offer 24/7 availability
            to ensure you have access to quality healthcare whenever you need
            it.
          </p>
        </div>
      </div>
    </div>
  );
};


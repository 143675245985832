import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { increment } from "../actions";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  BASE_URL,
  // AddCategoryItem,
  ACCESS_TOKEN,
  DeleteItem,
  DEBUG_LOG_ENABLED,
  AddDoctorSlotList,
  GetDoctorDatesListsByDoctorIdAndDates,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
export default function AddTimeSlot() {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };
  const [timeslots, setTimeSlots] = useState([]);

  const [dateBooking, setdateBooking] = useState([]);

  const catData = localStorage.getItem("dateItem");
  const itemData = JSON.parse(catData);
  const myData = localStorage.getItem("myProduct");
  const product = JSON.parse(myData);
  // const dispatch = useDispatch();
  const [Checkbox, setCheckbox] = useState(false);
  const navigate = useNavigate();
  const [nextSlot, setnextSlot] = useState(30);
  var x = {
    //nextSlot: 30,
    breakTime: [
      // ["11:00", "14:00"],
      // ["16:00", "18:00"],
    ],
    startTime: "6:00",
    endTime: "23:30",
  };

  var slotTime = moment(x.startTime, "HH:mm ");
  var endTime = moment(x.endTime, "HH:mm");

  function isInBreak(slotTime, breakTimes) {
    return breakTimes.some((br) => {
      return (
        slotTime >= moment(br[0], "HH:mm") && slotTime < moment(br[1], "HH:mm ")
      );
    });
  }
  useEffect(() => {
    getslot();
  }, []);

  let times = [];
  while (slotTime < endTime) {
    if (!isInBreak(slotTime, x.breakTime)) {
      times.push(slotTime.format("HH:mm"));
    }
    slotTime = slotTime.add(nextSlot, "minutes");
  }

  // console.log("Time slots: ", times);

  const [categoryItem, setCategoryItem] = useState({
    doctorDateId: itemData.doctorDateId,
    timing: itemData.timing,
  });

  const handleChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    setCategoryItem({ ...categoryItem, [e.target.name]: value });
  };

  const constSumittiming = () => {
    if (categoryItem.timing) {
      setnextSlot(categoryItem.timing);
    } else {
      setnextSlot(30);
    }
    //  alert(categoryItem.timing);
  };

  const saveCategoryItem = (Bookingdate) => {
    // e.stopPropagation();
    // const timeValue = e.target.value;

    // e.preventDefault();
    if (categoryItem.doctorDateId === "") {
      swal(" Please enter all fields ");
    } else {
      const categoryItemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            slotList: Bookingdate.length === 0 ? dateBooking : Bookingdate,
            // [
            //   {
            //     doctorDateId: categoryItem.doctorDateId,
            //     startTime: timeValue,
            //     endTime: "",
            //   },
            // ],
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      axios
        .post(BASE_URL + AddDoctorSlotList, categoryItemData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        })
        .then((response) => {
          swal(response.data.respData.message);
          // navigate("/");
          if (DEBUG_LOG_ENABLED) {
         //   console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/login");
          } else {
            swal("data : " + err);
            if (DEBUG_LOG_ENABLED) {
              console.log(err);
            }
          }
        });
    }
  };

  const removeCatItemData = async (e) => {
    //  e.stopPropagation();
    try {
      const removeCat = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            itemId: itemData.itemId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + DeleteItem, removeCat, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      swal(response.data.respData.message);
      navigate("/");
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = () => {
    removeCatItemData();
  };

  const Bookingdate = [];

  function dateSlotBoking(e) {
    // e.stopPropagation();
    // handleClick()
    const timeValue = e.target.value;
    const startTime = timeValue;
    const doctorDateId = categoryItem.doctorDateId;
    // const Bookingdate = [];

    Bookingdate.push({ startTime, doctorDateId });

    //  setdateBooking(Bookingdate);
    // setCheckbox(!Checkbox);
  }

  const AllDateAdd = () => {
    if (!Checkbox) {
      const doctorDateId = categoryItem.doctorDateId;
      const Booking = [];
      {
        times.map((startTime, index) => {
          Booking.push({ startTime, doctorDateId });
          setdateBooking(Booking);
        });
      }
      setCheckbox(!Checkbox);
    } else {
      setdateBooking([]);
      setCheckbox(!Checkbox);
    }
  };
  const ok = () => {
    setdateBooking(Bookingdate);
    saveCategoryItem(Bookingdate);
  };

  let [Loader, setLoader] = useState(false);

  const getslot = async () => {
    try {
      var endDate = moment(new Date(), "YYYY-MM-DD").add(28, "days");
      const dateData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            doctorId: product?.doctorId,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + GetDoctorDatesListsByDoctorIdAndDates,
        dateData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal(response.data);
      //  const date = response.data.respData.respMsg.doctorDatesList;
      // setSlots( response.data.respData.respMsg.doctorDatesList.sort(
      //   (a, b) => a.date - b.date
      // ))

      const numAscending = [
        ...response.data.respData.respMsg.doctorDatesList,
      ].sort((a, b) => (a.date > b.date) - (a.date < b.date));
      // setSlots(numAscending);
     // console.log(numAscending);
      //  setSlots(date);
      timeSet(numAscending);
      if (DEBUG_LOG_ENABLED) {
        // console.log(response.data);
      }

      // console.log(response.data);
    } catch (error) {
      swal("error : " + error);
      if (DEBUG_LOG_ENABLED) {
        console.log(error);
      }
    }
  };

  const timeSet = (slots) => {
    const getdata = [];
    {
      slots.map(
        (item, index) => {
          if (item.doctorDateId == categoryItem.doctorDateId) {
            item?.slotList.map((item) => getdata.push(item.startTime));

            setTimeSlots(getdata);
          }
          // else{
          //   // alert("kk")
          // }
        }

        // (index === 0 ? time(item) : null)
      );
    }
  };
  // const time = (item) => {
  //   setTimeSlots(item?.slotList);
  //  // localStorage.setItem("bookingDate", item.date);
  // };

  return (
    <>
      <section className=" py-5">
        <div className="container py-5 ">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className=" col-md-12 col-lg-6 col-xl-5 bg-primary px-0 d-none d-md-block">
                  <img
                    src="./../logo192.png"
                    style={{ width: 70 }}
                    class="img-fluid pt-5"
                    alt="appLogo"
                  ></img>
                  <div className=" card-body ps-5">
                    <div
                      id="carouselExampleSlidesOnly"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src="https://drive.google.com/uc?id=1-PyrNinpmmJirBW37MqEBN2A5yUxB1hb"
                            className="d-block pt-5 "
                            alt="..."
                          />
                          <p className="pt-3 fw-bold h-3 text-light">
                            Video consult top doctors from with your comfort
                          </p>
                        </div>
                        <div className="carousel-item ">
                          <img
                            src="https://drive.google.com/uc?id=1XfTSPej4NE0AUcm96XTfYmUHqcl5-D3c"
                            className="d-block pt-5 "
                            alt="..."
                          />
                          <p className="pt-3 fw-bold h-3 text-light ">
                            Video consult top doctors from with your comfort
                          </p>
                        </div>
                        <div className="carousel-item active">
                          <img
                            src="https://drive.google.com/uc?id=1-PyrNinpmmJirBW37MqEBN2A5yUxB1hb"
                            className="d-block pt-5 "
                            alt="..."
                          />
                          <p className="pt-3 fw-bold h-3 text-light ">
                            Video consult top doctors from with your comfort
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-4 offset-xl-1 p-5">
                  {/*  <div className="align-items-center container  py-5 px-xl-5 mx-auto mt-5">
                    <div className="wrapper">
                      <div className="col-lg-9">
                        <div className="d-flex flex-column  align-items-center"> */}
                  {/* <ScrollToTopOnMount /> */}
                  {/* <form className="Auth-form ">
                    <div className="Auth-form-content  "> */}
                  <h4 className="form-title">Add Time Slot</h4>
                  <div className="form-group mt-3">
                    <label>Date Id</label>
                    <input
                      type="number"
                      name="doctorDateId"
                      value={categoryItem.doctorDateId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <label className="mt-2"> Set Timing </label>
                  <div className="input-group mb-3">
                    <input
                      type="number"
                      name="timing"
                      className="form-control mt-2"
                      placeholder="Each slot timing"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      value={categoryItem.timing}
                      onChange={(e) => handleChange(e)}
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      id="button-addon2"
                      onClick={constSumittiming}
                    >
                     submit
                    </button>
                  </div>

                  {/* <div>
                    {times.map((data, index) => (
                      <div> {data} </div>
                    ))}
                  </div> */}
                  <button
                    // onClick={saveCategoryItem}
                    type="submit"
                    className={`btn  h3 btn-${
                      Checkbox ? "primary mt-3" : "outline-primary mt-3"
                    }`}
                    onClick={AllDateAdd}
                  >
                    Select all
                  </button>
                  <div class="row row-cols-3 row-cols-sm-4 row-cols-md-8">
                    {times.map((data, idx) => (
                      <div>
                        <div class="col my-2">
                          {/* <button
                            onClick={dateSlotBoking}
                            //  onClick={saveCategoryItem}
                            type="submit"
                            value={data}
                            //className="btn btn-outline-primary"
                            className={`btn  h3 btn-${
                              Checkbox ? "primary" : "outline-primary"
                            }`}
                          >
                            {data}
                          </button> */}
                          <div
                            className="btn-group "
                            role="group"
                            aria-label="Basic checkbox toggle button group"
                          >
                            <input
                              disabled={timeslots.includes(data) ? true : false}
                              type="checkbox"
                              className="btn-check "
                              id={data}
                              autoComplete="off"
                              value={data}
                              onClick={(e) => {
                                dateSlotBoking(e);
                              }}
                            />
                            <label
                              // className=  {'btn        btn-${outline-primary }   '}
                              className={`btn  btn-${
                                Checkbox ? "primary" : "outline-primary"
                              }`}
                              htmlFor={data}
                              value={data}
                            >
                              {/* {data} */}
                              {moment(data, ["HH.mm"]).format("hh:mm a")}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary   mt-3"
                      // onClick={saveCategoryItem}
                      onClick={ok}
                    >
                      submit
                    </button>
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary   mt-3"
                      onClick={removeItem}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
                    </button>
                  </div>
                </div>
                {/* </form>
                </div> */}
              </div>
            </div>
          </div>
          {/* </div>
              </div>
            </div>
          </div>*/}
        </div>
      </section>
    </>
  );
}

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Placeholder from "../../src/default_img/placeholder.png";
import DefaultImage from "../Images/doctor.png";

// import PropTypes from "prop-types";
import {
  // DEBUG_LOG_ENABLED,
  // SESSION_ID,
  // ADD_DOCTOR,
  // BASE_URL,
  // ACCESS_TOKEN,
  USER_ROLE,
  ROLE,
  IMG_BASE_URL,
} from "../Constant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
// import axios from "axios";
import unavailableImage from "../Images/unavailable.png";
import { useSelector } from "react-redux";
import { Color } from "../report/visitConstant/Color";

function DoctorDetail() {
  const navigate = useNavigate();
  const editMode = useSelector((state) => state.editMode.editMode);
  const myData = localStorage.getItem("myProduct");
  const product = JSON.parse(myData);

  return (
    <div className="align-items-center container  py-4 px-xl-4  mt-5">
      <ScrollToTopOnMount />

      <form className="  rounded-top  card-body mt-5">
        <div className="card " style={{ backgroundColor: Color.lightPrimary }}>
          {product?.iconURL ? (
            <img
              src={IMG_BASE_URL + product?.iconURL}
              style={{ maxHeight: "100px", width: "100px" }}
              class="rounded-circle align-self-center mt-5"
              alt="Cinque Terre"
              onError={(event) => {
                event.target.src = DefaultImage;
              }}
            ></img>
          ) : (
            <img
              src={unavailableImage}
              style={{ maxHeight: "100px", width: "100px" }}
              class="rounded-circle align-self-center mt-5"
              alt="Cinque Terre"
            ></img>
          )}
          <h4 className="align-self-center pt-2">{product.title}</h4>
          <p className="align-self-center fw-bold ">{product.titleID}</p>

          {editMode ? (
            <p className="align-self-center  fw-bold">
              Reg.ID -{product.doctorId}
            </p>
          ) : null}

          <div class="d-grid gap-2 col-6 mx-auto">
            <button
              className="btn btn-outline-primary m-3"
              onClick={() => navigate("/slotbooking/" + product?.itemId)}
              // to={"/slotbooking"}
              // onClick={() =>
              //   localStorage.setItem("catItem", JSON.stringify(showEdit))
              // }
              replace
              type="submit"
            >
              Book
            </button>
            {editMode ? (
              <>
                <Link
                  className="btn btn-outline-primary m-3"
                  to={"/doctorcatitem"}
                  // onClick={() => navigate("/doctorcatitem")}
                  replace
                  onClick={() =>
                    localStorage.setItem("catItem", JSON.stringify(product))
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                </Link>

                <Link
                  className="btn btn-outline-primary m-3"
                  to={"/adddate"}
                  replace
                  onClick={() =>
                    localStorage.setItem("catItem", JSON.stringify(product))
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Date Slot
                </Link>
              </>
            ) : null}
          </div>
        </div>
        <div class="card rounded-top ">
          <div class="card-body ">
            <ol class="list-group list-group-numbered">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Qualification detaiis </div>
                  {product.subTitle}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Experience</div>
                  {product.subTitleID}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Languages known</div>
                  {product.viewMoreTitle}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Consultation fees</div>
                  {product.price}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Email</div>
                  {product.emailId}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Contact</div>
                  Jivdani hospital <br></br>(+91) 93217 75857
                </div>
              </li>
            </ol>
          </div>
        </div>
      </form>
    </div>
    // </div>
    // </div>
    // </div>
  );
}
export default DoctorDetail;

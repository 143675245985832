import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import BottomNavigation from "./BottomNavigation";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function Template(props) {
   const showData = useSelector((state) => state.recatData);
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  return (
    <>
      {/* <div id="google_translate_element"></div> */}
      {/* {!showData && <Header />} */}
      <Header />
      <Content>{props.children}</Content>
      {/* <BottomNavigation /> */}
      {!showData && <BottomNavigation />}
      {!showData && <Footer />}
    </>
  );
}

export default Template;

import React from "react";
import moment from "moment";

const VitalsDisplay = ({ vitalsData, Color }) => {
  if (!vitalsData) return null;

  const styles = {
    container: {
      display: "flex",
      marginTop: 8,
    },
    title: {
      paddingRight: 8,
      fontSize: 16,
      margin: 0,
      fontWeight: "500",
    },
    dataContainer: {
      display: "flex",
      flexWrap: "wrap",
      color: Color.subTextColor,
    },
    item: {
      fontSize: 16,
      margin: "0 10px 0 0",
    },
    label: {
      color: Color.graydark,
      marginRight: 5,
    },
  };

  const renderVital = (label, value, unit) => (
    <p style={styles.item}>
      <strong style={styles.label}>{label}:</strong> {value} {unit}
    </p>
  );

  return (
    <div style={styles.container}>
      <p style={styles.title}>Vitals:</p>
      <div style={styles.dataContainer}>
        {vitalsData.bloodPressure && renderVital("BP", vitalsData.bloodPressure, "mmHg")}
        {vitalsData.pulse !== 0 && renderVital("Pulse", vitalsData.pulse, "bpm")}
        {vitalsData.height !== 0 && renderVital("Height", vitalsData.height, "cm")}
        {vitalsData.weight !== 0 && renderVital("Weight", vitalsData.weight, "kg")}
        {vitalsData.temperature !== 0 && renderVital("Temp", vitalsData.temperature, "F")}
        {vitalsData.bmi !== 0 && renderVital("BMI", vitalsData.bmi, "kg/m2")}
        {vitalsData.waistHip !== 0 && renderVital("Waist/Hip", vitalsData.waistHip)}
        {vitalsData.spo2 !== 0 && renderVital("SPO2", vitalsData.spo2, "%")}
        {vitalsData.fetalHeartSound && renderVital("FHS", vitalsData.fetalHeartSound)}
        {vitalsData.fetalMovement && renderVital("FM", vitalsData.fetalMovement)}
        {vitalsData.hgt !== 0 && renderVital("HGT", vitalsData.hgt)}
        {vitalsData.lastMenstrualPeriod &&
          renderVital("LMP", moment(vitalsData.lastMenstrualPeriod).format("DD-MM-YYYY"))}
        {vitalsData.estimatedDueDate &&
          renderVital("EDD", moment(vitalsData.estimatedDueDate).format("DD-MM-YYYY"))}
      </div>
    </div>
  );
};

export default VitalsDisplay;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import React, { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReportHeader from "./ReportHeader";
import CustomTooltip from "./CustomTooltip";
import DropdownComponent from "./ui/CustomDropdown";
import Diagnosis from "./from/tables/Diagnosis";
import Menstrual from "./from/tables/Menstrual";
import ObstetricH from "./from/tables/ObstetricH";
import History from "./from/tables/History";
import Medicine from "./from/tables/Medicine";
import customStyles from "./ui/CSS/customStyles";
import DateCalculator from "./from/DateCalculator";
import Referred from "./from/Referred";
import TextModal from "./modal/TestMOdal";
import SystemicExamination from "./from/SystemicExamination";
import PhysicalExamination from "./from/PhysicalExamination";
import IconFunctionality from "./IconFunctionality";
// import DataConverte from "./DataConverte/DataConverte";

import {
  DataConverte,
  convertSelectedDataToServerFormat,
} from "./DataConverte/DataConverte";

import Investigations from "./from/tables/Investigations";

import { useSelector, useDispatch } from "react-redux";
import { TempletsubmitData, updateField } from "./ReduxApi/ReduxApi";
import Vitals from "./ui/Vitals";
import { setDataStatus } from "./ReduxApi/ClearAll";
import Complaints from "./from/tables/Complaints";
import GeneralExaminations from "./from/tables/GeneralExaminations";
import LabTestsandImaging from "./from/tables/LabTestsandImaging";
import Advice from "./from/tables/Advice";
import TestsRequested from "./from/tables/TestsRequested";
import { fetchTemplateNames } from "./ReduxApi/FetchTemplateApi";
import { RootFetchTemplateData } from "./ReduxApi/RootTemplateData";
import Categories from "./visitConstant/Categories";
import { getRootTemplateData } from "./ReduxApi/rootTemplateReducer";
import { visitBackgroundColor } from "./visitConstant/repotOtherConstant";
import DietPlan from "./from/tables/DietPlan";
import DietPrint from "./from/tables/DietPrint";
import moment from "moment";

const ReportFormDetail = ({ patientData }) => {
  const [inputFieldOpen, setInputFieldOpen] = React.useState("");

  const reduxApiData = useSelector((state) => state.reduxApiData);
 // console.log("INPFUT FIELD DATA -< ", reduxApiData);
  // console.log("Patient Data-> ", patientData);
  const visitCount = useSelector((state) => state.visit.visitCount);

  const handleFocus = (valu) => {
    setInputFieldOpen(valu);
  };

  const handleBlur = () => {
    setInputFieldOpen("");
  };

  const dispatch = useDispatch();

  const handleClearAll = () => {
    dispatch(setDataStatus(true));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await dispatch(TempletsubmitData(reduxApiData, inputValue));
      // Show the toast
      setIsLoading(false);
      if (data) {
        setInputValue("");
      }
    } catch (error) {
     // console.error("Failed to save data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dispatch(fetchTemplateNames());
  }, [dispatch]);

  useEffect(() => {
    console.log("last update");
  }, [patientData]);

  const { loading, templateNames, error } = useSelector(
    (state) => state?.templateNames
  );

  const category24 = Array.isArray(templateNames)
    ? templateNames?.find((item) => item?.category === 24)
    : null;

  const Templetdropdown = category24?.names.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedItem) => {
    setSelectedOption(selectedItem);
    dispatch(getRootTemplateData(selectedItem.value));
    // dispatch(  GetRootTemplateData( Categories.ROOT_TEMPLATE.catID,selectedItem.value))
  };

  const getOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = num % 100;

    return (
      num +
      (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0])
    );
  };

  const visitMessage = `${getOrdinal(visitCount !== 0 ? visitCount : 1)} `;

  return (
    <div className="col-12">
      <div
        className="card rounded-top-4  "
        style={{ backgroundColor: visitBackgroundColor }}
      >
        <div className=" card-header rounded-top-4  d-flex flex-wrap justify-content-between align-items-center mb-3 bg-white">
          <div className="flex-grow-1">
            {/* {visitCount !== 0 ? visitCount : 1} Visit */}
            {visitMessage} Visit
          </div>

          <div className="d-flex flex-wrap">
            <div
              className="p-2"
              onFocus={() => handleFocus("Load")}
              onBlur={handleBlur}
              tabIndex={0} // Makes the div focusable
            >
              {inputFieldOpen === "Load" ? (
                <Select
                  options={Templetdropdown}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  placeholder="Select Template"
                  openMenuOnFocus={true}
                  closeMenuOnSelect={true}
                  autoFocus
                />
              ) : (
                <>
                  <CustomTooltip
                    icon="fa-regular fa-circle-down"
                    tooltipText="Saved Templates"
                    mainText="Load template"
                  />
                </>
              )}
            </div>
            <div
              className="p-2"
              onFocus={() => handleFocus("Save")}
              // onBlur={() => handleBlur()}
              tabIndex={0} // Makes the div focusable
            >
              {inputFieldOpen === "Save" ? (
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Save as template"
                    onFocus={() => handleFocus("Save")}
                    value={inputValue}
                    onChange={handleChange}
                  />

                  <button
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              ) : (
                <>
                  <CustomTooltip
                    icon="fa-regular fa-floppy-disk"
                    tooltipText="Save as template"
                    mainText="Save as template"
                  />
                </>
              )}
            </div>
            <div className="p-2" onClick={() => handleClearAll()}>
              <CustomTooltip
                icon="fa-solid fa-broom"
                tooltipText=" Clear All"
                mainText="Clear All"
              />
            </div>
          </div>
        </div>

        {/* <div  style={{ maxWidth: maxWidth }}> */}

        {/* </div> <div className="col-12      col-xxl-9    "> */}
        <div className="mx-auto w-100  w-xxl-90">
          <div className="row pe-3">
            <Vitals patientData={patientData} />
          </div>

          <>
            <div className="row justify-content-start mt-5 me-3">
              <Complaints patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-5 pe-3">
              <History patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <GeneralExaminations patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <SystemicExamination patientData={patientData} />

              {/* card add ui */}
              {/* <div class="card ">
                <div class=" row card-body">
                  <p class=" col card-text">
                    Allergies
                    <br></br>
                    <span> Personal History : </span> <br></br>
                    <span> Past Medical History : </span>
                    <br></br>
                    <span>Family History : </span>
                    <br></br>
                  </p>
                  <p className=" col text-end text-primary">
                    {" "}
                    <FontAwesomeIcon icon="fa-regular fa-pen-to-square" /> Edit
                  </p>
                </div>
              </div> */}
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <Diagnosis patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <Menstrual patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <ObstetricH patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <LabTestsandImaging patientData={patientData} />
            </div>
            <div className="row justify-content-start mt-4 pe-3">
              <PhysicalExamination patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <Medicine patientData={patientData} />
            </div>
            <div className="row justify-content-start mt-4 pe-3">
              <DietPrint patientData={patientData} />
            </div>
            <div className="row justify-content-start mt-4 pe-3">
              <Advice patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <TestsRequested patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <DateCalculator patientData={patientData}  />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <Investigations patientData={patientData} />
            </div>

            <div className="row justify-content-start mt-4 pe-3">
              <Referred patientData={patientData} />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ReportFormDetail;

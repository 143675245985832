import  { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import PropTypes from "prop-types";
import { DEBUG_LOG_ENABLED, SESSION_ID, ADD_DOCTOR, BASE_URL, ACCESS_TOKEN } from "../Constant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import axios from "axios";
import LoginScrollpages from "../login/LoginScrollpages";
import swal from "sweetalert";
export default function DrSignUp(props) {



  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [customer, setCustomer] = useState({

    // doctorId: 0,
    name: "",
    mobileNo: "",
    emailId: "",
    password: "",
    type: 0,
    specialization: "",
    registrationNo: "",
    registrationDate: "",
    dateOfBirth: "",
    // role: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const saveCustomer = async (e) => {
    e.preventDefault();
    if (
      customer.name === "" ||
      customer.password === "" ||
      customer.emailId === "" ||
      customer.mobileNo === "" ||
      customer.dateOfBirth === "" ||
      customer.registrationDate === "" ||
      customer.registrationNo === "" ||
      customer.specialization === "" ||
      customer.type === ""



    ) {
      swal(" Please enter all fields ");
    } else if (confirmPassword !== customer.password) {
      swal(" password not matched ");
    } else {
      
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: customer.name,
            emailId: customer.emailId,
            mobileNo: customer.mobileNo,
            password: customer.password,
            type: customer.type,
            specialization: customer.specialization,
            registrationNo: customer.registrationNo,
            registrationDate: customer.registrationDate,
            dateOfBirth: customer.dateOfBirth,

          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      try {
      let response = await axios.post(BASE_URL + ADD_DOCTOR, customerData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
    
      swal(response.data.respData.message);
      // if (DEBUG_LOG_ENABLED) {
      //   console.log(response.data.respData)
      // }
      navigate("/login");

      }catch(error){
        swal("error : " + error);
          if(DEBUG_LOG_ENABLED){
          console.log(error);
          }
      }
     
    }
  };

  return (
    <section className=" py-5">
      <div className="container py-5 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="card rounded-3 text-black">
            <div className="row g-0">
             <LoginScrollpages/>
              <div className="col-md-6 col-lg-6 col-xl-6  my-5 px-3">
               
                            <h3 className="Auth-form-title">Dr Sign Up</h3>
                            <div className="text-center">
                              Already registered?{" "}
                              <Link
                                className="link-info"
                                onClick={() => navigate("/login")}
                              >
                  
                                Sign In
                              </Link>
                            </div>
                            <div className="form-group mt-3">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="name"
                                value={customer.name}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="e.g Ram Kumar"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>Mobile Number</label>
                              <input
                                type="text"
                                name="mobileNo"
                                value={customer.mobileNo}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="Mobile Number"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>Email address</label>
                              <input
                                type="email"
                                name="emailId"
                                value={customer.emailId}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="Email Address"
                              />
                            </div>
                            <div className="form-group mt-4">
                              <label className="me-2"> Gender</label>
                              <input
                                class="form-check-input  "
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value="Male"
                                onChange={(e) => handleChange(e)}
                                aria-label="..."
                              />
                              <small className="me-3"> Male</small>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value="Female"
                                onChange={(e) => handleChange(e)}
                                aria-label="..."
                              />
                              <small> Female</small>{" "}
                            </div>
                            <div className="form-group mt-3"></div>

                            <div className="form-group mt-4">
                              <label className="me-2">Type</label>
                              <input
                                class="form-check-input  "
                                type="radio"
                                name="radioNoLabel2"
                                id="radioNoLabel2"
                                value="General"
                                onChange={(e) => handleChange(e)}
                                aria-label="..."
                              />
                              <small className="me-3"> General</small>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="radioNoLabel2"
                                id="radioNoLabel2"
                                onChange={(e) => handleChange(e)}
                                value="Specialization"
                                aria-label="..."
                              />
                              <small>Specialization</small>{" "}
                            </div>
                            <div className="form-group mt-3">
                              <label>specialization</label>
                              <input
                                type="text"
                                name="specialization"
                                value={customer.specialization}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="registrationDate"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>registrationNo</label>
                              <input
                                type="text"
                                name="registrationNo"
                                value={customer.registrationNo}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="registrationNo"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>registrationDate</label>
                              <input
                                type="date"
                                name="registrationDate"
                                value={customer.registrationDate}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="registrationDate"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>dateOfBirth</label>
                              <input
                                type="date"
                                name="dateOfBirth"
                                value={customer.dateOfBirth}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder=" "
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>Password</label>
                              <input
                                type="password"
                                name="password"
                                value={customer.password}
                                onChange={(e) => handleChange(e)}
                                className="form-control mt-1"
                                placeholder="Password"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                name="confirm password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                className="form-control mt-1"
                                placeholder="Confirm Password"
                              />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                              <button
                                onClick={saveCustomer}
                                type="submit"
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                          


                     
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

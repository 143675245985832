import Template from "./components/Template";
import ProductDetail from "./products/detail/ProductDetail";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./landing/Landing";
import ProductList from "./products/ProductList";
import AddToCart from "./addtocart/AddToCart";

import Contact from "./contact/Contact";
import Error from "./components/Error";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import DrSignUp from "./signup/DrSignUp";
import TransKey from "./services/TransKey";
import { useState, useEffect, createContext } from "react";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import UpDatePassword from "./updatepassword/UpDatePassword";
import BottomNavigation from "./components/BottomNavigation";
import Placeholder from "./ placeholder/Placeholder";
import Tokenbooking from "./booking/Booking";
import CustomerReviewCart from "./components/CustomerReviewCart";
import TokenPage from "./components/TokenPage";
// import swal from "./swal.js/swal";
import swal from "sweetalert";
import ReactGA from "react-ga4";
import Video from "./landing/Video";
import Article from "./landing/Article";
import { Navigate } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import {
  SESSION_ID,
  ABOUT_US_VIEW,
  DEBUG_LOG_ENABLED,
  GetCategoryLists,
  BASE_URL,
  GOOGLE_ANALYTICS_TRACKING_ID,
  GetTransKey,
  ACCESS_TOKEN,
  CARD_VIEW_WITH_TEXT,
  Custome_scroll,
  remove_number_spin,
  LOGIN,
  USER_ROLE,
  ROLE,
  SUPER_ADNIM,
  CART_COUNT,
} from "./Constant";
import Profile from "./profile/Profile";
import Logout from "./logout/Logout";
import AddCat from "./addcategory/AddCat";
import CatItem from "./addcategoryitem/CatItem";
import OrderReview from "./oder_review/OrderReview";
import AddAddress from "./newaddress/AddAddress";
import OrderHistory from "./order_history/OrderHistory";
import OrderHistoryDetail from "./order_history/OrderHistoryDetail";
import OrderSuccess from "./oder_review/OrderSuccess";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SlotBooking from "./slots_booking/SlotBooking";
import DoctorDetail from "./slots_booking/DoctorDetail";
import DoctorCatItem from "./addcategoryitem/DoctorCatItem";
import AddDate from "./addDate/AddDate";
import AddTimeSlot from "./addtimeslots/AddTimeSlot";
import Axios from "axios";
import CustomerReview from "./components/CustomerReviewCart";
import Otp from "./login/Otp";
import TermsAndConditions from "./components/TermsAndConditions";
import BannerCard from "./landing/BannerCard";
import ItemsViewMore from "./Itemviewmore/CatItemsViewMore";
import Headline from "./landing/ Headline";
import Privacypolicy from "./components/ Privacypolicy";

//Your Firebase  npm install firebase/messaging uncommint this line
// import { messaging } from "./firebase/Firebase";
// import { getToken } from "firebase/messaging";
// import { initializeApp } from "firebase/app";

import Loader from "./loader/Loader";
import Phonpe from "./oder_review/Phonpe";
import { WebView } from "@capacitor/core";
import OrderCancel from "./oder_review/OrderCancel";
import Xray from "./landing/x-ray/Xray";
import AboutUs from "./about/AboutUs";
import Dialysis from "./dialysiss/Dialysis";
import RequestCall from "./requestCall/RequestCall";
import ProductListdetail from "./landing/ourservices/ProductListdetail";
import Neurologist from "./landing/ourservices/Neurologist/Neurologist";
import RefundPolicy from "./components/RefundPolicy";
import Topproduct from "../src/minicard/Topproduct";
import Minicard from "./minicard/Minicard";
import Labtest from "./labTest/Labtest";
import CreateToken from "./booking/CreateToken";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import ReportMain from "./report/ReportPage";
import PrintDivComponent from "./report/Report";
import Report from "./report/Report";

import MedicinePage from "../src/report/master/master/MasterMedicinePage";
import { useDispatch } from "react-redux";
import { handleLoggin, increment } from "./actions";
import axios from "axios";
import UnauthorisedPage from "./components/UnauthorisedPage";

export const UserContext = createContext();

const Routing = ({ listData, testData, topproduct }) => {
  return (
    <Routes>
      <Route path="/bannerCard/:id" element={<BannerCard />} />
      <Route
        path="/"
        element={
          <Landing
            listData={listData}
            testData={testData}
            topproduct={topproduct}
          />
        }
      />
      <Route path="/products" element={<ProductList />} />
      <Route path="/productdetail/:id" element={<ProductDetail />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/drSignUp" element={<DrSignUp />} />
      <Route path="/addcat" element={<AddCat />} />
      <Route path="/catitem" element={<CatItem />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/upDatepassword" element={<UpDatePassword />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/addtocart" element={<AddToCart />} />
      <Route path="/addcatitem" element={<CatItem />} />

      {/* <ProtectedRoute path="/addcatitem" component={<CatItem />} adminOnly={true} /> */}

      <Route path="/orderreview" element={<OrderReview />} />
      <Route path="/addaddress" element={<AddAddress />} />
      <Route path="/orderhistory" element={<OrderHistory />} />
      <Route path="/orderhistorydetail" element={<OrderHistoryDetail />} />

      <Route path="/ordersuccess" element={<OrderSuccess />} />
      <Route path="/ordercancel" element={<OrderCancel />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/doctordetail/:id" element={<DoctorDetail />} />
      <Route path="/slotbooking/:id" element={<SlotBooking />} />
      <Route path="/doctorcatitem" element={<DoctorCatItem />} />
      <Route path="/adddate" element={<AddDate />} />
      <Route path="/addtimeslot" element={<AddTimeSlot />} />
      <Route path="/BottomNavigation" element={<BottomNavigation />} />
      <Route path="/placeholder" element={<Placeholder />} />
      <Route path="/tokenbooking" element={<Tokenbooking />} />
      <Route path="/customerReviewCart" element={<CustomerReviewCart />} />
      <Route path="/tokenPage" element={<TokenPage />} />
      {/* <Route path="/swal" element={<swal />} /> */}
      <Route path="/video" element={<Video listData={listData} />} />
      <Route path="/article" element={<Article listData={listData} />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/itemsviewmore" element={<ItemsViewMore />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/headline" element={<Headline />} />
      <Route path="/privacypolicy" element={<Privacypolicy />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/phonpe" element={<Phonpe />} />
      <Route path="/webview" element={<WebView />} />
      <Route path="/Xray" element={<Xray />} />
      <Route path="/Dialysis" element={<Dialysis />} />
      <Route path="/requestCall" element={<RequestCall />} />
      <Route path="/ProductListdetail" element={<ProductListdetail />} />
      <Route path="/neurologist" element={<Neurologist />} />
      <Route path="refundPolicy" element={<RefundPolicy />} />
      <Route path="topproduct" element={<Topproduct />} />
      <Route path="minicard" element={<Minicard />} />
      <Route path="labtest" element={<Labtest />} />
      <Route path="createToken" element={<CreateToken />} />
      {/* <Route
        path="/createToken"
        element={
          <ProtectedRoute allowedRoles={[ROLE, SUPER_ADNIM]}>
            <CreateToken />
          </ProtectedRoute>
        }
      /> */}
      <Route path="/unauthorized" element={<UnauthorisedPage />} />
      <Route path="/mastermedicine" element={<MedicinePage />} />

      <Route path="/reportMain" element={<ReportMain />} />

      <Route path="/Report" element={<Report />} />

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default function App() {
  //Your Firebase configuration
  // const Permission = async  ()=> {
  //    const permission = await Notification.requestPermission();
  //    if (permission === "granted") {
  //     //  Generate Token
  //      try {
  //        const token = await getToken(messaging, {
  //          vapidKey:
  //            "BPVb4BNKn6aIlfHuwFbVfBLmqqqJqwzRpG23d5CZNGWrMbFgqCIKqqR7VLH2-fPQLD1UQ_6aA6uWSSeV7FC3-X4",
  //        });
  //         // console.log("Token Gen-----", token);
  //        localStorage.setItem("FCMToken", token);
  //       // alert(token);
  //     } catch (err) {
  //  const token = await getToken(messaging, {
  //    vapidKey:
  //      "BPVb4BNKn6aIlfHuwFbVfBLmqqqJqwzRpG23d5CZNGWrMbFgqCIKqqR7VLH2-fPQLD1UQ_6aA6uWSSeV7FC3-X4",
  //  });
  //    localStorage.setItem("FCMToken", token);
  // //  console.log("Token Gen-----", token);
  // //  alert(  token);
  //     }
  //     //  Send this token  to server ( db)
  //    } else if (permission === "denied") {
  //     // alert("You denied for the notification");
  //    }
  //  }

  const [comments, setComments] = useState([]);
  const [data, setData] = useState({});
  const [testData, setTestData] = useState([]);
  const dispatch = useDispatch();
  const [topproduct, settopproduct] = useState([]);
  const userRole = localStorage.getItem(USER_ROLE);
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

  useEffect(() => {
    try {
      const isLogIn = localStorage.getItem("is_Login");
      const cartCount = localStorage.getItem(CART_COUNT);
      if (isLogIn) {
        dispatch(handleLoggin(true));
      }
      if (cartCount) {
        dispatch(increment(cartCount));
      }
    } catch (e) {}

    getTransKeyData();
    SendAnalytics();
  }, []);

  const SendAnalytics = () => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  };

  const getTransKeyData = async () => {
    try {
      const transKeyData = JSON.stringify({
        pubInfo: {
          sessionId: "",
        },
        request: {
          busiParams: {
            key: "",
            type: 0,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await TransKey.create(transKeyData);
      let response = await Axios.post(BASE_URL + GetTransKey, transKeyData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      // swal("data : "+response.data.respData.respMsg.sessionId);
      setData(response.data.respData.respMsg);
      const session = response.data.respData.respMsg?.sessionId;
      localStorage.setItem(SESSION_ID, session);

      if (userRole?.includes(ROLE) || userRole?.includes(SUPER_ADNIM)) {
        const AccsesTokan = localStorage.getItem(ACCESS_TOKEN);
        loginDataAccesTokan(AccsesTokan, session);
      }
      fetchComments(session);
    } catch (err) {
      swal("Oops, Something Went Wrong ");
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const fetchComments = async (sessionId) => {
    const categoryListData = JSON.stringify({
      pubInfo: {
        sessionId: sessionId,
      },
      request: {
        busiParams: {},
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });
    try {
      const response = await Axios.post(
        BASE_URL + GetCategoryLists,
        categoryListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      const numAscending = [
        ...response?.data?.respData?.respMsg?.categoryList,
      ].sort((a, b) => a.orderNo - b.orderNo);

      //   console.log("useEffect-> getCategoryLists-> :" + numAscending?.length);
      localStorage.setItem("cat_ascending_data", JSON.stringify(numAscending));

      const itemData = numAscending?.filter(
        (it) => it.viewType === ABOUT_US_VIEW
      );
      localStorage.setItem("categorydata", JSON.stringify(itemData));

      const filtered = numAscending?.filter((item) => {
        return item.viewType === CARD_VIEW_WITH_TEXT;
      });
      setTestData(filtered);
      setComments(numAscending);

      const topproduct = numAscending?.filter((item) => {
        return (
          item.viewType === "Digital X-ray" ||
          item.viewType === "FullBodyCheakup" ||
          item.viewType === "card_view_01"
        );
      });
      settopproduct(topproduct);
    } catch (err) {
      swal("Oops, Something Went Wrong  ");
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };
  const loginDataAccesTokan = async (AccsesTokan, session) => {
    try {
      const reAccess = JSON.stringify({
        pubInfo: {
          sessionId: session,
        },
        request: {
          busiParams: {
            tokenType: "1",
            idToken: AccsesTokan,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const res = await axios.post(BASE_URL + LOGIN, reAccess, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });

      // console.log(res, "ff");

      // res.data.respData.respMsg?.accessToken,
      // res.data.respData.respMsg?.customerId,
      // res.data.respData.respMsg?.doctorId,
      // res.data.respData.respMsg,
      // res.data.respData.respMsg?.role,
      // res.data.respData.respMsg?.name,
      // res.data.respData.respMsg?.emailId,
      localStorage.setItem(
        ACCESS_TOKEN,
        res.data.respData.respMsg?.accessToken
      );
    } catch (error) {
    //  console.log(error + "-------------loginDataAccesTokan");
    }
  };

  return (
    <>
      <HashRouter>
        {/* <BrowserRouter> */}
        <GoogleOAuthProvider clientId="749422304762-fv0niiaipgd2fs3n1t953k5dtjo1slou.apps.googleusercontent.com">
          <Template>
            {/* <div> */}
            {comments?.length > 0 ? (
              // <div>
              <Routing
                listData={comments}
                testData={testData}
                topproduct={topproduct}
              />
            ) : (
              // </div>
              // <div>
              <Placeholder />
              // </div>
            )}
            {/* </div> */}
          </Template>
        </GoogleOAuthProvider>
        {/* </BrowserRouter> */}

        <style>{Custome_scroll}</style>
        <style> {remove_number_spin} </style>
      </HashRouter>
    </>
  );
}

import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TokenModal from "../booking/TokenModal";
import {
  SESSION_ID,
  BASE_URL,
  getUserTokens,
  Tokan_Type_Generate,
  update_User_Token,
  Tokan_Type_RequestCall,
  USER_ROLE,
  ROLE,
  get_User_Token_By_DateRange,
  ACCESS_TOKEN,
  SUPER_ADNIM,
  ERROR_MSG,
  ROLE_LAB,
} from "../Constant";

import ScrollToTopOnMount from "./ScrollToTopOnMount";
import Dropwoun from "../booking/Dropwoun";
import { useNavigate } from "react-router-dom";
import ToastFist from "../report/Toast/ToastFist";
import { useDispatch } from "react-redux";
import { fetchVisitPad } from "../report/ReduxApi/VistPadApiCall";
import { Roles } from "../booking/Booking";
import {
  calculateTimeDifference,
  getGenFormate,
} from "../report/DataConverte/DataConverte";
import ModalLabTest from "../repotLabTest/ReportLabTest";
import OPDSelectionDropdown from "../booking/OpdDeopdwn";

function TokenPage() {
  const [userTokenList, setUserTokenList] = useState([]);
  const [status, setStatus] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [editToken, setEditToken] = useState(null);
  const [labToken, setLabToken] = useState(null);
  const [isLab, setIsLab] = useState(false);
  const oldsetData = sessionStorage.getItem("getTakenbyDate");
  const [getTakenbyDate, setGetTakenbyDate] = useState(
    oldsetData ? oldsetData : moment(new Date()).format("YYYY-MM-DD")
  );
  const navigate = useNavigate();
  const [getTakenbyEndDate, setGetTakenbyEndDate] = useState("");
  const [activeFilter, setActiveFilter] = useState(0);

  // const [filteredTokens, setFilteredTokens] = useState(userTokenList);

  const [filteredTokens, setFilteredTokens] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [option, setOption] = useState(Tokan_Type_Generate);
  const [isReqToken, setIsReqToken] = useState(Tokan_Type_Generate);
  const section = localStorage.getItem(SESSION_ID);

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const dispatch = useDispatch();

  //visit pad Api calling
  useEffect(() => {
    dispatch(fetchVisitPad(navigate));
  }, [dispatch]);

  const fetchTokens = useCallback(
    async (date, status) => {
      try {
        const requestPayload = {
          pubInfo: { sessionId: section },
          request: {
            busiParams: {
              date: date || getTakenbyDate,
              type: option,
              status: status || "",
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        };
        const response = await axios.post(
          BASE_URL + getUserTokens,
          JSON.stringify(requestPayload),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const res = response.data?.respData?.respMsg?.userTokenList || [];
        // console.log(res, " ktoken11");

        if (userRole?.includes(ROLE)) {
          setUserTokenList(res);
          setFilteredTokens(res);
        } else if (userRole?.includes(ROLE_LAB)) {
          const labTokens = res?.filter((item) => item?.labTest == true);
          setFilteredTokens(labTokens);
          setUserTokenList(labTokens);
        }
        setIsReqToken(option);
      } catch (error) {
        // swal("Error", error.message, "error");
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(ERROR_MSG);
        setIsReqToken(option);
      }
    },
    [option]
  );
  const currentDate = moment().format("YYYY-MM-DD");
  // console.log(currentDate, "Dddd");
  const [SuprAdmin, setSuprAdmin] = useState(false);
  const userRole = localStorage.getItem(USER_ROLE);
  // const userRole = Roles;
  useEffect(() => {
    if (userRole?.includes(SUPER_ADNIM)) {
      setShowEdit(true);
      setSuprAdmin(true);
    } else if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    } else if (userRole?.includes(ROLE_LAB)) {
      setIsLab(true);
    }

    // if (userRole?.includes(ROLE)) {
    //   setShowEdit(true);
    // } else if (userRole?.includes(SUPAR_ADMIN)) {

    //   setSuprAdmin(true);
    // }

    fetchTokens(getTakenbyDate);
  }, [editToken]);

  const handleDateChange = () => {
    // setGetTakenbyDate(start);
    // setGetTakenbyEndDate(endDate)

    dateByFetchToken();
  };

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const Datafilter = (status) => {
    setActiveFilter(status);
    if (status === 0) {
      setFilteredTokens(userTokenList);
    } else if (status === 5) {
      setFilteredTokens([]);
      setFilteredTokens(userTokenList.filter((token) => token.visitPad));
    } else if (status === 6) {
      setFilteredTokens([]);
      setFilteredTokens(userTokenList.filter((token) => token.labTest));
    } else {
      setFilteredTokens([]);
      setFilteredTokens(
        userTokenList.filter((token) => token.status === status)
      );
      // console.log(userTokenList.filter((token) => token.status === status))
    }
  };

  const TokenSearchings = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchInput(query);
    const result = filteredTokens.filter(
      (token) =>
        token.token.toString().includes(query) ||
        token.name.toLowerCase().includes(query)
    );
    setFilteredTokens(query.length === 0 ? userTokenList : result);

    if (query.length === 0) {
      setActiveFilter(0);
    }
  };

  const TokenSearching = (e) => {
    const keyword = e.target.value;
    setSearchInput(keyword);

    // If the search input is empty, reset to the full list of tokens
    if (keyword.trim() === "") {
      setFilteredTokens(userTokenList); // Reset to original unfiltered token list
      setActiveFilter(0); // Optionally reset active filter if needed
      return;
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    // Filter the tokens based on the search input
    const searchedData = userTokenList.filter((item) => {
      const isNameMatch = item.name.toLowerCase().includes(lowerCaseKeyword);
      const isTokenIdMatch = item.token.toString().includes(lowerCaseKeyword);
      const isMobileNoMatch = item.mobileNo
        .toString()
        .includes(lowerCaseKeyword);

      return isNameMatch || isTokenIdMatch || isMobileNoMatch;
    });

    setFilteredTokens(searchedData);
  };

  const handleStatusChange = async (data, status) => {
    //  console.log(data, "data " + " stat: ", status);
    try {
      const requestPayload = {
        pubInfo: { sessionId: section },
        request: {
          busiParams: {
            ...data,
            status,
            endTime:
              data?.status == "2" ? moment().format("HH:mm") : data?.endTime,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };
      await axios.post(
        BASE_URL + update_User_Token,
        JSON.stringify(requestPayload),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      fetchTokens(getTakenbyDate);
    } catch (error) {
      // swal("Error", error.message, "error");
      // console.log("error form handle ", error);
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };
  const handleOPDChange = async (data, value) => {
    try {
      const requestPayload = {
        pubInfo: { sessionId: section },
        request: {
          busiParams: {
            ...data,
            opd: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };
      await axios.post(
        BASE_URL + update_User_Token,
        JSON.stringify(requestPayload),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      fetchTokens(getTakenbyDate);
    } catch (error) {
      // swal("Error", error.message, "error");
      // console.log("error form handle ", error);
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const handleSubmit = () => {
    fetchTokens();
  };

  const handleUpdateToken = async (tokenData) => {
    // console.log(tokenData);
    try {
      const requestPayload = {
        pubInfo: { sessionId: section },
        request: {
          busiParams: {
            ...tokenData,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };
      const response = await axios.post(
        BASE_URL + update_User_Token,
        JSON.stringify(requestPayload),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        // swal("Success", "User token updated successfully", "success");
        setShowToast(true);
        setColorStatus(1);
        setToastMsg("Token updated successfully");
        fetchTokens(getTakenbyDate);
      }
    } catch (error) {
      // swal("Error", error.message, "error");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const handleVisit = async (data) => {
    if (data?.status == 1) {
      try {
        const requestPayload = {
          pubInfo: { sessionId: section },
          request: {
            busiParams: {
              ...data,
              status: 2,
              endTime: moment().format("HH:mm"),
              type: 0,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        };
        let res = await axios.post(
          BASE_URL + update_User_Token,
          JSON.stringify(requestPayload),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        navigate("/reportMain", { state: data });
        // console.log("success", res)
      } catch (error) {
        navigate("/reportMain", { state: data });
      }
    } else {
      navigate("/reportMain", { state: data });
    }
  };

  const dateByFetchToken = async () => {
    setUserTokenList([]);
    setFilteredTokens([]);
    try {
      const requestPayload = {
        pubInfo: { sessionId: section },
        request: {
          busiParams: {
            startDate: moment(getTakenbyDate).format("YYYY-MM-DD"),
            endDate: getTakenbyEndDate
              ? moment(getTakenbyEndDate).format("YYYY-MM-DD")
              : "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };
      // console.log(requestPayload);
      const response = await axios.post(
        `${BASE_URL}${get_User_Token_By_DateRange}`,
        requestPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );
      const res = response.data?.respData?.respMsg?.userTokenList || [];
      // console.log(res, " ktoken");

      if (userRole?.includes(ROLE)) {
        setUserTokenList(res);
        setFilteredTokens(res);
      } else if (userRole?.includes(ROLE_LAB)) {
        const labTokens = res?.filter((item) => item?.labTest == true);
        setFilteredTokens(labTokens);
        setUserTokenList(labTokens);
      }
    } catch (error) {
      // swal("Error", error.message, "error");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const getPaymentModes = (data) => {
    const modes = ["upi", "cash", "card", "other"].filter((mode) => data[mode]);
    return modes.length ? modes.join(", ") : "";
  };

  const calculateTotalAmount = () => {
    return filteredTokens?.reduce(
      (total, token) => total + (parseFloat(token.amount) || 0),
      0
    );
  };

  useEffect(() => {
    setTotalAmount(calculateTotalAmount());
  }, [filteredTokens]);

  // const [fileExportType, setFileExportType] = useState("csv");

  // const handleExport = () => {
  //   const data = filteredTokens;
  //   const fileName = "jivdaniExportReport";

  //   let exportType;
  //   if (fileExportType === "csv") {
  //     exportType = exportFromJSON.types.csv;
  //   } else if (fileExportType === "xls") {
  //     exportType = exportFromJSON.types.xls;
  //   } else if (fileExportType === "xml") {
  //     exportType = exportFromJSON.types.xml;
  //   }

  //   exportFromJSON({ data, fileName, exportType });

  //   // waching this vide :https://youtu.be/_UYxBT_Qwt0?si=C26j6aK5aKZ_xxOb
  //   // https://www.npmjs.com/package/export-from-json
  // };

  let rupee = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const jsonToCSV = (json) => {
    const header = Object.keys(json[0]).join(",");
    const rows = json.map((row) => Object.values(row).join(","));

    // Calculate number of columns
    const numColumns = header.split(",").length;

    // Create padding for centering text
    const totalPaddingCells = numColumns - 1;
    const leftPaddingCells = Math.floor(totalPaddingCells / 2);
    const rightPaddingCells = totalPaddingCells - leftPaddingCells;

    const centeredText = `${",".repeat(
      leftPaddingCells
    )}"Jivdani Hospital"${",".repeat(rightPaddingCells)}`;

    const csvContent = [centeredText, header, ...rows].join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = jsonToCSV(filteredTokens);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container" style={{ marginTop: 120, overflowX: "hidden" }}>
      <ScrollToTopOnMount />
      {!isLab && (
        <div
          className="card text-center bg-primary"
          style={{ overflow: "revert-layer" }}
        >
          <div className="card-header text-light">Total Revenue</div>
          <div
            className="card-body d-flex justify-content-center align-items-center"
            style={{ height: 200 }}
          >
            <div className="col   d-block d-lg-none">
              <h1
                className="card-title text-light fw-bold"
                style={{ fontSize: 50 }}
              >
                {rupee.format(totalAmount || 0)}
              </h1>
            </div>
            <div className="d-none d-lg-block ">
              <h1
                className="card-title text-light fw-bold"
                style={{ fontSize: 150 }}
              >
                {rupee.format(totalAmount || 0)}
              </h1>
            </div>
            {/* <h1
            className="card-title text-light fw-bold"
            style={{
              fontSize: "3rem", // Default font size
              fontWeight: "bolder",
              '@media (max-width: 768px)': {
                fontSize: "2rem" // Font size for small screens
              },
              '@media (max-width: 576px)': {
                fontSize: "1.5rem" // Font size for extra small screens
              }
            }}
          >
            {rupee.format(totalAmount)}
          </h1> */}
          </div>
          <div className="card-footer">
            <div className="row justify-content-between">
              <div className="col-7 text-end text-light fs-3 fw-bold">
                Jivdani Hospital
              </div>
              <div className="col-5 text-end" style={{ maxWidth: 150 }}>
                <div className="input-group">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={downloadCSV}
                  >
                    Export File
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row d-flex align-items-center">
        {!isLab && (
          <div className="col-lg-6      col-xl-7 ">
            <div className="row row-cols-auto " style={{ gap: "1px" }}>
              {[
                "All",
                "In Queue",
                "In Progress",
                "Complete",
                "Pending",
                "Visit Pad",
                "Lab",
              ].map((label, index) => (
                <div className="col mt-2" key={index}>
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id={`option${index + 1}`}
                    autoComplete="off"
                    checked={activeFilter === index}
                    onChange={() => Datafilter(index)} // Use onChange for radio buttons
                  />
                  <label
                    className={`btn ${
                      activeFilter === index
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    htmlFor={`option${index + 1}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="col-md-12  col-lg-6 col-xl-5   mt-2  ">
          <div className="row row-cols-3    align-items-center">
            <div className="col">
              <div className="form-floating">
                <input
                  name="startdate"
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    setGetTakenbyDate(e.target.value);
                    sessionStorage.setItem("getTakenbyDate", e.target.value);
                  }}
                  value={getTakenbyDate}
                  required
                  aria-required="true"
                  style={{ outline: "none", boxShadow: "none" }}
                />
                <label htmlFor="floatingInputGrid">startDate</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <input
                  name="endtdate"
                  type="date"
                  className="form-control"
                  onChange={(e) => setGetTakenbyEndDate(e.target.value)}
                  value={getTakenbyEndDate}
                  required
                  aria-required="true"
                  style={{ outline: "none", boxShadow: "none" }}
                />
                <label htmlFor="floatingInputGrid">endDate</label>
              </div>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dateByFetchToken()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {isLab && (
          <div className="col-auto ms-auto  ">
            <div className="input-group">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={downloadCSV}
              >
                Export File
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="row text-center">
        <div className="border-success">
          <div className="row border-success d-flex align-items-center my-2">
            <div className="col-sm-9">
              <div className="bg-transparent border-success h5 ms-2 d-flex align-items-start">
                All Token ({filteredTokens?.length})
              </div>
            </div>
            <div className="col-sm-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder=" token no./name"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  style={{ outline: "none", boxShadow: "none" }}
                  onChange={TokenSearching}
                />
                <button
                  className="btn btn-outline-primary border border-start-0 bg-white"
                  type="button"
                  id="button-addon2"
                >
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                </button>
              </div>
            </div>
          </div>

          {!isLab && (
            <div className="input-group w-full">
              <select
                className="form-select "
                id="inputGroupSelect04"
                aria-label="Example select with button addon "
                name="option"
                onChange={handleOptionChange}
                style={{ outline: "none", boxShadow: "none" }}
              >
                <option value={Tokan_Type_Generate}>List Of Token</option>
                <option value={Tokan_Type_RequestCall}>
                  Request Call Back
                </option>
              </select>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}

          <div style={{ overflowX: "auto", marginBottom: "100px" }}>
            <table className="table table-hover border p-2 ">
              <thead style={{ verticalAlign: "center" }}>
                <tr>
                  <th scope="col">Token No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile No.</th>
                  <th scope="col">{isLab ? "Age " : "Payment Mode"}</th>
                  {isLab ? (
                    <th scope="col">Gender</th>
                  ) : (
                    <th scope="col">
                      {isReqToken === Tokan_Type_RequestCall
                        ? "Purpose"
                        : "Amount"}
                    </th>
                  )}
                  {isLab && <th scope="col">Lab</th>}
                  {showEdit && (
                    <>
                      <th scope="col">Current Status</th>
                      <th scope="col">Waited</th>
                      <th scope="col"> Print</th>
                      <th scope="col"> Visit</th>
                      <th scope="col"> View Tests</th>{" "}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredTokens?.map((data, index) => (
                  <tr
                    key={index}
                    style={{ verticalAlign: "middle", cursor: "pointer" }}
                    onClick={
                      SuprAdmin
                        ? () => setEditToken(data)
                        : showEdit
                        ? data?.status == "3"
                          ? () => {
                              setShowToast(true);
                              setColorStatus(3);
                              setToastMsg("Access Denied");
                            }
                          : () => setEditToken(data)
                        : () => {
                            setShowToast(true);
                            setColorStatus(3);
                            setToastMsg("Access Denied");
                          }
                    }
                  >
                    <th scope="row">{data?.token}</th>
                    <td>{data?.customerId?.name}</td>
                    <td>{data?.customerId?.mobileNo}</td>
                    {isLab ? (
                      <td>
                        {data?.customerId?.age > 0
                          ? data?.customerId?.age + " Y"
                          : ""}
                      </td>
                    ) : (
                      <td>{data.paymentMode + getPaymentModes(data)}</td>
                    )}
                    {isLab ? (
                      <td>
                        {data?.customerId?.age
                          ? getGenFormate(data?.customerId?.gender)
                          : ""}
                      </td>
                    ) : (
                      <td>
                        {isReqToken === Tokan_Type_RequestCall
                          ? data.comment
                          : rupee.format(data?.amount)}
                      </td>
                    )}
                    {isLab && (
                      <td style={{ justifyItems: "center" }}>
                        {" "}
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => setLabToken(data)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          View Tests
                          <FontAwesomeIcon
                            icon="fa-square-arrow-up-right"
                            className="btn-outline-primary border-0 ms-2"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        </button>
                      </td>
                    )}
                    {showEdit && (
                      <>
                        <td onClick={(e) => e.stopPropagation()}>
                          <Dropwoun
                            token={data}
                            showEdit={showEdit}
                            handleStatusChange={handleStatusChange}
                            Size={"t"}
                          />
                          {/* <div className="mt-2">
                            <OPDSelectionDropdown
                              token={data}
                              showEdit={showEdit}
                              handleOPDChange={handleOPDChange}
                              Size={"t"}
                            />
                          </div> */}
                        </td>
                        <td>
                          <p style={{ fontFamily: "sans-serif", margin: 0 }}>
                            {data?.date == currentDate
                              ? calculateTimeDifference(
                                  data?.startTime,
                                  data?.endTime,
                                  data?.date
                                )
                              : data?.date !== currentDate && !data?.endTime
                              ? "_ _"
                              : calculateTimeDifference(
                                  data?.startTime,
                                  data?.endTime,
                                  data?.date
                                )}
                          </p>
                        </td>

                        <td>
                          {data.visitPad &&
                            (data?.status === 4 || data?.status === 3) && (
                              <button
                                type="button"
                                className="btn  btn-outline-primary"
                                //  onClick={()=>navigate('/reportMain')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate("/Report", {
                                    state: {
                                      receivedData: data,
                                      selectedLanguages: "en",
                                      ageGender: {
                                        age: data?.customerId?.age,
                                        gender: data?.customerId?.gender,
                                      },
                                    },
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-print"
                                  className=" btn-outline-primary  "
                                />
                                {/* Print */}
                              </button>
                            )}
                        </td>

                        <td style={{ justifyItems: "center" }}>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleVisit(data);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Visit pad
                            <FontAwesomeIcon
                              icon="fa-square-arrow-up-right"
                              className="btn-outline-primary border-0 ms-2"
                              style={{ marginLeft: "0.5rem" }}
                            />
                          </button>
                        </td>
                        <td style={{ justifyItems: "center" }}>
                          {data?.labTest && (
                            <button
                              type="button"
                              className="btn justify-items-center align-items-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLabToken(data);
                              }}
                              style={{
                                backgroundColor: "#FCFF6F",
                                height: "38px",
                                width: "80px",
                                borderColor: "lightgray",
                              }}
                            >
                              Lab
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredTokens?.length == 0 && (
              <p
                style={{ height: 60 }}
                className="alignitems-center text-dark text-center fw-semibold fs-6 "
              >
                No Tokens Found
                <FontAwesomeIcon
                  className="text-primary ms-3"
                  icon="fa-solid fa-circle-nodes"
                />
              </p>
            )}
          </div>
        </div>
        <ToastFist
          showToast={showToast}
          setShowToast={setShowToast}
          title="Indication"
          message={toastMsg}
          duration="Just now"
          status={colorStatus}
        />
      </div>

      {labToken && (
        <ModalLabTest
          recevedTokens={labToken}
          onClose={() => setLabToken(null)}
        />
      )}
      {editToken && (
        <TokenModal
          token={editToken}
          onUpdate={handleUpdateToken}
          onClose={() => setEditToken(null)}
        />
      )}
    </div>
  );
}

export default TokenPage;

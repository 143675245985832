

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import ProductList from "../products/ProductList";


import { Link } from "react-router-dom";



import {
  ROLE,
  USER_ROLE,
 
  you_tube_view,
  
} from "../Constant";

function Video({ listData }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);
  // const bannerItemRemoved = listData.splice(0, 1);
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  useEffect(() => {
    if (listData.length > 0) {
      setCatListData(listData);
    } else {
      setCatListData(catItemList);
    }
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  });
  return (
    <>
      {catListData?.map((item) => (
        <div>
          {item?.viewType === you_tube_view ? (
            <div className="container mt-5 pt-5">
          
{/*                
                <Headline
                  item={item}
                  navigate={navigate}
                  showEdit={showEdit}
              
                /> */}
               
                  <div className="row row-cols-md-2  row-cols-lg-2  row-cols-xl-3   ">
                  {item?.items?.map((it) => (
                    <div>
                      <div
                        class="card rounded-4 border border-dark    "
                        style={{
                          minWidth: 290,
                          minHeight: 200,
                          width: 350,
                          height: 400,
                        }}
                      >
              
                        <iframe
                          title="{it?.iconURL}"
                          height="220"
                          src={it?.iconURL}
                         
                          allowFullScreen
                          className="rounded-4 d-block"
                          loading="lazy"
                        ></iframe>

                        <div class="card-body ">
                          <h5 class="card-title">{it?.title}</h5>
                          <p class="card-text">{it?.subTitle}</p>
                          {showEdit ? (
                            <Link
                              to={"/productdetail/" + it?.itemId}
                              onClick={() =>
                                localStorage.setItem(
                                  "myProduct",
                                  JSON.stringify(it)
                                )
                              }
                            >
                              <button class="btn btn-primary ">
                                Video Edit
                              </button>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
           
            </div>
          ) : null}
        </div>
      ))}
      ;
    </>
  );
}
export default Video;

// import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import ProductList from "../products/ProductList";
import ProductListCard from "./ProductListCard";
import ProductGridCard from "./ProductGridCard";
import BannerCard from "./BannerCard";
import ProductFullWidthCard from "./ProductFullWidthCard";
import TextCardView from "./TextCardView";
import YouTubecard from "./YouTubecard";

import CustomerReview from "../components/CustomerReview";
import images from "../Images/doctor.png";
import {
  ROLE,
  USER_ROLE,
  TOP_BANNER,
  TOP_PRODUCT,
  CARD_VIEW,
  ABOUT_US_VIEW,
  BRAND_HEADING_VIEW,
  full_width_card_view,
  you_tube_view,
  CARD_VIEW_WITH_TEXT,
  CUSTOMER_REVIEW,
  you_tube_view01,
  Article_view,
  article_view_01,
  FULL_BODY_CHEAKUP,
  CART_COUNT,
} from "../Constant";
// import ProductItemCart from "../landing/ProductItemCart";

import BrandHeadingView from "./BrandHeadingView";
import InstagramCarousel from "../social/InstagramPosts";
import ArticleCard from "./ArticleCard";
import FullBodyCheakup from "./fullBodyCheckp/FullBodyCheakup";
import CashLessPolicy from "./CashLessPolicy";
import Ourservices from "./ourservices/Ourservices";
import NoteBoard from "../noteboard/NoteBoard";
import Topproduct from "../minicard/Topproduct";
import Minicard from "../minicard/Minicard";
import { increment, handleLoggin } from "../actions";
import { useDispatch, useSelector } from "react-redux";

function Landing({ listData, testData, topproduct }) {
  const one = TOP_PRODUCT;
  const navigate = useNavigate();
  const editMode = useSelector((state) => state.editMode.editMode);
  // const bannerItemRemoved = listData.splice(0, 1);
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  useEffect(() => {
    if (listData.length > 0) {
      setCatListData(listData);
    } else if (catItemList.length > 0) {
      setCatListData(catItemList);
    } else {
      setCatListData(null);
    }
  }, []);

  const data = [
    {
      Image: { images },
      nama: "hello",
      bgcolor: "red",
      Imaseclick: "/",
    },
  ];

  return (
    <div style={{ minHeight: "100vh" }}>
      <ScrollToTopOnMount />
      {/* <> */}
      {catListData?.map((item, index) => (
        // <div>
        //   {
        //     <>
        //       {
        <>
          {item?.viewType === TOP_BANNER ? (
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="  justify-contet-center  mt-5 pt-5">
                <Topproduct
                  item={topproduct}
                  rowItem={3}
                  key={item.id}
                  navigate={navigate}
                  showEdit={editMode}
                />
                {/* <Minicard item={item} rowItem={3} /> */}

                <Ourservices />
                <BannerCard
                  key={item.id}
                  item={item}
                  navigate={navigate}
                  showEdit={editMode}
                  nama={TOP_BANNER}
                />
                <NoteBoard
                  Text={"Get active and be a healthy weight."}
                  Text_02={" Do not get thirsty,Do not skip breakfast"}
                />
              </div>
            </div>
          ) : (
            <div div className="container">
              <div>
                {item?.viewType === BRAND_HEADING_VIEW ? (
                  <div>
                    <BrandHeadingView
                      key={item.id}
                      item={item}
                      showEdit={editMode}
                      nama={BRAND_HEADING_VIEW}
                    />
                  </div>
                ) : (
                  <div>
                    {item?.viewType === TOP_PRODUCT ? (
                      <div>
                        <ProductGridCard
                          key={item.id}
                          item={item}
                          navigate={navigate}
                          showEdit={editMode}
                          nama={TOP_PRODUCT}
                        />
                      </div>
                    ) : (
                      <div>
                        <div>
                          {item?.viewType === CARD_VIEW ? (
                            <div>
                              <ProductListCard
                                key={item.id}
                                item={item}
                                navigate={navigate}
                                showEdit={editMode}
                                nama={CARD_VIEW}
                              />
                            </div>
                          ) : (
                            <div>
                              {item?.viewType === "TestByCategroy" ? (
                                // ABOUT_US_VIEW
                                // <div>
                                //   {showEdit ? (
                                //     <div>
                                //       <AboutUs
                                //         key={item.id}
                                //         item={item}
                                //         navigate={navigate}
                                //         showEdit={showEdit}
                                //         nama={ABOUT_US_VIEW}
                                //       />
                                //     </div>
                                //   ) : (
                                //     <div></div>
                                //   )}
                                // </div>
                                <div>
                                  <Minicard
                                    item={testData}
                                    Text={item}
                                    rowItem={3}
                                    key={item.id}
                                    navigate={navigate}
                                    showEdit={editMode}
                                    nama={CARD_VIEW_WITH_TEXT}
                                  />
                                </div>
                              ) : (
                                <div>
                                  {item?.viewType === full_width_card_view ? (
                                    <div>
                                      <ProductFullWidthCard
                                        item={item}
                                        showEdit={editMode}
                                        nama={full_width_card_view}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {item?.viewType === you_tube_view ? (
                                        <div>
                                          <YouTubecard
                                            key={item.id}
                                            item={item}
                                            navigate={navigate}
                                            showEdit={editMode}
                                            nama={you_tube_view}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {item?.viewType ===
                                          CARD_VIEW_WITH_TEXT ? (
                                            <div>
                                              <TextCardView
                                                key={item.id}
                                                item={item}
                                                navigate={navigate}
                                                showEdit={editMode}
                                                nama={CARD_VIEW_WITH_TEXT}
                                              />
                                            </div>
                                          ) : (
                                            <div>
                                              {item?.viewType ===
                                              CUSTOMER_REVIEW ? (
                                                <div>
                                                  <CustomerReview
                                                    key={item.id}
                                                    item={item}
                                                    navigate={navigate}
                                                    showEdit={editMode}
                                                    nama={CUSTOMER_REVIEW}
                                                  />
                                                </div>
                                              ) : (
                                                <div>
                                                  {item?.viewType ===
                                                  you_tube_view01 ? (
                                                    <div>
                                                      {editMode ? (
                                                        <YouTubecard
                                                          key={item.id}
                                                          item={item}
                                                          navigate={navigate}
                                                          showEdit={editMode}
                                                          nama={you_tube_view01}
                                                        />
                                                      ) : null}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {item?.viewType ===
                                                      article_view_01 ? (
                                                        <div>
                                                          <NoteBoard
                                                            Text={
                                                              "Limit alcohol use. Alcohol is high in calories and may worsen health conditions common among older adults."
                                                            }
                                                          />
                                                          <CashLessPolicy />
                                                          <ProductGridCard
                                                            key={item.id}
                                                            item={item}
                                                            navigate={navigate}
                                                            showEdit={editMode}
                                                            nama={
                                                              article_view_01
                                                            }
                                                          />

                                                          {/* <InstagramCarousel/> */}
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {item?.viewType ===
                                                          "InstagramPosts" ? (
                                                            // <div>
                                                            //   <InstagramCarousel
                                                            //     key={item.id}
                                                            //     item={item}
                                                            //     navigate={
                                                            //       navigate
                                                            //     }
                                                            //     showEdit={
                                                            //       showEdit
                                                            //     }
                                                            //   />
                                                            // </div>
                                                            ""
                                                          ) : (
                                                            <div>
                                                              {item?.viewType ===
                                                              FULL_BODY_CHEAKUP ? (
                                                                <div>
                                                                  <FullBodyCheakup
                                                                    key={
                                                                      item.id
                                                                    }
                                                                    item={item}
                                                                    navigate={
                                                                      navigate
                                                                    }
                                                                    showEdit={
                                                                      editMode
                                                                    }
                                                                    nama={
                                                                      FULL_BODY_CHEAKUP
                                                                    }
                                                                  />
                                                                  {/* <CashLessPolicy /> */}
                                                                </div>
                                                              ) : (
                                                                <div> </div>
                                                              )}
                                                            </div>
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
        //       }
        //     </>
        //   }
        // </div>
      ))}
      {/* </> */}
    </div>
  );
}

export default Landing;

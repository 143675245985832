

export const setUserToken = (userToken) => ({
    type: 'SET_USER_TOKEN',
    payload: userToken,
});

const initialState = {
    userToken: null,
};

const userTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_TOKEN':
            return {
                ...state,
                userToken: action.payload,
            };
        default:
            return state;
    }
};

export default userTokenReducer;

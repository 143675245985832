import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  ROLE,
  USER_ROLE,
  you_tube_view,
  BACKGROUND,
  CARD_VIEW,
  USER_ROLE_CHANGES_BUTTON,
  ROLEDOCTOR,
  ROLE_LAB,
  SUPER_ADNIM,
} from "../Constant";
import { useState, useEffect } from "react";
import Heade from "./Heade.css";
import LanguageSelector from "./LanguageSelector";
import { Roles } from "../booking/Booking";
import CustomSwitch from "./CustomeSwitch";

const Header = () => {
  const counter = useSelector((state) => state.counter);
  const isLogged = useSelector((state) => state.isLogged);
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState();
  const [isLab, setIsLab] = useState(false);
  const Location = useLocation();

  useEffect(() => {
    try {
      const userRole = localStorage.getItem(USER_ROLE);
      if (userRole?.includes(ROLE) || userRole?.includes(SUPER_ADNIM)) {
        setShowEdit(true);
      } else if (
        userRole?.includes(ROLE_LAB) ||
        userRole?.includes(SUPER_ADNIM)
      ) {
        setIsLab(true);
      } else {
        setShowEdit(false);
        setShowEdit(false);
      }
    } catch (e) {}
  }, [isLogged, showEdit]);

  const RenderMenu = () => {
    // console.log(Location);

    if (isLogged) {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0  d-none d-md-block">
            <li className="nav-item pb-3">
              <Link
                // to="/logout"
                className="nav-link text-light "
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/logout")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-right-from-bracket"
                  className="me-3 text-light"
                  style={{ width: "16px", height: "16px" }}
                />
                Logout
              </Link>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0 ">
            <li className="nav-item pb-3">
              <Link
                // to="/login"
                className="nav-link text-light "
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/login")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user"
                  className="text-light me-3"
                  style={{ width: "16px", height: "16px" }}
                />
                Login
              </Link>
            </li>
          </ul>
        </>
      );
    }
  };

  const [scrollingText, setScrollingText] = useState([
    "Appointment",
    "Dr. Booking",
    "Lab Test",
  ]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % scrollingText.length);
    }, 2000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, [scrollingText.length]);

  const [hoveredLink, setHoveredLink] = useState(null);
  const isActive = (path, currentPath) => path === currentPath;

  const commonStyle = {
    textDecoration: "none",
    color: "#fff",
    paddingBottom: "1px",
    marginBottom: "0",
    transition: "all 0.1s ease", // Smooth transition for hover effects
  };

  // Function to get underline style and add margin on hover
  const getUnderlineStyle = (path, currentPath, isHovered) => ({
    ...commonStyle,
    borderBottom:
      isActive(path, currentPath) || isHovered ? "3px solid #cedff6" : "none",
    color: isActive(path, currentPath) || isHovered ? "#cedff6" : "#fff",
    marginLeft: "10px",
    marginRight: "10px",
  });

  return (
    <nav
      className="navbar navbar-dark  border-bottom fixed-top  bg-primary pt-3"
      // style={{
      //   background: BACKGROUND,
      // }}
    >
      <div className="container ">
        <Link
          className="navbar-brand text-center d-flex justify-content-center align-items-center"
          style={{ width: Location.pathname === "/" ? "" : "28px" }}
          onClick={() => navigate(-1)}
        >
          {Location.pathname === "/" ? (
            ""
          ) : (
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="text-center"
            />
          )}
        </Link>
        <div className="col overflow-auto  d-block d-lg-none ">
          <Link className="navbar-brand  " to="/">
            <img src="./../favicon.ico" class="img" alt="appLogo"></img>
            {/* <span> </span> */}

            {/* <small className="d-none d-md-block"> JIVDANI HOSPITAL</small> */}
            <small className="   fw-bold">JIVDANI HOSPITAL </small>
          </Link>
          {/* <LanguageSelector/> */}
        </div>
        <div className="d-none d-lg-block overflow-auto ">
          <Link className="navbar-brand  " to="/">
            <img src="./../favicon.ico" class="img" alt="appLogo"></img>
            <span> </span>

            {/* <small className="d-none d-md-block"> JIVDANI HOSPITAL</small> */}
            <small className="   fw-bold">JIVDANI HOSPITAL </small>
          </Link>
          {/* <LanguageSelector/> */}
        </div>

        <div
          className="d-none d-md-flex mx-auto align-items-center   position-sticky  "
          style={{ width: "42%" }}
        >
          <Link
            className="input-group mx-auto "
            style={{ width: "100%", textDecoration: "none" }}
            to="/products"
          >
            {
              // Location.pathname === "/products" ? null :
              <div className="input-group text-primary">
                <input
                  className="form-control"
                  type="search"
                  placeholder={"Search  " + scrollingText[activeIndex]}
                  aria-label="Search"
                  size="32"
                  style={{ outline: "none", boxShadow: "none" }}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary  border-0 border-start-0 bg-white"
                >
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    className="text-primary"
                  />
                </button>
              </div>
            }
          </Link>
        </div>
        <div className="  px-0 d-none d-sm-block ">
          {isLogged ? null : (
            <button
              className="btn text-light  "
              onClick={() => navigate("/login")}
            >
              Login
            </button>
          )}
        </div>

        {showEdit && <CustomSwitch />}

        <button
          to="/addtocart"
          className="btn text-light  me-1  position-relative  d-none d-md-block "
          replace
          // onClick={changeNav}
          onClick={() => navigate("/addtocart")}
        >
          <FontAwesomeIcon
            icon={["fas", "shopping-cart"]}
            data-bs-placement="bottom"
            title="Cart"
          />
          <span class="position-absolute top-0 mt-1 translate-middle badge rounded-circle bg-secondary">
            {counter == 0 ? "" : counter}
          </span>
        </button>

        <div className="">
          <FontAwesomeIcon
            icon="fa-regular fa-user"
            type="button"
            className="  navbar-toggler "
            //  style={{height:30 ,width:13}}
            //border border-light rounded-5
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          />
        </div>

        <div
          className="offcanvas offcanvas-end  bg-primary "
          // style={{
          //   background: BACKGROUND,

          // }}
          tabIndex={-1}
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5
              className="offcanvas-title text-light"
              id="offcanvasDarkNavbarLabel"
            >
              <img src="./../favicon.ico" class="img me-2" alt="appLogo"></img>
              JIVDANI HOSPITAL
            </h5>

            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>

          <div className="offcanvas-bodyn " style={{ overflowY: "scroll" }}>
            <ul className="navbar-nav justify-content-end flex-grow-1 px-4 ">
              {isLogged && (
                <li className="nav-item text-truncate ">
                  <Link
                    // to="/profile"
                    className="nav-link text-light "
                    replace
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => navigate("/profile")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-user"
                      className="me-3"
                      style={{ width: "16px", height: "16px" }}
                    />
                    Profile
                  </Link>
                </li>
              )}
              <li className="nav-item text-truncate ">
                <Link
                  className="nav-link text-light "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/orderhistory")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-clock-rotate-left"
                    className="me-3"
                    style={{ width: "16px", height: "16px" }}
                  />
                  Booking History
                </Link>
              </li>

              <li className="nav-item text-truncate">
                <Link
                  className="nav-link  text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/tokenbooking")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-list-ol"
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  View all Tokens
                </Link>
              </li>

              {showEdit ? (
                <li className="nav-item text-truncate">
                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/createToken")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-list-ol"
                      flip
                      className="me-3 text-light"
                      style={{ width: "16px", height: "16px" }}
                    />
                    Create Token
                  </Link>

                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/tokenPage")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      // flip
                      className="me-3 text-light"
                      style={{ width: "16px", height: "16px" }}
                    />
                    Token report
                  </Link>
                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/drsignup")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-user-doctor"
                      className="me-3 text-light"
                      style={{ width: "16px", height: "16px" }}
                    />
                    Create new doctor
                  </Link>

                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/mastermedicine")}
                  >
                    <FontAwesomeIcon
                      icon="fas fa-file-medical"
                      className="me-3 text-light"
                      style={{ width: "16px", height: "16px" }}
                    />
                    {""}
                    Master
                  </Link>
                </li>
              ) : null}

              {isLab && (
                <li className="nav-item text-truncate">
                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/tokenPage")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      className="me-3 text-light"
                      style={{ width: "16px", height: "16px" }}
                    />
                    Lab report
                  </Link>
                </li>
              )}
              <li className="nav-item text-truncate">
                <Link
                  // to="/contact"
                  className="nav-link  text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/contact")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  Contact Us
                </Link>

                <Link
                  // to="/aboutgridcard"
                  className="nav-link text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/aboutus")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-address-card"
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  About Us
                </Link>

                <Link
                  // to="/addtocart"
                  className="nav-link text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  onClick={() => navigate("/addtocart")}
                >
                  <FontAwesomeIcon
                    icon={["fas", "shopping-cart"]}
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  Cart{" "}
                  <span class=" ">
                    {counter == 0 ? "" : " (" + counter + ")"}
                  </span>
                </Link>

                <Link
                  to="/video"
                  className="nav-link text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  onClick={() =>
                    navigate(
                      "/video"
                      //localStorage.setItem("myDataview", you_tube_view)
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-video"
                    flip
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  Videos
                </Link>

                <Link
                  // to="/article"
                  className="nav-link text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  // onClick={() => navigate("/article")}
                  onClick={() => navigate("/reportMain")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-newspaper"
                    className="me-3 text-light"
                    style={{ width: "16px", height: "16px" }}
                  />
                  Article
                </Link>
              </li>

              <li className="nav-item">
                <RenderMenu />
              </li>
            </ul>
          </div>
        </div>

        {/* BOTTOME SCREEN TAB */}
        <div className="col-12 d-none d-md-block  text-center fw-semibold text-white mt-3 ms-4 ">
          <Link
            to="/"
            style={getUnderlineStyle(
              "/",
              Location.pathname,
              hoveredLink === "/"
            )}
            onMouseEnter={() => setHoveredLink("/")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Home</small>
          </Link>
          {showEdit && (
            <Link
              to="/createToken"
              style={getUnderlineStyle(
                "/createToken",
                Location.pathname,
                hoveredLink === "/createToken"
              )}
              onMouseEnter={() => setHoveredLink("/createToken")}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <small className="mx-2 text-white">Creat Token</small>
            </Link>
          )}
          <Link
            to="/tokenbooking"
            style={getUnderlineStyle(
              "/tokenbooking",
              Location.pathname,
              hoveredLink === "/tokenbooking"
            )}
            onMouseEnter={() => setHoveredLink("/tokenbooking")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">View All Tokens</small>
          </Link>

          <Link
            to="/itemsviewmore"
            style={getUnderlineStyle(
              "/itemsviewmore",
              Location.pathname,
              hoveredLink === "/itemsviewmore"
            )}
            onMouseEnter={() => setHoveredLink("/itemsviewmore")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Doctors</small>
          </Link>
          <Link
            to="/orderhistory"
            style={getUnderlineStyle(
              "/orderhistory",
              Location.pathname,
              hoveredLink === "/orderhistory"
            )}
            onMouseEnter={() => setHoveredLink("/orderhistory")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Booking History</small>
          </Link>
          <Link
            to="/aboutus"
            style={getUnderlineStyle(
              "/aboutus",
              Location.pathname,
              hoveredLink === "/aboutus"
            )}
            onMouseEnter={() => setHoveredLink("/aboutus")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">About Us</small>
          </Link>

          <Link
            to="/contact"
            style={getUnderlineStyle(
              "/contact",
              Location.pathname,
              hoveredLink === "/contact"
            )}
            onMouseEnter={() => setHoveredLink("/contact")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Contact Us</small>
          </Link>
        </div>

        <div className="col-12  d-flex  d-block d-md-none  gap-1 text-center fw-semibold text-light ">
          <div className="input-group mx-auto ">
            <span
              className="input-group-text text-primary"
              id="inputGroup-sizing-default"
            >
              <FontAwesomeIcon
                icon={["fas", "search"]}
                data-bs-placement="bottom"
                title="Search"
              />
            </span>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              placeholder="Appointment, Dr.Booking, Lab test"
            />
          </div>

          <button
            to="/addtocart"
            className="btn text-light    position-relative border border-light p-2 rounded "
            replace
            // onClick={changeNav}
            onClick={() => navigate("/addtocart")}
          >
            <FontAwesomeIcon
              icon={["fas", "shopping-cart"]}
              data-bs-placement="bottom"
              title="Cart"
            />

            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              {counter === 0 ? "" : counter}
            </span>
          </button>
        </div>
      </div>
    </nav>

    // </div>
  );
};

export default Header;

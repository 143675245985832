import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  BASE_URL,
  SESSION_ID,
  USER_INFO,
  USER_LOGIN,
  CUSTOMER_ID,
  GET_LOGOUT,
  DEBUG_LOG_ENABLED,
  CART_COUNT,
  USER_ROLE,
  USER_ROLE_CHANGES_BUTTON,
} from "../Constant";
import { useDispatch } from "react-redux";
import { handleLoggin, increment } from "../actions";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

export default function Logout() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logoutCustomer = () => {
    // localStorage.setItem(CUSTOMER_ID, "");
    // localStorage.setItem(ACCESS_TOKEN, "");
    // //localStorage.setItem(SESSION_ID,"");
    // localStorage.setItem(USER_LOGIN, "");
    // localStorage.setItem(CART_COUNT, "");
    // localStorage.setItem(USER_ROLE, "");
    // localStorage.setItem(USER_ROLE_CHANGES_BUTTON, "");
    // localStorage.removeItem("showEdit");
    // localStorage.removeItem(CUSTOMER_ID);
    // localStorage.removeItem(ACCESS_TOKEN);
    // localStorage.removeItem(USER_LOGIN);
    // localStorage.removeItem(CART_COUNT);
    // localStorage.removeItem(USER_INFO);
    // localStorage.removeItem(USER_ROLE);
    // localStorage.removeItem(USER_ROLE_CHANGES_BUTTON);
    // localStorage.removeItem("is_Login");
    const sessionId = localStorage.getItem(SESSION_ID); // Save the session ID value
    localStorage.clear(); // Clear all local storage items
    if (sessionId) {
      localStorage.setItem(SESSION_ID, sessionId); // Restore the session ID
    }

    dispatch(handleLoggin(false));
    dispatch(increment(0));
    navigate("/");
  };
  const backToHome = (e) => {
    navigate("/");
  };

  return (
    <div className="align-items-center   py-5  " style={{ marginTop: 100 }}>
      <ScrollToTopOnMount />
      <div className="d-flex flex-column  align-items-center">
        <div className="card p-5">
          <div>
            <div className="text-center pt -5">Do you want to logout ? </div>
            <div class="d-flex justify-content-center pt-5">
              <button
                type="button"
                class="btn btn-primary btn-lg me-2"
                onClick={logoutCustomer}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary btn-lg"
                onClick={backToHome}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useState } from "react";
import {
  SESSION_ID,
  BASE_URL,
  GENERATEUSERTOKEN,
  Tokan_Type_RequestCall,
  Tokan_Status_In_Queue,
  Request_Call_Msg,
  IMG_BASE_URL,
  BACKGROUND,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ROLE, USER_ROLE } from "../Constant";
import swal from "sweetalert";

// import FooterStyel from "./FooterStyel.css";
// import Alert from "../alert.js/Alert";

function Footer(props) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  });

  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    address: "",
    date: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const savaUser = (e) => {
    e.preventDefault();
    if (user.name === "" || user.mobileNo === "") {
      // props.showAlert("Please entery username and mobail no", "success");
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Danger:"
        >
          <use class="#exclamation-triangle-fill" />
        </svg>
        <div>Please entery username and mobail no., success</div>
      </div>;

      //  alert("Please entery username and mobail no ");
      //  showAlert("Please entery username and mobail no", "success");
    } else {
      userData(user.name, user.mobileNo);
    }
  };

  const section = localStorage.getItem(SESSION_ID);

  const userData = async (name, mobileNo) => {
    try {
      const userData = JSON.stringify({
        pubInfo: {
          sessionId:localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: name,
            date: "" + moment(new Date()).format("YYYY-MM-DD"),
            mobileNo: mobileNo,
            address: "",
            type: Tokan_Type_RequestCall,
            status: Tokan_Status_In_Queue,
            comment: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + GENERATEUSERTOKEN, userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });
      swal(Request_Call_Msg);



      
    } catch (err) {
     // swal(err);
      console.log(err);
    }
  };

  return (
    <div className="vstack mt-auto   ">
      <footer
        className="mt-auto py-5  bg-primary "
        // style={{ background: BACKGROUND }}
      >
        <div className="container d-flex justify-content-center">
          <div className="row g-3">
            <div className="col-md-6 col-lg-4 d-none d-md-block">
              <h5 className="text-light">Contact us</h5>
              <div className="vstack gap-1">
                <p className="mb-2 text-light text-opacity-75 small">
                  Jivdani hospital, Dombivali Multispeciality Hospital | ICU,
                  General, Surgical, Maternity, Panchkarma
                </p>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fas", "map-marker"]}
                    className="mt-1"
                  />
                  <div>
                    Kalyan - Shilphata Road Desai Village Sagarli Gaon,
                    Maharashtra 421204 India{" "}
                  </div>
                </small>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="mt-1"
                  />
                  <div>info@jivdanihospital.com</div>
                </small>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="mt-1"
                  />
                  <div>jivdanihospital1@gmail.com</div>
                </small>

                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon icon={["fas", "phone"]} className="mt-1" />
                  <div>(+91) 093217 75857</div>
                </small>
              </div>
            </div>

            <div className="col-md-6 col-lg-2 d-none d-md-block">
              <h5 className="text-light">Information</h5>
              <div className="vstack small gap-2">
                <Link to="/aboutus">
                  <a className="footer-link text-light">About us</a>
                </Link>
                <Link to="/contact">
                  <a className="footer-link text-light ">Contact us</a>
                </Link>
                <Link to="/termsandconditions">
                  <a className="footer-link text-light ">Terms & Conditions </a>
                </Link>
                <Link to="/Privacypolicy">
                  <a className="footer-link text-light "> Privacy Policy</a>
                </Link>
                <Link to="/refundPolicy">
                  <a className="footer-link text-light ">Refund Policy</a>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 d-none d-md-block">
              <h5 className="text-light">Account</h5>
              <div className="vstack small gap-2">
                <Link to="/login">
                  <a className="footer-link text-light">User Login</a>
                </Link>
                <Link to="/signup">
                  <a className="footer-link text-light ">User Register</a>
                </Link>
                <Link to="/profile">
                  <a className="footer-link text-light ">Account Setting</a>
                </Link>
                <Link to="/orderhistory">
                  <a className="footer-link text-light ">My Orders</a>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <h5 className="text-light text-center text-md-start">
                Request Call Back
              </h5>
              {/* <div className="text-light text-opacity-75 mb-3 small text-center text-md-start">
                Subscribe for promotions and wonderful events
              </div> */}
              <form className="hstack needs-validation  novalidate gap-2 justify-content-center justify-content-md-start mb-3">
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={user.name}
                    onChange={(e) => handleChange(e)}
                    className="form-control mt-1"
                    required
                  />
                  {/* <input
                    type="name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={user.name}
                    className="form-control border border-primary "
                    placeholder="Enter name"
                    required
                    aria-required="true"
                    id="validationDefaultUsername"
                    aria-describedby="inputGroupPrepend2"
                  /> */}
                  <input
                    name="mobileNo"
                    onChange={(e) => handleChange(e)}
                    value={user.mobileNo}
                    className="form-control border border-primary my-3"
                    placeholder="Mobile No"
                    required
                    aria-required="true"
                    type="tel"
                    size="10"
                    minlength="10"
                    maxlength="12"
                  />
                  <button
                    className="btn form-control btn-warning  text-light px-5"
                    type="submit"
                    onClick={(e) => savaUser(e)}
                  >
                    Request call
                  </button>
                  <br></br>
                  {showEdit ? (
                    <Link
                      className="btn form-control btn-warning  text-light mt-2 px-5"
                      to="tokenPage"
                    >
                      View calls
                    </Link>
                  ) : null}
                </div>
                <div></div>
              </form>

              {/* <div className="hstack gap-2 justify-content-center justify-content-md-start">
                <a href="#" className="text-decoration-none">
                  <img
                    src="/images/apple-app-store-badge.svg"
                    alt=""
                  />
                </a>
                <a href="#" className="text-decoration-none">
                  <img src="/images/google-play-badge.svg" alt="" />
                </a>
              </div> */}
            </div>
          </div>
          <iframe
            title="Google Map"
            src="https://maps.google.com/maps?q=Jivdani+hospital%2C+Dombivali+Multispeciality+Hospital+%7C+ICU%2C+General%2C+Surgical%2C+Maternity%2C+Panchkarma%2C+Kalyan+-+Shilphata+Road%2C+Desai+Village%2C+Sagarli+Gaon%2C+Maharashtra&t=k&z=15&ie=UTF8&iwloc=&output=embed"
            width="45%"
            height="250"
            allowFullScreen="true"
          ></iframe>
        </div>
      </footer>
      <div className="row  mx-0  bg-primary "
      style={{opacity:'0.9'}}
      // style={{ background: BACKGROUND }}
       >
        <div className="col-sm-9">
          <footer className="py-4 pb-3  ">
            <div className="container d-flex">
              <span className="text-light text-opacity-50 my-auto">
                &copy; {new Date().getFullYear()} Jivdani hospital, Dombivali
                Multispeciality Hospital | ICU, General, Surgical, Maternity,
                Panchkarma{" "}
              </span>
              <div className="ms-auto hstack gap-4">
                <a
                  href="https://www.instagram.com/jivdanihospital/"
                  className="ms-auto link-light"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} size="lg" />
                </a>
                <a
                  href="https://www.instagram.com/jivdanihospital/"
                  className="ms-auto link-light"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" />
                </a>
                <a
                  href="https://www.instagram.com/jivdanihospital/"
                  className="ms-auto link-light"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
                </a>
              </div>
            </div>
          </footer>
        </div>
        <div
          className="col-sm-3    my-2 d-none d-md-block  "
          //style={{ background: BACKGROUND }}
        >
          <div className="row py-4 pb-3 ">
            <div className="col-sm-6">
              <Link
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.hospital.jivdani"
                  )
                }
              >
                <img
                  src={IMG_BASE_URL + "socialmedia/GOOGLRPLAY.png"}
                  className="img-fluid rounded float-start"
                  alt="Image 1"
                />
              </Link>
            </div>
            <div className="col-sm-6">
              <Link
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/in/app/jivdanihospital/id6462515043"
                  )
                }
              >
                <img
                  src={IMG_BASE_URL + "socialmedia/IOSSTORE.png"}
                  className="img-fluid rounded float-start"
                  alt="Image 2"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

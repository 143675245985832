import React from 'react'

export default function   Privacypolicy() {
  return (
    <div className="container">
      <div class="row g-0  mt-5 pt-4">
        <div class="col p-3 md-5">
          <div class="card-body">
            <h1> Privacy Policy:</h1>
            <p class="fs-10 lh-base bold">
              Thank you for using JivdaniHospital. Your privacy is important to
              us. This privacy policy gives an account of our privacy practices
              with respect to your information.
            </p>
            <p class="fs-10">
              <h4> Scope:: </h4>
              JivdaniHospital Website (here in after referred to as “Website”,
              “we”, “our”, “us”) is committed to protecting and respecting your
              privacy This policy (“Privacy Policy”), together with the Terms
              and Conditions of the Website hosted at website outlines the
              Website’s privacy practices regarding the collection, use and
              safeguard of your information through the Website and the services
              offered thereupon. A feature in JivdaniHospital that enables users
              to store and share their health data with doctors. Your use of
              registration on the Website and submission of personal information
              (or if you are a minor, the submission of your personal
              information either by your parents (or your guardian) or by you
              with the permission of your parents (or guardian)) to the Website
              will be deemed as your acceptance of this Privacy Policy. This
              document is an electronic record in terms of the Information
              Technology Act, 2000 (as amended / re-enacted) ("IT Act") and
              rules thereunder, and is published in accordance with the
              provisions of Rule 3 <br></br>(1) of the Information Technology
              (Intermediary Guidelines and Digital Media Ethics Code) Rules,
              2021, which mandates for publishing of rules and regulations,
              privacy policy and terms of use for access or usage of Website.
              This electronic record is generated by a computer system and does
              not require any physical or digital signatures. This Privacy
              Policy is published in compliance with, inter alia: i. Section 43A
              of the Information Technology Act, 2000; Regulation 4 of the
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules, 2011
              (the “SPDI Rules”); and Regulation 3(1) of the Information
              Technology (Intermediary Guidelines and Digital Media Ethics Code)
              Rules, 2021.
            </p>
            <p class="fs-10">
              <h4> Changes to the Privacy Policy: </h4>
              Changes to the Privacy Policy: Your information will be treated in
              accordance with applicable laws, and regulations. This Privacy
              Policy is subject to modification as per the Company’s discretion
              including as a consequence to changes in the business, legal and
              regulatory requirements and such modifications will be updated
              online. We will notify you whenever any change to the Privacy
              Policy is made. You are encouraged to periodically visit this page
              to review the Privacy Policy and any changes to it. Your continued
              use of the Website after such modifications will be deemed as your
              acceptance to the modified terms. If the modifications to the
              terms are not agreeable to you, you may opt to not use the
              Website.
            </p>
            <p class="fs-10">
              <h4> Third Party Services: </h4> Patient information and medical
              Please note that your internet service provider, operating system
              provider, third party service providers defined as Partner Service
              Providers under the Terms and Conditions of the Website, websites
              that you access, third party operators may also collect, use and
              share information about you and your usage. We cannot control how
              these third parties collect, use, share or secure this
              information. For information about third-party privacy practices,
              please refer to their respective privacy policies. The Website may
              include links to other websites/Websites and other content that
              links to third party websites. Such websites/Websites are governed
              by their respective privacy policies, which are out of the
              Website’s control. Use of any information you provide while using
              a third party website or Website through the Website, is governed
              by the privacy policy of the operator of the website / Website /
              operator you are using/ visiting. That policy may differ from that
              of the Website. If you can't find the privacy policy of any of
              such websites /Website via a link from its website's homepage, you
              should contact the relevant website /Website operator directly for
              more information.
              <br></br>
              The Website implements reasonable security practices and
              procedures and has a comprehensive documented information security
              programme and information security policies that contain
              managerial, technical, operational and physical security control
              measures that are commensurate with respect to the information
              being collected and the nature of Website’s business. The
              reasonable security practices and procedures implemented by the
              Website include but are not limited to: encrypting data when it is
              on the move using industry standard practices, keeping all the
              data within private cloud, regularly changing production keys and
              password, secure and very limited access to all production
              servers, performing regular security updates on our servers and
              more. In spite of implementing such measures, if an user incurs a
              loss due to hacking, phishing or other such techniques, then
              Website shall not be held liable for the same.
            </p>
            <p class="fs-10">
              <h4> Personal Information we collect: </h4>
              Personal information is defined as information that can be used to
              identify you and may include details such as your name, age,
              height, weight, gender, contact information, products and services
              you are interested in or require more information about. Insofar
              as sensitive personal information is concerned, it will carry the
              meaning as may be defined by applicable laws from time to time.
              Your Personal Information as well as your sensitive personal
              information (from your use of ‘HealthPatri’) is maintained by
              Website in electronic form on its equipment, and on the equipment
              of its employees. Website takes all necessary precautions to
              protect your personal information both online and off-line, and
              implements reasonable security practices and measures including
              certain managerial, technical, operational and physical security
              control measures that are commensurate with respect to the
              information being collected and the nature of Website’s business.
              <br></br>
              Part of the functionality of the Website is assisting the patients
              to access information relating to them. Website may, therefore,
              retain and submit all such records to the relevant individuals.
              Notwithstanding the above, Website is not responsible for the
              confidentiality, security or distribution of your personal
              information by our partners and third parties outside the scope of
              our agreement with such partners and third parties. Further,
              Website shall not be responsible for any breach of security or for
              any actions of any third parties or events that are beyond the
              reasonable control of Website including, acts of government,
              computer hacking, unauthorised access to computer data and storage
              device, computer crashes, breach of security and encryption, poor
              quality of Internet service or telephone service of the User etc.
              The following is the manner in which we collect, use, share and
              retain personal information:
            </p>
            <p class="fs-10">
              <h4>  Collection: </h4>
              You agree that the Website may collect such personal information
              or sensitive personal information, whenever relevant, to help
              providing you with information and to provide any services you
              have requested or authorized to be performed. You also consent to
              the collection of certain personal information that is necessary
              for provision of services to you. This includes any information
              that you voluntarily choose to provide to us through the Website,
              website, email, during interaction with us on call or chat and
              through other modes of communication. For the purposes of this
              Website and the features that it offers, we may further collect
              personal information and/or sensitive personal data or
              information, which you have provided to any of our group
              companies, affiliates, or subsidiaries.
            </p>
            <p class="fs-10">
              <h4> Usage: </h4> The Website may use this information either to
              (i) validate and process your request for the services of the
              Website or information; (ii) to improve the quality of the
              Website; (iii) to assist you to determine which services best meet
              your needs; or (iv) to facilitate our internal business
              operations, including the fulfilment of any legal and regulatory
              requirements; or (v) to provide you with recommendation about
              services you may be interested in, based on your use of the
              Website; or (vi) to provide you with marketing communications and
              advertising that the Website believe may be of interest of you; or
              (vii) contacting you for offering new products or services; or
              (viii) contacting End-Users for taking product and Service
              feedback, or (ix) creating platform specific unique health ids or
              (x) to communicate with you (regarding the services you wish to
              avail) through call or chat and other modes of electronic
              communication such as WhatsApp. You agree that the Website may
              collect such personal information and/or sensitive personal
              information, whenever relevant, to help providing you with
              information and to complete any transaction or provide any product
              or services you have requested or authorized. You also consent to
              the collection of certain personal information in the course of
              you applying for the products and/or services.
            </p>
          
            <p class="fs-10">
              <h4> Retention: </h4> The information so collected shall be
              retained only for a limited duration necessary to fulfil the
              purposes outlined here in unless a longer retention period is
              required or permitted by law and only for the purposes defined
              above. Once the purpose is achieved, all personal information is
              deleted in a safe and secure mode.
            </p>
            <p class="fs-10">
              <h4> Non-Personal Information: </h4>
              Non-personal information is defined as any data that does not
              personally identify you and may include unique system or hardware
              identifiers, system or Website software, and peripherals. Any
              non-personal information, when clubbed with personal information
              shall be treated as personal information. The following is the
              manner in which we collect, use, share and retain non-personal
              information:
            </p>
            <p class="fs-10">
              <h4> Collection: </h4>
              You agree that the Website may collect diagnostic, technical,
              usage related information, for the usage purposes described below.
            </p>
            <p class="fs-10">
              <h4> Usage: </h4>
              The above mentioned information is gathered periodically to
              provide and improve the Website and services therein, facilitate
              the provision of Website support and other services to you and to
              verify compliance with the terms of this Policy.
            </p>
            <p class="fs-10">
              <h4> Sharing: </h4>
              To enable the Website partners to improve their software, hardware
              and services designed for use with the Website, we may also
              provide any such partner with a subset of diagnostic information
              that is relevant to that partner’s software, hardware and/or
              services, as long as the diagnostic information is in a form that
              does not personally identify you
            </p>
            <p class="fs-10">
              <h4> Retention: </h4>
              The information so collected shall be retained only for a limited
              duration necessary to fulfil the purposes outlined herein unless a
              longer retention period is required or permitted by law and only
              for the purposes defined above. Once the purpose is achieved, all
              information is deleted in a safe and secure mode.
            </p>
            <p class="fs-10">
              <h3> Location Services </h3>
              You are responsible for maintaining the accuracy of the
              information you submit to us, such as your contact information
              provided as part of account registration. If, your personal
              information changes, you may correct, delete inaccuracies, or
              amend information by making the change wherever possible. We will
              retain your information for as long as your account with the
              Services is active and as needed to provide you the Services. We
              shall not retain such information for longer than is required for
              the purposes for which the information may lawfully be used or is
              otherwise required under any other law for the time being in
              force. After a period of time, your data may be anonymized and
              aggregated, and then may be held by us as long as necessary for us
              to provide our Services effectively. Due to the communications
              standards on the internet, when you visit the Website, our systems
              automatically receives the URL of the site from which anyone
              visits. Website also receives the Internet Protocol (IP) address
              of your computer (or the proxy server a user used to access the
              World Wide Web), your computer operating system and type of web
              browser you are using, email patterns, as well as the name of your
              ISP. This information is used to analyse overall trends to help
              Website improve its Service. The linkage between your IP address
              and your personally identifiable information is not shared with
              third parties.
            </p>
            <p class="fs-10">
              <h4> Retention: </h4>
              The Website strives hard to keep its records updated and accurate
              with your latest information. You shall be responsible to ensure
              that the information or data you provide from time to time is and
              shall be correct, current and updated and you have all the rights,
              permissions and consents to provide such information or data
            </p>
            <h4> Queries and Complaints: </h4>
            <p class="fs-10">
              We are committed to protect your personal information collected
              and processed by us and look forward to your continued support for
              the same. In case of any feedback or concern regarding protection
              of your personal information, or any privacy-related feedback or
              concerns you may contact:
            </p>
            <p class="fs-10 fw-bold">
              Jivdani hospital,
              <br /> Dombivali Multispeciality Hospital |<br /> ICU, General,
              Surgical, Maternity,
              <br /> Panchkarma Kalyan - Shilphata Road Desai <br /> Village
              Sagarli Gaon, Maharashtra 421204 India
              <br />
              jivdanihospital1@gmail.com <br /> (+91) 093217 75857
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

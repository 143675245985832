import React from "react";
import { IMG_BASE_URL } from "../Constant";
import { Link } from "react-router-dom";

function Topproduct({ item, rowItem, navigate, showEdit }) {
  const imgwidth = 350;

  const data = (item, nama) => {


    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    <div className="container text-center mt-5">
      <div
        className={`row row-cols-3 row-cols-sm-${(rowItem) =>
          3 ? 1 : rowItem - 1} row-cols-md-${rowItem}`}
      >
        <>
          {item.map((item, index) => (
            <Link
              to={
                item.viewType === "card_view_01"
                  ? "/itemsviewmore"
                  : item?.viewType === "Digital X-ray"
                    ? "/Xray"
                    : "/itemsviewmore"
              }
              style={{ textDecoration: "none" }}
              onClick={() => data(item, item.viewType)}
            >
              <div className="col me-2 ">
                <div style={{ maxWidth: imgwidth }}>
                  <div
                    className="row   rounded-4"
                    style={{
                      background:
                        index == 1
                          ? "rgba(212, 247, 250, 0.6)"
                          : index == 2
                            ? "rgba(255, 227, 185, 0.6)"
                            : "rgba(224, 243, 170, 0.6)"
                    }}
                  >
                    <div className="col-md-4 text-center bg-muted  ">
                      <img
                        src={IMG_BASE_URL + item.viewMoreTitleID}
                        className="img-fluid rounded-start p-3"

                        alt="..."
                        color="#008D96"
                        style={{
                          filter:
                            index == 1
                              ? `invert(38%) sepia(87%) saturate(2837%) hue-rotate(158deg) brightness(87%) contrast(101%)`
                              : index == 2
                                ? `invert(44%) sepia(89%) saturate(2726%) hue-rotate(22deg) brightness(97%) contrast(101%)`
                                : `invert(35%) sepia(99%) saturate(1568%) hue-rotate(53deg) brightness(93%) contrast(101%)`,

                          maxWidth: "100px",/* Set your desired width */
                          maxHeight: "100px",/* Set your desired height */
                          objectFit: "cover", /* Maintains aspect ratio and crops to fill */
                          objectPosition: "center" /* Centers the image */
                        }}
                      />
                    </div>

                    <div
                      className={
                        "col-md-8 d-none d-md-block text-center my-auto ps-2"
                      }
                    >
                      <div className="card-body d-flex">
                        <p
                          className="card-text   text-wrap text-center fw-bold align-items-center   justify-content-center  "
                          style={{
                            color:
                              index == 1
                                ? "#008D96"
                                : index == 2
                                  ? "#C27500"
                                  : "#628500",
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {item.title ? (
                  <p
                    className="text-center fw-bold mt-3 d-block d-md-none"
                    style={{ color: "#008D96" }}
                  >
                    {item.title}
                  </p>
                ) : null}
              </div>
            </Link>
          ))}
        </>
      </div>
    </div>
  );
}

export default Topproduct;

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { increment } from "../actions";
// import { useDispatch } from "react-redux";
import { useState } from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import swal from "sweetalert";
import {
  SESSION_ID,
  BASE_URL,
  AddCategoryItem,
  ACCESS_TOKEN,
  DeleteItem,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
export default function DoctorCatItem() {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };

  const catData = localStorage.getItem("catItem");
  const itemData = JSON.parse(catData);
  //   const dispatch = useDispatch();

  const navigate = useNavigate();

  //   const [loading, setLoading] = useState(true);
  const [categoryItem, setCategoryItem] = useState({
    itemId: itemData.itemId,
    catId: itemData.catId,
    orderNo: itemData.catId,
    title: itemData.title,
    titleID: itemData.titleID,
    subTitle: itemData.subTitle,
    subTitleID: itemData.subTitleID,
    viewMoreTitle: itemData.viewMoreTitle,
    viewMoreTitleID: itemData.viewMoreTitleID,
    viewType: itemData.viewType,
    iconURL: itemData.iconURL,
    price: itemData.price,
    mobileNo: itemData.mobileNo,
    emailId: itemData.emailId,
    doctorId: itemData.doctorId,
    catType: itemData.catType,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCategoryItem({ ...categoryItem, [e.target.name]: value });
  };

  const saveCategoryItem = (e) => {
    e.preventDefault();

    if (
      categoryItem.catId === "" ||
      categoryItem.catId === undefined ||
      categoryItem.title === "" ||
      categoryItem.title === undefined ||
      categoryItem.titleID === "" ||
      categoryItem.subTitle === "" ||
      categoryItem.subTitleID === "" ||
      categoryItem.viewType === "" ||
      categoryItem.iconURL === "" ||
      categoryItem.price === "" ||
      categoryItem.mobileNo === "" ||
      categoryItem.emailId === "" ||
      categoryItem.doctorId === "" ||
      categoryItem.catType === "" ||
      categoryItem.viewMoreTitle === ""
    ) {
      swal(" Please enter all fields ");
    } else {
      const categoryItemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            itemId: categoryItem.itemId,
            catId: categoryItem.catId,
            orderNo: categoryItem.orderNo,
            title: categoryItem.title,
            titleID: categoryItem.titleID,
            subTitle: categoryItem.subTitle,
            subTitleID: categoryItem.subTitleID,
            viewMoreTitle: categoryItem.viewMoreTitle,
            viewMoreTitleID: categoryItem.viewMoreTitleID,
            viewType: categoryItem.viewType,
            iconURL: categoryItem.iconURL,
            price: categoryItem.price,
            mobileNo: categoryItem.mobileNo,
            emailId: categoryItem.emailId,
            doctorId: categoryItem.doctorId,
            catType: categoryItem.catType,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      axios
        .post(BASE_URL + AddCategoryItem, categoryItemData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        })
        .then((response) => {
          swal(response.data.respData.message);
          navigate("/");
          if (DEBUG_LOG_ENABLED) {
            // console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            swal("data : " + err);
          }
        //  console.log(err, "Dd");
        });
    }
  };

  const removeCatItemData = async () => {
    try {
      const removeCat = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            itemId: itemData.itemId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + DeleteItem, removeCat, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      swal(response.data.respData.message);
      navigate("/");
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = () => {
    removeCatItemData();
  };

  return (
    <>
      <div className="align-items-center container  py-5 px-xl-5 mx-auto mt-5">
        <div className="wrapper">
          <div className="col-lg-9">
            <div className="d-flex flex-column  align-items-center">
              <ScrollToTopOnMount />
              <form className="Auth-form ">
                <div className="Auth-form-content  ">
                  <h4 className="Auth-form-title">Doctor category item</h4>
                  <div className="form-group mt-3">
                    <label>ItemId</label>
                    <input
                      type="number"
                      name="itemId"
                      value={categoryItem.itemId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>CatId</label>
                    <input
                      type="number"
                      name="catId"
                      value={categoryItem.catId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>orderNo</label>
                    <input
                      type="number"
                      name="orderNo"
                      value={categoryItem.orderNo}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-1">
                    <label>Name</label>
                    <input
                      type="text"
                      name="title"
                      value={categoryItem.title}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Specialization</label>
                    <input
                      type="text"
                      name="titleID"
                      value={categoryItem.titleID}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Qualification detaiis</label>
                    <input
                      type="text"
                      name="subTitle"
                      value={categoryItem.subTitle}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Experience</label>
                    <input
                      type="text"
                      name="subTitleID"
                      value={categoryItem.subTitleID}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>ViewType</label>
                    <input
                      type="text"
                      name="viewType"
                      value={categoryItem.viewType}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Languages known :</label>
                    <input
                      type="text"
                      name="viewMoreTitle"
                      value={categoryItem?.viewMoreTitle}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Icon URL</label>
                    <input
                      type="text"
                      name="iconURL"
                      value={categoryItem.iconURL}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />

                    <div className="form-group mt-3">
                      <label>Image Uplod</label>
                      <input
                        type="file"
                        class="form-control"
                        id="inputGroupFile02"
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label>Consultation fees in INR (₹)</label>
                    <input
                      type="numbar"
                      name="price"
                      value={categoryItem.price}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>mobileNo : </label>
                    <input
                      type="numbar"
                      name="mobileNo"
                      value={categoryItem.mobileNo}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>emailId : </label>
                    <input
                      type="numbar"
                      name="emailId"
                      value={categoryItem.emailId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      CatType (0-customer, 1-Doctor, 2-Lab, 3-Ambulance, 4-Room)
                    </label>
                    <input
                      type="number"
                      name="catType"
                      value={categoryItem.catType}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>doctorId</label>
                    <input
                      type="number"
                      name="doctorId"
                      value={categoryItem.doctorId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>

                  <div className="d-grid gap-2 mt-3">
                    <button
                      onClick={saveCategoryItem}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <button
                      // onClick={saveCategoryItem}
                      type="submit"
                      className="btn btn-primary   mt-3"
                      onClick={removeItem}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
                    </button>
                  </div>

                  {/* <p className="text-center mt-2">
            Forgot <a href="#" onClick={()=> navigate("/login")}>password?</a>
          </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

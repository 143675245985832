import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useMemo, useState } from "react";
import getApiHelper from "../report/ApiHelpar/getApiHelper";
import {
  deleteLabTestRecord,
  getLabTestDataByToken,
  GOOGLE_DRIVE_DOWNLOAD_URL,
  GOOGLE_DRIVE_PREVIEW_URL,
  saveLabTestRecord,
  uploadFileToDrive,
} from "../report/visitConstant/ApiConstant";
import moment from "moment";
import { Color } from "../report/visitConstant/Color";
import ToastFist from "../report/Toast/ToastFist";
import apiHelper from "../report/ApiHelpar/apiHelper";
import {
  ACCESS_TOKEN,
  BASE_URL,
  ERROR_MSG,
  GOOGLE_DRIVE_URL,
  ROLE,
  ROLE_LAB,
  SUPER_ADNIM,
  USER_ROLE,
} from "../Constant";
import axios from "axios";
import { Roles } from "../booking/Booking";

import PreviewSection from "./PreviewSection";
import ReportDownloadSection from "./ReportDownloadSection";
// React.memo(({ recevedTokens }) => {
const ModalLabTest = ({ recevedTokens, onClose }) => {
  const [activeTests, setActiveTests] = useState("all");
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLab, setIsLab] = useState(false);
  const [isSuprAdmin, setIsSuprAdmin] = useState(false);
  const userRole = useMemo(() => localStorage.getItem(USER_ROLE), []);
  // const userRole = useMemo(() => (Roles))

  const [colorStatus, setColorStatus] = useState(3);
  const [tests, setTests] = useState({
    all: [],
    pending: [],
    complete: [],
    ongoing: [],
    deactive: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingS, setIsLoadingS] = useState(false);
  const [isLoadingD, setIsLoadingD] = useState(false);

  const [activeFilter, setActiveFilter] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [DeactiveId, setDeactiveId] = useState(null);
  const [activeItem, setActiveItem] = useState({});
  const [isPaymentActive, setIsPaymentActive] = useState(true);
  const [error, setError] = useState("");

  const [labTestRecord, setLabTestRecord] = useState({
    labTestRecordID: activeItem?.labTestRecord?.labTestRecordID,
    testDate:
      activeItem?.labTestRecord?.testDate ||
      moment(recevedTokens?.date).format("YYYY-MM-DD"),
    time: activeItem?.labTestRecord?.time || moment().format("HH:mm"),
    paymentStatus: false,
    note: activeItem?.labTestRecord?.note || "",
    reportUrl: activeItem?.labTestRecord?.reportUrl || "",
    cash: activeItem?.labTestRecord?.cash || "",
    upi: activeItem?.labTestRecord?.upi || "",
    card: activeItem?.labTestRecord?.card || "",
    other: activeItem?.labTestRecord?.other || "",
    discount: activeItem?.labTestRecord?.discount || "",
    amount: activeItem?.labTestRecord?.amount,
    ipd: false,
    free: false,
  });

  const [downloadUrl, setDownloadUrl] = useState("");
  const [file, setFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  useEffect(() => {
    // console.log("callng fetx")
    if (onClose) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "6px";

      if (userRole?.includes(SUPER_ADNIM)) {
        setIsAdmin(true);
        setIsSuprAdmin(true);
      } else if (userRole?.includes(ROLE)) {
        setIsAdmin(true);
      } else if (userRole?.includes(ROLE_LAB)) {
        setIsLab(true);
      }
      fetchTests();
    } else {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    };
  }, []);

  const calculateTotalAmount = () => {
    return tests[activeTests]?.reduce(
      (total, token) =>
        total +
        (parseFloat(
          token?.active
            ? token?.labTestRecord?.amount > 0
              ? token?.labTestRecord?.amount
              : token?.labTestsAndImaging?.price
            : !token?.active && token?.labTestsAndImaging?.price
        ) || 0),
      0
    );
  };

  const handleCloseOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      onClose();
    }
  };
  // console.log("lah ", recevedTokens)
  const enableError = () => {
    setToastMsg(ERROR_MSG);
    setShowToast(true);
    setColorStatus(0);
  };
  const enableSuccess = (msg = "") => {
    setToastMsg(msg);
    setShowToast(true);
    setColorStatus(1);
  };

  const fetchTests = async () => {
    try {
      const data = {
        tokenId: recevedTokens?.tokenId,
      };
      const response = await getApiHelper(getLabTestDataByToken, data);
      const res = response == false ? response : response?.respData?.respMsg;
      // console.log("response", res);
      let sortedTests;
      if (res) {
        sortedTests = {
          all: Array.isArray(res) ? res : [],
          pending: Array.isArray(res)
            ? res?.filter(
                (item) =>
                  item?.active &&
                  !item?.labTestRecord?.paymentStatus &&
                  !item?.labTestRecord?.reportUrl
              )
            : [],
          ongoing: Array.isArray(res)
            ? res?.filter(
                (item) =>
                  item?.active &&
                  item?.labTestRecord?.paymentStatus &&
                  !item?.labTestRecord?.reportUrl
              )
            : [],
          complete: Array.isArray(res)
            ? res?.filter(
                (item) =>
                  item?.active &&
                  item?.labTestRecord?.paymentStatus &&
                  item?.labTestRecord?.reportUrl
              )
            : [],
          deactive: Array.isArray(res)
            ? res?.filter((item) => !item?.active)
            : [],
        };
      } else {
        sortedTests = {
          all: [],
          pending: [],
          complete: [],
          ongoing: [],
        };
        enableError();
      }
      setTests(sortedTests);

      setIsLoading(false);
    } catch (error) {
      enableError();
      setShowToast(true);
      setIsLoading(false);
    }
  };

  const removeTests = async () => {
    setIsLoadingD(true);
    try {
      const data = {
        labTestsAndImagingDataID: DeactiveId,
      };
      const response = await apiHelper(deleteLabTestRecord, data);
      const res = response == false ? response : response?.respData?.respMsg;
      if (res) {
        fetchTests();
        setDeactiveId(null);
      } else {
        enableError();
      }
      setIsLoadingD(false);
    } catch (error) {
      enableError();
      setIsLoadingD(false);
    }
  };

  useEffect(() => {
    setTotalAmount(calculateTotalAmount());
  }, [tests, activeTests]);

  const Datafilter = (status) => {
    setActiveFilter(status);
    const statusMapping = {
      1: "pending",
      2: "ongoing",
      3: "complete",
      4: "deactive",
    };

    const selectedTest = statusMapping[status] || "all";
    setActiveTests(selectedTest);
  };

  const toggleAccordion = (item) => {
    setLabTestRecord({
      labTestRecordID: item?.labTestRecord?.labTestRecordID || null,
      testDate:
        item?.labTestRecord?.testDate ||
        moment(recevedTokens?.date).format("YYYY-MM-DD"),
      time: item?.labTestRecord?.time || moment().format("HH:mm"),
      paymentStatus: item?.labTestRecord?.paymentStatus,
      note: item?.labTestRecord?.note || "",
      reportUrl: item?.labTestRecord?.reportUrl || "",
      cash: item?.labTestRecord?.cash || "",
      upi: item?.labTestRecord?.upi || "",
      card: item?.labTestRecord?.card || "",
      other: item?.labTestRecord?.other || "",
      discount: item?.labTestRecord?.discount || "",
      amount: item?.labTestRecord?.amount,
      ipd: item?.labTestRecord?.ipd,
      free: item?.labTestRecord?.free,
    });

    const fileId = item?.labTestRecord?.reportUrl || "";
    const previewableUrl = fileId
      ? GOOGLE_DRIVE_PREVIEW_URL.replace("{fileId}", fileId)
      : "";
    const downloadableUrl = fileId
      ? GOOGLE_DRIVE_DOWNLOAD_URL.replace("{fileId}", fileId)
      : "";
    setPreviewUrl(previewableUrl);
    setDownloadUrl(downloadableUrl);
    setFile("");
    setActiveItem((previtem) =>
      previtem?.labTestsAndImaging?.id === item?.labTestsAndImaging?.id
        ? {}
        : item
    );
  };

  const toggleDelte = (id) => {
    setDeactiveId((prevId) => (prevId === id ? null : id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const paymentFields = ["cash", "upi", "card", "other"];

    // Allow only numeric values for payment fields
    const numericValue = paymentFields.includes(name)
      ? value === ""
        ? ""
        : Number(value)
      : value;

    // Update state for the specific field
    setLabTestRecord((prevData) => {
      const updatedRecord = {
        ...prevData,
        [name]: numericValue, // Update the current field
      };
      const activePrice = activeItem?.labTestsAndImaging?.price;
      const totalAmount =
        (Number(updatedRecord.cash) || 0) +
        (Number(updatedRecord.upi) || 0) +
        (Number(updatedRecord.card) || 0) +
        (Number(updatedRecord.other) || 0);

      if (totalAmount > activePrice) {
        setError(
          `Total payment amount exceeds allowed limit of ${activePrice}.`
        );
        return prevData; // Do not update state if total exceeds
      }

      // Check if discount exceeds activePrice
      if (name === "discount" && Number(numericValue) > activePrice) {
        setError(
          `Discount amount cannot exceed the maximum allowed amount of ${activePrice}.`
        );
        return prevData; // Do not update state if discount exceeds
      }

      setError("");

      if (name == "discount") {
        return {
          ...updatedRecord,
          amount: activePrice - Number(updatedRecord?.discount) || 0,
          cash: "",
          upi: "",
          card: "",
          other: "",
          // discount: activePrice - totalAmount !== 0 ? activePrice - totalAmount : '',
          ipd: false,
          free: false,
          paymentStatus: false,
          // paymentStatus: totalAmount > 0 || updatedRecord.ipd || updatedRecord.free, // Update payment status based on amount
        };
      } else if (paymentFields.includes(name)) {
        return {
          ...updatedRecord,
          amount: totalAmount,
          discount:
            activePrice - totalAmount > 0 &&
            activePrice - totalAmount !== activePrice
              ? activePrice - totalAmount
              : "",
          ipd: false,
          free: false,
          paymentStatus:
            totalAmount > 0 || updatedRecord.ipd || updatedRecord.free, // Update payment status based on amount
        };
      }

      return updatedRecord; // If not a payment field, just return updated record
    });
  };

  const handleSelectionChange = (selection) => {
    if (selection == "Payment") {
      setIsPaymentActive(true);
      setLabTestRecord((prevData) => ({
        ...prevData,
        ipd: false,
        free: false,
      }));
    } else {
      setIsPaymentActive(false);
      setLabTestRecord((prevData) => ({
        ...prevData,
        paymentStatus: true,
        ipd: selection === "IPD",
        free: selection === "FREE",
        amount: "",
        cash: "",
        upi: "",
        card: "",
        other: "",
        discount: "",
      }));
    }
  };

  const handleSubmit = async () => {
    setIsLoadingS(true);
    try {
      //get reportUrl
      let reportUrl;
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("mobileNo", recevedTokens?.mobileNo);
        formData.append("tokenId", recevedTokens?.tokenId);
        formData.append("labTestId", activeItem?.labTestsAndImaging?.id);
        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}:`, value);
        // }

        const uploadResponse = await axios.post(
          `${BASE_URL}${uploadFileToDrive}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
            },
          }
        );
        const url = uploadResponse?.data?.url;
        reportUrl = url.split("id=")[1];
        // console.log("id: ", uploadResponse)
        if (!reportUrl) {
          enableError("Failed to upload file");
          setIsLoadingS(false);
          return;
        }
      }

      // save api
      const data = {
        ...labTestRecord,
        reportUrl,
      };
      const response = await apiHelper(saveLabTestRecord, data);
      const res = response == false ? response : response?.respData?.respMsg;
      // console.log("response", res);
      if (res == "done") {
        enableError();
      } else if (res) {
        fetchTests();
        enableSuccess("RECORD SAVED");
        setActiveItem({});
      } else {
        enableError();
      }
      setIsLoadingS(false);
    } catch (error) {
      enableError();
    //  console.log("response", error);
      setIsLoadingS(false);
    }
  };

  let rupee = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  <input
    type="file"
    className="form-control"
    onChange={(e) => {
      handleDrop(e);
    }}
  />;

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.target.files[0]; // Changed to get file from input

    // Define the maximum file size in bytes (e.g., 5 MB)
    const maxSize = 5 * 1024 * 1024; // 5 MB

    // Allowed file types
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];

    if (file) {
      // Check file size
      if (file.size > maxSize) {
        alert("File size exceeds the maximum limit of 5 MB.");
        return;
      }

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert("Only PDF, JPEG, and PNG files are allowed.");
        return;
      }

      // Set the file state if it passes the checks
      setFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);
      // console.log(previewUrl, " preveiw")
    }
  };

  const isAccessible = () => {
    return (
      isLab || (activeItem?.labTestRecord?.reportUrl && isAdmin && !isSuprAdmin)
    );
  };
  const isAccessibleF = () => {
    return activeItem?.labTestRecord?.reportUrl && isAdmin && !isSuprAdmin;
  };
  const size = { width: 141, height: 34, outline: "none", boxShadow: "none" };
  const handlePrint = () => {
    if (previewUrl) {
      const printWindow = window.open(previewUrl);
      // printWindow.onload = () => {
      //   // Ensure the document is ready before printing
      //   if (printWindow.document.readyState === 'complete') {
      //     printWindow.print();
      //   } else {
      //     printWindow.document.onreadystatechange = function () {
      //       if (printWindow.document.readyState === 'complete') {
      //         printWindow.print();
      //       }
      //     };
      //   }
      // };
    } else {
      console.error("No file URL available to open.");
    }
  };

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      onClick={handleCloseOutsideClick}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable  modal-xl ">
        <div className="modal-content h-100">
          <div className="d-flex justify-content-between px-3 pt-3">
            <h1
              className="fs-6 text-start "
              style={{ color: "rgba(0, 0, 0, 0.6)", margin: 0 }}
              id="exampleModalLabel"
            >
              {recevedTokens?.token?.toString()?.length > 0
                ? recevedTokens?.token?.toString()?.length == 1
                  ? "#0" + recevedTokens?.token
                  : "#" + recevedTokens?.token
                : "#" + recevedTokens?.tokenId}{" "}
              <br />{" "}
              <span className="text-black fs-5 ">
                {recevedTokens?.name ?? recevedTokens?.customerName}{" "}
              </span>{" "}
              <span className="text-black fs-5 ms-5">
                {" "}
                {moment(recevedTokens?.date).format("DD-MMM-YYYY")}
              </span>
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div
            className="d-flex justify-content-between align-items-center  px-3 pt-2 mb-2"
            style={{ width: "100%" }}
          >
            <h1
              className="modal-title fs-5 fw-semibold"
              id="exampleModalLabel"
              style={{ color: "gray" }}
            >
              Total Tests{" "}
              <span className="text-black">({tests[activeTests]?.length})</span>
            </h1>

            <div className="d-none d-md-block pe-3">
              <div className="d-flex">
                {[
                  "All",
                  "Pending",
                  "On Going",
                  "Complete",
                  //  "Delete"
                ].map((label, index) => (
                  <div className="mx-2" key={index}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id={`option${index + 1}`}
                      autoComplete="off"
                      checked={activeFilter === index}
                      onChange={() => Datafilter(index)}
                    />
                    <label
                      className={`btn ${
                        activeFilter === index
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      style={{
                        backgroundColor:
                          activeFilter === index ? "#D9D9D9" : "transparent",
                        color:
                          activeFilter === index
                            ? "rgba(0, 0, 0, 0.8)"
                            : "rgba(0, 0, 0, 0.6)",
                        borderColor: "lightgray",
                      }}
                      htmlFor={`option${index + 1}`}
                    >
                      {label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div
                className="accordion accordion-flush  card  rounded-2 border-0 gap-4 "
                id="customAccordionExample"
              >
                {tests[activeTests]?.length === 0 && isLoading ? (
                  <p
                    class="d-flex spinner-border text-primary  mt-4"
                    role="status"
                    style={{
                      alignItems: "center",
                      alignSelf: "center",
                      verticalAlign: "center",
                      height: 30,
                      width: 30,
                    }}
                  ></p>
                ) : tests[activeTests]?.length > 0 ? (
                  tests[activeTests]
                    ?.sort(
                      (a, b) =>
                        a.labTestsAndImagingDataID - b.labTestsAndImagingDataID
                    )
                    ?.map((item, idx) => (
                      <div
                        className="accordion-item border-0 "
                        key={item?.labTestsAndImaging?.id}
                      >
                        <h2
                          className="accordion-header"
                          id={item?.labTestsAndImaging?.id}
                        >
                          <button
                            className={`accordion-button ${
                              activeItem?.labTestsAndImaging?.id ===
                              item?.labTestsAndImaging?.id
                                ? ""
                                : "collapsed"
                            } rounded-2 border-0`}
                            type="button"
                            aria-expanded={
                              activeItem?.labTestsAndImaging?.id ===
                              item?.labTestsAndImaging?.id
                            }
                            aria-controls={item?.labTestsAndImaging?.id}
                            onClick={(e) => {
                              e.preventDefault();
                              toggleAccordion(item);
                            }}
                            disabled={!isSuprAdmin && !item?.active}
                            style={{
                              transition: "background-color 0.4s ease",
                              backgroundColor: item?.labTestRecord?.reportUrl
                                ? Color.lightPrimary
                                : Color.lightGray,
                              height: 56,
                              opacity: !item?.active && 0.8,
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center w-100 ">
                              <p
                                className="flex-grow-1"
                                style={{
                                  width: 220,
                                  overflow: "clip",
                                  lineClamp: 2,
                                  margin: 0,
                                }}
                              >
                                <span
                                  className="fw-bold  me-3"
                                  style={{
                                    color: !item?.active ? "" : Color.primary,
                                  }}
                                >
                                  {idx + 1}.{" "}
                                </span>
                                {item?.labTestsAndImaging?.name}
                              </p>
                              <div className="d-flex">
                                {item?.labTestsAndImaging?.type ==
                                  "OUTSIDE" && (
                                  <p
                                    className="mx-2 rounded-3 d-flex justify-content-center align-items-center"
                                    style={{
                                      backgroundColor: Color.disabled,
                                      minWidth: 100,
                                      color: "black",
                                      margin: 0,
                                      height: 36,
                                    }}
                                  >
                                    Outside
                                  </p>
                                )}
                                <p
                                  className="mx-2 rounded-3 d-flex justify-content-center align-items-center"
                                  style={{
                                    backgroundColor: item?.labTestRecord
                                      ?.reportUrl
                                      ? Color.completeBG
                                      : item?.labTestRecord?.paymentStatus
                                      ? Color.sentBg
                                      : Color.pendingBg,
                                    minWidth: 100,
                                    color: item?.labTestRecord?.reportUrl
                                      ? "white"
                                      : "black",
                                    margin: 0,
                                    height: 36,
                                  }}
                                >
                                  {item?.labTestRecord?.reportUrl
                                    ? "Complete"
                                    : item?.labTestRecord?.paymentStatus
                                    ? "On Going"
                                    : "Pending"}
                                </p>
                              </div>

                              <div
                                className="flex-grow-1  fw-semibold "
                                style={{
                                  color: item?.labTestRecord?.paymentStatus
                                    ? Color.completeBG
                                    : "black",
                                  width: 100,
                                  justifyItems: "center",
                                }}
                              >
                                <p
                                  className={`text-start  ms-5`}
                                  style={{ margin: 0, minWidth: 100 }}
                                >
                                  <span
                                    className={
                                      item?.labTestRecord?.amount > 0 &&
                                      item?.labTestRecord?.amount !==
                                        item?.labTestsAndImaging?.price
                                        ? "text-decoration-line-through"
                                        : ""
                                    }
                                  >
                                    {rupee.format(
                                      item?.labTestsAndImaging?.price
                                    )}
                                  </span>

                                  <span className="ms-3">
                                    {item?.labTestRecord?.amount > 0 &&
                                    item?.labTestRecord?.amount !==
                                      item?.labTestsAndImaging?.price
                                      ? rupee.format(
                                          item?.labTestRecord?.amount
                                        )
                                      : ""}
                                  </span>
                                </p>
                              </div>

                              <div
                                className="me-5 text-center d-flex"
                                style={{ width: 120 }}
                              >
                                {isLab && item?.labTestRecord?.paymentStatus ? (
                                  <div
                                    className="d-flex text-start"
                                    style={{ width: 370 }}
                                  >
                                    {(file || labTestRecord?.reportUrl) && (
                                      <PreviewSection item={item} />
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {item?.labTestRecord?.reportUrl && (
                                      <ReportDownloadSection item={item} />
                                    )}
                                    {item?.labTestRecord?.reportUrl && (
                                      <PreviewSection item={item} />
                                    )}
                                  </>
                                )}
                              </div>

                              <div
                                className="me-5  text-center "
                                style={{ width: 120 }}
                              >
                                {DeactiveId ===
                                item?.labTestsAndImagingDataID ? (
                                  <div className="d-flex gap-3  ">
                                    <button
                                      type="button"
                                      className="btn text-white"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeTests();
                                      }}
                                      style={{
                                        backgroundColor: Color.completeBG,
                                        height: 36,
                                        minWidth: 71,
                                        borderRadius: 6,
                                        fontSize: 12,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {isLoadingD ? (
                                        <span
                                          class=" spinner-border text-light text-center "
                                          role="status"
                                          style={{
                                            fontSize: 10,
                                            height: 13,
                                            width: 13,
                                          }}
                                        ></span>
                                      ) : (
                                        "Confirm"
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn bg-danger text-white "
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleDelte(
                                          item?.labTestsAndImagingDataID
                                        );
                                      }}
                                      style={{
                                        height: 36,
                                        width: 64,
                                        borderRadius: 6,
                                        fontSize: 12,
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : !item?.active ? (
                                  <div className="d-flex justify-content-center align-items-center ">
                                    <span className="text-danger ps-3 text-center ">
                                      Deleted
                                    </span>
                                    {isSuprAdmin && (
                                      <FontAwesomeIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleDelte(
                                            item?.labTestsAndImagingDataID
                                          );
                                        }}
                                        icon="fa-solid fa-delete-left"
                                        style={{
                                          // color: Color.completeBG,
                                          cursor: "pointer",
                                        }}
                                        className="text-danger ps-4 p-2 fs-6 "
                                      />
                                    )}
                                  </div>
                                ) : item?.labTestRecord?.reportUrl ||
                                  item?.labTestRecord?.paymentStatus ||
                                  isLab ? (
                                  <span
                                    style={{ color: Color.completeBG }}
                                  ></span>
                                ) : (
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleDelte(
                                        item?.labTestsAndImagingDataID
                                      );
                                    }}
                                    icon="fa-solid fa-trash"
                                    className="text-danger ps-4 p-2 fs-6"
                                  />
                                )}
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={item?.labTestsAndImaging?.id}
                          className={`accordion-collapse collapse ${
                            activeItem?.labTestsAndImaging?.id ===
                            item?.labTestsAndImaging?.id
                              ? "show"
                              : ""
                          }`}
                          aria-labelledby={item?.labTestsAndImaging?.id}
                          data-bs-parent="#customAccordionExample"
                          // style={{
                          //   transition: "max-height 0.4s ease, opacity 0.4s ease",
                          //   maxHeight: activeItem?.labTestsAndImaging?.id === item?.labTestsAndImaging?.id ? "1000px" : "0",
                          //   opacity: activeItem?.labTestsAndImaging?.id === item?.labTestsAndImaging?.id ? "1" : "0",
                          //   overflow: "hidden"
                          // }}
                        >
                          <div
                            className="accordion-body rounded-2 my-3"
                            style={{ backgroundColor: "#EEEEEE" }}
                          >
                            <div className="mb-3">
                              <p className="text-primary fw-semibold text-start">
                                {item?.labTestsAndImaging?.name}
                              </p>
                            </div>

                            <div className="d-flex justify-content-between mb-3 flex-wrap">
                              <div className=" text-start text-black">
                                <label
                                  htmlFor={`testDate-${item?.labTestsAndImaging?.id}`}
                                  className="form-label "
                                >
                                  Test Date
                                </label>
                                <input
                                  type="date"
                                  name="testDate"
                                  className="form-control"
                                  value={labTestRecord?.testDate}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: isAccessibleF()
                                      ? Color.disabled
                                      : null,
                                    ...size,
                                  }}
                                  disabled={isAccessibleF()}
                                />
                              </div>

                              <div className="text-start">
                                <label
                                  htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                  className="form-label text-black"
                                >
                                  Time
                                </label>
                                <input
                                  type="time"
                                  name="time"
                                  className="form-control"
                                  value={labTestRecord?.time}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: isAccessibleF()
                                      ? Color.disabled
                                      : null,
                                    ...size,
                                  }}
                                  disabled={isAccessibleF()}
                                />
                              </div>

                              <div className=" text-start">
                                <label className="form-label text-black">
                                  Lab Status
                                </label>
                                <p
                                  className="rounded-2 d-flex justify-content-center align-items-center"
                                  style={{
                                    backgroundColor: item?.labTestRecord
                                      ?.reportUrl
                                      ? Color.completeBG
                                      : item?.labTestRecord?.paymentStatus
                                      ? Color.sentBg
                                      : Color.pendingBg,
                                    color: item?.labTestRecord?.reportUrl
                                      ? "white"
                                      : "black",
                                    ...size,
                                  }}
                                >
                                  {item?.labTestRecord?.reportUrl
                                    ? "Complete"
                                    : item?.labTestRecord?.paymentStatus
                                    ? "On Going"
                                    : "Pending"}
                                </p>
                              </div>
                              <div className=" text-start">
                                <label
                                  htmlFor={`notes-${item?.labTestsAndImaging?.id}`}
                                  className="form-label text-black"
                                  // style={{ fontSize: 14 }}
                                >
                                  Notes
                                </label>
                                <textarea
                                  className="form-control"
                                  name="note"
                                  value={labTestRecord?.note}
                                  onChange={handleChange}
                                  rows="1"
                                  style={{
                                    ...size,
                                    width: 370,
                                    backgroundColor: isAccessibleF()
                                      ? Color.disabled
                                      : null,
                                  }}
                                  disabled={isAccessibleF()}
                                ></textarea>
                              </div>
                              {/* <div className="text-start">
                                      <label
                                        htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                        className="form-label text-black"
                                      >
                                        Waited Time
                                      </label>
                                      <input
                                        type="text"

                                        className="form-control"
                                        value={'1 hr 20 min'}
                                        // onChange={handleChange}
                                        style={{
                                          //  backgroundColor: '#D9D9D9' 
                                          ...size,
                                        }}
                                      />
                                    </div> */}
                            </div>

                            <div>
                              <label className="form-label text-black">
                                Payment Mode
                              </label>
                              {/* <br></br> */}
                              <div className="d-flex justify-content-between mb-3 flex-wrap">
                                <div className="cursor-pointer rounded-2  text-white">
                                  <select
                                    className="form-select rounded-2  bg-primary text-white border-0 "
                                    style={{
                                      ...size,
                                      paddingTop: 5,
                                    }}
                                    disabled={isAccessible()}
                                    onChange={(e) =>
                                      handleSelectionChange(e.target.value)
                                    }
                                  >
                                    <option
                                      value="Payment"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      Payment
                                    </option>
                                    <option
                                      value="IPD"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      IPD
                                    </option>
                                    <option
                                      value="FREE"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      FREE
                                    </option>
                                  </select>
                                </div>
                                {isPaymentActive && (
                                  <>
                                    <input
                                      type="number"
                                      name="cash"
                                      autoComplete="off"
                                      className="form-control"
                                      value={labTestRecord?.cash}
                                      onChange={handleChange}
                                      placeholder="Cash"
                                      disabled={isAccessible()}
                                      min="0"
                                      style={{
                                        ...size,
                                        backgroundColor: isAccessible()
                                          ? Color.disabled
                                          : null,
                                      }}
                                    />
                                    <input
                                      type="number"
                                      name="upi"
                                      autoComplete="off"
                                      className="form-control"
                                      value={labTestRecord?.upi}
                                      onChange={handleChange}
                                      placeholder="UPI"
                                      disabled={isAccessible()}
                                      min="0"
                                      style={{
                                        ...size,
                                        backgroundColor: isAccessible()
                                          ? Color.disabled
                                          : null,
                                      }}
                                    />
                                    <input
                                      type="number"
                                      name="card"
                                      autoComplete="off"
                                      className="form-control"
                                      value={labTestRecord?.card}
                                      onChange={handleChange}
                                      placeholder="Card"
                                      disabled={isAccessible()}
                                      min="0"
                                      style={{
                                        ...size,
                                        backgroundColor: isAccessible()
                                          ? Color.disabled
                                          : null,
                                      }}
                                    />
                                    <input
                                      type="number"
                                      name="other"
                                      autoComplete="off"
                                      className="form-control"
                                      value={labTestRecord?.other}
                                      onChange={handleChange}
                                      placeholder="Other"
                                      disabled={isAccessible()}
                                      min="0"
                                      style={{
                                        ...size,
                                        backgroundColor: isAccessible()
                                          ? Color.disabled
                                          : null,
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              {error && (
                                <div className="text-danger">{error}</div>
                              )}
                            </div>

                            <div className="d-flex justify-content-between pt-3 flex-wrap">
                              <div
                                className=" text-start "
                                //  style={{ width: "150px" }}
                              >
                                <label
                                  htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                  className="form-label text-black"
                                >
                                  Discount
                                </label>
                                <input
                                  type="number"
                                  name="discount"
                                  autoComplete="off"
                                  className="form-control"
                                  value={labTestRecord?.discount}
                                  onChange={handleChange}
                                  placeholder="Amount"
                                  disabled={isAccessible()}
                                  min="0"
                                  style={{
                                    ...size,
                                    backgroundColor: isAccessible()
                                      ? Color.disabled
                                      : null,
                                  }}
                                />
                              </div>

                              <div className="text-center mt-1 ">
                                <label
                                  htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                  className="form-label text-black"
                                ></label>
                                <button
                                  type="button"
                                  className="btn btn-primary mt-1 d-flex justify-content-center align-items-center"
                                  // disabled={!isLab || (!labTestRecord?.paymentStatus && !isLab) || isAccessibleF()}
                                  // disabled={!(isSuprAdmin || isLab) && (!labTestRecord?.paymentStatus || isAccessibleF())} // Enable if isSuprAdmin or isLab is true
                                  disabled={
                                    !item?.active ||
                                    (!(isSuprAdmin || isLab) &&
                                      (!labTestRecord?.paymentStatus ||
                                        isAccessibleF()))
                                  }
                                  onClick={() => handleSubmit()}
                                  style={{ ...size }}
                                >
                                  Save{" "}
                                  {isLoadingS && (
                                    <span
                                      class=" spinner-border text-light ms-1 "
                                      role="status"
                                      style={{
                                        fontSize: 10,
                                        height: 15,
                                        width: 15,
                                      }}
                                    ></span>
                                  )}
                                </button>
                              </div>

                              {isLab &&
                              activeItem?.labTestRecord?.paymentStatus ? (
                                <div
                                  className="d-flex text-start"
                                  style={{ width: 370 }}
                                >
                                  <div>
                                    <label
                                      htmlFor={`uploadReport-${item?.labTestsAndImaging?.id}`}
                                      className="form-label text-black"
                                    >
                                      Upload Report
                                    </label>
                                    {labTestRecord?.reportUrl ? (
                                      <div style={{ width: 205 }}>
                                        {/* <br /> */}
                                        <button
                                          type="button"
                                          className="btn bg-danger text-white "
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setLabTestRecord({
                                              ...labTestRecord,
                                              reportUrl: "",
                                            });
                                          }}
                                          style={{
                                            height: 36,
                                            width: 164,
                                            borderRadius: 6,
                                            fontSize: 14,
                                          }}
                                        >
                                          Replace Uploaded File
                                        </button>
                                      </div>
                                    ) : (
                                      <input
                                        type="file"
                                        className="form-control"
                                        style={{
                                          outline: "none",
                                          boxShadow: "none",
                                        }}
                                        onChange={(e) => {
                                          handleDrop(e);
                                        }}
                                      />
                                    )}
                                  </div>
                                  {(file || labTestRecord?.reportUrl) && (
                                    <div className="ms-4">
                                      <label
                                        htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                        className="form-label text-black"
                                      >
                                        Preview
                                      </label>
                                      <div>
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-file-image"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handlePrint();
                                          }}
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                          }}
                                          className=" text-primary p-2 bg-white rounded p-1"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <div
                                    className=" text-start "
                                    style={{ ...size }}
                                  >
                                    {activeItem?.labTestRecord?.reportUrl && (
                                      <>
                                        <label
                                          htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                          className="form-label text-black"
                                        >
                                          Download Report
                                        </label>
                                        <div>
                                          <FontAwesomeIcon
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (downloadUrl) {
                                                // window.location.href = downloadUrl;
                                                window.open(downloadUrl);
                                              }
                                            }}
                                            icon="fa-solid fa-file-arrow-down"
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                            }}
                                            className="text-primary p-2 bg-white rounded p-1"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    className=" text-start "
                                    style={{ ...size }}
                                  >
                                    {activeItem?.labTestRecord?.reportUrl && (
                                      <>
                                        <label
                                          htmlFor={`testTime-${item?.labTestsAndImaging?.id}`}
                                          className="form-label text-black"
                                        >
                                          Preview
                                        </label>
                                        <div>
                                          <FontAwesomeIcon
                                            icon="fa-solid fa-file-image"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handlePrint();
                                            }}
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                            }}
                                            className=" text-primary p-2 bg-white rounded p-1"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}

                              <div className=" text-start">
                                <label className="form-label">Amount</label>
                                <p
                                  className="fw-bold text-primary fs-4"
                                  style={{ ...size, height: 52 }}
                                >
                                  <span
                                    className={
                                      labTestRecord?.amount > 0 &&
                                      labTestRecord?.amount !==
                                        activeItem?.labTestsAndImaging?.price
                                        ? "text-decoration-line-through"
                                        : ""
                                    }
                                  >
                                    {rupee.format(
                                      activeItem?.labTestsAndImaging?.price
                                    )}
                                  </span>
                                  <br />
                                  <span className="pb-2">
                                    {labTestRecord?.amount > 0 &&
                                    labTestRecord?.amount !==
                                      activeItem?.labTestsAndImaging?.price
                                      ? rupee.format(labTestRecord?.amount)
                                      : ""}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <p
                    className="d-flex  align-items-center justify-content-center text-dark fw-semibold text-center fs-4 mt-4"
                    // style={{ height: "70vh" }}
                  >
                    There is no tests for now
                    <FontAwesomeIcon
                      className="text-primary ms-2"
                      icon="fa-solid fa-circle-nodes"
                    />
                  </p>
                )}
              </div>
            </form>
          </div>

          <div className="modal-footer justify-content-start w-100 border-0">
            <div
              className="d-flex align-items-center bg-primary  rounded-2 w-100 px-3"
              style={{ height: 56 }}
            >
              <div className="flex-grow-1 text-light fw-semibold  text-start">
                Total Test Amount
              </div>
              <button
                type="button"
                className="btn btn-light fw-bold "
                onClick={onClose}
              >
                {rupee.format(totalAmount)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        message={toastMsg}
        status={colorStatus}
      />
    </div>
  );
};

export default memo(ModalLabTest);

import React, { useEffect, useState } from "react";
import { headerText } from "../visitConstant/repotOtherConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  ERROR_MSG,
  GENERATEUSERTOKEN,
  Tokan_Status_In_Pending,
  Tokan_Status_In_Queue,
  Tokan_Type_Generate,
} from "../../Constant";
import ToastFist from "../Toast/ToastFist";
import apiHelper from "../ApiHelpar/apiHelper";

import { useLocation, useNavigate } from "react-router-dom";
import { User } from "@auth0/auth0-react";
import { setUserToken } from "../ReduxApi/UserToken";

const NewVisitCaed = ({ receivedDatas }) => {
  const visitCount = useSelector((state) => state.visit.visitCount);
  const lastHistory = useSelector((state) => state?.visit?.lastHistory);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);
  const navigate = useNavigate();
  useEffect(() => {
    const busiParams = {
      name: receivedDatas?.name,
      date: moment(new Date()).format("YYYY-MM-DD"),
      mobileNo: receivedDatas?.mobileNo,
      address: receivedDatas?.address,
      type: Tokan_Type_Generate,
      status: Tokan_Status_In_Queue,
      comment: receivedDatas?.comment,
      age: receivedDatas?.age,
      gender: receivedDatas?.gender,
    };

    // console.log(busiParams, "busiParams");
  }, [lastHistory, receivedDatas]);

  const RecreatTokan = async () => {
    setLoading(true);

    // alert("calling");
    if (receivedDatas) {
    //  console.log(receivedDatas, "newVisitcard");
      try {
        const endpoint = GENERATEUSERTOKEN;
        const {
          tokenId,
          token,
          amount,
          card,
          cash,
          other,
          visitPad,
          labTest,
          upi,
          paymentMode,
          ...NewData
        } = receivedDatas;

        const data = {
          ...NewData,
          opd: "1",
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: Tokan_Type_Generate,
          status: Tokan_Status_In_Pending,
          endTime: null,
          startTime: moment().format("HH:mm"),
        };
      //  console.log(data, "kk");
        const response = await apiHelper(endpoint, data);
        const dataConvert = response?.respData?.message;
        // console.log("dataConvert", response);
        const newtokanData =
          response?.respData?.respMsg?.userTokenList?.slice(-1)[0];

        if (dataConvert == "UserToken has been generated successfully") {
        //  console.log(response, "newtokanData");
          // navigate("/reportMain", { state: newtokanData });
          navigate("/reportMain", { state: newtokanData, replace: true });
        }
        setLoading(false);
      } catch (error) {
       // console.log("Error fetching data:", error);

        setShowToast(true);
        toastMsg(ERROR_MSG);
        setLoading(false);
      } finally {
        // console.log("ok");
        setLoading(false);
      }
    }
  };

  const getOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = num % 100;

    return (
      num +
      (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0])
    );
  };

  // const visitCount = 5; // Example visit count
  //console.log(visitMessage); // Outputs: "6th Visit"

  const visitMessage = `${getOrdinal(visitCount !== 0 ? visitCount : 1)} `;

  const saveToken = (tokenValue) => {
    dispatch(setUserToken(tokenValue));
  };

  return (
    <>
      <div
        className="card text-center d-flex justify-content-center align-items-center my-4 mx-3"
        style={{ height: "25vh" }}
      >
        <div className="d-grid gap-4 mx-auto">
          <div className="d-flex justify-content-center align-items-center my-3 gap-3">
            <button
              className="btn btn-success d-flex align-items-center justify-content-center"
              type="button"
              onClick={() => RecreatTokan()}
            >
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Create New Visit"
              )}
            </button>

            {/* Divider - use 'or' text or a vertical line */}
            <span className="mx-2 text-muted">|</span>

            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => saveToken(receivedDatas?.tokenId)}
            >
              Token Updation
            </button>
          </div>

          <p>
            <span className="fw-bold me-3" style={{ color: headerText }}>
              {visitCount !== 0 ? visitMessage : 1} Visit
            </span>
            | Since
            <span className="fw-bold ms-2" style={{ color: headerText }}>
              {moment(lastHistory?.date).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        message={toastMsg}
        status={colorStatus}
      />
    </>
  );
};

export default NewVisitCaed;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Placeholder from "../../src/default_img/placeholder.png";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Ratings from "react-ratings-declarative";
import { IMG_BASE_URL } from "../Constant";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
// function changeRating(newRating) {}

const ArticleCard = ({ item, showEdit }) => {
  const navigate = useNavigate();
  return (
    <div>
      {
        <div>
          <div class="mb-3 fw-semibold mt-3">
            <table>
              <td>
                <h3>{item?.title}</h3>
              </td>

              <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
                <div className="">
                  {showEdit ? (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            "products",
                            localStorage.setItem("myData", JSON.stringify(item))
                          )
                        }
                        type="button"
                        class="btn btn-primary me-md-2"
                      >
                        View all
                      </button>
                      <button
                        className="btn btn-outline-primary "
                        onClick={() =>
                          navigate(
                            "addcat",

                            localStorage.setItem(
                              "catData",
                              JSON.stringify(item)
                            )
                          )
                        }
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        Edit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </table>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 px-md-6 pt-1">
            {item.items.map((it) => (
              <div class="cards  " aria-hidden="true">
                <div class="card width: 18rem; rounded-5 ">
                  {/* <Link
                  
                    to={"/productdetail" + it.itemId}
                    onClick={() =>
                      localStorage.setItem("myProduct", JSON.stringify(it))
                    }
                  > */}
                  <Link
                    to={"/productdetail/" + it?.itemId}
                    onClick={() =>
                      localStorage.setItem("myProduct", JSON.stringify(it))
                    }
                  >
                    <img
                      className="card-img-top bg-dark cover  rounded-4  "
                      // PlaceholderSrc={Placeholder}
                      // effect="blur card-img-top cover "
                      key={it.iconURL}
                      // onError={({ currentTarget }) => {
                      //   currentTarget.onerror = null; // prevents looping
                      //   currentTarget.src = { Placeholder };
                      // }}
                      height="150"
                      alt=""
                      src={IMG_BASE_URL+it.iconURL}
                    />
                  </Link>
                  <div class="card-body">
                    <h5 className="card-title text-center text-dark text-truncate">
                      {it.title.length > 20
                        ? parse(it.title.substring(0, 100) + "...")
                        : parse(it.title)}
                    </h5>

                    <h6 className="card-title text-center ">
                      {it.titleID.length > 400
                        ? parse(it.titleID.substring(0, 400) + "...")
                        : parse(it.titleID)}
                      {/* {it.titleID.length > 100
                        ? it.titleID.substring(0, 100) + "......."
                        : it.titleID} */}
                    </h6>

                    {/* <div className="card-title text-center text-dark text-truncate">
                      <Ratings
                        rating={4.5}
                        widgetRatedColors="rgb(253, 204, 13)"
                        // changeRating={changeRating}
                        widgetSpacings="2px"
                      >
                        {Array.from({ length: 5 }, (_, i) => {
                          return (
                            <Ratings.Widget
                              key={i}
                              widgetDimension="20px"
                              svgIconViewBox="0 0 19 20"
                              svgIconPath={iconPath}
                              widgetHoverColor="rgb(253, 204, 13)"
                            />
                          );
                        })}
                      </Ratings>
                    </div> */}
                    {/* <p className="card-text text-center text-muted">
                      {"₹" + it.price + "/- "}
                    </p> */}

                    {/* <div className="d-grid gap-2">
                      
                      <Link
                        className="btn btn-outline-primary rounded-pill"
                        // to={"/slotbooking"}

                        to={
                          it?.callingURL === "" || it?.callingURL === null
                            ? "slotbooking"
                            : it.callingURL
                        }
                        onClick={() =>
                          localStorage.setItem(
                            "myProduct",
                            JSON.stringify(it, showEdit)
                          )
                        }
                        replace
                      >
                        Book appointment
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default ArticleCard;

import React, { useState, useEffect, useRef } from "react";
import "./CustomDropdown.css";
import AddMedicinOnEnter from "../modal/AddMedicinOnEnter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserSearchDropdown = ({
  options,
  onSelect,
  handleInputChange,
  searchTerm,
  setSearchTerm,
  value,
  rowIndex,
  loading,
  Placeholder,
  onAddNewItem,
  newEntry,
  inputType = "search",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef(null); // Reference to the input field

  const handleOptionClick = (option) => {
    // setSearchTerm(option?.name); // Show selected item in input
    setIsOpen(false); // Close dropdown after selection
    onSelect(option, rowIndex); // Return the selected option to parent
  };

  const handleInputFocusOut = () => {
    setTimeout(() => setIsOpen(false), 100);
  };

  const handleInputFocus = () => {
    setIsOpen(true); // Open dropdown on input focus
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      AddMedicinOnEnter({ nam: "d" }, "");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === options.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === 0 ? options.length - 1 : prevIndex - 1
      );
    } else if (e.key === "Enter") {
      if (newEntry) {
        const selected = options[highlightedIndex];
        if (selected) {
          handleOptionClick(selected);
          inputRef.current.blur();
        } else {
          handleAddNewItem();
          inputRef.current.blur();
        }
      } else {
        const selected = options[highlightedIndex];
        if (selected) {
          handleOptionClick(selected);
        }
      }
    }
  };

  const handleAddNewItem = () => {
    //  alert(searchTerm)
    if (searchTerm.trim()) {
      onAddNewItem(searchTerm?.trim());
      setIsOpen(false); // Close dropdown after adding
    }
  };

  useEffect(() => {
    if (isOpen) {
      setHighlightedIndex(0); // Reset the highlighted index when dropdown opens
    }
  }, [isOpen]);

  return (
    <div className="dropdown" onKeyDown={handleKeyDown} tabIndex="0">
      <div className="dropdown-header">
        <input
          ref={inputRef} // Attach ref to the input field inside the dropdown
          type={inputType}
          placeholder={Placeholder ? Placeholder : "Search Patient"}
          // value={ searchTerm? searchTerm : value } // Display selected option in input
          value={searchTerm} // Display selected option in input
          onChange={(e) => handleInputChange(e.target.value)} // Handle input changes
          onFocus={handleInputFocus}
          className={`prompt form-control   ${Placeholder ? "" : "rounded-2"}`}
          onBlur={handleInputFocusOut}
          onKeyDown={handleKey}
          maxLength={50}
          // style={Placeholder ? {} : { width: 300, marginRight: 30 }}

          // required
        />
        {/* <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> */}
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {loading && <div className=" text-center ">Loading...</div>}

          {options?.length > 0 ? (
            <>
              <p
                className="text-primary s py-2 fw-bold"
                style={{ margin: 0, paddingLeft: 13 }}
              >
                Patients list
              </p>{" "}
              {/* Added enclosing fragment */}
              {options?.map((option, index) => (
                <div
                  key={option?.tokenId}
                  className={`dropdown-item ${
                    index === highlightedIndex ? "highlighted" : ""
                  }`}
                  onMouseDown={() => handleOptionClick(option)}
                >
                  <p
                    className="fst-normal py-1"
                    style={{
                      textAlign: "left",
                      verticalAlign: "center",
                      margin: 0,
                    }}
                  >
                    {/* <strong>{option?.tokenId}</strong> : */}
                    {option?.customerId?.name} ({option.customerId?.age},{" "}
                    {option?.customerId?.gender}) Ph:{" "}
                    {option?.customerId?.mobileNo}
                  </p>
                </div>
              ))}
            </>
          ) : (
            <div className="dropdown-item">
              <div class="card  text-center">
                {newEntry ? (
                  <>
                    {searchTerm ? (
                      <div
                        className="dropdown-item"
                        onMouseDown={handleAddNewItem}
                        onKeyDown={handleKeyDown}
                      >
                        New Patient "{searchTerm}"
                      </div>
                    ) : (
                      <div className="dropdown-item">Search....</div>
                    )}
                  </>
                ) : (
                  <div class="card-body p-2 text-primary">
                    + Create New Patient
                  </div>
                )}

                {/* {
                 searchTerm ? (
                  <div className="dropdown-item"  onMouseDown={handleAddNewItem}   onKeyDown={handleKeyDown}>
                   Create "{searchTerm}" 
                  </div>
                ) : (
                  <div class="card-body p-2 text-primary">
                    + Create New Patient
                  </div>
                )} */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearchDropdown;


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import ProductList from "../products/ProductList";

import AboutUs from "../about/AboutUs";
import ProductGridCard from "./ProductGridCard";
import ArticleCard from "./ArticleCard";



import {
  ROLE,
  USER_ROLE,
  ABOUT_US_VIEW,
  Article_view,
  article_view_01,
} from "../Constant";


function Article({ listData }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);
  // const bannerItemRemoved = listData.splice(0, 1);
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  useEffect(() => {
    if (listData.length > 0) {
      setCatListData(listData);
    } else {
      setCatListData(catItemList);
    }
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  });
  return (
    <>
      {catListData?.map((item) => (
        <div className="container">
          {item?.viewType ===
          // Article_view
          article_view_01 ? (
            <div>
              <ArticleCard
                key={item.id}
                item={item}
                navigate={navigate}
                showEdit={showEdit}
              />
            </div>
          ) : (
            <div> </div>
          )}
        </div>
      ))}
      ;
    </>
  );
}
export default Article;

import axios from "axios";
import { ACCESS_TOKEN, addMedicine, BASE_URL, SESSION_ID } from "../../Constant";

const AddMedicinOnEnter = async ({ medItem }) => {
   // console.log(medItem, "bjai")

    try {
        const response = await axios.post(
            BASE_URL + addMedicine,
            {
                pubInfo: { sessionId: localStorage.getItem(SESSION_ID) },
                request: {
                    busiParams: {
                        name: medItem?.medicineId,
                        contents: "",
                        dosage: medItem?.dosage,
                        unit: medItem?.unit,
                        whens: medItem?.when,
                        frequency: medItem?.frequency,
                        duration: medItem?.duration,
                        notes: medItem?.notes,
                    },
                    isEncrypt: false,
                    transactionId: "897987987989",
                },
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
                },
            }
        );

      // // console.log(response, '  respone dform em')


    } catch (err) {
       // console.log(err, ":coming from addnewmedicine")
    } finally {

    }
}

export default AddMedicinOnEnter;
import "./bootstrap-custom.css";
//import "./index.css";
//import "./login/Login.css";
//import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
//import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import React from "react";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { createStore } from "redux";
import allReducer from "./reducer";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "./Constant";

import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

library.add(fas, far, fab);
const store = createStore(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk)
);
//  const root = createRoot(document.getElementById("root"));
//  root.render(
//       <App />
// );

ReactDOM.render(
  
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

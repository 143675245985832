import apiHelper from "../ApiHelpar/apiHelper";
import { ADDNEWDATAFORALL } from "../visitConstant/ApiConstant";

// helper/addItemToServer.js
export const addItemToServer = async (item, categoryKey) => {
  if (!item) return null;

  try {
    const endpoint = ADDNEWDATAFORALL;
    const data = {
      categoryId: categoryKey,  // Pass category ID dynamically for reuse
      name: item,
    };

   // const response = false//await apiHelper(endpoint, data);
    const response = await apiHelper(endpoint, data);
    const dataConvert = response?.respData?.respMsg;
   // console.log("Api res  ---<<<---", response)
    if (response == false) {

      return false
    } else if (dataConvert) {
      return { value: dataConvert.id, label: dataConvert.name };
    }
  } catch (error) {
    console.error("Error adding new item:", error);
  } finally {
    console.log("Item added to server");
  }
  return null;
};

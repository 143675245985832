import React, { useState } from "react";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import {
  BASE_URL,
  ERROR_MSG,
  GENERATEUSERTOKEN,
  IMG_BASE_URL,
  ROLE,
  SESSION_ID,
  SUPER_ADNIM,
  Tokan_Status_In_Queue,
  Tokan_Type_Generate,
  USER_ROLE,
} from "../Constant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

import TokenForm from "./TokenForm";
import Carousel from "./Carousel";
import ToastFist from "../report/Toast/ToastFist";
import { useDispatch } from "react-redux";
import { setUserToken } from "../report/ReduxApi/UserToken";

const CreateToken = () => {
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [user, setUser] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    name: "",
    mobileNo: "",
    address: "",
    opd: "1",
    startTime: "",
    endTime: "",
    // visitPad: true,
    token: null,
    // labTest: false,
    // age: null,
    type: Tokan_Type_Generate,
    status: Tokan_Status_In_Queue,
    gender: "M",
    amount: null,
    paymentMode: "",
    comment: null,
    cash: null,
    upi: null,
    card: null,
    other: null,
    dob: "",
    preferredLanguage: "",
    city: "",
    pin: "",
    customerId: {
      customerId: null,
      salutation: "Mr",
      name: "",
      emailId: "",
      mobileNo: "",
      role: "",
      address: "",
      age: "",
      ageUnit: "",
      gender: "M",
      dob: "",
      preferredLanguage: "",
      city: "",
      pin: "",
      newEntry: true,
      customerDetails: {
        id: null,
        maritalStatus: "",
        since: "",
        spouseBloodGroup: "",
        bloodGroup: "",
        spouseName: "",
        existingId: "",
        channel: "",
        occupation: "",
        email: "",
        co: "",
        aadharNumber: "",
        tag: "",
        mobile2: "",
        newEntry: true,
      },
    },
  });
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");
  const section = localStorage.getItem(SESSION_ID);
  const [inputValue, setInputValue] = useState("");
  const [inputMobailValue, setInputMobailValue] = useState("");

  // console.log("USER,l", user);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUser((prevUser) => {
      const keys = name.split("."); // Split the name string by dots for nested keys
      const updatedUser = { ...prevUser }; // Clone the state to avoid direct mutation
      let temp = updatedUser;

      // Traverse to the appropriate level
      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) temp[keys[i]] = {}; // Create nested objects if they don't exist
        temp = temp[keys[i]];
      }

      // Set the value at the final key
      temp[keys[keys.length - 1]] = value;

      return updatedUser;
    });
  };

  const saveUser = async (newEntry) => {
    // e.preventDefault(); // Prevent default form submission behavior

    // Update user object with input values
    setUser((prevUser) => ({
      ...prevUser,
      name: inputValue.trim(),
      mobileNo: inputMobailValue.trim(),
      // You can set other fields based on your requirements or user inputs
    }));

    // Validation
    if (!inputValue || !inputMobailValue) {
      setShowToast(true);
      setColorStatus(3);
      setToastMsg("Please enter name and mobile number");
      return; // Early exit if validation fails
    }

    // Call the function to generate the user token
    await generateUserToken(
      user.customerId?.name,
      user.customerId?.mobileNo,
      user.customerId?.address,
      user.customerId?.age,
      user.customerId?.gender,
      newEntry
    );
  };

  const cleanObject = (obj) => {
    if (!obj || typeof obj !== "object") return {};
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) => value !== undefined && value !== null
      )
    );
  };

  // console.log(user, "user");
  const generateUserToken = async (
    name,
    mobileNo,
    address,
    age,
    gender,

    NewPresent
  ) => {
    try {
      // Step 1: Build request data
      const buildRequestData = () => {
        const customerDetailsInput = cleanObject({
          id: NewPresent ? user.customerId?.customerDetails?.id : null,
          maritalStatus: user.customerId?.customerDetails?.maritalStatus,
          since: user.customerId?.customerDetails?.since,
          spouseBloodGroup: user.customerId?.customerDetails?.spouseBloodGroup,
          bloodGroup: user.customerId?.customerDetails?.bloodGroup,
          spouseName: user.customerId?.customerDetails?.spouseName,
          existingId: user.customerId?.customerDetails?.existingId,
          channel: user.customerId?.customerDetails?.channel,
          email: user.customerId?.customerDetails?.email,
          co: user.customerId?.customerDetails?.co,
          aadharNumber: user.customerId?.customerDetails?.aadharNumber,
          tag: user.customerId?.customerDetails?.tag,
          mobile2: user.customerId?.customerDetails?.mobile2,
          occupation: user.customerId?.customerDetails?.occupation,
          newEntry: user.customerId?.customerDetails?.newEntry,
        });

        // Exclude "newEntry" from checks
        const { newEntry, ...rest } = customerDetailsInput;

        // Check if at least one value exists in `rest`
        const hasValues = Object.values(rest).some(
          (value) => value != null && value !== ""
        );

        // const customerDetails = hasValues ? customerDetailsInput : null;

        let customerDetails = null;

        // Handle the logic for New Present vs Old Present
        if (!NewPresent) {
          // If New Present
          if (user.customerId?.customerDetails?.newEntry) {
            // If newEntry is true, pass all customerDetailsInput
            if (hasValues) {
              customerDetails = customerDetailsInput;
            }
          } else if (user.customerId?.customerDetails?.newEntry === false) {
            // If newEntry is false, check if the id is available to pass customerDetailsInput

            customerDetails = null;
          }
        } else {
          // If Old Present
          // Always pass customerDetailsInput if customerId exists
          if (hasValues) {
            customerDetails = customerDetailsInput;
          }
        }

        const customerId = cleanObject({
          customerId: NewPresent ? user.customerId?.customerId : null,
          salutation: user.customerId?.salutation || "",
          name: user.customerId?.name.trim(),
          emailId: user.customerId?.emailId,
          mobileNo: user.customerId?.mobileNo.trim(),
          // role: user.customerId?.role,
          address: user.customerId?.address,
          age: user.customerId?.age,
          ageUnit: user.customerId?.ageUnit
            ? user.customerId?.ageUnit
            : "years",
          gender: user.customerId?.gender,
          dob: user.customerId?.dob,
          preferredLanguage: user.customerId?.preferredLanguage,
          city: user.customerId?.city,
          pin: user.customerId?.pin,
          newEntry: true,
          customerDetails,
        });

        const busiParams = cleanObject({
          date: currentDate,
          name: user.customerId?.name.trim(),
          mobileNo: user.customerId?.mobileNo.trim(),
          address: user.customerId?.address,
          dob: user.customerId?.dob,
          opd: user.opd || "1",

          gender: user.customerId?.gender.trim(),
          preferredLanguage: user.customerId?.preferredLanguage,
          city: user.customerId?.city,
          pin: user.customerId?.pin,
          type: Tokan_Type_Generate,
          status: Tokan_Status_In_Queue,
          startTime: moment().format("HH:mm"),
          customerId,
        });

        return {
          pubInfo: {
            sessionId: section,
          },
          request: {
            busiParams,
            isEncrypt: false,
            transactionId: "897987987989",
          },
        };
      };

      const userData = buildRequestData();

      // Step 2: Log structured data for debugging
      // console.log("Generated User Data:", userData);

     // console.log(userData, "busiParams");

      //  Step 3: Send API request
      const response = await axios.post(
        `${BASE_URL}${GENERATEUSERTOKEN}`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Step 4: Handle API response
      const userTokenData = response?.data?.respData?.message;
      // ?.userTokenList?.[0];
      
     // console.log("User Token Data:", response);

      // Show success toast
      setShowToast(true);
      setColorStatus(1);
      setToastMsg(
        //"Patient token generated successfully"
        userTokenData
      );

      // Reset user state
      resetUserState();
    } catch (err) {
      // Show error toast and log error
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
      console.log("Error generating user token:", err);
    } finally {
      resetUserState();
    }
  };

  const resetUserState = () => {
    setUser({
      date: "",
      name: "",
      mobileNo: "",
      address: null,
      startTime: "",
      endTime: "",
      // visitPad: true,
      token: null,
      // labTest: false,
      age: "",
      ageUnit: "",
      gender: "M",
      opd: "1",
      amount: null,
      paymentMode: "",
      type: 0,
      status: null,
      comment: null,
      cash: null,
      upi: null,
      card: null,
      other: null,
      dob: "",
      preferredLanguage: "",
      city: "",
      pin: "",
      customerId: {
        salutation: "Mr",
        customerId: null,
        name: "",
        emailId: "",
        mobileNo: "",
        role: "",
        address: "",
        age: "",
        ageUnit: "",
        gender: "M",
        dob: "",
        preferredLanguage: "",
        city: "",
        pin: "",
        newEntry: true,
        customerDetails: {
          id: null,
          maritalStatus: "",

          since: "",
          spouseBloodGroup: "",
          bloodGroup: "",
          spouseName: "",
          existingId: "",
          channel: "",
          occupation: "",
          email: "",
          co: "",
          aadharNumber: "",
          tag: "",
          mobile2: "",
          newEntry: true,
        },
      },
    });
    setInputValue("");
    setInputMobailValue("");
  };

  const onSelect = (value) => {
    if (value) {
      setUser((prevUser) => ({
        ...prevUser,
        name: value?.customerId?.name || "",
        mobileNo: value?.customerId?.mobileNo || "",
        address: value?.customerId?.address || "",
        dob: value?.customerId?.dob || "",
        opd: value?.opd || "1",
        ///  age: value?.age || "",
        gender: value?.customerId.gender || "",
        preferredLanguage: value?.customerId?.preferredLanguage || "",
        city: value?.customerId?.city || "",
        pin: value?.customerId?.pin || "",
        type: Tokan_Type_Generate,
        status: Tokan_Status_In_Queue,
        customerId: {
          ...prevUser.customerId,
          customerId: value?.customerId?.customerId || null,
          salutation: value?.customerId?.salutation || "",
          name: value?.customerId?.name || "",
          emailId: value?.customerId?.emailId || null,
          mobileNo: value?.customerId?.mobileNo || "",
          role: value?.customerId?.role || "",
          address: value?.customerId?.address || "",
          age: value?.customerId?.age || "",
          ageUnit: value?.customerId?.ageUnit || "",
          gender: value?.customerId?.gender || "",
          dob: value?.customerId?.dob || "",
          preferredLanguage: value?.customerId?.preferredLanguage || "",
          city: value?.customerId?.city || "",
          pin: value?.customerId?.pin || "",
          newEntry: value?.customerId?.newEntry || false,
          customerDetails: {
            ...prevUser.customerId.customerDetails,
            id: value?.customerId?.customerDetails?.id || null,
            maritalStatus:
              value?.customerId?.customerDetails?.maritalStatus || "",
            since: value?.customerId?.customerDetails?.since || "",
            spouseBloodGroup:
              value?.customerId?.customerDetails?.spouseBloodGroup || "",
            bloodGroup: value?.customerId?.customerDetails?.bloodGroup || "",
            spouseName: value?.customerId?.customerDetails?.spouseName || "",
            existingId: value?.customerId?.customerDetails?.existingId || "",
            channel: value?.customerId?.customerDetails?.channel || "",
            occupation: value?.customerId?.customerDetails?.occupation || "",
            email: value?.customerId?.customerDetails?.email || "",
            co: value?.customerId?.customerDetails?.co || "",
            aadharNumber:
              value?.customerId?.customerDetails?.aadharNumber || "",
            tag: value?.customerId?.customerDetails?.tag || "",
            mobile2: value?.customerId?.customerDetails?.mobile2 || "",
            newEntry: value?.customerId?.customerDetails?.newEntry || false,
          },
        },
      }));

      // Optionally update input values
      setInputValue(value.name || "");
      setInputMobailValue(value.mobileNo || "");
    }
  };

  return (
    <>
      <div className=" " style={{ marginTop: 130, marginBottom: 50 }}>
        <ScrollToTopOnMount />
        {/* <div className=" rounded-3 text-black">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-12">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <div className="row g-0">
                    <div className="col-lg-8 d-flex align-items-center">
                      <div className="card-body  text-black">
                        <TokenForm

                          user={user}
                          handleChange={handleChange}
                          saveUser={saveUser}
                          onSelect={onSelect}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setInputMobailValue={setInputMobailValue}
                          currentDate={currentDate}
                          setCurrentDate={setCurrentDate}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-4 d-none d-lg-block bg-primary align-self-center"
                      style={{ height: "80vh", borderRadius: "1rem" }}
                    >
                      <Carousel />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="d-flex justify-content-center align-items-center  text-black">
          <div
            className="card"
            style={{ borderRadius: "1rem", width: "100%", maxWidth: "900px" }}
          >
            <div className="row g-0">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="card-body text-black">
                  <TokenForm
                    user={user}
                    handleChange={handleChange}
                    saveUser={saveUser}
                    onSelect={onSelect}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setInputMobailValue={setInputMobailValue}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastFist
          showToast={showToast}
          setShowToast={setShowToast}
          title="Indication"
          message={toastMsg}
          duration="Just now"
          status={colorStatus}
        />
      </div>
    </>
  );
};

export default CreateToken;

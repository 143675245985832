import React, { useState, useRef, useEffect } from "react";
import CustomTooltip from "./CustomTooltip";
import Select from "react-select";
import DropdownComponent from "./ui/CustomDropdown";
import AddNewDataForAll from "./modal/AddNewDataForAll";
import { useDispatch, useSelector } from "react-redux";
import { saveTemplate } from "./ReduxApi/SaveTemplateApi";
import Categories from "./visitConstant/Categories";
import { RootFetchTemplateData } from "./ReduxApi/RootTemplateData";
import { DataConverte, removeEmptyValues } from "./DataConverte/DataConverte";
import ToastFist from "./Toast/ToastFist";
import { icon } from "@fortawesome/fontawesome-svg-core";
import icons from "./Utils/icons";
import { headerText } from "./visitConstant/repotOtherConstant";
import { DATA_SAVED, ERROR_MSG } from "../Constant";

const IconFunctionality = ({
  catID,
  name,
  fieldName,
  iconSave,
  iconRefresh,
  iconErase,
  iconPlus,
  onClick,
  setTempData,
  InputField,
}) => {
  const reduxApiData = useSelector((state) => state.reduxApiData);
  const [templateName, setTemplateName] = useState("");
  const [savedTemplateName, setSavedTemplateName] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);
  const dropdownOptions = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
  ];

  const dispatch = useDispatch();
  const handleOnChange = (text) => {
    setTemplateName(text);
    // console.log("ss", text);
  };
  const handleOptionChange = async (selectedOption) => {
    if (selectedOption) {
      // console.log("dd", selectedOption);

      let res = await dispatch(
        RootFetchTemplateData(catID, selectedOption.value)
      );
      // console.log(res, 'rexs');
      setTempData(res);
    }
  };
  const onSubmitName = async () => {
    if (!templateName) {
      setColorStatus(3)
      setToastMsg("Enter template name")
      setShowToast(true);
      return false;
    }
    let data = reduxApiData?.request?.busiParams?.[fieldName];
    //  console.log(data, "comming from diled");
    if (catID === Categories.DIAGNOSIS.catID && Array.isArray(data)) {
      const modifiedData = data.map((item) => {
        const { id, ...rest } = item; // Destructure to remove `id`
        return rest; // Return the object without `id`
      });

      data = modifiedData;
    }

    if (catID === Categories.INVESTIGATIONS.catID && Array.isArray(data)) {
      const modifiedData = data.map((item) => {
        const { investigationsDates, ...rest } = item; // Destructure to remove `investigationsDates`
        return rest; // Return the object without `id`
      });

      data = modifiedData;
    }

    if (catID === Categories.PAST_MEDICATION.catID) {
      // Assuming data is a comma-separated string like "1,3,4"
      const pastMedicationData = { pastMedicationData: data };
      data = pastMedicationData;
    }
    if (catID === Categories.LAB_TESTS_AND_IMAGING.catID) {
      const modifiedData = {
        labTestsAndImaging: data?.map((item) => item?.labTestsAndImaging?.id).join(',') || ''
      };
      data = modifiedData;
    }

    const sendableData = removeEmptyValues(data);

    let res = await saveTemplate(
      catID,
      templateName,
      sendableData,
      fieldName,
      dispatch
    );
    // console.log("Save template", res)
    if (res?.respData?.respMsg == "Template name already exists. Please use a different name.") {
      setColorStatus(3)
      setToastMsg("Template name already exists")
      setShowToast(true);
      return false;
    } else if (res?.respData?.respMsg == "done") {
      setColorStatus(0)
      setToastMsg("Template not saved")
      setShowToast(true);
      setTemplateName("");
      return true;
    }
    else if (res) {
      setColorStatus(1)
      setToastMsg(DATA_SAVED)
      setShowToast(true);
      setTemplateName("");
      return true;

    } else {
      setColorStatus(0)
      setToastMsg(ERROR_MSG)
      setTemplateName("");
      setShowToast(true);
      console.log("ss")
      return true;
    }
  };

  const { loading, templateNames, error } = useSelector(
    (state) => state?.templateNames
  );

  // const DropdownData = templateNames?.find(item => item?.category === catID);
  const DropdownData = Array.isArray(templateNames)
    ? templateNames?.find((item) => item?.category === catID)
    : null;

  const Templetdropdown = DropdownData?.names?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  // console.log(saveTemplate?.id, saveTemplate?.templateName, "ss");
  return (
    <div
      className="col-auto    col-xxl-auto ms-4  fs-6 "
      style={{ minWidth: "200px" }}
    >
      <span
        className="fw-bold  d-none d-xxl-block  text-center "
        style={{ color: headerText }}
      >
        {name}
      </span>
      <div className="d-flex align-items-center gap-2 mb-3 justify-content-md-start justify-content-xxl-center">
        <span
          className="fw-bold  d-block d-xxl-none pe-3 fs-6"
          style={{ color: headerText }}
        >
          {name}
        </span>

        {iconSave && (
          <DropdownComponent
            icon="fa-solid fa-floppy-disk"
            // icon={icons.disk}
            tooltipText="Save"
            options={null}
            handleOnChange={handleOnChange}
            onSubmit={onSubmitName}
            templateName={templateName?.toUpperCase()}
          />
        )}

        {iconRefresh && (
          <DropdownComponent
            icon="fa-solid fa-magnifying-glass"
            // icon={icons.search}
            tooltipText="Load templates"
            options={Templetdropdown || []}
            handleOnChange={handleOptionChange}
            catID={catID}
          />
        )}
        {iconErase && (
          <div onClick={onClick}>
            <CustomTooltip icon="fa-solid fa-eraser" tooltipText="Clear" />

            {/* <img
              src={icons.erase}
              alt={".."}
              style={{ width: 20, height: 20 }}
            /> */}
          </div>
        )}

        {/* <CustomTooltip
          icon="fa-solid fa-square-plus"
          tooltipText="Add Medicine"
        /> */}

        {iconPlus && (
          <AddNewDataForAll
            catID={catID || ""}
            InputField={InputField}
            name={name}
          />
        )}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </div>
  );
};

export default IconFunctionality;

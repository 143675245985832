import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL, SESSION_ID } from '../../Constant';
import { GETALLTEMPLATENAME } from '../visitConstant/ApiConstant';

// Action Types
const FETCH_TEMPLATE_NAMES_REQUEST = 'FETCH_TEMPLATE_NAMES_REQUEST';
const FETCH_TEMPLATE_NAMES_SUCCESS = 'FETCH_TEMPLATE_NAMES_SUCCESS';
const FETCH_TEMPLATE_NAMES_FAILURE = 'FETCH_TEMPLATE_NAMES_FAILURE';

// Action Creators
const fetchTemplateNamesRequest = () => ({
  type: FETCH_TEMPLATE_NAMES_REQUEST,
});

const fetchTemplateNamesSuccess = (templateNames) => ({
  type: FETCH_TEMPLATE_NAMES_SUCCESS,
  payload: templateNames,
});

const fetchTemplateNamesFailure = (error) => ({
  type: FETCH_TEMPLATE_NAMES_FAILURE,
  payload: error,
});

// Async Thunk Action
export const fetchTemplateNames = () => async (dispatch) => {

  dispatch(fetchTemplateNamesRequest());

  try {
    const section = localStorage.getItem(SESSION_ID);
    const response = await axios.post(
      `${BASE_URL}${GETALLTEMPLATENAME}`,
      {
        pubInfo: {
          sessionId: section,
        },
        request: {
          busiParams: {},
          isEncrypt: false,
          transactionId: "897987987989",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      }
    );

    const templateNames = response.data.respData.respMsg;
    dispatch(fetchTemplateNamesSuccess(templateNames));

  } catch (error) {
    dispatch(fetchTemplateNamesFailure(error.message));
  }
};

// Initial State
const initialState = {
  loading: false,
  templateNames: [],
  error: '',
};

// Reducer
export const templateNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATE_NAMES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEMPLATE_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        templateNames: action.payload,
        error: '',
      };
    case FETCH_TEMPLATE_NAMES_FAILURE:
      return {
        ...state,
        loading: false,
        templateNames: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

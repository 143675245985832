import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ProductItemCart from "./ProductItemCart";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../src/default_img/placeholder.png";
import { IMG_BASE_URL } from "../Constant";

const ProductListCard = ({ item, showEdit }) => {
  const navigate = useNavigate();
  return (
    <div className="container mt-5 py-5 px-xl-5">
      <div className="d-flex flex-column  py-full pt-1">
        <div>
          {/* <h1 className="text-center mt-100" >{item?.title}</h1> */}
          <div class="text-center">
            
            <img
              class="img-fluid rounded-start"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = { Placeholder };
              }}
              src={ IMG_BASE_URL+item?.iconURL}
              
              alt=""
            ></img>
            <p className="text-center px-8 pt-4">{item?.subTitle}</p>
          </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <div>
            {showEdit ? (
              <button
                className="btn btn-outline-primary "
                onClick={() =>
                  navigate(
                    "addcat"  + item.catId,
                    localStorage.setItem("catData", JSON.stringify(item))
                  )
                }
                type="submit"
              >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                Edit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListCard;

import { IMG_BASE_URL } from "../Constant";
import icons from "../report/Utils/icons";

function LoginScrollpages() {
  return (
    <div className=" col-md-6 col-lg-6 col-xl-6 bg-primary px-0 d-none d-md-block rounded-start-3 ">
      {/* <img
        src="./../logo192.png"
        style={{ width: 70, height: 105 }}
        class="img-fluid pt-5"
        alt="appLogo"
      ></img> */}
      <div className=" card-body ps-5">
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={IMG_BASE_URL + "socialmedia/login1.png"}
                className="d-block pt-5 "
                alt="..."
              />
              {/* <img src={icons.slider1} className=" w-100" style={{}} alt="Images" /> */}

              {/* <p className="pt-3 fw-bold h-3  text-white">
                Video consult top doctors from with your comfort
              </p> */}
            </div>
            <div className="carousel-item ">
              <img
                src={IMG_BASE_URL + "socialmedia/login2.png"}
                className="d-block pt-5 "
                alt="..."
              />
              {/* <p className="pt-3 fw-bold h-3 text-white">
                Video consult top doctors from with your comfort
              </p> */}
            </div>
            {/* <div className="carousel-item active">
              <img
                src={IMG_BASE_URL + "socialmedia/login1.png"}
                className="d-block pt-5 "
                alt="..."
              />
            </div> */}
            {/* <p className="pt-3 fw-bold h-3 text-white">
                Video consult top doctors from with your comfort
              </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginScrollpages;

import ProductItemCart from "./ProductItemCart";

import Headline from "./ Headline";

const ProductListCard = ({ item, navigate, showEdit, nama }) => {
  return (
    <div>
      {/* <div class="mb-3 fw-semibold">
        <table>
          <td>
            <h3>{item.title}</h3>
          </td>

          <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
            <div className="">
              {showEdit ? (
                <div>
                  <Link
                    to={"/products"}
                    // onClick={() =>
                    //   navigate(
                    //     localStorage.setItem("/myData", JSON.stringify(item))
                    //   )
                    // }
                    type="button"
                    class="btn btn-primary me-md-2"
                  >
                    View all
                  </Link>
                  <Link
                    to={"addcat"}
                    className="btn btn-primary "
                    onClick={() =>
                      navigate(
                        localStorage.setItem("catData", JSON.stringify(item))
                      )
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    Edit
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </table>
      </div> */}
      <Headline
        item={item}
        navigate={navigate}
        showEdit={showEdit}
        nama={nama}
      />
      <div className="d-flex flex-row flex-nowrap overflow-auto ">
        {item.items
          ?.sort((a, b) => b?.orderNo - a?.orderNo)
          ?.map((it) => (
            <div>
              <ProductItemCart key={it.itemId} product={it} showEdit />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductListCard;

import Ratings from "react-ratings-declarative";
import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  getCategoryItemByItemId,
  you_tube_view01,
  GENERATEUSERTOKEN,
  Tokan_Type_RequestCall,
  Tokan_Status_In_Queue,
  Request_Call_Msg,
  IMG_BASE_URL,
} from "../../Constant";
import { useSelector, useDispatch } from "react-redux";
import {
  increment,
  decrement,
  handleLoggin,
  Reactnativedatahshow,
} from "../../actions";
import { useNavigate } from "react-router-dom";

// import FeatureProduct from "../../landing/FeatureProduct";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLE, USER_ROLE } from "../../Constant";
import axios from "axios";

import { useParams } from "react-router-dom";
import swal from "sweetalert";
import RequestCall from "../../requestCall/RequestCall";
import ProductListdetail from "../../landing/ourservices/ProductListdetail";
import reactnatviedata from "../../reducer/reactnatviedata";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

function ProductDetail() {
  function changeRating(newRating) {}
  const myData = localStorage.getItem("myProduct");
  const itemList = JSON.parse(myData);
  const editMode = useSelector((state) => state.editMode.editMode);
  const showData = useSelector((state) => state.recatData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.isLogged);

  const Params = useParams();
  const [item, setItem] = useState(itemList);
  const [searchParams] = useSearchParams();
  const hadearValue = searchParams.get("header");
  // console.log("hadearValue : "+ hadearValue); // 'name'

  const urlItemId = Params.id;

  const getCategoryItem = async () => {
    try {
      const ItemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            itemId: urlItemId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCategoryItemByItemId,
        ItemData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      setItem(response.data?.respData?.respMsg?.item);
    } catch (error) {
      console.log(error);
      swal(error);
    }
  };

  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (DEBUG_LOG_ENABLED) {
      // console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "") {
      addToCart();
    } else {
      navigate("/login");
    }
  };
  const addToCart = async () => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: itemList.itemId,
            price: itemList.price,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        // console.log("CartService : " + itemData);
      }

      // const response = await CartService.addToCart(itemData);

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      //dispatch({type:ADD_CART, payload: 10})
      dispatch(increment(response.data.respData.respMsg.cartCount));
      swal(response.data.respData.message);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  // const fileContent = () => {
  //   axios
  //     .create({
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  //         "Access-Control-Allow-Headers":
  //           "Content-Type,X-Auth-Token, Origin, Authorization",
  //       },
  //     })
  //     .get(itemList.viewMoreTitle)
  //     .then(function (response) {
  //       setDiscription(response.data);
  //     })
  //     .catch((error) => {
  //       swal("error : " + error);
  //       if (DEBUG_LOG_ENABLED) {
  //         console.log(error);
  //       }
  //     });
  // };

  useEffect(() => {
    {
      hadearValue === "no"
        ? dispatch(Reactnativedatahshow(1))
        : dispatch(Reactnativedatahshow(0));
    }

    if (itemList === null) {
      getCategoryItem();
    } else if (urlItemId != itemList?.itemId) {
      //  getCategoryItem();
    }

    //fileContent();
  }, [localStorage.getItem("myProduct")]);
  let [hindi, setHindi] = useState(true);
  const changeHindi = () => {
    setHindi(false);
  };
  const changeEnglish = () => {
    setHindi(true);
  };

  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    address: "",
    date: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // const savaUser = (e) => {
  //   e.preventDefault();
  //   if (user.name === "" || user.mobileNo === "") {
  //     // props.showAlert("Please entery username and mobail no", "success");
  //     <div class="alert alert-danger d-flex align-items-center" role="alert">
  //       <svg
  //         class="bi flex-shrink-0 me-2"
  //         width="24"
  //         height="24"
  //         role="img"
  //         aria-label="Danger:"
  //       >
  //         <use class="#exclamation-triangle-fill" />
  //       </svg>
  //       <div>Please entery username and mobail no., success</div>
  //     </div>;

  //     //  alert("Please entery username and mobail no ");
  //     //  showAlert("Please entery username and mobail no", "success");
  //   } else {
  //     userData(user.name, user.mobileNo);
  //   }
  // };

  const section = localStorage.getItem(SESSION_ID);

  // const userData = async (name, mobileNo) => {
  //   try {
  //     const userData = JSON.stringify({
  //       pubInfo: {
  //         sessionId: localStorage.getItem(SESSION_ID),
  //       },
  //       request: {
  //         busiParams: {
  //           name: name,
  //           date: "" + moment(new Date()).format("YYYY-MM-DD"),
  //           mobileNo: mobileNo,
  //           address: "",
  //           type: Tokan_Type_RequestCall,
  //           status: Tokan_Status_In_Queue,
  //           comment: "",
  //         },
  //         isEncrypt: false,
  //         transactionId: "897987987989",
  //       },
  //     });

  //     let response = await axios.post(BASE_URL + GENERATEUSERTOKEN, userData, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  //         "Access-Control-Allow-Headers": "Content-Type",
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     swal(Request_Call_Msg);
  //   } catch (err) {
  //     swal(err);
  //     console.log(err);
  //   }
  // };

  const handleArticalClick = (item) => {
    setItem(item);
  };

  return (
    <div>
      <div
        className="container-fluid"
        style={{ overflow: "hidden", width: "100%", height: "100%" }}
      >
        <ScrollToTopOnMount />
        <div
          className="row   align-self-center "
          // style={{
          // marginTop: 100,
          // overflow: "hidden",
          // width: "100%",
          // height: "100%",
          // }}
          style={
            !showData
              ? {
                  marginTop: 100,
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                }
              : {
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                }
          }
        >
          <div className="col-md-8  " style={{ maxHeight: 410 }}>
            <div className={item?.price === "0" ? "col" : "col"}>
              <div className="row">
                <div className="col-12 mb-4 ">
                  {item?.viewType === you_tube_view01 ||
                  item?.viewType === "you_tube_view" ? (
                    <iframe
                      title="{it?.iconURL}"
                      height={500}
                      width="100%"
                      src={item?.iconURL}
                      allow="autoplay"
                      allowFullScreen
                      className="rounded-4 d-block"
                      loading="lazy"
                    ></iframe>
                  ) : (
                    <div className="card text-bg-dark mt-3 ">
                      <img
                        //  className=" card-img  border rounded ratio ratio-1x1 mt-5 "
                        className="img-fluid  "
                        alt=""
                        src={IMG_BASE_URL + item?.iconURL}
                      />
                    </div>
                  )}
                  {item?.price === "0" ? (
                    <div>
                      {editMode ? (
                        <Link
                          className="btn btn-outline-primary "
                          to={"/addcatitem" + "/"}
                          replace
                          onClick={() =>
                            localStorage.setItem(
                              "catItem",
                              JSON.stringify(item)
                            )
                          }
                          type="submit"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
                          Edit
                        </Link>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4  align-self-center  ">
            {item?.price === "0" ? (
              <div className=" " style={{ width: "95%" }}>
                <RequestCall />
              </div>
            ) : (
              <div className="col ">
                <div className=" card p-5">
                  <div className="">
                    <div className="d-inline h5 mb-0 fw-semibold mt-3">
                      {item?.title}
                    </div>
                  </div>
                  <div className="vstack">
                    <div className="d-flex mb-3 gap-2">
                      <Ratings
                        rating={4.5}
                        widgetRatedColors="rgb(253, 204, 13)"
                        changeRating={changeRating}
                        widgetSpacings="2px"
                      >
                        {Array.from({ length: 5 }, (_, i) => {
                          return (
                            <Ratings.Widget
                              key={i}
                              widgetDimension="20px"
                              svgIconViewBox="0 0 19 20"
                              svgIconPath={iconPath}
                              widgetHoverColor="rgb(253, 204, 13)"
                            />
                          );
                        })}
                      </Ratings>
                    </div>
                    {/* {JSON.parse(item.description).map((item) => (
                <h1>{item}</h1>
              ))} */}
                    <div className="my-2">
                      {/* <p className="text-muted ">

                    {parse(`${item?.subTitle.substring(0, 100) + "......."}`)}</p> */}

                      {/* {item?.subTitle.length > 200
                ? item?.subTitle.substring(0, 200) + "......."
                : item.subTitle} */}
                    </div>
                    <div className="my-2">
                      <h4 className="text-muted "> {"₹" + item?.price}</h4>
                    </div>

                    <div className="row g-3 mt-3 mb-4">
                      <div className="col">
                        <button
                          className="btn btn-outline-primary py-2 w-100"
                          onClick={addToMycart}
                        >
                          Add to cart
                        </button>
                      </div>
                      {editMode ? (
                        <Link
                          className="btn btn-outline-primary mt-3"
                          to={"/addcatitem" + "/"}
                          replace
                          onClick={() =>
                            localStorage.setItem(
                              "catItem",
                              JSON.stringify(item)
                            )
                          }
                          type="submit"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
                          Edit
                        </Link>
                      ) : null}
                    </div>
                    {editMode ? (
                      <Link
                        className="btn btn-outline-primary mt-3"
                        to={"/addcatitem" + "/"}
                        replace
                        onClick={() =>
                          localStorage.setItem("catItem", JSON.stringify(item))
                        }
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
                        Edit
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            {editMode ? (
              <Link
                className="btn btn-outline-primary mt-3"
                to={"/addcatitem" + "/"}
                replace
                onClick={() =>
                  localStorage.setItem("catItem", JSON.stringify(item))
                }
                type="submit"
              >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
              </Link>
            ) : null}
          </div>
        </div>
      </div>

      {/* <div className="row mt-5 mx-2">
        <ScrollToTopOnMount /> */}

      {/* <div className="d-none d-lg-block col-lg-1">
          <div className="image-vertical-scroller">
            <div className="d-flex flex-column">
              {Array.from({ length: 6 }, (_, i) => {
                let selected = i !== 1 ? "opacity-6" : "";
                return (
                  <a key={i}>
                    <img
                      className={"rounded mb-2 ratio " + selected}
                      alt=""
                      // onClick={changeImage}
                      src={item?.iconURL}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div> */}

      {/* <div className={item?.price === "0" ? "col" : "col-lg-8"}>
          <div className="row">
            <div className="col-12 mb-4 ">
              {item?.viewType === you_tube_view01 ||
              item?.viewType === "you_tube_view" ? (
                <iframe
                  title="{it?.iconURL}"
                  height={500}
                  width="100%"
                  src={item?.iconURL}
                  allow="autoplay"
                  allowFullScreen
                  className="rounded-4 d-block"
                  loading="lazy"
                ></iframe>
              ) : (
                <div
                  className="card text-bg-dark mt-3 "
                  style={{ height: 400 }}
                >
                  <img
                    //  className=" card-img  border rounded ratio ratio-1x1 mt-5 "
                    className="img-fluid  "
                    alt=""
                    src={item?.iconURL}
                    style={{ height: "100%" }}
                  />
                </div>
              )}
              {item?.price === "0" ? (
                <div>
                  {showEdit ? (
                    <Link
                      className="btn btn-outline-primary "
                      to={"/addcatitem" + "/"}
                      replace
                      onClick={() =>
                        localStorage.setItem("catItem", JSON.stringify(item))
                      }
                      type="submit"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                    </Link>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}

      {/* {item?.price === "0" ? (
          <div className=" col-lg-4   mt-5 ">
            <RequestCall />
          </div>
        ) : (
          <div className="col-lg-4">
            <div className="d-flex flex-column h-100">
              <div className="d-flex">
                <div className="d-inline h5 mb-0 fw-semibold mt-3">
                  {item?.title}
                </div>
              </div>
              <div className="vstack">
                
                <div className="my-2">
                 
                </div>
                <div className="my-2">
                  <h4 className="text-muted "> {"₹" + item?.price}</h4>
                </div>

                <div className="row g-3 mt-3 mb-4">
                  <div className="col">
                    <button
                      className="btn btn-outline-primary py-2 w-100"
                      onClick={addToMycart}
                    >
                      Add to cart
                    </button>
                  </div>
                  {showEdit ? (
                    <Link
                      className="btn btn-outline-primary mt-3"
                      to={"/addcatitem" + "/"}
                      replace
                      onClick={() =>
                        localStorage.setItem("catItem", JSON.stringify(item))
                      }
                      type="submit"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )} */}
      {/* </div> */}

      <div
        className="row "
        style={{ overflow: "hidden", width: "100%", height: "100%" }}
      >
        <div className={item?.price === "0" ? " col-lg-8 " : "col-lg-8"}>
          {/* <ScrollToTopOnMount /> */}
          <div className="card border-0 shadow-sm">
            {item?.viewType === "article_view_01" ? (
              <div
                className="px-3 d-flex border-bottom "
                style={{ height: 70 }}
              >
                <ul className="nav nav-pills my-auto flex-nowrap">
                  <li className="nav-item">
                    <button
                      onClick={changeEnglish}
                      className="btn btn-outline-primary "
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={changeHindi}
                      className="btn btn-outline-primary ms-4"
                    >
                      Hindi
                    </button>
                  </li>
                </ul>
              </div>
            ) : null}

            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-example2"
              data-bs-root-margin="0px 0px -40%"
              data-bs-smooth-scroll="true"
              class="scrollspy-example bg-light p-3 rounded-2"
              bindex="0"
            >
              {hindi ? (
                <div className="card-body">
                  <small>{parse(`${item?.subTitle}`)}</small>
                  <hr />
                </div>
              ) : (
                <p className="lead flex-shrink-0">
                  <small>{parse(`${item?.subTitleID}`)}</small>
                </p>
              )}
            </div>
          </div>
        </div>
        {!showData && (
          <div className="col  ">
            <ProductListdetail onArticalClick={handleArticalClick} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDetail;

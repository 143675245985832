import  { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEBUG_LOG_ENABLED,AddCategoryItem,BASE_URL,ACCESS_TOKEN } from "../Constant";
import swal from "sweetalert";
import axios from "axios";

export default function UpDatePassword (props) {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setPassword({ ...password, [e.target.name]: value });
  };



  const savePassword = async (e) => {
    e.preventDefault();
    try {
      const categoryItemData = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            currentassword: password.CurrentPassword,
            newpassword: password.NewPassword,
            confirmpassword: password.ConfirmPassword,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + AddCategoryItem,
        categoryItemData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // .then((response) => {
      swal("successful : " + response.data);
      // if (DEBUG_LOG_ENABLED) {
      //   console.log(response.data);
      // }
    } catch (err) {
      
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Up Date Password</h3>
          <div className="form-group mt-3">
            <label>Current Password</label>
            <input
              type=" Password"
              name="Current Password"
              value={password.password}
              onChange={(e) => handleChange(e)}
              className="form-control mt-1"
              placeholder="Current Password"
            />
          </div>

          <div className="form-group mt-3">
            <label>New Password</label>
            <input
              type=" Password"
              name="Confirm Password"
              value={password.password}
              onChange={(e) => handleChange(e)}
              className="form-control mt-1"
              placeholder="New Password"
            />
          </div>

          <div className="form-group mt-3">
            <label>Confirm Password</label>
            <input
              type="password"
              name="Confirm Password"
              value={password.password}
              onChange={(e) => handleChange(e)}
              className="form-control mt-1"
              placeholder="Confirm Password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              onClick={savePassword}
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
          <p className="text-center mt-2">
            Forgot{" "}
            <a href="#" onClick={() => navigate("/forgotpassword")}>
              password?
            </a>
          </p>
        </div>
      </form>
    </div>
  );
}

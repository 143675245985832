import React from 'react';
import ScrollToTopOnMount from './ScrollToTopOnMount';

const RefundPolicy = () => {
  return (
    <div className="container ">
      <ScrollToTopOnMount />
      <div className="refund-policy-content " style={{ marginTop: 100 }}>
        <h1>Refund Policy</h1>
        <small className="lead text-cente fs-6">Last Updated:15 Feb 2024</small>

        <div>
          <h3>Interpretation and Definitions</h3>
          <p>
            Words capitalized herein have meanings as defined under the
            following conditions. The following definitions apply regardless of
            singular or plural usage.
          </p>

          <h3>Definitions</h3>
          <p>For the purposes of this Refund Policy:</p>
          <ul>
            <li>
              <strong>Company:</strong> Jivdani Hospital, a service provider
              website offering online doctor consultations and health-related
              services.
            </li>
            <li>
              <strong>Service:</strong> The online doctor consultations and
              health-related services provided by the Company.
            </li>
            <li>
              <strong>Customer:</strong> Any individual or entity that purchases
              or intends to purchase services from the Company.
            </li>
            <li>
              <strong>Website:</strong> The Jivdani Hospital website, accessible
              from{" "}
              <a href="https://jivdanihospital.com">
                https://jivdanihospital.com
              </a>
              .
            </li>
            <li>
              <strong>You:</strong> The individual accessing or using the
              Service, or any legal entity on whose behalf such individual
              accesses or uses the Service.
            </li>
          </ul>
        </div>

        <div>
          <h3>Refund Policy</h3>
          <p>
            We understand that situations may arise where you might request a
            refund for the services provided by Jivdani Hospital. Our refund
            policy is as follows:
          </p>
          <ol>
            <li>
              <strong>Cancellation and Refunds:</strong>
              <ul>
                <li>
                  You have the right to cancel your appointment or service order
                  within 24 hours of booking.
                </li>
                <li>
                  To request a cancellation and refund, please contact us via{" "}
                  <a href="https://jivdanihospital.com/#/contact">
                    https://jivdanihospital.com/#/contact
                  </a>{" "}
                  within the specified time frame.
                </li>
                <li>
                  Refunds for eligible cancellations will be processed using the
                  original method of payment within 2 weeks (14 days) of
                  receiving your cancellation request.
                </li>
              </ul>
            </li>
            <li>
              <strong>Non-Refundable Services:</strong>
              <p>
                Some services may be non-refundable due to the nature of the
                service provided or specific arrangements made by the Company.
                In such cases, it will be clearly indicated at the time of
                booking.
              </p>
            </li>
            <li>
              <strong>Refund Eligibility:</strong>
              <p>
                Refunds will only be issued for cancellations made within the
                specified time frame and in accordance with our cancellation
                policy. Refunds will not be provided for services that have
                already been performed or completed, unless there are
                extenuating circumstances deemed acceptable by the Company.
              </p>
            </li>
          </ol>
        </div>

        <div>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about our Refund Policy or need assistance
            with a refund request, please contact us via{" "}
            <a href="https://jivdanihospital.com/#/contact">
              https://jivdanihospital.com/#/contact
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};





export default RefundPolicy
// categories.js

const Categories = {
    COMPLAINTS: { catID: 1, name: "complaintsData" },
    ALLERGIES: { catID: 2, name: "allergies" },
    PERSONAL_HISTORY: { catID: 3, name: "personalHistory" },
    PAST_MEDICAL_HISTORY: { catID: 4, name: "pastMedicalHistory" },
    FAMILY_HISTORY: { catID: 5, name: "familyHistory" },
    GENERAL: { catID: 6, name: "general" },
    CVS: { catID: 7, name: "cvs" },
    RS: { catID: 8, name: "rs" },
    CNS: { catID: 9, name: "cns" },
    PA: { catID: 10, name: "pa" },
    ENT: { catID: 11, name: "ent" },
    DIAGNOSIS: { catID: 12, name: "diagnosisData" },
    LAB_TESTS_AND_IMAGING: { catID: 13, name: "labTestsAndImagingData" },
    PAST_MEDICATION: { catID: 14, name: "pastMedicationData" },
    TESTS_REQUESTED: { catID: 15, name: "testsRequestedData" },
    INVESTIGATIONS: { catID: 16, name: "investigationsData" },
    SPECIALITY: { catID: 17, name: "speciality" },
    GENERAL_EXAMINATIONS: { catID: 18, name: "generalExaminations" },
    HISTORY: { catID: 19, name: "historyData" },
    MENSTRUAL_INFO: { catID: 20, name: "menstrualInfoData" },
    PHYSICAL_EXAMINATION: { catID: 21, name: "physicalExamination" },
    CURRENT_MEDICATION: { catID: 22, name: "currentMedicationData" },
    ADVICE: { catID: 23, name: "advice" },
    ROOT_TEMPLATE: { catID: 24, name: "ROOT TEMPLATE" },
    MEDICINE: { catID: 25, name: "MEDICINE" },
    DURATION: { catID: 26, name: "DURATION" },
    REGULARITY: { catID: 27, name: "REGULARITY" },
    PREGNANCY_OUTCOME: { catID: 28, name: "PREGNANCY_OUTCOME" },
    DOSAGE: { catID: 29, name: "DOSAGE" },
    UNITS: { catID: 30, name: "UNITS" },
    WHENS: { catID: 31, name: "WHENS" },
    FREQUENCY: { catID: 32, name: "FREQUENCY" },
    PERIOD: { catID: 33, name: "PERIOD" },
    DOCTOR_LIST: { catID: 34, name: "DOCTOR_LIST" },
    


};

export default Categories;

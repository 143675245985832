import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import FeatureProduct from "../landing/FeatureProduct";
import { useParams } from "react-router-dom";
import {
  SESSION_ID,
  BASE_URL,
  PRODUCT_LIST,
  ACCESS_TOKEN,
  SEARCH_API,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import { useState, useEffect } from "react";
// import GetCategoryItemService from "../services/GetCategoryItemService";
import { useNavigate } from "react-router-dom";
import ErrorImages from "../Images/error.png";
import swal from "sweetalert";
// import SearchApi from "./SearchApi";

function ProductList() {
  const [viewType, setViewType] = useState({ grid: true });

  let [ItemShow, setItemShow] = useState(true);

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }
  const navigate = useNavigate();
  const { catId } = useParams();
  //const responseJson = navigate.getParam("myJSON");

  const [catItemList, setCatItemList] = useState([]);

  const getCategoryLists = async () => {
    try {
      const categoryListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: catId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });


      let response = await axios.post(
        BASE_URL + PRODUCT_LIST,
        categoryListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal("data : "+response.data.respData.respMsg.sessionId);
      setCatItemList(response.data.respData.respMsg.catItemList);
      if (DEBUG_LOG_ENABLED) {
      //  console.log("catItemList : " + catItemList);
      }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  useEffect(() => {
    // const myData = localStorage.getItem("myData");
    // const itemList = JSON.parse(myData);
    // if (itemList?.items?.lenght > 0) {
    //   setCatItemList(itemList.items);
    // } else {
    //   getCategoryLists();
    // }
    const value = "dr";

    Search(value);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      Search(value);
    } else {
      setItemShow(false);
      Search("dr");
    }
  };

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   if (value) {
  //     Search(value);
  //   } else {
  //     const myData = localStorage.getItem("myData");
  //     const itemList = JSON.parse(myData);
  //     setItemShow(true);
  //     setCatItemList(itemList.items);
  //   }

  const Search = async (value) => {
    try {
      const searchApi = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            title: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await SearchApi.create(searchApi);

      let response = await axios.post(BASE_URL + SEARCH_API, searchApi, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      setCatItemList(response.data.respData.respMsg.catItemList);
      if (response.data.respData.respMsg.catItemList.length === 0) {
        setItemShow(false);
      } else {
        setItemShow(true);
      }
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container mt-5 pt-5 py-4 px-xl-5" style={{ minHeight: "700px" }}>
      <div className="row mb-4 mt-lg-3">
        <div className="col">
          <div className="d-flex flex-column h-100">
            <div className="row my-3 mx-auto" style={{ width: "80%" }}>
              <div className="input-group">
                <input
                  className="form-control"
                  // value={searchApi}
                  onChange={(e) => handleChange(e)}
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  size="32"
                />
                <button type="button" className="btn btn-primary ">
                  <FontAwesomeIcon icon={["fas", "search"]} />
                </button>
              </div>
            </div>
            {ItemShow ? (
              <div
                className={
                  "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 " +
                  (viewType.grid ? "row-cols-xl-4" : "row-cols-xl-2")
                }
              >
                {catItemList.map((item, index) => {
                  if (
                    item.viewType === "scroll_01" ||
                    item.viewType === "article_view_01" ||
                    item.viewType === "CustomerReview" ||
                    item.viewType === "you_tube_view" ||
                    item.viewType === "you_tube_view_01" ||
                    //item.viewType === "Article_view" ||
                    item.viewType === "about_us_view" ||
                    item.viewType === "you_tube_view01"
                  ) {
                    <div></div>;
                  } else {
                    if (viewType.grid) {

                      return <FeatureProduct key={index} product={item} />;
                    }
                    return <FeatureProduct key={index} product={item} />;
                  }


                })}
              </div>
            ) : (
              <div class="d-flex justify-content-center">
                <div className=" text-center">
                  <img src={ErrorImages} style={{ width: "18rem" }} />
                  <h5 className="card-title">
                    Item not found, please try another way
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;

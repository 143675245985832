import React from "react";
import { Color } from "../report/visitConstant/Color";

const Dropwoun = ({ token, showEdit, handleStatusChange, Size }) => {
  // console.log(token, " tokedna data")
  let filterColor;

  if (Size == "t") {
    if (token?.status == 2) {
      filterColor = Color.primary;
    } else if (token?.status == 3) {
      filterColor = Color.completeBG;
    } else if (token?.status == 4) {
      filterColor = Color.pendingBg;
    } else {
      filterColor = "black";
    }
  } else {
    if (token?.status == 4) {
      filterColor = "black";
    } else {
      filterColor = "white";
    }
  }
  const arrowColor = token?.status === "4" ? "#ff9800" : Color.arrow || "#fff"; // Default white for the arrow color

  return (
    <div>
      {showEdit && (
        <div className={`input-group mb-${Size == "t" ? "0" : "2"} `}>
          <select
            className="form-select  text-center "
            value={token.status}
            onChange={(e) => {
              e.stopPropagation();
              handleStatusChange(token, e.target.value);
            }}
            style={{
              height: Size == "t" ? "38px" : Size == "s" ? "40px" : "44px",
              fontSize: Size == "t" ? "" : Size == "s" ? "18px" : "20px",
              width: Size == "t" ? "80px" : "",
              backgroundColor:
                Size == "t"
                  ? ""
                  : token?.status == 4
                  ? "#FCFF6F"
                  : Color.primary,
              color: filterColor,
              outline: "none",
              boxShadow: "none",
              cursor: "pointer",
            }}
          >
            {token.status == "3" ? (
              <option
                value="3"
                style={{ backgroundColor: "white", color: "black" }}
              >
                Complete
              </option>
            ) : (
              <>
                <option
                  value="1"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  In Queue
                </option>
                <option
                  value="2"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  In Progress
                </option>
                <option
                  value="4"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  Pending
                </option>
              </>
            )}
          </select>
        </div>
      )}
    </div>
  );
};

export default Dropwoun;

import React, { useState, useEffect } from "react";

import axios from "axios";

import { MasterApi } from "../masterApi/MasterApi";

import Categories from "../../visitConstant/Categories";
import { Loader } from "./Loader";

import useDebounce from "../../useDebounce/useDebounce";
import { DeleteApi } from "../masterApi/DeleteApi";
import { AddEditMedicine } from "../masterApi/AddEditMedicine";
import { TableFooter } from "../master/TableFooter";
import { Color } from "../../visitConstant/Color";
import { TableHeader } from "./TableHeader";
import {
  MedicineHeader,
  DiagnosisHeader,
  InvestigationHeader,
  Complaints,
} from "./HeaderData";
import { useDispatch } from "react-redux";

import { Reactnativedatahshow } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Medication from "../../modal/AddMedication";
import MasterSideBar from "./MasterSideBar";

import icons from "../../Utils/icons";
import MedicineTable from "./masterComponents/MedicineTable";
import ToastFist from "../../Toast/ToastFist";
import { ACCESS_TOKEN, DATA_SAVED, ERROR_MSG } from "../../../Constant";

import DiagnosisTable from "./masterComponents/DiagnosisTable";
import InvestigationTable from "./masterComponents/InvestigationTable";
import ComplaintsTable from "./masterComponents/ComplaintsTable";
import { components } from "react-select";

const MedicinePage = () => {
  const [medicines, setMedicines] = useState([]);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const debouncedInput = useDebounce(search, 500);
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const [catId, setCatId] = useState(Categories.MEDICINE.catID);

  const [editMedicine, setEditMedicine] = useState({
    id: "",
    name: "",
    contents: "",
    unit: {
      id: "",
      name: "",
    },
    dosage: {
      id: "",
      name: "",
    },
    whens: {
      id: "",
      name: "",
    },
    frequency: {
      id: "",
      name: "",
    },
    duration: "",
    type: "",
    notes: "",
    active: true
  });
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const resetTheValue = () => {
    setEditingId(null);
    setEditMedicine({
      id: "",
      name: "",
      contents: "",
      type: "",
      notes: "",
      duration: "",
      unit: {
        id: "",
        name: "",
        active: true
      },
      dosage: {
        id: "",
        name: "",
        active: true
      },
      whens: {
        id: "",
        name: "",
        active: true
      },
      frequency: {
        id: "",
        name: "",
        active: true
      },
      active: true
    });
  }

  const handleSideBar = (clickedCatId) => {
    setCatId(clickedCatId);
    getData("", 10, 1, clickedCatId);
  };

  useEffect(() => {
    setLoading(true);
    getData();
    dispatch(Reactnativedatahshow("DATASHOW"));

    return () => {
      dispatch(Reactnativedatahshow(""));
    };
  }, []);

  //Hitting api after certain delay
  useEffect(() => {
    if (debouncedInput) {
      setLoading(true);
      getData(debouncedInput);
    }
  }, [debouncedInput]);

  const downloadCSV = () => {
    const csvContent = jsonToCSV(medicines);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const jsonToCSV = (json) => {
    const header = Object.keys(json[0]).join(",");
    const rows = json.map((row) => Object.values(row).join(","));

    // Calculate number of columns
    const numColumns = header.split(",").length;

    // Create padding for centering text
    const totalPaddingCells = numColumns - 1;
    const leftPaddingCells = Math.floor(totalPaddingCells / 2);
    const rightPaddingCells = totalPaddingCells - leftPaddingCells;

    const centeredText = `${",".repeat(
      leftPaddingCells
    )}"Jivdani Hospital"${",".repeat(rightPaddingCells)}`;

    const csvContent = [centeredText, header, ...rows].join("\n");
    return csvContent;
  };

  const getData = async (
    searchKey = "",
    PerPage = 10,
    crntPage = 1,
    activeCatId
  ) => {
    try {
    //  console.log("for api", activeCatId);
      const results = await MasterApi(
        !activeCatId ? Categories.MEDICINE.catID : activeCatId,

        crntPage,
        searchKey,
        PerPage
      );
      if (!results) {
      //  console.log("result", results);
      } else {
        setMedicines(results);
      }
      // console.log("result", results);
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchKey) => {
    if (searchKey) {
      setSearch(searchKey);
    } else {
      setSearch("");
      getData("", rowsPerPage, currentPage);
    }
    setCurrentPage(1);
  };

  const handleEdit = (id, med) => {
    setEditingId(id);
    setEditMedicine({
      id: med?.id,
      name: med?.name,
      contents: med?.contents,
      unit: { id: med.unit?.id, name: med.unit?.name },
      dosage: { id: med.dosage?.id, name: med.dosage?.name },
      whens: { id: med.whens?.id, name: med.whens?.name },
      frequency: { id: med.frequency?.id, name: med.frequency?.name },
      duration: med?.duration,
      type: med?.type,
      notes: med?.notes

    })

  };

  const handleEditChange = (e, obj = null) => {
    const { name, value } = e.target;
    if (obj != null) {
      setEditMedicine({
        ...editMedicine,
        [obj]: {
          ...editMedicine[obj],
          name: value
        }
      });
    } else {
      setEditMedicine({ ...editMedicine, [name]: name == 'name' ? value.toUpperCase() : value });
    }
  };


  const saveEdit = async () => {

    const response = await AddEditMedicine({ data: editMedicine });

    if (response) {
      setMedicines(
        medicines.map((med) =>
          med.id === editingId ? { ...med, ...editMedicine } : med
        )
      );
      setShowToast(true);
      setColorStatus(1);
      setToastMsg(DATA_SAVED);
      resetTheValue();
    } else {
      resetTheValue();
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowConfirm(true);
  };

  const confirmDelete = async (confirmed) => {
    if (confirmed && deleteId) {
      try {
        const reponse = await DeleteApi(catId, deleteId);
        if (reponse) {
          setMedicines(medicines.filter((med) => med.id !== deleteId));
          setShowToast(true);
          setColorStatus(1);
          setToastMsg("Deleted successfully");
          clearSearch();
        } else {

          setColorStatus(0);
          setShowToast(true);
          setToastMsg(ERROR_MSG);
        }
      } catch (error) {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(ERROR_MSG);
        console.error("Error deleting data:", error);
      }
    }
    setShowConfirm(false);
    setDeleteId(null);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    getData("", e.target.value, currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData("", rowsPerPage, page);
  };

  const indexOfLastMedicine = currentPage * rowsPerPage;
  const indexOfFirstMedicine = indexOfLastMedicine - rowsPerPage;

  const clearSearch = () => {
    setSearch("");
    getData("", rowsPerPage, currentPage);
  };

 // console.log("Medicines", medicines);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2 pe-4 pt-4 float-end" style={{ width: '90%', marginTop: 90 }}>
        <h6 style={{ color: Color.primary }}>Medicine Analysis</h6>
        <div className="position-relative w-50">
          <input
            type="text"
            placeholder="Search medicines..."
            value={search}
            onChange={
              (e) => handleSearch(e.target.value.toUpperCase())
              // setSearch(e.target.value)
            }
            className="form-control pe-5"
            style={{ height: "38px" }} // Set height here
          />
          {search ? (
            <FontAwesomeIcon
              icon="fa-solid fa-xmark"
              className="text-primary"
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={clearSearch}
            />
          ) : (
            <FontAwesomeIcon
              icon="fa-solid fa-magnifying-glass"
              className="text-primary"
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          )}
        </div>
        <div className="d-flex">
          <button
            className="btn btn-outline-black border  me-3"
            style={{ fontSize: 15, height: "38px" }}
            onClick={() => downloadCSV()}
          >
            {/* <img
              src={icons.exports}
              alt={".."}
              style={{ width: 22, height: 22, marginRight: 4 }}
            /> */}
            <FontAwesomeIcon
              icon="fa-solid fa-download"
              className="pe-2"
              style={{
                color: "gray",
              }}
            />
            Export
          </button>
          {/* <button className="btn btn-primary">Create New +</button> */}
          {<Medication customeKey={true} />}
        </div>
      </div>

      <div
        className=" "
        style={{ flexDirection: "row", display: "flex", width: "100%" }}
      >
        <div
          className="col d-flex flex-column align-items-center py-3 me-3  px-2 bg-white mb-1"
          style={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            overflowY: "auto",
            overflowX: "hidden",
            height: '86vh',
            width: "10%"
          }}
        >
          <MasterSideBar handleSideBar={handleSideBar} />
        </div>
        <div className="pe-2" style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column', width: '90%' }}>
          <div className="" style={{ flexGrow: 1, }} >
            {/* Table header */}
            <table
              className=" table-fixed  table-borderless "
              style={{
                width: "100%",
                borderRadius: 8,
                overflow: "hidden",
              }}
            >
              <TableHeader
                headerName={
                  catId == Categories.MEDICINE.catID
                    ? MedicineHeader
                    : catId == Categories.DIAGNOSIS.catID
                      ? DiagnosisHeader
                      : catId == Categories.INVESTIGATIONS.catID
                        ? InvestigationHeader
                        : catId == Categories.COMPLAINTS.catID
                          ? Complaints
                          : []
                }
              />
            </table>

            {/* Table body */}
            <table
              className="table table-fixed table-borderless "
              style={{ borderRadius: 8 }}
            >
              <tbody
                style={{
                  display: "block",
                  maxHeight: "calc(75vh - 50px)",
                  overflow: "auto",
                  overflowX: "hidden",
                  width: "100%",
                }}
              >
                {loading ? (
                  <Loader />
                ) : Array.isArray(medicines) && medicines.length > 0 ? (
                  medicines?.map((medicine, index) =>
                    catId == Categories.MEDICINE.catID ? (
                      <tr
                        className="mt-2 "
                        key={medicine.id}
                        style={{
                          display: "table",
                          width: "100%",
                          tableLayout: "fixed",
                          minHeight: 45,
                          maxHeight: 60,
                          verticalAlign: "middle",
                          borderRadius: 8,
                          fontSize: 14,
                          backgroundColor: editingId === medicine?.id ? Color.focusColor : "transparent", // Highlight when editing
                          transition: "background-color 0.3s ease", // Smooth transition effect
                        }}
                      >
                        <td
                          className="text-start"
                          style={{
                            width: "30px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            backgroundColor: editingId === medicine?.id && "transparent",
                          }}
                        >
                          {indexOfFirstMedicine + index + 1}
                        </td>

                        <td className="text-start" style={{
                          width: "260px",
                          backgroundColor: editingId === medicine?.id && "transparent",
                        }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={editMedicine?.name}
                              onChange={handleEditChange}
                              className="form-control "
                              style={{ width: "99%", margin: 0, height: 32, paddingLeft: 4, paddingRight: 4, fontSize: 14, fontSize: 14, }}
                            />
                          ) : (
                            medicine?.name
                          )}
                        </td>
                        <td className="text-start" style={{
                          width: "140px",
                          backgroundColor: editingId === medicine?.id && "transparent",
                        }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="contents"
                              name="contents"
                              value={editMedicine?.contents}
                              onChange={handleEditChange}
                              className="form-control "
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.contents
                          )}
                        </td>
                        <td className="text-start" style={{ width: "80px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="type"
                              name="type"
                              value={editMedicine?.type}
                              onChange={handleEditChange}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.type
                          )}
                        </td>
                        <td className="text-start" style={{ width: "80px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="dosage"
                              name="dosage"
                              value={editMedicine?.dosage?.name}
                              onChange={(e) => handleEditChange(e, "dosage")}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32, }}
                            />
                          ) : (
                            medicine?.dosage?.name
                          )}
                        </td>
                        <td className="text-start" style={{ width: "80px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="duration"
                              name="duration"
                              value={editMedicine?.duration}
                              onChange={handleEditChange}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.duration
                          )}
                        </td>
                        <td className="text-start" style={{ width: "80px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="unit"
                              name="unit"
                              value={editMedicine?.unit?.name}
                              onChange={(e) => handleEditChange(e, "unit")}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.unit?.name
                          )}
                        </td>

                        <td className="text-start" style={{ width: "100px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="frequency"
                              name="frequency"
                              value={editMedicine?.frequency?.name}
                              onChange={(e) => handleEditChange(e, "frequency")}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.frequency?.name
                          )}
                        </td>
                        <td className="text-start" style={{ width: "100px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="whens"
                              name="whens"
                              value={editMedicine?.whens?.name}
                              onChange={(e) => handleEditChange(e, "whens")}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.whens?.name
                          )}
                        </td>

                        <td className="text-start" style={{ width: "100px", backgroundColor: editingId === medicine.id && "transparent", }}>
                          {editingId === medicine?.id ? (
                            <input
                              type="text"
                              id="notes"
                              name="notes"
                              value={editMedicine?.notes}
                              onChange={handleEditChange}
                              className="form-control"
                              style={{ width: "100%", margin: 0, paddingLeft: 4, paddingRight: 4, fontSize: 14, height: 32 }}
                            />
                          ) : (
                            medicine?.notes
                          )}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            width: "90px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            backgroundColor: editingId === medicine?.id && "transparent",
                          }}
                        >
                          {editingId === medicine?.id ? (

                            <FontAwesomeIcon icon="fa-solid fa-floppy-disk"
                              className="text-primary"
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={saveEdit}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="fa-solid fa-pencil"
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                color: "gray",
                              }}
                              onClick={() => handleEdit(medicine?.id, medicine)}
                            />
                            // <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                          )}
                          <FontAwesomeIcon icon="fa-solid fa-trash"
                            style={{ color: "gray", cursor: "pointer", marginLeft: "15px", }}
                            onClick={() => handleDelete(medicine?.id)}
                          />
                        </td>
                      </tr>
                    ) : catId == Categories.DIAGNOSIS.catID ? (
                      <DiagnosisTable
                        medicine={medicine}
                        duration={medicine}
                        date={medicine}
                        index={index}
                        indexOfFirstMedicine={0}
                        editingId={editingId}
                        editMedicine={editMedicine}
                        handleEditChange={handleEditChange}
                        saveEdit={saveEdit}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    ) : catId == Categories.INVESTIGATIONS.catID ? (
                      <InvestigationTable
                        medicine={medicine}
                        duration={medicine}
                        date={medicine}
                        index={index}
                        indexOfFirstMedicine={0}
                        editingId={editingId}
                        editMedicine={editMedicine}
                        handleEditChange={handleEditChange}
                        saveEdit={saveEdit}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    ) : catId == Categories.COMPLAINTS.catID ? (
                      <ComplaintsTable
                        complaint={medicine}
                        index={index}
                        indexOfFirstMedicine={0}
                        editingId={editingId}
                        editMedicine={editMedicine}
                        handleEditChange={handleEditChange}
                        saveEdit={saveEdit}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    ) : null
                  )
                ) : (
                  <tr
                    style={{
                      display: "table",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <td
                      colSpan="9"
                      className="text-center align-upper"
                      style={{ height: "400px" }}
                    >
                      No items found.🩺🥼🫀
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{ flexShrink: 0 }}>
            <TableFooter
              rowsPerPage={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              medicinesLength={medicines?.length}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      {showConfirm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            width: "100vw",
          }}
          aria-hidden="true"
          onClick={() => setShowConfirm(false)}
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-body">
                <p>Are you sure you want to delete this medicine?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => confirmDelete(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => confirmDelete(true)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </div>
  );
};

export default MedicinePage;

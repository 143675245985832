

const { color } = require("framer-motion");

module.exports = Object.freeze({
 





///  color

visitBackgroundColor: "#efeff1",      // Background color for visit
//visitPadBackgroundSecondary: "#ffffff", // Secondary background color for visit pad
allVisitPadBackground: '#d0d5dd',    // Background color for all visit pads
tableBackgroundHeader: "#eaecf5",     // Background color for table header
iconColor: '#667085'  ,                // Color for icons
headerText :'#3e4784',


  
  
});

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateField } from "../ReduxApi/ReduxApi";
import IconFunctionality from "../IconFunctionality";
import moment from "moment";

const DateCalculator = ({ patientData }) => {
  const [quantity, setQuantity] = useState();
  const [dateType, setDateType] = useState("days");
  const [calculatedDate, setCalculatedDate] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (quantity) {
      handleButtonClick(dateType);
    } else {
      setCalculatedDate("");
    }
  }, [quantity]);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleButtonClick = (type) => {
    setDateType(type);
    calculateDate(quantity, type);
  };

  const calculateDate = (quantity, type) => {
    if (!quantity) return;
    const currentDate = new Date();
    let newDate = new Date();

    switch (type) {
      case "days":
        newDate.setDate(currentDate.getDate() + parseInt(quantity));
        break;
      case "weeks":
        newDate.setDate(currentDate.getDate() + parseInt(quantity) * 7);
        break;
      case "months":
        newDate.setMonth(currentDate.getMonth() + parseInt(quantity));
        break;
      default:
        break;
    }

    setCalculatedDate(newDate.toISOString().split("T")[0]); // Format to YYYY-MM-DD

    dispatch(updateField("nextVisit", newDate.toISOString().split("T")[0]));
  };

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setCalculatedDate("");
    setDateType("days");
    setQuantity("");
    dispatch(updateField("nextVisit", ""));
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

    useEffect(() => {
      handleClearData();
    }, [patientData]);

  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (e) => {
    const dateValue = e.target.value; // Get selected date
    dispatch(updateField("nextVisit", dateValue));
    setCalculatedDate(
      dateValue ? moment(dateValue).format("DD-MMM-YYYY (dddd)") : ""
    ); // Update formatted date state
  };

  return (
    <>
      <IconFunctionality
        name="Next Visit"
        // iconSave
        // iconRefresh
        iconErase
        onClick={() => handleClearData()}
      />

      <div className="col-12 col-xxl-9 px-4 ">
        <div className="row">
          <div className="col-3">
            <input
              type="number"
              className="form-control"
              placeholder="days/ week/ months"
              value={quantity}
              onChange={handleQuantityChange}
            />
          </div>
          <div className="col-3 px-0">
            <div
              className=" border border-primary d-flex rounded gap-0 column-gap-2"
              role=""
            >
              <button
                type="button"
                className={` col btn btn-outline-primary border-0 ${
                  dateType === "days" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("days")}
              >
                Days
              </button>
              <button
                type="button"
                className={` col btn btn-outline-primary border-0 ${
                  dateType === "weeks" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("weeks")}
              >
                Weeks
              </button>
              <button
                type="button"
                className={` col btn btn-outline-primary border-0  ${
                  dateType === "months" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("months")}
              >
                Months
              </button>
            </div>
          </div>

          {/* <div className="col-auto d-flex align-items-center ">
          <input
              type="date"
              className="form-control border-0"
              style={{width:45}}
            
             onChange={handleDateChange}
            />
            <input
              type="text"
              className="form-control border-0"
              value={moment(calculatedDate).format("DD-MMM-YYYY (dddd)")}
              readOnly
            />
          </div> */}

          <div className="col-auto d-flex align-items-center ">
            {/* Container with single border around both inputs */}
            <div
              className="d-flex border rounded "
              style={{ borderRadius: "7px" }}
            >
              {" "}
              {/* Added border and rounded corners */}
              {/* Date input */}
              <input
                type="date"
                className="form-control border-0" // Remove default input border
                style={{ width: 45 }} // Adjust width and round only left corners
                onChange={handleDateChange}
              />
              {/* Text input to display formatted date */}
              <input
                type="text"
                className="form-control border-0" // Remove default input border
                value={
                  calculatedDate
                    ? moment(calculatedDate).format("DD-MMM-YYYY (ddd)")
                    : ""
                } // Format selected date
                readOnly // Make input read-only
                // Round only right corners
                placeholder="Select Date"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateCalculator;

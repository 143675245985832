import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomDropdown from "../../CustomDropdown/CustomDropdown.js";
import CustomDropdownSingleValue from "../../CustomDropdown/CustomDropdownSingalValue.js";
import CustomTooltip from "../../CustomTooltip";
import useDebounce from "../../useDebounce/useDebounce.js";
import axios from "axios";
import {
  ACCESS_TOKEN,
  BASE_URL,
  searchMedicationsByName,
  SESSION_ID,
} from "../../../Constant.js";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi.js";
import Select from "react-select";
import {
  convertCmedicinToServerFormat,
  ConvertObj,
  convertOnlyValue,
  convertSelectedDataToServerFormat,
  DataConverte,
  DataConvertecheck,
  filterNewOptions,
  filterNumericValues,
  isValidData,
  removeMatchingItems,
  ReversObj,
} from "../../DataConverte/DataConverte.js";
import IconFunctionality from "../../IconFunctionality.js";
import Medication from "../../modal/AddMedication.js";
import Categories from "../../visitConstant/Categories.js";
import IconFunctionalityForMedicine from "../../IconFunctionalityForMedicine.js";
import { frequencyOptions } from "../../visitConstant/localDropdowns/frequencyOptions.js";
import { whenOptions } from "../../visitConstant/localDropdowns/whenOptions.js";
import { unitOptions } from "../../visitConstant/localDropdowns/unitOptions.js";
import { dosageOptions } from "../../visitConstant/localDropdowns/dosageOptions.js";
import { durationOptions } from "../../visitConstant/localDropdowns/durationOptions.js";
import ToastFist from "../../Toast/ToastFist.js";
import apiHelper from "../../ApiHelpar/apiHelper.js";
import {
  ADDMEDICINE,
  DROPDOWNSARCHYNAME,
} from "../../visitConstant/ApiConstant.js";

import CreatableSelect from "react-select/creatable";

import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from "../../ui/CSS/customStyles.js";

import { handleNewOptions } from "../../Utils/handleNewOptions";
import DurationDropdown from "../../CustomDropdown/durationDropdown.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentsEdtiable from "../ContentsEdtiable.js";
import DropdownSearchApl from "../../ApiHelpar/DropdownSearchApl.js";
// const MedicineTable = ({ DropdownData, patientData }) => {
const MedicineTable = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const reduxApiData = useSelector((state) => state.reduxApiData);
  // console.log("kdkd,", reduxApiData);
  const inputRefs = useRef([]);
  const [rows, setRows] = useState([
    {
      id: Date.now(),
      medicineId: "",
      unit: { value: "", label: "" },
      medicationScheduleList: [
        {
          dosages: {
            value: "",
            label: "",
          },
          whens: {
            value: "",
            label: "",
          },
          frequency: {
            value: "",
            label: "",
          },
          duration: "",
          notes: "",
        },
      ],
    },
  ]);
  // console.log("rows ,,", rows);
  const [PastMedication, setPastMedication] = useState([]);
  const [pastsetOptions, setpastsetOptions] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("Item has already been selected");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState([]);

  const [SmallDropDwonSearch, setSmallDropDwonSearch] = useState("");
  const [searchWithCatID, setSearchWithCatID] = useState("");

  const defaultOptions = visitPadData?.pastMedications || [];
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [TempletDataReserve, setTempletDataReserve] = useState([]);
  const CurrentMedicationData = patientData?.currentMedicationData;

  const [isLoading, setIsLoading] = useState(false); // Loader state

  const [isLoadingpast, setIsLoadingPast] = useState(false); // Loader state

  const dispatch = useDispatch();
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);
  const debouncedInput = useDebounce(searchTerm);

  const Past_debouncedInput = useDebounce(inputValue);

  const handleInputChange = (value, rowIndex) => {
    const newSearchTerms = [...searchTerms];
    newSearchTerms[rowIndex] = value;
    setSearchTerms(newSearchTerms);
    setSearchTerm((newSearchTerms[rowIndex] = value));
  };

  const handleClearData = useCallback(() => {
    setPastMedication("");
    setTempletDataReserve([]);
    dispatch(updateField("pastMedicationData", ""));
  }, [dispatch]);

  const handleClearDataC = useCallback(() => {
    setRows([
      {
        id: Date.now(),
        medicineId: "",
        unit: {
          value: "",
          label: "",
        },
        medicationScheduleList: [
          {
            dosages: {
              value: "",
              label: "",
            },
            whens: {
              value: "",
              label: "",
            },
            frequency: {
              value: "",
              label: "",
            },
            duration: "",
            notes: "",
          },
        ],
      },
    ]);

    dispatch(updateField("currentMedicationData", []));
    setSearchTerm("");
    setSearchTerms([]);
  }, [dispatch]);

  //Hitting api after certain delay
  useEffect(() => {
    // const newOptions = filterNewOptions(rows, defaultOptions);
    if (debouncedInput && debouncedInput.length >= 1) {
      const inputExistsInDefault = defaultOptions.some((option) => {
        return option?.name
          ?.toString()
          .toLowerCase()
          .startsWith(debouncedInput.toString().toLowerCase());
      });

      // console.log(defaultOptions, " default option")
      // console.log(inputExistsInDefault, " what do for ", debouncedInput)

      if (!inputExistsInDefault) {
        setLoading(true);
        fetchData(debouncedInput);
        // console.log("1");
      } else {
        setOptions(defaultOptions);
        // console.log("2");
      }
    } else {
      setOptions(defaultOptions);
      // console.log("3");
    }
  }, [debouncedInput]);

  useEffect(() => {
    if (Past_debouncedInput) {
      PatfetchData(Past_debouncedInput);
    }
  }, [Past_debouncedInput]);

  const PatfetchData = async (query) => {
    try {
      setIsLoadingPast(true);
      setError(null);

      const url = BASE_URL + searchMedicationsByName;
      const data = JSON.stringify({
        pubInfo: {
          sessionId: "3762D32E2F01170A2D5B50B5C74F48C7",
        },
        request: {
          busiParams: {
            name: query,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      });
      // console.log(
      //   response.data.respData.respMsg,
      //   "response.data.respData.respMsg"
      // );
      const Data = DataConvertecheck(response?.data?.respData?.respMsg);
      setpastsetOptions(Data);
      setIsLoadingPast(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoadingPast(false);
    }
  };

  //Clear all data
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
      handleClearDataC();
    }
  }, [clearAllData, handleClearData, handleClearDataC]);

  // console.log(localStorage.getItem(ACCESS_TOKEN), " access token")
  //Handling Current medicine data from default or root
  useEffect(() => {
    // console.log("CurrentMedicationData =>", CurrentMedicationData);
    if (isValidData(CurrentMedicationData)) {
      if (CurrentMedicationData?.length > 0) {
        const initializedRows = CurrentMedicationData.sort(
          (a, b) => a?.currentMedicationDataId - b?.currentMedicationDataId
        ).map((med) => ({
          id: med?.medicineId?.id || null,
          medicineId: med?.medicineId || "",
          dosage: med?.dosage || "",
          unit: {
            value: med?.unit?.id || "",
            label: med?.unit?.name || "",
          },
          medicationScheduleList:
            med?.medicationScheduleList?.length > 0
              ? med?.medicationScheduleList?.map((value) => ({
                  dosages: {
                    value: value?.dosages?.id || "",
                    label: value?.dosages?.name || "",
                  },
                  whens: {
                    value: value?.whens?.id || "",
                    label: value?.whens?.name || "",
                  },
                  frequency: {
                    value: value?.frequency?.id || "",
                    label: value?.frequency?.name || "",
                  },
                  duration: value?.duration || "",
                  notes: value?.notes || "",
                }))
              : [
                  {
                    dosages: {
                      value: "",
                      label: "",
                    },
                    whens: {
                      value: "",
                      label: "",
                    },
                    frequency: {
                      value: "",
                      label: "",
                    },
                    duration: "",
                    notes: "",
                  },
                ],
        }));

        const rowsWithExtraRow = [
          ...initializedRows,
          {
            id: Date.now(),
            medicineId: "",
            unit: {
              value: "",
              label: "",
            },
            medicationScheduleList: [
              {
                dosages: {
                  value: "",
                  label: "",
                },
                whens: {
                  value: "",
                  label: "",
                },
                frequency: {
                  value: "",
                  label: "",
                },
                duration: "",
                notes: "",
              },
            ],
          },
        ];

        setRows(rowsWithExtraRow);
        const transformedDataServer =
          convertCmedicinToServerFormat(rowsWithExtraRow);
        dispatch(updateField("currentMedicationData", transformedDataServer));
        // console.log(transformedDataServer, " formatedt data ")
      }
    } else {
      handleClearDataC();
    }
  }, [patientData]);

  // //Handling Past medicine data from default or root
  useEffect(() => {
    if (isValidData(patientData?.pastMedicationData)) {
      setTempletDataReserve(patientData?.pastMedicationData);
    } else {
      handleClearData();
    }
  }, [patientData, dispatch]);

  useEffect(() => {
    if (TempletDataReserve?.length > 0) {
      const localData = DataConvertecheck(TempletDataReserve);
      setPastMedication(localData);
      const convertedData = convertOnlyValue(localData);
      dispatch(updateField("pastMedicationData", convertedData));
    }
  }, [TempletDataReserve]);

  const TempletDataTransfer = (TempletData) => {
    const formattedData = TempletData.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    const currentTempletData = Array.isArray(TempletDataReserve)
      ? TempletDataReserve
      : [];

    const combinedData = [...currentTempletData, ...formattedData];

    // Remove duplicates based on the 'value' field
    const uniqueData = Array.from(
      new Set(combinedData.map((item) => item.value))
    ).map((value) => combinedData?.find((item) => item.value === value));
    setTempletDataReserve(uniqueData);
  };

  const onSelect = (value, rowIndex, NewEntry, cellIndex) => {
    // console.log(value, " kk??");
    const isMedicineAlreadySelected = rows.some(
      (row, index) => row.medicineId?.id === value?.id && index !== rowIndex
    );

    if (isMedicineAlreadySelected) {
      setShowToast(true);
      return null;
    }
    const newRows = [...rows];
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      id: value?.id,
      medicineId: value,
      unit: { value: value?.unit?.id, label: value?.unit?.name },
      medicationScheduleList: [
        {
          dosages: {
            value: value?.dosage?.id,
            label: value?.dosage?.name,
          },
          whens: {
            value: value?.whens?.id,
            label: value?.whens?.name,
          },
          frequency: {
            value: value?.frequency?.id,
            label: value?.frequency?.name,
          },
          duration: value?.duration,
          notes: value?.notes,
        },
      ],
      newEntry: NewEntry ? true : false,
    };
    setRows(newRows);

    // console.log(newRows);

    inputRefs.current[rowIndex][1]?.focus();
    setFocusedRowIndex(null);
    // moveFocusToNextCell(rowIndex, 0)

    setOptions(defaultOptions);

    const transformedDataServer = convertCmedicinToServerFormat(newRows);

    dispatch(updateField("currentMedicationData", transformedDataServer));

    //  console.log("currentMedicationData", transformedDataServer);

    const newSearchTerms = [...searchTerms];
    newSearchTerms[rowIndex] = "";
    if (rowIndex === rows.length - 1) {
      newSearchTerms.push("");
    }
    // Update the search terms state
    setSearchTerms(newSearchTerms);
    setSearchTerm("");
    if (rowIndex === rows.length - 1) {
      setRows([
        ...newRows,
        {
          id: Date.now(),
          medicineId: "",
          unit: {
            value: "",
            label: "",
          },
          medicationScheduleList: [
            {
              dosages: {
                value: "",
                label: "",
              },
              whens: {
                value: "",
                label: "",
              },
              frequency: {
                value: "",
                label: "",
              },
              duration: "",
              notes: "",
            },
          ],
        },
      ]);
    }
  };
  const handleSelectChange = (
    rowIndex,
    field,
    selectedOption,
    cellIndex = null
  ) => {
    const newRows = [...rows];
    if (field == "unit" && cellIndex == null) {
      newRows[rowIndex][field] = {
        value: selectedOption ? selectedOption.value : "",
        label: selectedOption ? selectedOption.label : "",
      };
    } else if ((field == "duration" || field == "notes") && cellIndex != null) {
      newRows[rowIndex].medicationScheduleList[cellIndex][field] =
        selectedOption ? selectedOption : "";
    } else if (cellIndex != null) {
      if (newRows[rowIndex].medicationScheduleList[cellIndex] === undefined) {
        newRows[rowIndex].medicationScheduleList[cellIndex] = {};
      }

      if (
        newRows[rowIndex].medicationScheduleList[cellIndex][field] === undefined
      ) {
        newRows[rowIndex].medicationScheduleList[cellIndex][field] = {
          value: "",
          label: "",
        };
      }

      // Now safely set the value and label
      newRows[rowIndex].medicationScheduleList[cellIndex][field] = {
        value: selectedOption ? selectedOption.value : "",
        label: selectedOption ? selectedOption.label : "",
      };
    }

    setRows(newRows);

    if (selectedOption) {
      // moveFocusToNextCell(rowIndex, cellIndex + 1)
    }
    const transformedDataServer = convertCmedicinToServerFormat(newRows);

    dispatch(updateField("currentMedicationData", transformedDataServer));
    // console.log("cMedicine--------x", transformedDataServer);
  };

  const moveFocusToNextCell = (currentRowIndex, currentCellIndex) => {
    const totalRows = rows.length;
    const totalCells = rows[0].medicationScheduleList.length;

    // Move focus to the next cell in the same row if available
    if (currentCellIndex < totalCells - 1) {
      inputRefs.current[currentRowIndex][currentCellIndex + 1]?.focus();
    }
    // If it's the last cell, move to the first cell of the next row
    else if (currentRowIndex < totalRows - 1) {
      inputRefs.current[currentRowIndex + 1][0]?.focus();
    }
  };

  const removeDosageRow = (rowIndex, cellIndex) => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      const newSearchTerms = [...searchTerms];
      if (newRows[rowIndex]?.medicationScheduleList?.length > 1) {
        newRows[rowIndex]?.medicationScheduleList?.splice(cellIndex, 1);
      } else {
        newRows.splice(rowIndex, 1);
        newSearchTerms.splice(rowIndex, 1);
      }
      const transformedDataServer = convertCmedicinToServerFormat(newRows);
      dispatch(updateField("currentMedicationData", transformedDataServer));
      setSearchTerm("");
      setSearchTerms(newSearchTerms);
      return newRows;
    });
  };

  const fetchData = async (query) => {
    // console.log("Appic callings", query);
    try {
      setError(null);

      const url = BASE_URL + searchMedicationsByName;
      const data = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: query ? query : "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      });
      // console.log("ddd", response)

      // const existingRowIds = rows?.map((row) => row.medicineId.id); // Extract all the medicine IDs from the selected rows

      // const newOptions = response.data.respData.respMsg?.filter(
      //   (item) => !existingRowIds?.includes(item.id)
      // ); // Filter out the medicines that already exist
      const newOptions = response.data.respData.respMsg;

      if (newOptions.length > 0) {
        setOptions(response.data.respData.respMsg);
      } else {
        setOptions([]);
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const handlePastMedicationChange = async (selectedOptions, actionMeta) => {
  //   const updatedOptions = [...selectedOptions];

  //   const valueretun = true;

  //   // Iterate over selectedOptions to find new items and replace them with server data
  //   for (let i = 0; i < selectedOptions.length; i++) {
  //     const option = selectedOptions[i];

  //     if (option.__isNew__) {
  //       try {
  //         const newData = await AddItem(option.label, "", valueretun);

  //         const Data = ConvertObj(newData);
  //         // console.log("newData--->",Data);

  //         // Replace the newly created option with the response from the server
  //         // if (newData) {
  //         //   updatedOptions[i] = Data;
  //         // }
  //         if (newData) {
  //           updatedOptions[i] = newData;
  //           setShowToast(true);
  //           setColorStatus(1);
  //         } else {
  //           setShowToast(true);
  //           setToastMsg(`Failed to add item: ${option.label}`);
  //           setColorStatus(0);
  //         }
  //       } catch (error) {
  //         console.error("Error adding new item:", error);
  //       }
  //     }
  //   }

  //   try {
  //     if (actionMeta.action === "clear") {
  //       handleClearData();
  //     }
  //     const idsString = updatedOptions.map((option) => option.value).join(",");

  //     setPastMedication( removeMatchingItems( updatedOptions));

  //     const filterNumericValues = (inputString) => {
  //       // Split the input string by commas
  //       const items = inputString.split(",");

  //       // Filter out non-numeric values
  //       const numericValues = items.filter((item) => !isNaN(item.trim()) && item.trim() !== '');

  //       // Check if there were any non-numeric values
  //       const hasNonNumeric = items.some((item) => isNaN(item.trim()));

  //       // If non-numeric values are found, run the filtering logic
  //       if (hasNonNumeric) {
  //         return numericValues.join(",");
  //       }

  //       // Otherwise, just return the original string without modification
  //       return inputString;
  //     };

  //     console.log(updatedOptions ,"updatedOptions")
  //     console.log(serverData ,"idsString")
  //     console.log("pastMedicationData", filterNumericValues( idsString ))

  //     dispatch(updateField("pastMedicationData",filterNumericValues( idsString )));
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };

  // Handle adding a new item

  const handlePastMedicationChange = async (selectedOptions, actionMeta) => {
    const updatedOptions = [...selectedOptions];
    const valueretun = true;

    // Iterate over selectedOptions to find new items
    for (const option of selectedOptions) {
      if (option.__isNew__) {
        try {
          const newData = await AddItem(option.label, "", valueretun);
          if (newData) {
            updatedOptions[updatedOptions.indexOf(option)] =
              ConvertObj(newData);
            setShowToast(true);
            setColorStatus(1);
            setToastMsg("Data Saved Successfully.");
          } else {
            throw new Error(`Failed to add item: ${option.label}`);
          }
        } catch (error) {
          console.error("Error adding new item:", error);
          setShowToast(true);
          setToastMsg(error.message);
          setColorStatus(0);
        }
      }
    }

    if (actionMeta.action === "clear") {
      handleClearData();
    }

    const idsString = updatedOptions.map((option) => option.value).join(",");
    const filteredItems = removeMatchingItems(updatedOptions);
    setPastMedication(filteredItems);

    // console.log(updatedOptions, "updatedOptions");
    // console.log("idsString", idsString);
    // console.log("pastMedicationData", filterNumericValues(idsString));

    dispatch(updateField("pastMedicationData", filterNumericValues(idsString)));
  };

  const handleAddNewItem = (newItemName, rowIndex) => {
    AddItem(newItemName, rowIndex);
  };

  // const AddItem = async (items, rowIndex, valueretun) => {
  //   // alert("calling");
  //   if (items) {
  //     try {
  //       const endpoint = ADDMEDICINE;
  //       const data = {
  //         name: items,
  //       };

  //       const response = await apiHelper(endpoint, data);
  //       const dataConvert =response?.respData?.respMsg;

  //       if (dataConvert) {
  //         const NewEntry = true;
  //         // console.log("data added", dataConvert);
  //         //  return { value: dataConvert.id, label: dataConvert.name };
  //         if (valueretun) {
  //          // console.log("AddItem---->", dataConvert);
  //           return dataConvert;
  //         } else {
  //           onSelect(dataConvert, rowIndex, NewEntry, 0);
  //         }
  //       } else {
  //         setSearchTerm( searchTerms[rowIndex]=null);
  //       //  searchTerms[rowIndex]
  //         setShowToast(true);
  //         setToastMsg(`Failed to add item: ${items}`);
  //         setColorStatus(0);
  //       }

  //       setSearchTerm("");
  //     } catch (error) {
  //       // console.log("Error fetching data:", error);
  //     } finally {
  //       // console.log("ok");
  //     }
  //   }
  // };

  const AddItem = async (items, rowIndex, valueretun) => {
    if (!items) {
      console.error("No items provided.");
      return null;
    }

    try {
      const endpoint = ADDMEDICINE;
      const data = { name: items };

      const response = await apiHelper(endpoint, data);
      const dataConvert = response?.respData?.respMsg;

      if (dataConvert) {
        if (valueretun) {
          return dataConvert; // Return the new entry if requested
        } else {
          onSelect(dataConvert, rowIndex, true, 0); // Call onSelect with the new entry
        }
      } else {
        setSearchTerm((searchTerms[rowIndex] = null));
        throw new Error(`Failed to add item: ${items}`); // Throw an error for clarity
      }
    } catch (error) {
      console.error("Error adding item:", error);
      setSearchTerm("");
      setShowToast(true);
      setToastMsg(error.message || `Failed to add item: ${items}`);
      setColorStatus(0);
    } finally {
      console.log("AddItem process completed.");
    }
  };

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (rowIndex) => {
    setHoveredRowIndex(rowIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const addTableRow = (rowIndex) => {
    const newRows = [...rows];
    newRows[rowIndex].medicationScheduleList.push({
      dosages: {
        value: "",
        label: "",
      },
      whens: {
        value: "",
        label: "",
      },
      frequency: {
        value: "",
        label: "",
      },
      duration: "",
      notes: "",
    });
    setRows(newRows);
  };

  const handleAddNewItemOther = async (
    rowIndex,
    fieldName,
    cellIndex,
    selectedOption,
    categoryKey
  ) => {
    const newDataAdd = {
      label: selectedOption,
      __isNew__: true,
    };

    const updatedOptions = await handleNewOptions(newDataAdd, categoryKey);

    const Data =
      typeof updatedOptions.value === "number" ? updatedOptions : null;

    const AddItem = ReversObj(updatedOptions);
    // console.log(Data, " Data Add succufull  ******--->>  updatedOptions");

    handleSelectChange(rowIndex, fieldName, Data, cellIndex);
  };

  const [optionsData, setOptionsData] = useState({
    [Categories?.DOSAGE?.catID]: DataConvertecheck(visitPadData?.dosages),
    [Categories?.UNITS?.catID]: DataConvertecheck(visitPadData?.units),
    [Categories?.WHENS?.catID]: DataConvertecheck(visitPadData?.whens),
    [Categories?.FREQUENCY?.catID]: DataConvertecheck(
      visitPadData?.frequencies
    ),
  });

  const onBulrUdpate = (typeV, rowIndex, filed, cellIndex) => {
    const updatedRows = [...rows];
    if (filed == "unit" && cellIndex == null) {
      if (!typeV?.trim()) {
        updatedRows[rowIndex][filed] = { value: "", label: "" };
      } else {
        updatedRows[rowIndex][filed] = { ...updatedRows[rowIndex][filed] };
      }
    } else {
      if (!typeV?.trim()) {
        updatedRows[rowIndex].medicationScheduleList[cellIndex][filed] = {
          value: "",
          label: "",
        };
      } else {
        updatedRows[rowIndex].medicationScheduleList[cellIndex][filed] = {
          ...updatedRows[rowIndex].medicationScheduleList[cellIndex][filed],
        };
      }
    }

    setRows(updatedRows);
    const transformedDataServer = convertCmedicinToServerFormat(updatedRows);

    dispatch(updateField("currentMedicationData", transformedDataServer));
  };

  const { DropdownServerData, DropdownIsLoading, DropdownError } =
    DropdownSearchApl(
      DROPDOWNSARCHYNAME,
      SmallDropDwonSearch?.searchV,
      SmallDropDwonSearch?.Categories
    );

  const updateOptionsData = (categoryId, newOptions) => {
    setOptionsData((prevOptionsData) => ({
      ...prevOptionsData,
      [categoryId]: DataConvertecheck(newOptions),
    }));
  };

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      if (!SmallDropDwonSearch?.searchV) return;

      setIsLoading((prevLoading) => ({
        ...prevLoading,
        [SmallDropDwonSearch?.Categories]: true,
      }));

      try {
        const searchCat = SmallDropDwonSearch?.Categories;
        // console.log(
        //   SmallDropDwonSearch?.searchV,
        //   "term SEARCHDATA ",
        //   DropdownServerData
        // );
        if (DropdownServerData?.length > 0) {
          updateOptionsData(searchCat, DropdownServerData);
        } else {
          updateOptionsData(searchCat, []);
        }
      } catch (error) {
        //  console.log("Error fetching dropdown data:", error);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [SmallDropDwonSearch.Categories]: false,
        }));
      }
    };

    fetchDropdownOptions();
  }, [SmallDropDwonSearch?.searchV, SmallDropDwonSearch?.Categories]);

  const handleOnSearchChange = (searchV, catId) => {
    setSearchWithCatID({ searchV, catID: catId });
    const defaultOptions =
      catId === Categories.DOSAGE.catID
        ? visitPadData?.dosages
        : catId === Categories.UNITS.catID
        ? visitPadData?.units
        : catId === Categories.WHENS.catID
        ? visitPadData?.whens
        : catId === Categories.FREQUENCY.catID
        ? visitPadData?.frequencies
        : [];

    if (searchV && searchV?.length >= 1) {
      const inputExistsInDefault = defaultOptions.some((option) => {
        return option?.name
          ?.toString()
          .toLowerCase()
          .startsWith(searchV.toString().toLowerCase());
      });

      if (!inputExistsInDefault) {
        setSmallDropDwonSearch({ searchV, Categories: catId });
        // console.log("1");
      } else {
        updateOptionsData(catId, defaultOptions);
        // console.log("2");
      }
    } else {
      updateOptionsData(catId, defaultOptions);
      // console.log("3");
    }
  };

  // console.log("MEDICINE ROWS", rows)

  return (
    <>
      <IconFunctionality
        catID={Categories?.PAST_MEDICATION?.catID}
        fieldName={Categories?.PAST_MEDICATION?.name}
        name="Past Medication"
        iconSave
        iconRefresh
        iconErase
        onClick={() => handleClearData()}
        setTempData={TempletDataTransfer}
        InputField={1}
        iconPlus
      />

      <div
        className="col-12  col-xxl-9 px-4 py-3"
        style={{ position: "relative" }}
      >
        <CreatableSelect
          closeMenuOnSelect={false}
          isMulti
          //  options={DataConverte(visitPadData?.pastMedications)}
          options={
            pastsetOptions.length > 0
              ? pastsetOptions
              : DataConverte(visitPadData?.pastMedications)
          }
          placeholder="Past Medication..."
          onChange={handlePastMedicationChange}
          value={PastMedication}
          onInputChange={(value) => setInputValue(value.toUpperCase())}
          inputValue={inputValue}
          // menuIsOpen={true} // Keep the menu open to show loader
          isLoading={isLoadingpast} // Show loading state
          loadingMessage={() => "Loading options..."} // Custom loader message
          components={{ DropdownIndicator, IndicatorSeparator }}
          styles={customSelectStyles}
        />
      </div>

      {/* <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message="Item has already been selected."
        duration="Just now"
      /> */}
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
      <div className="ps-4 " style={{ maxWidth: "100%" }}>
        <table className="table table-sm table-bordered">
          <thead className="table-primary " style={{ fontSize: 13 }}>
            <tr>
              <th style={{ width: 30, paddingLeft: 13 }}>#</th>
              <th style={{ width: 380, paddingLeft: 15 }}>Medicine</th>
              <th style={{ paddingLeft: 13 }}>Dosage</th>
              <th style={{ width: 90, paddingLeft: 13 }}>Unit</th>
              <th style={{ paddingLeft: 13 }}>When</th>
              <th style={{ paddingLeft: 13 }}>Frequency</th>
              <th style={{ paddingLeft: 13 }}>Duration</th>
              <th style={{ paddingLeft: 13 }}>Notes</th>
              <th style={{ width: 32 }}></th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, rowIndex) => (
              <React.Fragment key={row?.id}>
                <tr>
                  <th
                    rowSpan={row?.medicationScheduleList?.length + 1}
                    className="text-center "
                    style={{
                      verticalAlign: "middle",
                      fontSize: 13,
                    }}
                  >
                    {rowIndex + 1}
                  </th>
                  <th
                    rowSpan={row.medicationScheduleList.length + 1}
                    style={{
                      position: "relative",
                      verticalAlign: "middle",
                    }}
                  >
                    <div className="col-11 ">
                      <CustomDropdown
                        inputRef={(el) => {
                          if (!inputRefs.current[rowIndex]) {
                            inputRefs.current[rowIndex] = [];
                          }
                          inputRefs.current[rowIndex][0] = el;
                        }}
                        key={rowIndex}
                        options={options}
                        handleInputChange={(value, rowIndex) =>
                          handleInputChange(value, rowIndex)
                        }
                        onFocus={() => setFocusedRowIndex(rowIndex)}
                        onBlur={() => setFocusedRowIndex(null)}
                        onSelect={(option, rowIndex) =>
                          onSelect(option, rowIndex, false, 0)
                        }
                        value={
                          focusedRowIndex === rowIndex
                            ? searchTerms[rowIndex]?.toUpperCase() || ""
                            : row.medicineId?.name ||
                              searchTerms[rowIndex]?.toUpperCase()
                        }
                        rowIndex={rowIndex} // Pass row index to dropdown
                        placeHolder="Add Medicine"
                        loading={loading}
                        searchTerm={
                          focusedRowIndex === rowIndex // Track specific search term for each row
                            ? searchTerms[rowIndex]?.toUpperCase() || ""
                            : ""
                        }
                        onAddNewItem={(item, rowIndex) =>
                          handleAddNewItem(item, rowIndex)
                        } // Handle adding new items per row
                      />
                    </div>

                    {row?.medicineId?.name && <ContentsEdtiable row={row} />}

                    {row.medicineId?.name && (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          zIndex: 0,
                          fontSize: 13,
                        }}
                      >
                        <button
                          className="align-self-end"
                          style={{
                            width: hoveredRowIndex == rowIndex ? 90 : 30,
                            cursor: "pointer",
                            border: 0,
                            color:
                              hoveredRowIndex == rowIndex ? "blue" : "black",
                            backgroundColor: "lightgray",
                            fontSize: 13,
                          }}
                          title=""
                          onMouseEnter={() => handleMouseEnter(rowIndex)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => addTableRow(rowIndex)}
                        >
                          {hoveredRowIndex == rowIndex ? (
                            <>
                              <span>&#43;</span> Add Table
                            </>
                          ) : (
                            <span className="text-white fw-medium">&#43;</span>
                          )}
                        </button>
                      </div>
                    )}
                  </th>
                </tr>
                {row?.medicationScheduleList?.map((item, cellIndex) => (
                  <tr key={cellIndex} style={{ verticalAlign: "middle" }}>
                    <td>
                      {row.medicineId?.name && (
                        <CustomDropdownSingleValue
                          inputRef={(el) => {
                            if (!inputRefs.current[rowIndex]) {
                              inputRefs.current[rowIndex] = [];
                            }
                            inputRefs.current[rowIndex][cellIndex + 1] = el;
                          }}
                          options={
                            isLoading[Categories?.DOSAGE?.catID]
                              ? []
                              : optionsData[Categories?.DOSAGE?.catID]
                          }
                          value={
                            searchWithCatID?.catID == Categories?.DOSAGE?.catID
                              ? searchWithCatID?.searchV || ""
                              : item?.dosages?.value || searchWithCatID?.searchV
                          }
                          onBlur={(typeV) =>
                            onBulrUdpate(typeV, rowIndex, "dosages", cellIndex)
                          }
                          onSearch={(searchV) =>
                            handleOnSearchChange(
                              searchV,
                              Categories?.DOSAGE?.catID
                            )
                          }
                          onChange={(selectedOption) =>
                            handleSelectChange(
                              rowIndex,
                              "dosages",
                              selectedOption,
                              cellIndex
                            )
                          }
                          onAddNewItem={(selectedOption) =>
                            handleAddNewItemOther(
                              rowIndex,
                              "dosages",
                              cellIndex,
                              selectedOption,
                              Categories?.DOSAGE?.catID
                            )
                          }
                        />
                      )}
                    </td>
                    {cellIndex === 0 && (
                      <td rowSpan={row.medicationScheduleList.length + 1}>
                        {row.medicineId?.name && (
                          <CustomDropdownSingleValue
                            inputRef={(el) => {
                              if (!inputRefs.current[rowIndex]) {
                                inputRefs.current[rowIndex] = [];
                              }
                              inputRefs.current[rowIndex][cellIndex + 1] = el;
                            }}
                            options={
                              isLoading[Categories?.UNITS?.catID]
                                ? []
                                : optionsData[Categories?.UNITS?.catID]
                            }
                            value={
                              searchWithCatID?.catID == Categories?.UNITS?.catID
                                ? searchWithCatID?.searchV || ""
                                : row?.unit?.value || searchWithCatID?.searchV
                            }
                            onBlur={(typeV) =>
                              onBulrUdpate(typeV, rowIndex, "unit", null)
                            }
                            onSearch={(searchV) =>
                              handleOnSearchChange(
                                searchV,
                                Categories?.UNITS?.catID
                              )
                            }
                            onChange={(selectedOption) =>
                              handleSelectChange(
                                rowIndex,
                                "unit",
                                selectedOption,
                                null
                              )
                            }
                            onAddNewItem={(selectedOption) =>
                              handleAddNewItemOther(
                                rowIndex,
                                "unit",
                                cellIndex,
                                selectedOption,
                                Categories?.UNITS?.catID
                              )
                            }
                          />
                        )}
                      </td>
                    )}
                    <td>
                      {row.medicineId?.name && (
                        <CustomDropdownSingleValue
                          inputRef={(el) => {
                            if (!inputRefs.current[rowIndex]) {
                              inputRefs.current[rowIndex] = [];
                            }
                            inputRefs.current[rowIndex][cellIndex + 1] = el;
                          }}
                          options={
                            isLoading[Categories?.WHENS?.catID]
                              ? []
                              : optionsData[Categories?.WHENS?.catID]
                          }
                          value={
                            searchWithCatID?.catID == Categories?.WHENS?.catID
                              ? searchWithCatID?.searchV || ""
                              : item?.whens?.value || searchWithCatID?.searchV
                          }
                          onBlur={(typeV) =>
                            onBulrUdpate(typeV, rowIndex, "whens", cellIndex)
                          }
                          onSearch={(searchV) =>
                            handleOnSearchChange(
                              searchV,
                              Categories?.WHENS?.catID
                            )
                          }
                          onChange={(selectedOption) =>
                            handleSelectChange(
                              rowIndex,
                              "whens",
                              selectedOption,
                              cellIndex
                            )
                          }
                          onAddNewItem={(selectedOption) =>
                            handleAddNewItemOther(
                              rowIndex,
                              "whens",
                              cellIndex,
                              selectedOption,
                              Categories?.WHENS?.catID
                            )
                          }
                        />
                      )}
                    </td>
                    <td>
                      {row.medicineId?.name && (
                        <CustomDropdownSingleValue
                          inputRef={(el) => {
                            if (!inputRefs.current[rowIndex]) {
                              inputRefs.current[rowIndex] = [];
                            }
                            inputRefs.current[rowIndex][cellIndex + 1] = el;
                          }}
                          options={
                            isLoading[Categories?.FREQUENCY?.catID]
                              ? [] // Display empty while loading
                              : optionsData[Categories?.FREQUENCY?.catID]
                          }
                          value={
                            searchWithCatID?.catID ==
                            Categories?.FREQUENCY?.catID
                              ? searchWithCatID?.searchV || ""
                              : item?.frequency?.value ||
                                searchWithCatID?.searchV
                          }
                          onBlur={(typeV) =>
                            onBulrUdpate(
                              typeV,
                              rowIndex,
                              "frequency",
                              cellIndex
                            )
                          }
                          onSearch={(searchV) =>
                            handleOnSearchChange(
                              searchV,
                              Categories?.FREQUENCY?.catID
                            )
                          }
                          onChange={(selectedOption) =>
                            handleSelectChange(
                              rowIndex,
                              "frequency",
                              selectedOption,
                              cellIndex
                            )
                          }
                          onAddNewItem={(selectedOption) =>
                            handleAddNewItemOther(
                              rowIndex,
                              "frequency",
                              cellIndex,
                              selectedOption,
                              Categories?.FREQUENCY?.catID
                            )
                          }
                        />
                      )}
                    </td>
                    <td>
                      {row.medicineId?.name && (
                        <DurationDropdown
                          inputRef={(el) => {
                            if (!inputRefs.current[rowIndex]) {
                              inputRefs.current[rowIndex] = [];
                            }
                            inputRefs.current[rowIndex][cellIndex + 1] = el;
                          }}
                          value={item?.duration}
                          onChange={(selectedOption) =>
                            handleSelectChange(
                              rowIndex,
                              "duration",
                              selectedOption,
                              cellIndex
                            )
                          }
                        />
                      )}
                    </td>
                    <td>
                      {row.medicineId?.name && (
                        <input
                          ref={(el) => {
                            if (!inputRefs.current[rowIndex]) {
                              inputRefs.current[rowIndex] = [];
                            }
                            inputRefs.current[rowIndex][cellIndex + 1] = el;
                          }}
                          type="text"
                          name="notes"
                          className="form-control border-0"
                          value={item?.notes}
                          style={{ fontSize: 13, borderRadius: 0 }}
                          onChange={(e) =>
                            handleSelectChange(
                              rowIndex,
                              "notes",
                              e.target.value,
                              cellIndex
                            )
                          }
                        />
                      )}
                    </td>
                    <td
                      onClick={() =>
                        rowIndex !== rows?.length - 1 &&
                        removeDosageRow(rowIndex, cellIndex)
                      }
                      style={{ verticalAlign: "middle" }}
                    >
                      {rowIndex !== rows.length - 1 && (
                        <CustomTooltip
                          icon="fa-solid fa-trash-can"
                          tooltipText="Delete"
                          onClick={() => removeDosageRow(rowIndex, cellIndex)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>

        <div class="d-flex flex-row-reverse bd-highlight  gap-3">
          <IconFunctionalityForMedicine
            catID={Categories?.CURRENT_MEDICATION.catID}
            fieldName={Categories?.CURRENT_MEDICATION.name}
            currentRows={rows}
            iconSave
            iconRefresh
            iconErase
            onClick={() => handleClearDataC()}
            setTempData={setRows}
          />
        </div>
      </div>
    </>
  );
};

export default MedicineTable;

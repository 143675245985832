import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconFunctionality from "../../IconFunctionality";
import { updateField } from "../../ReduxApi/ReduxApi";
import Categories from "../../visitConstant/Categories";
import { isValidData } from "../../DataConverte/DataConverte";

const Advice = ({ patientData }) => {
  const dispatch = useDispatch();
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  const [advice, setAdvice] = useState("");
  const [templateDataReserve, setTemplateDataReserve] = useState("");

  const handleAdviceChange = (e) => {
    const { value } = e.target;
    setAdvice(value);
  };

  const handleClearData = useCallback(() => {
    setAdvice("");
    setTemplateDataReserve("");
    dispatch(updateField("advice", ""));
  }, [dispatch]);

  const handleTemplateDataTransfer = (templateData) => {
    setTemplateDataReserve(`${advice}  ${templateData.advice}`.trim());
  };

  useEffect(() => {
    dispatch(updateField("advice", advice));
  }, [advice, dispatch]);

  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  useEffect(() => {
    if (isValidData(patientData.advice)) {
      setTemplateDataReserve(patientData.advice);
    } else {
      handleClearData();
    }
  }, [patientData, dispatch]);

  useEffect(() => {
    if (templateDataReserve) {
      setAdvice(templateDataReserve);
      dispatch(updateField("advice", templateDataReserve));
    }
  }, [templateDataReserve, dispatch]);

  return (
    <>
      <IconFunctionality
        catID={Categories?.ADVICE?.catID}
        fieldName={Categories?.ADVICE?.name}
        name="Advice"
        iconSave
        iconRefresh
        iconErase
        onClick={handleClearData}
        setTempData={handleTemplateDataTransfer}
      />

      <div className="col-12 col-xxl-9 px-4">
        <textarea
          className="form-control"
          id="advice"
          rows="3"
          placeholder="Advice..."
          name="advice"
          value={advice}
          onChange={handleAdviceChange}
        />
      </div>
    </>
  );
};

export default Advice;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleEditMode } from "../reducer/toggleReducer";
import { Color } from "../report/visitConstant/Color";

const CustomSwitch = () => {
  const dispatch = useDispatch();
  const editMode = useSelector((state) => state.editMode.editMode);
  const [isOn, setIsOn] = useState(editMode);
  const toggleSwitch = () => {
    setIsOn(!isOn);
    dispatch(toggleEditMode());
  };

  return (
    <div
      className="me-sm-2 me-2 "
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className=""
        onClick={toggleSwitch}
        style={{
          display: "inline-block",
          width: "40px",
          height: "20px",
          borderRadius: "15px",
          backgroundColor: isOn ? Color.graydark : "#ccc",
          position: "relative",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
      >
        <div
          className=""
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            position: "absolute",
            top: "2.5px",
            left: isOn ? "22.5px" : "2.5px",
            transition: "left 0.3s",
            // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          }}
        />
      </div>
    </div>
  );
};

export default CustomSwitch;

import React, { useState, useEffect } from "react";
import "./CustomDropdown.css";
import "../ui/CSS/focusinput.css";

const RefreshToCustomDropdown = ({
  options,
  value,
  onChange,
  onAddNewItem,
  DataClear
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setHighlightedIndex(0); // Reset highlighted index on open
    }
  }, [isOpen]);

  useEffect(() => {
    // Set initial value if available
    if(DataClear){
      setSearchTerm('')
    }


    if (value && value && value.doctorId.name) {
      setSearchTerm(value.doctorId.name);
    }
  }, [value ,DataClear]);

  const handleOptionClick = (option) => {
    setSearchTerm(option.name); // Set input to selected doctor's name
    setIsOpen(false);
    onChange(option); // Pass the full object to onChange
  };

  const handleInputFocus = () => setIsOpen(true);
  const handleInputBlur = () => setTimeout(() => setIsOpen(false), 150); // Close dropdown after a slight delay

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const filteredOptions = options?.filter((option) =>
    option.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === filteredOptions?.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === 0 ? filteredOptions?.length - 1 : prevIndex - 1
      );
    } else if (e.key === "Enter") {
      const selected = filteredOptions[highlightedIndex];
      if (selected) {
        handleOptionClick(selected);
      } else {
        handleAddNewItem();
      }
    }
  };

  // Handle adding a new item
  const handleAddNewItem = () => {
    if (searchTerm.trim()) {
      onAddNewItem(searchTerm.trim());
      onChange({ doctorId: { name: searchTerm }, specialityId: null });
      setIsOpen(false); // Close dropdown after adding
    }
  };

  return (
    <div
      className="dropdown"
      onKeyDown={handleKeyDown}
      tabIndex="0"
      style={{ position: "relative" }}
    >
      <div className="dropdown-header">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className="prompt form-control border-0"
          style={{ fontSize: 16, borderRadius: 0 }}
          placeholder="Doctor Name"
        />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {filteredOptions?.length > 0 ? (
            filteredOptions?.map((option, index) => (
              <div
                key={option.id}
                className={`dropdown-item ${
                  index === highlightedIndex ? "highlighted" : ""
                }`}
                onMouseDown={() => handleOptionClick(option)}
              >
                {option.name}
              </div>
            ))
          ) : searchTerm ? (
            <div className="dropdown-item" onMouseDown={handleAddNewItem}>
              Create "{searchTerm}"
            </div>
          ) : (
            <div className="dropdown-item">Search...</div>
          )}
        </div>
      )}
    </div>
  );
};

export default RefreshToCustomDropdown;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { useState, useEffect } from "react";
import {
  BASE_URL,
  USER_ROLE,
  ROLE,
  ROLEDOCTOR,
  DEBUG_LOG_ENABLED,
  ACCESS_TOKEN,
  SESSION_ID,
} from "../Constant";

import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

function OrderHistoryItem({ order, id, product, addressData }) {
  const [address, setaddress] = useState();

  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLEDOCTOR)) {
      setShowEdit(true);
    }
    try {
      let jsonData = JSON.parse(addressData);

      setaddress(jsonData);
    } catch (error) {
      setaddress();
    }
  }, []);

  // console.log(moment().year());

  return (
    <div className="card border-0 shadow-sm mb-3">
      <div className="card-header py-3 bg-white">
        <div className="row">
          <div className="col d-flex">
            <div className="row">
              <span className="fw-semibold h5 my-auto">
                {" "}
                Booking ID: {order}{" "}
              </span>
              <span className="  "> Date: {product.date}</span>
            </div>
          </div>
          <div className="col-auto">
            <Link
              className="btn btn-sm btn-outline-primary"
              to="/orderhistorydetail/"
            >
              View Detail
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row gx-2 gy-3">
          <div className="col-md-5">
            <h6 className="fw-bold">Patient Details</h6>
            {/* <div> {address?.id} </div> */}
            <div className="vstack text-dark small">
              <span className="h6">
                {`${address?.name} S/o ${address?.country} 
                 `}
              </span>
              <span>{`Gender ${address?.purpose}`}</span>
              <span>
                {address?.landmark
                  ? `Age ${moment().year() - address?.landmark.split("-")[0]}`
                  : null}
              </span>
              <span>
                {" "}
                {"D.O.B: " + moment(address?.landmark).format("MM-DD-YYYY")}
              </span>
              <span>{address?.addressLine1 + "" + address?.addressLine2}</span>
              <span>
                {address?.city +
                  " " +
                  address?.state +
                  " " +
                  // address?.country +
                  +" " +
                  address?.pincode}
              </span>
              <span className="fw-bold">
                {"Mobile No: " + address?.mobileNo}
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <h6 className="fw-bold">Payment Method</h6>
            <div className="text-success">
              {product.paymentGateway == "CASH" ? (
                <>
                  <span className="fw-bold">
                    <FontAwesomeIcon
                      icon={["fas", "money-bill-wave"]}
                      size="lg"
                    />
                  </span>

                  <span className="ms-2 small ">payment at Hospital</span>
                </>
              ) : (
                <>
                  <span className="fw-bold">
                    <FontAwesomeIcon icon={["fab", "cc-visa"]} size="lg" />
                  </span>
                  <span className="ms-2 small ">Online payment</span>
                </>
              )}
            </div>
            <span className="ms-2 small bg-warning">{product.paymentId}</span>
            <div>
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Subtotal: " + "₹" + product.amount}
            </div>

            <div className="fw-semibold">
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Total: " + "₹" + product.amount}
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="fw-bold">Status</h6>
            <div
              className={
                product.orderStatus === 2
                  ? "text-danger"
                  : product.orderStatus === 3
                  ? "text-success"
                  : product.orderStatus === 0
                  ? "text-warning"
                  : product.orderStatus === 1
                  ? "text-primary"
                  : product.orderStatus === -1
                  ? "text-secondary"
                  : ""
              }
            >
              <span className="fw-semibold">
                {product.orderStatus === 2
                  ? "CANCELLED"
                  : product.orderStatus === 3
                  ? "COMPLETED"
                  : product.orderStatus === 0
                  ? "PAYMENT PENDING"
                  : product.orderStatus === 1
                  ? "PAYMENT SUCCEESS "
                  : product.orderStatus === -1
                  ? "PAYMENT FAILED"
                  : ""}
              </span>
            </div>

            {/* <div class="card">
              <FontAwesomeIcon
                icon="fa-solid fa-download"
                // beat
                className="text-primary py-3 "
                style={{ width: 30, height: 30, alignSelf: "center" }}
              />
             
            </div> */}
          </div>
        </div>
      </div>
      {product?.doctorName && (
        <div className="card-footer small border-0 py-3 text-muted">
          <div>
            Doctor Name:{product?.doctorName}
            {/* doctorId: {product.doctorId} <br></br> */}
            {/* doctorEmailId: {product.doctorEmailId} */}
          </div>
          <div className="fw-bold">
            Appointment Date
            {product?.bookingDate}
            <br></br>
            Time : {product?.startTime}
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderHistoryItem;

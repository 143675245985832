// ReportDownloadSection.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GOOGLE_DRIVE_DOWNLOAD_URL } from "../report/visitConstant/ApiConstant";

const ReportDownloadSection = ({ item }) => {
  const fileId = item?.labTestRecord?.reportUrl || "";

  const downloadableUrl = fileId
    ? GOOGLE_DRIVE_DOWNLOAD_URL.replace("{fileId}", fileId)
    : "";

  return (
    <div className=" d-flex  gap-3 ms-4" style={{ alignItems: "center" }}>
      {/* <label className="form-label text-black">Report</label> */}
      <div>
        <FontAwesomeIcon
          icon="fa-solid fa-file-arrow-down"
          onClick={(e) => {
            e.stopPropagation();
            if (downloadableUrl) {
              window.open(downloadableUrl);
            }
          }}
          style={{ fontSize: 20, cursor: "pointer" }}
          className="text-primary p-2 bg-white rounded p-1"
        />
      </div>
    </div>
  );
};

export default ReportDownloadSection;

import React, { useEffect, useState } from "react";
import CustomTooltip from "./CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import apiHelper from "./ApiHelpar/apiHelper";
import {
  ACCESS_TOKEN,
  BASE_URL,
  CUSTOMER_ID,
  GETALLMASTERVISITPADSBUTD,
  SESSION_ID,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLastHistory, setVisitCount } from "./ReduxApi/VisitReducerCount";
import DynamicIcon from "./ui/DynamicIcon";
import icons from "./Utils/icons";
import dummyHistData from "./Utils/visitPadHistory.json";
import { resetState, updateField } from "./ReduxApi/ReduxApi";
import { fetchRootTemplatesSuccess } from "./ReduxApi/rootTemplateReducer";
import { Color } from "./visitConstant/Color";
import { headerText } from "./visitConstant/repotOtherConstant";
import NewVisitCaed from "./ui/newVisitCaed";
import ModalLabTest from "../repotLabTest/ReportLabTest";
import VitalsDisplay from "./reportHistory/VitalsDisplay";

function ScrollSpy({ receivedDatas }) {
  const [receivedData, setReceiveData] = useState(receivedDatas);

  useEffect(() => {
    // Update the state whenever the receivedDatas prop changes
    setReceiveData(receivedDatas);
  }, [receivedDatas]); // Dependency array includes receivedDatas
  // State variables
  const [labToken, setLabToken] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const currentDate = moment().format("YYYY-MM-DD");
  const lastHistory = useSelector((state) => state?.visit?.lastHistory);
  // console.log("data", data)
  // useEffect(() => {
  //   // Function to fetch data
  //   const fetchData = async () => {
  //     setLoading(true); // Start loading
  //     setError(null); // Clear previous errors

  //     try {
  //       const response = await axios.post(
  //         BASE_URL + GETALLMASTERVISITPADSBUTD,
  //         {
  //           pubInfo: {
  //             sessionId: localStorage.getItem(SESSION_ID),
  //           },
  //           request: {
  //             busiParams: {
  //               phoneNo: receivedData?.mobileNo,
  //               // phoneNo: 8120056106,
  //             },
  //             isEncrypt: false,
  //             transactionId: "897987987989",
  //           },
  //         },
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
  //           },
  //         }
  //       );
  //       // const datas = response.data.respData.respMsg;

  //       const apiResDAta = response?.data?.respData?.respMsg;

  //       const datas = apiResDAta.filter(
  //         (user) =>
  //           user?.customerName == receivedData?.name ||
  //           receivedData?.mobileNo === user?.phoneNo
  //       );

  //       console.log(datas, "datas--->");

  //       const sortedData = datas?.sort((a, b) => {
  //         return b?.masterVisitPadId - a?.masterVisitPadId; // Descending order
  //       });
  //       const data = sortedData;

  //       // console.log("FOOTAR API CODE ", data);
  //       if (data?.length == 0) {
  //         dispatch(resetState());
  //         dispatch(updateField("customerName", receivedData?.name));
  //         dispatch(updateField("date", receivedData?.date));
  //         dispatch(updateField("phoneNo", Number(receivedData?.mobileNo)));
  //         dispatch(updateField("tokenId", Number(receivedData?.tokenId)));
  //         dispatch(
  //           updateField("userId", Number(localStorage.getItem(CUSTOMER_ID)))
  //         );
  //         dispatch(fetchRootTemplatesSuccess([]));
  //       }

  //       if (response.data.respData.respMsg == "done") {
  //         setData([]);
  //         dispatch(setVisitCount(1));
  //       } else {
  //         setData(data);
  //         if (receivedData?.date == data[0]?.date) {
  //           dispatch(setVisitCount(data?.length));
  //         } else {
  //           dispatch(setVisitCount(data?.length + 1));
  //         }
  //       }

  //       dispatch(setLastHistory(data[0]));
  //     } catch (err) {
  //       // console.log(err, " rrr")
  //       setError(err.message);
  //       dispatch(resetState());
  //       dispatch(updateField("customerName", receivedData?.name));
  //       dispatch(updateField("date", receivedData?.date));
  //       dispatch(updateField("phoneNo", Number(receivedData?.mobileNo)));
  //       dispatch(updateField("tokenId", Number(receivedData?.tokenId)));
  //       dispatch(
  //         updateField("userId", Number(localStorage.getItem(CUSTOMER_ID)))
  //       );
  //       dispatch(fetchRootTemplatesSuccess([]));
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [receivedData]);

  useEffect(() => {
    const fetchData = async () => {
      const session = localStorage.getItem(SESSION_ID);
      setLoading(true);
      setError(null);
      // console.log(receivedData.date, "receivedData------------");
      try {
        const response = await axios.post(
          `${BASE_URL}${GETALLMASTERVISITPADSBUTD}`,

          {
            pubInfo: {
              sessionId: session,
            },
            request: {
              busiParams: {
                // phoneNo: receivedData?.mobileNo,

                customerId: receivedData?.customerId?.customerId,
              },
              isEncrypt: false,
              transactionId: "897987987989",
            },
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
            },
          }
        );

        const apiResData = response?.data?.respData?.respMsg;

        const sortedData = apiResData?.sort((a, b) => b?.date - a?.date);

        // console.log(sortedData, "FOOTER HISTORY");
        if (!sortedData || sortedData.length === 0) {
          // Handle case where no data is found

          setData([]);
          handleNoData();
          return;
        }

        if (response.data.respData.respMsg === "done") {
          setData([]);
          handleNoData();
          dispatch(setVisitCount(1));
        } else {
          setData(sortedData);
          const visitCount =
            receivedData?.date === sortedData[0]?.date
              ? sortedData.length
              : sortedData.length + 1;
          dispatch(setVisitCount(visitCount));
        }

        // console.log(sortedData, "sortedData[0]");

        // dispatch(setLastHistory(sortedData[0]));

        // Call the function to check the conditions and dispatch the appropriate action
        checkAndDispatch(receivedData, sortedData, dispatch);
      } catch (err) {
        // console.error("API call failed:", err);
        setError(err.message);
        handleNoData(); // Reset state on error
      } finally {
        setLoading(false);
      }
    };

    const handleNoData = () => {
      dispatch(resetState());
      dispatch(updateField("customerName", receivedData?.customerId?.name));
      dispatch(updateField("date", receivedData?.date));
      dispatch(
        updateField("phoneNo", Number(receivedData?.customerId?.mobileNo))
      );
      dispatch(updateField("tokenId", Number(receivedData?.tokenId)));
      dispatch(updateField("customerId", receivedData?.customerId?.customerId));

      dispatch(
        updateField("userId", Number(localStorage.getItem(CUSTOMER_ID)))
      );
      dispatch(fetchRootTemplatesSuccess([]));
      dispatch(setLastHistory([])); //added
    };

    fetchData();
  }, [receivedData, dispatch]);

  const checkAndDispatch = (receivedData, sortedData, dispatch) => {
    // Check if sortedData is available and has at least one item
    if (!sortedData || sortedData.length === 0 || !sortedData[0].date) {
      console.log(
        "No data available in sortedData or sortedData[0].date is missing"
      );
      // Handle the case where sortedData is empty or sortedData[0].date is unavailable
      // You can dispatch a default value or some error handling here
      return; // Early return if no valid data is present
    }

    // Parse the receivedData.date and sortedData[0].date using moment
    const receivedDate = moment(receivedData.date, "YYYY-MM-DD"); // Parse receivedData.date
    const sortedFirstDate = moment(sortedData[0].date, "YYYY-MM-DD", true); // Parse sortedData[0].date strictly

    // Check if sortedFirstDate is a valid date
    if (!sortedFirstDate.isValid()) {
      // console.log("Invalid sortedData[0].date");
      // Handle the case where sortedData[0].date is invalid
      return; // Early return if sortedFirstDate is invalid
    }

    // Condition 1: If receivedData.date is equal to sortedData[0].date
    if (receivedDate.isSame(sortedFirstDate)) {
      // console.log(
      //   "Condition 1: receivedData.date is equal to sortedData[0].date",
      //   sortedData[0]
      // );
      dispatch(setLastHistory(sortedData[0])); // Dispatch with sortedData[0] if dates are equal
    }
    // Condition 2: If receivedData.date is earlier than sortedData[0].date
    else if (receivedDate.isBefore(sortedFirstDate)) {
      // console.log(
      //   "Condition 2: receivedData.date is earlier than sortedData[0].date"
      // );
      //  dispatch(setLastHistory(sortedData[0])); // Dispatch with sortedData[0] if receivedDate is earlier

      const matchedData = sortedData.find((item) =>
        moment(item.date, "YYYY-MM-DD").isSame(receivedDate)
      );
      dispatch(setLastHistory(matchedData)); // Dispatch the matched data
    }
    // Condition 3: If receivedData.date exists in sortedData
    else if (
      sortedData.some((item) =>
        moment(item.date, "YYYY-MM-DD").isSame(receivedDate)
      )
    ) {
      //  console.log("Condition 3: receivedData.date exists in sortedData");
      // Find the matching data in sortedData
      const matchedData = sortedData.find((item) =>
        moment(item.date, "YYYY-MM-DD").isSame(receivedDate)
      );
      dispatch(setLastHistory(matchedData)); // Dispatch the matched data
    } else if (receivedDate.isAfter(sortedFirstDate)) {
      // console.log(
      //   "Condition 4: receivedData.date is earlier than sortedData[0].date"
      // );
      dispatch(setLastHistory(sortedData[0])); // Dispatch with sortedData[0] if receivedDate is earlier
    } else {
      dispatch(setLastHistory());
      // console.log("No matching condition found.");
    }
  };

  const [currentItemId, setCurrentItemId] = useState(0);

  const handleLinkClick = (event, index) => {
    event.preventDefault();
    setCurrentItemId(index);
    const targetElement = document.getElementById(`item-${index}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sidebarStyle = {
    position: "sticky",
    top: "0",
    height: "100vh",
    // backgroundColor: "#f8f9fa",
    padding: "1rem",
    width: 180,
    overflowY: "auto",
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none", // IE 10+
    WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
  };

  const contentStyle = {
    // height: "500vh",
    // padding: "1rem",
    // backgroundColor: "#ffffff",
  };

  const navigate = useNavigate();

  const reversedData = data ? [...data] : [];
  // const reversedData = dummyHistData.respData.respMsg;

  function formatDate(date) {
    const today = moment().startOf("day");
    const inputDate = moment(date).startOf("day");

    if (inputDate.isSame(today)) {
      return "Today";
    } else {
      const day = inputDate.format("DD");
      const month = inputDate.format("MMM");
      return `${day}\n${month}`;
    }
  }

  // console.log("DATA FOR PRINT: ", reversedData);

  const rowsStyle = { fontSize: 16, margin: 0, paddingLeft: 7 };

  const subHeading = {
    paddingRight: 8,
    fontSize: 16,
    margin: 0,
    whiteSpace: "nowrap",
  };
  const horizontalRow = {
    display: "flex",
    flexWrap: "wrap",
    listStyleType: "none",
    padding: 0,
    margin: 0,
  };

  return (
    <>
      {receivedData &&
        receivedData.date &&
        receivedData.date !== moment().format("YYYY-MM-DD") && (
          <NewVisitCaed receivedDatas={receivedData} />
        )}

      {reversedData?.length > 0 ? (
        <div className="row justify-content-center">
          <div className="col-xl-2 d-none d-xl-block " style={sidebarStyle}>
            <ScrollToTopOnMount />
            <style>
              {`
            .col-xl-2::-webkit-scrollbar {
              display: none;
            }
           `}
            </style>
            <div className="position-relative ">
              {/* <div
                className="position-absolute bg-primary"
                style={{ width: 8, height: "100vh", top: 0, }}
              /> */}
              <div
                style={{
                  borderLeft: "2px dashed #007bff",
                  height: "100vh",
                  position: "absolute",
                  left: "35%",
                  transform: "translateX(-50%)",
                }}
              />
              <nav className="nav nav-pills flex-column">
                {reversedData?.map((item, index) => (
                  <div
                    key={item.id}
                    className={`card fw-bold m-3 ${
                      index === currentItemId
                        ? "bg-primary text-white"
                        : "border-primary"
                    } rounded-3`}
                    style={{
                      width: 70,
                      height: 70,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleLinkClick(e, index)}
                  >
                    {index === 0 && (
                      <FontAwesomeIcon
                        icon="fa-solid fa-arrow-up"
                        className="fs-3 "
                      />
                    )}
                    {/* {item.date} */}

                    <span
                      className={`fw-medium fs-6 ${
                        index !== 0 && currentItemId !== index
                          ? "text-primary"
                          : ""
                      }`}
                      style={index !== 0 ? { whiteSpace: "pre-line" } : {}}
                    >
                      {formatDate(item?.date)}
                    </span>
                  </div>
                ))}
              </nav>
            </div>
          </div>
          <div className="col-xl-10">
            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-example3"
              data-bs-smooth-scroll="true"
              className="scrollspy-example-2 rounded "
              tabIndex={0}
              style={contentStyle}
            >
              {reversedData?.map((data, index) => (
                <div id={`item-${index}`} key={data?.id}>
                  <div
                    className={`card mb-5 rounded  ${
                      index === currentItemId ? "border-primary" : ""
                    }`}
                  >
                    <div className="card-header">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="p-2 bd-highlight fw-medium fs-6">
                          {moment(data?.date).format("DD-MMM-YYYY")} {"   "}
                          <span className="ps-3">By : Dr Somnath Dare</span>
                        </div>
                        <div className="d-flex bd-highlight fw-medium ">
                          <img
                            src={icons.whatsapp}
                            alt={"whatapp"}
                            style={{ width: 23, height: 23, cursor: "pointer" }}
                          />
                          <span className="ms-1 me-3">WhatsApp & SMS</span>
                          <img
                            src={icons.gmail}
                            alt={"gmail"}
                            style={{ width: 23, height: 23, cursor: "pointer" }}
                          />
                          <span className="ms-1 me-3">Gmail</span>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/Report", {
                                state: {
                                  receivedData: data,
                                  selectedLanguages: "en",
                                  ageGender: {
                                    age: receivedData?.customerId?.age,
                                    gender: receivedData?.customerId?.gender,
                                  },
                                },
                              })
                            }
                          >
                            <img
                              src={icons.print}
                              alt={"print"}
                              style={{
                                width: 22,
                                height: 22,
                                cursor: "pointer",
                              }}
                            />
                            <span className="ms-1 me-3">Print</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {/* <div class="border-bottom" style={{ paddingBottom: 4 }}>
                        <div className=" row row-cols-3 ">
                          <p>
                            <strong>Name:</strong> {data?.customerName}
                          </p>
                          <p>
                            <strong>Token ID:</strong> {data?.tokenId}
                          </p>
                          <p>
                            <strong>Date:</strong>
                            {moment(data?.date).format("DD-MM-YYYY")}
                          </p>

                          {data?.vitalsData?.height !== 0 && (
                            <p>
                              <strong>Height:</strong>
                              {data?.vitalsData?.height}
                            </p>
                          )}
                          {data?.vitalsData?.weight !== 0 && (
                            <p>
                              <strong>Weight:</strong>
                              {data?.vitalsData?.weight}
                            </p>
                          )}
                          <p>
                            <strong> Mob:</strong> {data?.phoneNo}
                          </p>
                        </div>
                      </div> */}
                      {/* Past Medication */}
                      {data?.pastMedicationData?.length > 0 && (
                        <div className="fs-6" style={{ display: "flex" }}>
                          <p className="fw-medium" style={subHeading}>
                            Past Medication :
                          </p>
                          <div style={horizontalRow}>
                            {data?.pastMedicationData?.map((med, idx, arr) => (
                              <li
                                style={{
                                  paddingRight: 10,
                                  fontSize: 16,
                                  color: Color.subTextColor,
                                }}
                                key={med?.id}
                              >
                                {med?.name}
                                {idx < arr.length - 1 && ", "}
                              </li>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="col " style={{ fontSize: 15 }}>
                        {data?.historyData?.allergies?.length > 0 && (
                          <div className="fs-6">
                            <p style={{ margin: 0 }}>
                              <span className="fw-medium pe-2 mt-1">
                                Allergies :
                              </span>
                              <span className="text-danger">
                                {data?.historyData?.allergies
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.familyHistory?.length > 0 && (
                          <div className="fs-6">
                            <p style={{ margin: 0 }}>
                              <span className="fw-medium pe-2 mt-1">
                                Family History :
                              </span>
                              <span style={{ color: Color.subTextColor }}>
                                {data?.historyData?.familyHistory
                                  ?.map((hist) => hist?.name)
                                  .join(",  ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.personalHistory?.length > 0 && (
                          <div className="fs-6">
                            <p style={{ margin: 0 }}>
                              <span className="fw-medium fs-6 pe-2 mt-1">
                                Personal History :
                              </span>
                              <span style={{ color: Color.subTextColor }}>
                                {data?.historyData?.personalHistory
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.pastMedicalhistory?.length > 0 && (
                          <div className="fs-6">
                            <p style={{ margin: 0 }}>
                              <span className="fw-medium fs-6 pe-2 mt-1">
                                Past Medical History :
                              </span>
                              <span style={{ color: Color.subTextColor }}>
                                {data?.historyData?.pastMedicalhistory
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                      <div style={{ paddingBottom: 4, paddingTop: 4 }}>
                        {/* Vitals */}

                        {/* {data?.vitalsData && (
                          <div style={{ display: "flex" }} className="my-2">
                            <p
                              style={{
                                paddingRight: 8,
                                fontSize: 16,

                                margin: 0,
                              }}
                              className="fw-medium"
                            >
                              Vitals:
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                color: Color.subTextColor,
                              }}
                              
                            >
                              {data?.vitalsData?.bloodPressure &&
                               <p  style={{ fontSize: 16, margin: 0 }}>
                                <strong  style={{ color: Color.graydark ,marginRight:10 }}>
                                  BP:
                                </strong>
                                {data?.vitalsData?.bloodPressure} mmhg
                              </p>}


                              {data?.vitalsData?.pulse !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    Pulse:</strong>
                                  {data?.vitalsData?.pulse} bpm
                                </p>
                              )}
                              {data?.vitalsData?.height !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    Height:
                                  </strong>
                                  {data?.vitalsData?.height} cm
                                </p>
                              )}
                              {data?.vitalsData?.weight !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    Weight:
                                  </strong>
                                  {data?.vitalsData?.weight} kg
                                </p>
                              )}

                              {data?.vitalsData?.temperature !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    Temp:
                                  </strong>
                                  {data?.vitalsData?.temperature} F
                                </p>
                              )}
                              {data?.vitalsData?.bmi !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    BMI:
                                  </strong>
                                  {data?.vitalsData?.bmi} kg/m2
                                </p>
                              )}
                              {data?.vitalsData?.waistHip !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    Waist/Hip:
                                  </strong>
                                  {data?.vitalsData?.waistHip}
                                </p>
                              )}
                              {data?.vitalsData?.spo2 !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    SPO2:
                                  </strong>
                                  {data?.vitalsData?.spo2} %
                                </p>
                              )}
                              {data?.vitalsData?.fetalHeartSound && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    FHS:
                                  </strong>
                                  {data?.vitalsData?.fetalHeartSound}
                                </p>
                              )}
                              {data?.vitalsData?.fetalMovement && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    FM:
                                  </strong>
                                  {data?.vitalsData?.fetalMovement}
                                </p>
                              )}
                              {data?.vitalsData?.hgt !== 0 && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    HGT:
                                  </strong>
                                  {data?.vitalsData?.hgt}
                                </p>
                              )}
                              {data?.vitalsData?.lastMenstrualPeriod && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    LMP:
                                  </strong>
                                  {moment(
                                    data?.vitalsData?.lastMenstrualPeriod
                                  ).format("DD-MM-YYYY")}
                                  
                                </p>
                              )}
                              {data?.vitalsData?.estimatedDueDate && (
                                <p style={rowsStyle}>
                                  | <strong style={{ color: Color.graydark }}>
                                    EDD:
                                  </strong>
                                  {moment(
                                    data?.vitalsData?.estimatedDueDate
                                  ).format("DD-MM-YYYY")}


                                </p>
                              )}
                            </div>
                          </div>
                        )} */}

                        {/* Vitals */}

                        <VitalsDisplay
                          vitalsData={data?.vitalsData}
                          Color={Color}
                        />

                        {/* Complaints */}
                        {data?.complaintsData?.complaints?.length > 0 && (
                          <div style={{ display: "flex" }} className="my-2">
                            <p className="fw-medium" style={subHeading}>
                              Complaints :
                            </p>
                            <div style={horizontalRow}>
                              {data?.complaintsData?.complaints?.map(
                                (complaint, idx, arr) => (
                                  <li
                                    style={{
                                      paddingRight: 10,
                                      fontSize: 16,
                                      color: Color.subTextColor,
                                    }}
                                    key={complaint?.id}
                                  >
                                    {complaint?.name}
                                    {idx < arr.length - 1 && ", "}
                                  </li>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* {Diagnosis} */}
                      {data?.diagnosisData?.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: 3,
                            paddingBottom: 3,
                            fontSize: 16,
                          }}
                        >
                          <p className="fw-medium" style={subHeading}>
                            Diagnosis :
                          </p>
                          <div
                            style={{
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                              fontSize: 16,
                            }}
                          >
                            {data?.diagnosisData?.map((item) => (
                              <li
                                style={{
                                  paddingRight: 10,
                                  borderBottomColor: "black",
                                  color: Color.subTextColor,
                                }}
                                key={item?.diagnosis?.id}
                              >
                                {item?.diagnosis?.name}
                                {item?.duration &&
                                  ` since ${item?.duration}`}{" "}
                                {item?.date && ","}
                                {item?.date && (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: Color.graydark,
                                    }}
                                  >
                                    From :
                                  </span>
                                )}
                                {item?.date &&
                                  `  ${moment(item?.date).format(
                                    "DD-MMM-YYYY"
                                  )}`}
                              </li>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Current Medication */}

                      {data?.currentMedicationData?.length > 0 && (
                        <>
                          <h5>Rx</h5>
                          <table className="table table-sm table-bordered">
                            <thead
                              className="table-primary "
                              style={{ fontSize: 13 }}
                            >
                              <tr>
                                <th style={{ width: 30, paddingLeft: 13 }}>
                                  #
                                </th>
                                <th style={{ width: 300, paddingLeft: 15 }}>
                                  Medicine
                                </th>
                                <th style={{ paddingLeft: 13 }}>Dosage</th>
                                <th style={{ width: 90, paddingLeft: 13 }}>
                                  Unit
                                </th>
                                <th style={{ paddingLeft: 13 }}>When</th>
                                <th style={{ paddingLeft: 13 }}>Frequency</th>
                                <th style={{ paddingLeft: 13 }}>Duration</th>
                                <th style={{ paddingLeft: 13 }}>Notes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.currentMedicationData
                                ?.sort(
                                  (a, b) =>
                                    a.currentMedicationDataId -
                                    b.currentMedicationDataId
                                )
                                .map((item, index) => (
                                  <React.Fragment key={item?.id}>
                                    <tr style={{ fontSize: 14 }}>
                                      <th
                                        rowSpan={
                                          item?.medicationScheduleList?.length +
                                          1
                                        }
                                        className="text-center "
                                        style={{
                                          verticalAlign: "middle",
                                          fontSize: 13,
                                        }}
                                      >
                                        {index + 1}
                                      </th>
                                      <td
                                        rowSpan={
                                          item?.medicationScheduleList?.length +
                                          1
                                        }
                                        style={{
                                          verticalAlign: "middle",
                                          paddingLeft: 13,
                                        }}
                                      >
                                        <span className="fw-medium">
                                          {item?.medicineId?.name}
                                        </span>
                                        <br />
                                        {item?.medicineId?.contents && (
                                          <>
                                            <span style={{ color: "gray" }}>
                                              Composition:
                                            </span>
                                            {item?.medicineId?.contents}
                                            <br />
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                    {item?.medicationScheduleList?.length > 0 &&
                                      item?.medicationScheduleList.map(
                                        (subItem, indx) => (
                                          <tr
                                            key={indx}
                                            style={{
                                              verticalAlign: "middle",
                                              fontSize: 13,
                                            }}
                                          >
                                            <td style={{ paddingLeft: 13 }}>
                                              {subItem?.dosages?.name}
                                            </td>
                                            {indx == 0 && (
                                              <td
                                                rowSpan={
                                                  item.medicationScheduleList
                                                    .length + 1
                                                }
                                                style={{ paddingLeft: 13 }}
                                              >
                                                {item?.unit?.name}
                                              </td>
                                            )}
                                            <td style={{ paddingLeft: 13 }}>
                                              {subItem?.whens?.name}
                                            </td>
                                            <td style={{ paddingLeft: 13 }}>
                                              {subItem?.frequency?.name}
                                            </td>
                                            <td style={{ paddingLeft: 13 }}>
                                              {subItem?.duration}
                                            </td>
                                            <td style={{ paddingLeft: 13 }}>
                                              {subItem?.notes}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </>
                      )}
                      {/* Investigation Examination*/}

                      {data?.investigationsData?.length > 0 && (
                        <>
                          <h5 className="fw-medium">Investigation</h5>
                          <table className="table table-sm table-bordered ">
                            <thead
                              className="table-primary"
                              style={{ fontSize: 13 }}
                            >
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    width: 30,
                                    verticalAlign: "middle",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: 300, paddingLeft: 14 }}
                                >
                                  Tests/Investigations
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: 90, paddingLeft: 14 }}
                                >
                                  Units
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: 150, paddingLeft: 14 }}
                                >
                                  Type
                                </th>
                                {data?.investigationsData
                                  .slice(0, 1)
                                  .map((dte, index) =>
                                    dte?.investigationsDates.map(
                                      (subdte, idx) => (
                                        <th
                                          scope="col"
                                          style={{ width: 200 }}
                                          key={index}
                                        >
                                          <div
                                            key={idx}
                                            className="d-flex justify-content-between align-items-center"
                                          >
                                            <p
                                              style={{
                                                margin: 0,
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                              }}
                                            >
                                              {subdte?.investigationsDate ===
                                              currentDate
                                                ? "Today"
                                                : moment(
                                                    subdte?.investigationsDate
                                                  ).format("DD-MMM-YYYY")}
                                            </p>
                                          </div>
                                        </th>
                                      )
                                    )
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.investigationsData?.map((item, index) => (
                                <tr key={index} style={{ fontSize: 14 }}>
                                  <th
                                    scope="row"
                                    className="text-center "
                                    style={{
                                      verticalAlign: "middle",
                                      fontSize: 13,
                                    }}
                                  >
                                    {index + 1}
                                  </th>
                                  <td
                                    className="fw-medium"
                                    style={{ paddingLeft: 14 }}
                                  >
                                    {item?.testsOrInvestigations?.name}
                                    <br />
                                  </td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {item?.testsOrInvestigations?.units?.name}
                                  </td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {item?.testsOrInvestigations?.type?.name}
                                  </td>
                                  {item?.investigationsDates?.map(
                                    (subdat, index) => (
                                      <td
                                        style={{ paddingLeft: 14 }}
                                        key={index}
                                      >
                                        {subdat?.value}
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}

                      {/* Advice */}
                      {data?.advice && (
                        <div style={{ display: "flex" }} className="my-2">
                          <p className="me-3 fw-medium" style={{ margin: 0 }}>
                            Advice:
                          </p>
                          <p style={{ margin: 0 }}>{data.advice}</p>
                        </div>
                      )}
                      {/* Test Requested */}
                      {data?.testsRequestedData?.testsRequesteds.length > 0 && (
                        <div style={{ display: "flex" }}>
                          <p
                            className="me-3 mb-3 fw-medium"
                            style={{ margin: 0 }}
                          >
                            Test Prescribed:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              listStyleType: "none",
                            }}
                          >
                            {data?.testsRequestedData?.testsRequesteds?.map(
                              (text, idx, arr) => (
                                <li
                                  style={{ margin: 0, paddingRight: 10 }}
                                  key={text?.id}
                                >
                                  {text?.name}
                                  {idx < arr.length - 1 && ", "}
                                </li>
                              )
                            )}
                            {data?.testsRequestedData?.whenBy && (
                              <p
                                style={{ margin: 0, paddingLeft: 10 }}
                                className="fw-medium"
                              >
                                {data?.testsRequestedData?.whenBy !=
                                " - undefined"
                                  ? `[ ${data?.testsRequestedData?.whenBy} ]`
                                  : ""}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      {/* labTestsAndImagingData */}
                      {data?.labTestsAndImagingData?.length > 0 && (
                        <div
                          style={{ display: "flex", flex: "wrap" }}
                          className=""
                        >
                          <p className="fw-medium" style={subHeading}>
                            Lab Test & Examination:
                          </p>
                          <div style={horizontalRow}>
                            {data?.labTestsAndImagingData
                              ?.sort(
                                (a, b) =>
                                  a.labTestsAndImagingDataID -
                                  b.labTestsAndImagingDataID
                              )
                              ?.map((lab, index) => (
                                <li
                                  style={{
                                    paddingRight: 10,
                                    fontSize: 16,
                                    color: Color.subTextColor,
                                  }}
                                  key={lab?.id}
                                >
                                  {lab?.labTestsAndImaging?.name}{" "}
                                  {index <
                                    data.labTestsAndImagingData.length - 1 &&
                                    ", "}
                                </li>
                              ))}
                          </div>
                          <button
                            type="button"
                            class="btn btn-primary justify-items-center rounded-2 ms-4 "
                            onClick={() => setLabToken(data)}
                            style={{
                              height: "30px",
                              width: "80px",
                              fontSize: "16px",
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: -3,
                            }}
                          >
                            Lab
                          </button>
                        </div>
                      )}
                      {/* SystemicExaminationData */}

                      <div style={{ display: "flex" }} className="my-2">
                        {data?.systemicExaminationData && (
                          <p className="fw-medium" style={subHeading}>
                            Systemic Examination :
                          </p>
                        )}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {data?.systemicExaminationData?.cns?.length > 0 && (
                            <div style={{ display: "flex" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                CNS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.cns?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.cvs?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                CVS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.cvs?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.ent?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                ENT:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.ent?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.generals?.length >
                            0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                GENERALS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.generals?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.pa?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                PA:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.pa?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.rs?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p
                                className="fw-medium"
                                style={{
                                  color: Color.graydark,
                                  paddingRight: 10,
                                  margin: 0,
                                }}
                              >
                                RS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.rs?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{
                                        paddingRight: 10,
                                        fontSize: 16,
                                        color: Color.subTextColor,
                                      }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {data?.generalExaminations && (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="my-2"
                        >
                          <p
                            style={{
                              paddingRight: 8,
                              fontSize: 16,
                              margin: 0,
                            }}
                            className="fw-medium"
                          >
                            General Examination:
                          </p>
                          {/* <div style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0, margin: 0, fontSize: 9, alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}> */}
                          <p
                            style={{
                              fontSize: 16,
                              margin: 0,
                              color: Color.subTextColor,
                            }}
                          >
                            {data?.generalExaminations}
                          </p>
                          {/* </div> */}
                        </div>
                      )}

                      {/* Physical Examination */}
                      {(data?.physicalExamination?.breastExamination ||
                        data?.physicalExamination?.perSpeculum ||
                        data?.physicalExamination?.perAbdominalExamination ||
                        data?.physicalExamination?.perVaginalExamination) && (
                        <div className="my-2">
                          <p
                            style={{
                              paddingRight: 8,
                              fontSize: 16,
                              margin: 0,
                            }}
                            className="fw-medium"
                          >
                            Physical Examination:
                          </p>
                          <div className="container">
                            <div className="row g-2">
                              {data?.physicalExamination?.breastExamination && (
                                <div className="d-flex ">
                                  <li>
                                    <span className="fw-medium">
                                      Breast Examination:
                                    </span>

                                    <span style={{ color: Color.subTextColor }}>
                                      {
                                        data?.physicalExamination
                                          ?.breastExamination
                                      }
                                    </span>
                                  </li>
                                </div>
                              )}
                              {data?.physicalExamination?.perSpeculum && (
                                <div className="d-flex">
                                  <li>
                                    <span className="fw-medium">
                                      Per Speculum:
                                    </span>
                                    <span style={{ color: Color.subTextColor }}>
                                      {data?.physicalExamination?.perSpeculum}
                                    </span>
                                  </li>
                                </div>
                              )}
                              {data?.physicalExamination
                                ?.perAbdominalExamination && (
                                <div className="d-flex ">
                                  <li>
                                    <span className="fw-medium">
                                      Abdominal Examination:
                                    </span>
                                    <span style={{ color: Color.subTextColor }}>
                                      {
                                        data?.physicalExamination
                                          ?.perAbdominalExamination
                                      }
                                    </span>
                                  </li>
                                </div>
                              )}
                              {data?.physicalExamination
                                ?.perVaginalExamination && (
                                <div className="d-flex">
                                  <li>
                                    <span className="fw-medium">
                                      Vaginal Examination:
                                    </span>

                                    <span style={{ color: Color.subTextColor }}>
                                      {
                                        data?.physicalExamination
                                          ?.perVaginalExamination
                                      }
                                    </span>
                                  </li>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div style={{ paddingTop: 7 }}>
                        {/* obstetricHistoryData */}

                        {data?.obstetricAdditionalData?.length > 0 && (
                          <h5 className="fw-medium mt-3">Obstetric History</h5>
                        )}
                        {data?.obstetricHistoryData && (
                          <>
                            <table className="table table-sm  table-bordered">
                              <thead
                                style={{ fontSize: 14 }}
                                className="table-primary"
                              >
                                <tr>
                                  <th style={{ paddingLeft: 14 }}>Gravida .</th>
                                  <th style={{ paddingLeft: 14 }}>
                                    TermBirths
                                  </th>
                                  <th style={{ paddingLeft: 14 }}>Preterm </th>
                                  <th style={{ paddingLeft: 14 }}>
                                    LivingChildren
                                  </th>
                                  <th style={{ paddingLeft: 14 }}>Abortions</th>
                                  <th style={{ paddingLeft: 14 }}>Deaths </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ fontSize: 14 }}>
                                  <td style={{ paddingLeft: 14 }}>
                                    {data?.obstetricHistoryData?.gravida}
                                  </td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {data?.obstetricHistoryData?.termBirths}
                                  </td>
                                  <td>{data?.obstetricHistoryData?.preterm}</td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {data?.obstetricHistoryData?.livingChildren}
                                  </td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {data?.obstetricHistoryData?.abortions}
                                  </td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {data?.obstetricHistoryData?.deaths}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                        {data?.obstetricAdditionalData?.length > 0 && (
                          <>
                            <table className="table table-bordered table-sm">
                              <thead
                                style={{ fontSize: 14 }}
                                className="table-primary"
                              >
                                <tr>
                                  <th style={{ paddingLeft: 14 }}>#</th>
                                  <th style={{ paddingLeft: 14 }}>
                                    Duration of Pregnancy
                                  </th>
                                  <th style={{ paddingLeft: 14 }}>
                                    Pregnancy Outcome
                                  </th>
                                  <th style={{ paddingLeft: 14 }}>
                                    Birth Weight
                                  </th>
                                  <th style={{ paddingLeft: 14 }}>DOB </th>
                                  <th style={{ paddingLeft: 14 }}>Sex </th>
                                  <th style={{ paddingLeft: 14 }}>Anomalies</th>
                                  <th style={{ paddingLeft: 14 }}>Deaths </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.obstetricAdditionalData?.map(
                                  (item, index) => (
                                    <tr style={{ fontSize: 14 }} key={index}>
                                      <th
                                        className="text-center fw-medium"
                                        style={{
                                          verticalAlign: "middle",
                                          fontSize: 13,
                                        }}
                                        scope="row"
                                      >
                                        {index + 1}
                                      </th>

                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.durationOfPregnancy}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.pregnancyOutcome?.name}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.birthWeight}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.dob}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.sex}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.anomalies}
                                      </td>
                                      <td style={{ paddingLeft: 14 }}>
                                        {item?.remarks}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        )}

                        {/* Menstural */}

                        {data?.menstrualInfoData && (
                          <>
                            <h5 className="fw-medium">Menstrual Info</h5>
                            <table
                              className="table table-bordered table-sm"
                              style={{ width: 650 }}
                            >
                              <thead
                                className="table-primary"
                                style={{ fontSize: 14 }}
                              >
                                <tr>
                                  <th
                                    style={{ paddingLeft: 14, width: 37 }}
                                    scope="col"
                                  >
                                    #
                                  </th>
                                  <th style={{ paddingLeft: 14 }} scope="col">
                                    Menstrual Info
                                  </th>
                                  <th style={{ paddingLeft: 14 }} scope="col">
                                    Details
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.menstrualInfoData?.ageAtMenarche && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>
                                      Age at Menarche
                                    </td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {data?.menstrualInfoData?.ageAtMenarche}
                                    </td>
                                  </tr>
                                )}

                                {data?.menstrualInfoData?.lmp && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>LMP</td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {moment(
                                        data?.menstrualInfoData?.lmp
                                      ).format("DD-MMM-YYYY")}
                                    </td>
                                  </tr>
                                )}
                                {data?.menstrualInfoData
                                  ?.regularityOfMenstrualCycle?.name && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>
                                      Regularity of Menstrual Cycle
                                    </td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {
                                        data?.menstrualInfoData
                                          ?.regularityOfMenstrualCycle?.name
                                      }
                                    </td>
                                  </tr>
                                )}
                                {data?.menstrualInfoData
                                  ?.durationForMenstrualBleeding && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>
                                      Duration for Menstrual Bleeding
                                    </td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {
                                        data?.menstrualInfoData
                                          ?.durationForMenstrualBleeding
                                      }
                                    </td>
                                  </tr>
                                )}
                                {data?.menstrualInfoData
                                  ?.periodOfMenstrualCycle && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>
                                      Period of Menstrual Cycle
                                    </td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {
                                        data?.menstrualInfoData
                                          ?.periodOfMenstrualCycle
                                      }
                                    </td>
                                  </tr>
                                )}
                                {data?.menstrualInfoData
                                  ?.detailsOfMenstrualIssues && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>

                                    <td style={{ paddingLeft: 14 }}>
                                      Details of Menstrual Issues
                                    </td>
                                    <td style={{ paddingLeft: 14 }}>
                                      {
                                        data?.menstrualInfoData
                                          ?.detailsOfMenstrualIssues
                                      }
                                    </td>
                                  </tr>
                                )}
                                {data?.menstrualInfoData?.note && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="text-center"
                                      style={{
                                        fontSize: 14,
                                        verticalAlign: "center",
                                      }}
                                    >
                                      .
                                    </th>
                                    <td style={{ paddingLeft: 14 }}>Notes</td>

                                    <td style={{ paddingLeft: 14 }} colspan="2">
                                      {data?.menstrualInfoData?.note}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <hr className="my-3" /> {/* Divider Line */}
                          </>
                        )}

                        {data?.dietPlan && (
                          <div style={{ display: "flex" }}>
                            <p className="me-3 fw-medium" style={{ margin: 0 }}>
                              Diet Plan:
                            </p>
                            <p style={{ margin: 0 }}>{data.dietPlan}</p>
                          </div>
                        )}

                        {/* Next Visit*/}
                        {data?.nextVisit && (
                          <div style={{ display: "flex" }}>
                            <p className="me-3 fw-medium" style={{ margin: 0 }}>
                              Next Visit:
                            </p>
                            <p style={{ margin: 0 }}>
                              {currentDate == data?.date &&
                                Math.ceil(
                                  moment(data?.nextVisit).diff(
                                    moment(),
                                    "hours"
                                  ) / 24
                                ) !== 0 &&
                                `In ${Math.ceil(
                                  moment(data?.nextVisit).diff(
                                    moment(),
                                    "hours"
                                  ) / 24
                                )} days `}
                              (
                              {moment(data?.nextVisit).format(
                                "DD-MMM-YYYY - dddd"
                              )}
                              )
                            </p>
                          </div>
                        )}
                        {/* Refered*/}
                        {data?.referredTo?.length > 0 && (
                          <div style={{ display: "flex" }}>
                            <p className="me-3 fw-medium">ReferredTo:</p>
                            <div
                              style={{
                                flexWrap: "wrap",
                                listStyleType: "none",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {data?.referredTo?.map((text, index) => (
                                <li key={text?.doctorId?.id}>
                                  <strong className=" fw-medium">
                                    {"     " + index + 1 + ") "}
                                  </strong>
                                  {text?.doctorId?.name}
                                  {text?.specialityId
                                    ? "(" + text?.specialityId?.name + ")"
                                    : null}
                                  {text?.doctorId?.mobileNo
                                    ? "  Mob: " + text?.doctorId?.mobileNo
                                    : null}
                                  {text?.doctorId?.email
                                    ? "    Email: " + text?.doctorId?.email
                                    : null}
                                </li>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="card text-center">
          <div className="card-header">Jivdani Hospital</div>
          <div className="card-body">
            <h1 className="card-title"> ! History</h1>
            <p className="card-text">There is no past visit history !</p>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/createToken")}
            >
              Create a Token
            </button>
          </div>
          <div className="card-footer text-muted">
            🩺🥼🫀 Have a great day! sir
          </div>
        </div>
      )}
      {labToken && (
        <ModalLabTest
          recevedTokens={labToken}
          onClose={() => setLabToken(null)}
        />
      )}
    </>
  );
}

export default ScrollSpy;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from ".././ui/CSS/customStyles";

import {
  convertOnlyValue,
  convertSelectedDataToServerFormat,
  DataConverte,
  isValidData,
  removeMatchingItems,
} from "../DataConverte/DataConverte";
import { updateField } from "../ReduxApi/ReduxApi";
import IconFunctionality from "../IconFunctionality";
import AddNewDataForAll from "../modal/AddNewDataForAll";
import PlusButton from "../ui/PlusButton";
import {
  ADDNEWDATAFORALL,
  DROPDOWNSARCHYNAME,
} from "../visitConstant/ApiConstant";
import apiHelper from "../ApiHelpar/apiHelper";
import { handleNewOptions } from "../Utils/handleNewOptions";
import DropdownSearchApl from "../ApiHelpar/DropdownSearchApl";
import ToastFist from "../Toast/ToastFist";

const SystemicExamination = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const [selectValues, setSelectValues] = useState({
    generals: null,
    cvs: null,
    rs: null,
    cns: null,
    pa: null,
    ent: null,
  });

  const [inputValues, setInputValues] = useState({});

  const [nadValue, setNadValue] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [DataSave, setDataSave] = useState();

  const [isLoading, setIsLoading] = useState(false); // Loader state

  const categories = [
    {
      key: "generals",
      placeholder: "General",
      optionsData: visitPadData?.systemicexamination?.generals,
    },
    {
      key: "cvs",
      placeholder: "CVS",
      optionsData: visitPadData?.systemicexamination?.cvs,
    },
    {
      key: "rs",
      placeholder: "RS",
      optionsData: visitPadData?.systemicexamination?.rs,
    },
    {
      key: "cns",
      placeholder: "CNS",
      optionsData: visitPadData?.systemicexamination?.cns,
    },
    {
      key: "pa",
      placeholder: "PA  ",
      optionsData: visitPadData?.systemicexamination?.pa,
    },
    {
      key: "ent",
      placeholder: "ENT ",
      optionsData: visitPadData?.systemicexamination?.ent,
    },
  ];

  const Categories = {
    GENERAL: { catID: 6, name: "general" },
    CVS: { catID: 7, name: "cvs" },
    RS: { catID: 8, name: "rs" },
    CNS: { catID: 9, name: "cns" },
    PA: { catID: 10, name: "pa" },
    ENT: { catID: 11, name: "ent" },
  };

  useEffect(() => {
    if (nadValue) {
      const nadData = "2";
      const newValues = categories.reduce((acc, category) => {
        acc[category.key] = nadData;
        return acc;
      }, {});
      setSelectValues(newValues);
      dispatch(updateField("systemicExaminationData", newValues));

      // console.log("systemicExaminationData", newValues)
    } else {
      setSelectValues({
        generals: null,
        cvs: null,
        rs: null,
        cns: null,
        pa: null,
        ent: null,
      });
      dispatch(updateField("systemicExaminationData", null));
    }
  }, [nadValue, dispatch]);

  // const handleChange = (selectedOption, categoryKey) => {
  //   const serverData = convertSelectedDataToServerFormat(categoryKey, selectedOption || []);
  //   const newValues = { ...selectValues, [categoryKey]: selectedOption };
  //   setSelectValues(newValues);
  //   dispatch(updateField("systemicExaminationData", newValues));
  // };.

  const handleChange = async (selectedOptions, categoryKey, catID) => {
    try {
      //  // console.log("Selected Options:", selectedOptions);
      const updatedOptions = await handleNewOptions(
        selectedOptions,
        catID,
        showToast,
        setShowToast,
        setToastMsg,
        setColorStatus
      );
      // // console.log("Updated Options:", updatedOptions);

      const localData = { ...selectValues, [categoryKey]: removeMatchingItems(updatedOptions )};
      setSelectValues(localData);
   //   console.log(localData)
  
      const serverData = convertSelectedDataToServerFormat(
        categoryKey,
        updatedOptions || []
      );
      const newValues = { ...DataSave, ...serverData };

    
      setDataSave(newValues);
      dispatch(updateField("systemicExaminationData", newValues));
      // console.log("systemicExaminationData", newValues);
    } catch (error) {
      console.error("Error in handleChange:", error);
    }
  };

  const [optionsData, setOptionsData] = useState({});
  const [DropDownInfo, setDropDownInfo] = useState({});

  // Function to handle input change and fetch data from search API
  const handleInputChange = (newValue, index, categoryId) => {
    const uppercasedValue = newValue.toUpperCase();

    setInputValues((prevValues) => ({
      ...prevValues,
      [index]: uppercasedValue,
    }));
    setDropDownInfo({ categoryId, uppercasedValue, index });
  };
  const { DropdownServerData, DropdownIsLoading, DropdownError } =
    DropdownSearchApl(
      DROPDOWNSARCHYNAME,
      DropDownInfo.uppercasedValue,
      DropDownInfo.categoryId
    );

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      if (!DropDownInfo.uppercasedValue) return; // Avoid fetching if searchTerm is empty

      setIsLoading({ [DropDownInfo.index]: DropdownIsLoading });
      try {
        // console.log( {[DropDownInfo.categoryId]: DropdownServerData,} ,"DropdownServerData------>>>")
        setOptionsData((prevOptions) => ({
          ...prevOptions,
          [DropDownInfo.categoryId]: DropdownServerData,
        }));
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setIsLoading({ [DropDownInfo.index]: DropdownIsLoading });
      }
    };

    fetchDropdownOptions(); // Call the function to fetch options
  }, [DropdownServerData, DropDownInfo.categoryId, DropdownIsLoading]); // Dependencies

  // const handleInputChange = (newValue, index) => {
  //   const uppercasedValue = newValue.toUpperCase();
  //   setInputValues((prevValues) => ({
  //     ...prevValues,
  //     [index]: uppercasedValue,
  //   }));
  //   return uppercasedValue;
  // };

  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  const handleClearData = useCallback(() => {
    setSelectValues({
      generals: null,
      cvs: null,
      rs: null,
      cns: null,
      pa: null,
      ent: null,
    });
    dispatch(updateField("systemicExaminationData", null));
    setDataSave("");
  }, [dispatch]);

  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  const systemicExaminationData = patientData?.systemicExaminationData;

  useEffect(() => {
    if (isValidData(systemicExaminationData)){
  if (systemicExaminationData != undefined || systemicExaminationData != null)
    setOpen(true);
  if (systemicExaminationData) {
    const localData = {
      generals: DataConverte(systemicExaminationData?.generals),
      cvs: DataConverte(systemicExaminationData?.cvs),
      rs: DataConverte(systemicExaminationData?.rs),
      cns: DataConverte(systemicExaminationData?.cns),
      pa: DataConverte(systemicExaminationData?.pa),
      ent: DataConverte(systemicExaminationData?.ent),
    };

    const serveData = {
      generals: convertOnlyValue(localData.generals),
      cvs: convertOnlyValue(localData.cvs),
      rs: convertOnlyValue(localData.rs),
      cns: convertOnlyValue(localData.cns),
      pa: convertOnlyValue(localData.pa),
      ent: convertOnlyValue(localData.ent),
    };

    setSelectValues(localData);
    setDataSave(serveData);
    // // console.log("systemicExaminationData", serveData);
    dispatch(updateField("systemicExaminationData", serveData));
  }
    }else{
      handleClearData();
    }
    
  }, [patientData]);

  return (
    <>
      <div className="d-flex">
        <IconFunctionality
          name="Systemic Examination"
          // iconSave
          // iconRefresh
          iconErase
          onClick={handleClearData}
        />

        <PlusButton setOpen={setOpen} open={open} />

        {open && (
          <div className="form-check ms-3 " style={{ marginTop: 2 }}>
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              style={{ width: 22, height: 20 }}
              checked={nadValue}
              onChange={() => setNadValue((prev) => !prev)}
            />
            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
              Mark all fields as NAD
            </label>
          </div>
        )}
      </div>

      <div
        className="col-12 col-xxl-8   mx-xxl-auto"
        style={{ paddingLeft: 40 }}
      >
        <div className="d-flex "></div>
        {open && (
          <div className=" mt-1">
            <div className="row row-cols-3 gx-2 ">
              {categories.map((category, index) => {
                const catID =
                  index === 0
                    ? Categories.GENERAL.catID
                    : index === 1
                    ? Categories.CVS.catID
                    : index === 2
                    ? Categories.RS.catID
                    : index === 3
                    ? Categories.CNS.catID
                    : index === 4
                    ? Categories.PA.catID
                    : index === 5
                    ? Categories.ENT.catID
                    : undefined; // In case index is out of bounds

                return (
                  <div className="col mb-1" key={index + 1}>
                    <div className="d-flex ">
                      <label className="me-3">{category.placeholder}</label>

                      {/* <div className="float-end" key={index}>
                        <AddNewDataForAll
                          fieldName={
                            index === 0
                              ? Categories.GENERAL.name
                              : index === 1
                                ? Categories.CVS.name
                                : index === 2
                                  ? Categories.RS.name
                                  : index === 3
                                    ? Categories.CNS.name
                                    : index === 4
                                      ? Categories.PA.name
                                      : index === 5
                                        ? Categories.ENT.name
                                        : undefined // In case index is out of bounds
                          }
                          InputField={1}
                        />
                      </div> */}
                    </div>

                    {nadValue ? (
                      <input
                        type="text"
                        className="form-control mb-2"
                        value="NAD"
                        readOnly
                        style={{ fontSize: 14, fontWeight: "bold" }}
                      />
                    ) : (
                      <CreatableSelect
                        closeMenuOnSelect={false}
                        key={index}
                        isMulti
                        options={
                          optionsData[catID]?.length > 0
                            ? optionsData[catID]
                            : DataConverte(category?.optionsData)
                        }
                        // options={DataConverte(category.optionsData)}
                        placeholder={category.placeholder}
                        // onChange={ComplaintshandleSelectChange}

                        onChange={(selectedOption) =>
                          handleChange(selectedOption, category.key, catID)
                        }
                        // value={Complaints}
                        value={selectValues[category.key]}
                        // onInputChange={(item) => setServerData(item.toUpperCase())}
                        onInputChange={(newValue) =>
                          handleInputChange(newValue, index, catID)
                        }
                        // inputValue={serverData || ""}
                        inputValue={inputValues[index] || ""}
                        // menuIsOpen={true} // Keep the menu open to show loader
                        isLoading={isLoading[index]} // Show loading state
                        loadingMessage={() => "Loading options..."} // Custom loader message
                        components={{ DropdownIndicator, IndicatorSeparator }}
                        styles={customSelectStyles}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default SystemicExamination;

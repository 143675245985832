// import { Link } from "react-router-dom";
// import parse from "html-react-parser";
// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeatureProduct from "./FeatureProduct";
import { Link } from "react-router-dom";
// import { DEBUG_LOG_ENABLE } from "../Constant";
import Headline from "./ Headline";

const ProductGridCard = ({ item, navigate, showEdit, nama }) => {
  return (
    <div>
      {/* <div class="mb-3 fw-semibold">
        <table>
          <td>
            <h3>{item.title}</h3>
          </td>

          <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
            <div className="">
              {showEdit ? (
                <div>
                  <Link
                    to={"products"}
                    onClick={() =>
                      navigate(
                        localStorage.setItem("/myData", JSON.stringify(item))
                      )
                    }
                    type="button"
                    class="btn btn-primary me-md-2"
                  >
                    View all
                  </Link>
                  <Link
                    to={"/addcat"}
                    className="btn btn-primary "
                    onClick={() =>
                      navigate(
                        "addcat",

                        localStorage.setItem("catData", JSON.stringify(item))
                      )
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    Edit
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </table>
      </div> */}
      <Headline
        item={item}
        navigate={navigate}
        showEdit={showEdit}
        nama={nama}
      />
      <div className="d-flex flex-row flex-nowrap overflow-auto">
        {/* <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 px-md-6 pt-1"> */}
        {item.items.map((it) => (
          <div>
            <FeatureProduct
              items={item}
              nama={nama}
              key={it.itemId}
              product={it}
             // showEdit={showEdit}
            />
         
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProductGridCard;

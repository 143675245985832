// helper/handleNewOptions.js
import { addItemToServer } from "./addItemToServer";
export const handleNewOptions = async (
  selectedOptions,
  categoryKey,
  showToast,
  setShowToast,
  setToastMsg,
  setColorStatus
) => {
  // Check if selectedOptions is an array
  if (Array.isArray(selectedOptions)) {
    const updatedOptions = [...selectedOptions];

    for (let i = 0; i < selectedOptions.length; i++) {
      const option = selectedOptions[i];

      if (option.__isNew__) {
        const newData = await addItemToServer(option.label, categoryKey);

        // Replace the newly created option with the server response
        // if (newData) {
        //   updatedOptions[i] = newData;
        // }
        if (newData) {
          updatedOptions[i] = newData;
          setShowToast(true);
          setColorStatus(1);
          setToastMsg(`Data Saved Successfully.`);
        } else {
          setShowToast(true);
          setToastMsg(`Failed to add item: ${option.label}`);
          setColorStatus(0);
        }
        
      }
    }

    return updatedOptions; // Return the modified options array
  } else {
    // Handle the case where selectedOptions is not an array (a single object)
    if (selectedOptions?.__isNew__) {
      const newData = await addItemToServer(selectedOptions.label, categoryKey);

      // Return the server response if new data is created
      // if (newData) {
      //   return newData;
      // }
      if (newData) {
        setShowToast(true);
        setColorStatus(1);
        setToastMsg(`Data Saved Successfully.`);
        return newData;
      } else {
        setShowToast(true);
        setToastMsg(`Failed to add item: ${selectedOptions.label}`);
        setColorStatus(0);
      }
    }

    return selectedOptions; // Return the original object if no new data is added
  }
};

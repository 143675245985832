import { useEffect, useState } from "react";

import {
  BASE_URL,
  CARD_VIEW,
  CARD_VIEW_WITH_TEXT,
  USER_ROLE,
  ROLE,
  IMG_BASE_URL,
  FULL_BODY_CHEAKUP,
} from "../Constant";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Minicard from "../minicard/Minicard";
import FullBodyCheakup from "../landing/fullBodyCheckp/FullBodyCheakup";
import NoteBoard from "../noteboard/NoteBoard";
import HighlightTest from "./HighlightTest";
import TestBySymptoms from "./TestBySymptoms";

const Labtest = () => {
  const [Categorydata, setCategoryData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }

    const filteredSearchList = catItemList.filter((item) => {
      return (
        //item.viewType === CARD_VIEW ||
        // item?.viewType === "FullBodyCheakup" ||
        item.viewType === CARD_VIEW_WITH_TEXT ||
        //item?.viewType === "you_tube_view" ||
        item?.viewType === "Digital X-ray" ||
        item?.viewType === "Specialised"
        // item?.viewType === "PanchakarmaUnit" ||
        // item?.viewType === "neurologist"
      );
    });
    setCatListData(filteredSearchList);
  }, []);

  const data = (item, nama) => {
    //  navigate("/itemsviewmore");

    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };

  return (
    <div className="container " style={{ marginTop: 100 }}>
      {/* <div class="d-flex bd-highlight mt-4 ">
        <div class="container overflow-hidden">
          <div class="row ">
            <div class="col">
              <h1 className="mb-5 fs-3 fw-semibold ">Our Services</h1>
            </div>
          </div>
        </div>

      
      </div> */}
      <div class="row  ">
        <Minicard
          item={catListData}
          Text={"item"}
          rowItem={6}
          // key={item.id}
          navigate={navigate}
          showEdit={showEdit}
          nama={CARD_VIEW_WITH_TEXT}
        />
      </div>
      <div className="">
        {catItemList?.map((item, index) => (
          <div>
            {item?.viewType == "FullBodyCheakup" ? (
              <div>
                <FullBodyCheakup
                  key={item.id}
                  item={item}
                  navigate={navigate}
                  showEdit={showEdit}
                  nama={FULL_BODY_CHEAKUP}
                  detailed={true}
                />
                <NoteBoard
                  Text={"Get active and be a healthy weight."}
                  Text_02={" Do not get thirsty,Do not skip breakfast"}
                />
                <TestBySymptoms column={4} />

                <HighlightTest />
              </div>
            ) : (
              <div>{/* <p> {item?.title + "=" + item.viewType}</p> */}</div>
            )}
          </div>
        ))}
        <>
          <TestBySymptoms column={6} />
          <div className="text-start">
            <h1 className="fs-3">Welcome to Jivdani Hospital</h1>
            <p>
              Jivdani Hospital understands the importance of lab tests in
              maintaining good health.As a cornerstone of the Indian healthcare
              sector, we are proud to offer a wide range of lab tests, including
              blood tests, right in the comfort of your own home.With our
              intuitive app, accessing Jivdani Hospital's diagnostic services
              has never been easier.
            </p>
            <p>
              Simply download the app and book your lab test online with just a
              few clicks.Gone are the days of long waiting times and multiple
              trips to the lab. We've streamlined the process for you,
            </p>

            <p>
              Simply download the app and book your lab test online with just a
              few clicks.Gone are the days of long waiting times and multiple
              trips to the lab. We've streamlined the process for you,
            </p>
            <p>
              offering hassle-free blood sample collection from home and speedy
              report delivery.Plus, we offer exclusive discounts to make
              healthcare more affordable for you and your family
            </p>
            <p>
              Our diagnostic packages, lab test packages, and blood test
              packages are competitively priced, ensuring you get the care you
              need without breaking the bank.
            </p>
            <h1 className="fs-3"> When do You Need Lab Test ?</h1>
            <p>
              Lab tests are essential for various healthcare purposes, including
              routine check-ups, diagnosing symptoms, monitoring chronic
              conditions, preventive screenings, pre-surgical evaluations,
              medication monitoring, fertility evaluations, infectious disease
              testing, allergy testing, and nutritional assessments.
            </p>
          </div>
        </>
      </div>
    </div>
  );
};

export default Labtest;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Placeholder from "../../src/default_img/placeholder.png";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Headline from "./ Headline";

const ProductFullWidthCard = ({ item, showEdit }) => {
  const navigate = useNavigate();
  return (
    <div>
      {
        <div>
          {/* <div class="mb-3 fw-semibold">
            <table>
              <td>
                <h3>{item?.title}</h3>
              </td>

              <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
                <div className="">
                  {showEdit ? (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            "products",
                            localStorage.setItem("myData", JSON.stringify(item))
                          )
                        }
                        type="button"
                        class="btn btn-primary me-md-2"
                      >
                        View all
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          navigate(
                            "addcat",

                            localStorage.setItem(
                              "catData",
                              JSON.stringify(item)
                            )
                          )
                        }
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        Edit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </table>
          </div> */}
          <Headline item={item} navigate={navigate} showEdit={showEdit} />
          <div>
            {item?.items?.map((it) => (
              <div class="card mt-3 " aria-hidden="true">
                <div class="card   ">
                  <div class="row ">
                    <div class="col-md-5  ">
                      <Link
                        to={"/productdetail/" + it?.itemId}
                        onClick={() =>
                          localStorage.setItem("myProduct", JSON.stringify(it))
                        }
                      >
                        <LazyLoadImage
                          PlaceholderSrc={Placeholder}
                          effect="blur card-img-top bg-dark "
                          key={it.iconURL}
                          src={it.iconURL}
                          height="250"
                          // onError={({ currentTarget }) => {
                          //   currentTarget.onerror = null; // prevents looping
                          //   currentTarget.src = { Placeholder };
                          // }}
                          class="card-img-top bg-dark   "
                          alt="..."
                        />
                      </Link>
                    </div>
                    <div class="col-md-7">
                      <div class="card-body">
                        <div className="d-flex">
                          <div className="d-inline h5 mb-0 fw-semibold me-3">
                            <h5 class="card-title">{it?.title}</h5>
                          </div>
                          <div className="ms-auto">
                            <h6 class="card-title">
                              {"₹" + it.price} <br />
                              <p>
                                <small class="text-muted"> Fee </small>
                              </p>
                            </h6>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-9 text-truncate">
                            {parse(`${it?.subTitle}`)}
                          </div>
                        </div>

                        <div className="d-flex">
                          <div className="ms-auto">
                            <Link
                              className="btn btn-outline-primary rounded-pill"
                              to={"/slotbooking/" + it?.itemId}
                              onClick={() =>
                                localStorage.setItem(
                                  "myProduct",
                                  JSON.stringify(it)
                                )
                              }
                              replace
                            >
                              Book
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default ProductFullWidthCard;

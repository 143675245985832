import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Placeholder from "../../src/default_img/placeholder.png";
import slotbooking from "./slotbooking.css";
import Loader from "../loader/Loader";

// import PropTypes from "prop-types";
import {
  DEBUG_LOG_ENABLED,
  SESSION_ID,
  // ADD_DOCTOR,
  BASE_URL,
  ACCESS_TOKEN,
  // ADD_TO_CART,
  // CUSTOMER_ID,
  USER_ROLE,
  ROLE,
  GetDoctorDatesListsByDoctorIdAndDates,
  IMG_BASE_URL,
} from "../Constant";
import swal from "sweetalert";
import Loaders from "../loader/Loader";

// import {  useDispatch } from "react-redux";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import axios from "axios";
import moment from "moment";
// import { increment } from "../actions";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import HorizontalScroll from "react-horizontal-scrolling";
import { LazyLoadImage } from "react-lazy-load-image-component";
import unavailableImage from "../Images/unavailable.png";
import notAvailable from "../Images/notAvailable.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequestCall from "../requestCall/RequestCall";
import DefaultImage from "../Images/doctor.png";
function SlotBooking() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slots, setSlots] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);
  const myData = localStorage.getItem("myProduct");
  const product = JSON.parse(myData);
  let [showEdit, setShowEdit] = useState(false);
  let [Buttonactiv, setButtonActiv] = useState(0);
  const [Activ, setActiv] = useState(true);
  let [Loader, setLoader] = useState(false);
  const [differenceTime, setDifference] = useState(10);
  const [availablityDays, setAvailablityDays] = useState("");

  // const [price, setPrice] = useState(product?.price);
  const [currency, setCurrency] = useState("INR");
  // const [bookinDate, setBookinDate] = useState("");

  useEffect(() => {
    if (timeslots.length >= 2) {
      const startTimeFirstSlot = moment(timeslots[0].startTime, "HH:mm");
      const endTimeSecondSlot = moment(timeslots[1].startTime, "HH:mm");
      const timeDifference = endTimeSecondSlot.diff(
        startTimeFirstSlot,
        "minutes"
      );
      setDifference(timeDifference);
    }
  }, [timeslots]);

  useEffect(() => {
    let availableDates = [];
    let daysDate = [];
    for (let i = 0; i < slots.length && availableDates.length < 7; i++) {
      const slot = slots[i];
      const slotDate = moment(slot.date).format("DD-MM-YYYY");
      const slotDaysDate = moment(slot.date).format("dddd");
      if (!daysDate.includes(slotDaysDate)) {
        daysDate.push(slotDaysDate);
      }
      availableDates.push(slotDate);
    }
    if (availableDates.length === 7 || availableDates.length === 6) {
      if (isConsecutive({ dates: availableDates, value: false })) {
        setAvailablityDays("Everyday");
      } else {
        const sortedDays = daysDate.sort((a, b) => {
          const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
        });
        if (isConsecutiveDays(sortedDays)) {
          setAvailablityDays(
            sortedDays[0] + " - " + sortedDays[sortedDays.length - 1]
          );
          // console.log('Sorted days are consecutive.');
        } else {
          // console.log('Sorted days are not consecutive.');
          setAvailablityDays(sortedDays.join(", "));
        }
      }
    } else {
      if (daysDate.length > 0) {
        const sortedDays = daysDate.sort((a, b) => {
          const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
        });
        if (isConsecutiveDays(sortedDays)) {
          if (sortedDays.length >= 2) {
            setAvailablityDays(
              sortedDays[0] + " - " + sortedDays[sortedDays.length - 1]
            );
          } else {
            setAvailablityDays(sortedDays[0]);
          }
          // console.log('Sorted days are consecutive.');
        } else {
          // console.log('Sorted days are not consecutive.');
          setAvailablityDays(sortedDays.join(", "));
        }
      }
    }
  }, [slots]);

  const shippingDetail = (data) => {
    localStorage.setItem("cartListData", JSON.stringify(product));
    // localStorage.setItem("price", "");
    localStorage.setItem("currency", currency);
    localStorage.setItem("timeslot", JSON.stringify(data));
    localStorage.setItem(
      "bookingTime",
      moment(data.startTime, ["HH.mm"]).format("hh:mm a")
    );

    localStorage.setItem("multipleprice", "");
    localStorage.setItem("multiplecurrency", "");
    localStorage.setItem("multiplecartListData", "");

    navigate("/addaddress");
  };

  const getslot = async () => {
    setLoader(true);
    try {
      var endDate = moment(new Date(), "YYYY-MM-DD").add(28, "days");
      const dateData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            doctorId: product?.doctorId,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + GetDoctorDatesListsByDoctorIdAndDates,
        dateData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal(response.data);
      //  const date = response.data.respData.respMsg.doctorDatesList;
      // setSlots( response.data.respData.respMsg.doctorDatesList.sort(
      //   (a, b) => a.date - b.date
      // ))

      const numAscending = [
        ...response.data.respData.respMsg.doctorDatesList,
      ].sort((a, b) => (a.date > b.date) - (a.date < b.date));
      setSlots(numAscending);

      //  setSlots(date);

      if (DEBUG_LOG_ENABLED) {
        // console.log(response.data);
      }
      timeSet(numAscending);

      // console.log(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swal("Oops...", "Something went wrong!", "error");
      if (DEBUG_LOG_ENABLED) {
        console.log(error);
      }
    }
  };
  let [DoctorDateId, setDoctorDateId] = useState();
  useEffect(() => {
    getslot();

    //fileContent();
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);
  const timeSet = (slots) => {
    {
      slots.map((item, index) => (index === 0 ? time(item) : null));
    }
  };
  const time = (item) => {
    setTimeSlots(item?.slotList);
    localStorage.setItem("bookingDate", item.date);
    // setCurrentDate(item.date)

    setDoctorDateId(item.doctorDateId);
  };

  // const dateSort =()=>{
  // slots.sort((a,b)=>{
  // setSlots(b.date - a.date);
  //   // return a.date-b.date
  // })

  // }

  const CurrentTime = moment(new Date()).format("HH:mm");
  const BookingButton = () => {
    if (!timeslots.length == 0) swal("Please select appointment time ");
  };

  return (
    <>
      <div
        className="align-items-center container    "
        style={{ marginTop: 120, marginBottom: 30 }}
      >
        <div className="row g-2">
          <div className="col-md-6">
            <div className="row g-2 ">
              <div className="col-12 card ">
                <div class="row  gap-5 ">
                  <div class="col-3   " style={{ alignSelf: "center" }}>
                    {product?.iconURL ? (
                      <img
                        src={IMG_BASE_URL + product?.iconURL}
                        style={{ maxHeight: "100px", width: "100px" }}
                        class="rounded-2 "
                        alt="Cinque Terre"
                        onError={(event) => {
                          event.target.src = DefaultImage;
                        }}
                      ></img>
                    ) : (
                      <img
                        src={unavailableImage}
                        style={{ maxHeight: "100px", width: "100px" }}
                        class="rounded-2"
                        alt="Cinque Terre"
                      ></img>
                    )}
                  </div>
                  <div class="col   " style={{ alignSelf: "self-start" }}>
                    <h4 className="  ">{product.title}</h4>
                    <small className="text-muted">{product.subTitle}</small>
                    <br></br>
                    {/* <small className="text-muted">
                      DNB (Internal Med) & MBBS{" "}
                    </small> */}
                  </div>
                </div>
                <div className="col-md-12 m-md-3 my-2  justify-content-center ">
                  <div
                    className="col-md-11 d-flex justify-content-between justify-content-center align-items-center"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div class="card  col-md-3 " style={{ maxWidth: 100 }}>
                      <small
                        className="text-muted"
                        style={{
                          fontSize: 10,
                          alignSelf: "center",
                          fontWeight: "bold",
                        }}
                      >
                        PATIENTS
                      </small>

                      <div className="row row-cols-2 ">
                        <div
                          className="col  "
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            src={IMG_BASE_URL + "icons/Wheelchair.png"}
                            class="m-2"
                            alt="..."
                            style={{ alignSelf: "center", maxWidth: 40 }}
                          />
                        </div>
                        <div className="col  my-3   ">
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "center",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            50+
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="card  col-md-3 ">
                      <small
                        className="text-muted"
                        style={{
                          fontSize: 10,
                          alignSelf: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Experience
                      </small>

                      <div className="d-flex ">
                        <div
                          className="col "
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            src={IMG_BASE_URL + "icons/BriefcaseSettings.png"}
                            class="m-2"
                            alt="..."
                            style={{ alignSelf: "center", maxWidth: 40 }}
                          />
                        </div>
                        <div className="col  my-3 pe-1">
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "center",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {product?.subTitleID}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="card col-md-3  " style={{ maxWidth: 100 }}>
                      <small
                        className="text-muted"
                        style={{
                          fontSize: 10,
                          alignSelf: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Booking
                      </small>

                      <div className="row row-cols-2 ">
                        <div
                          className="col "
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            src={IMG_BASE_URL + "icons/Booking.png"}
                            class="m-2"
                            alt="..."
                            style={{ alignSelf: "center", maxWidth: 40 }}
                          />
                        </div>
                        <div className="col  mt-3">
                          {/* <small
                                className=" "
                                style={{
                                  alignSelf: "center",
                                  fontSize: 15,
                                  fontWeight: "bold",
                                }}
                              >
                                1k +
                              </small> */}
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "center",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            1K+
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-block d-md-none">
                <div class="card rounded-top p-3 ">
                  {slots?.length === 0 ? (
                    // <div class="d-flex justify-content-center m-5">
                    //   <div className=" text-center">
                    //     <img src={notAvailable} style={{ width: "18rem" }} />

                    //   </div>
                    // </div>
                    <div className="container">
                      <div className="row justify-content-md-center">
                        <RequestCall />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="d-flex flex-row flex-nowrap overflow-auto pb-4">
                        {Loader ? (
                          <div
                            className="spinner-border text-primary mx-2"
                            style={{ width: 20, height: 20 }}
                            role="status"
                          ></div>
                        ) : (
                          slots.map((item, index) => (
                            <div class="col  me-3 ">
                              <button
                                type="button"
                                className={`btn btn-outline-primary bg-${
                                  Buttonactiv === index && "primary text-light"
                                }`}
                                // className={"btn btn-outline-primary   mb-2 bg-${}"}
                                onClick={(e) => {
                                  // handleClick(e);
                                  setButtonActiv(index);
                                  setTimeSlots(item?.slotList);
                                  localStorage.setItem(
                                    "bookingDate",
                                    item.date
                                  );
                                }}
                                style={{ width: 120, height: 55 }}
                              >
                                {/* {moment(item.date).format("DD-MM-YYYY")}
                            <br></br> */}
                                {/* {moment(item.date).format("dddd")} */}
                                {moment(item.date).format("DD MMM")}
                              </button>

                              {/* <div
                          className="btn-group "
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check "
                            name="btnradio"
                            id={item.date}
                            autoComplete="off"
                            defaultChecked=""
                            value={moment(item.date).format("DD-MM-YYYY")}
                            onClick={(e) => {
                              setTimeSlots(item?.slotList);
                              localStorage.setItem("bookingDate", item.date);
                            }}
                          />
                          <label
                            className="btn btn-outline-primary mb-2  "
                            htmlFor={item.date}
                          
                          >
                            {moment(item.date).format("DD-MM-YY")}
                           
                          </label>
                        </div> */}

                              <br></br>
                              {showEdit ? (
                                <Link
                                  to={"/addtimeslot"}
                                  type="button"
                                  onClick={() =>
                                    navigate(
                                      localStorage.setItem(
                                        "dateItem",
                                        JSON.stringify(item)
                                      )
                                    )
                                  }
                                  class="btn btn-outline-primary  ms-2"
                                >
                                  Time Solot
                                </Link>
                              ) : null}
                            </div>
                          ))
                        )}
                      </div>

                      <h4 className="mt-4"> Available slots</h4>
                      <div class="row justify-content-between">
                        <div class="col-4">
                          <small> Jivdani hospital </small>
                        </div>
                        <div class="col-3">
                          <small> For: {differenceTime} Min </small>
                        </div>
                      </div>
                      {timeslots.length == 0 ? (
                        <>
                          {/* <div className=" text-center">
                        <h4 className=" text-primary ">
                          Time is not available for booking
                        </h4>

                       
                      </div> */}

                          {/* <RequestCall /> */}

                          <div className="container">
                            <div className="row justify-content-md-center">
                              {/* <div className="col-md-6"> */}
                              <RequestCall />
                              {/* </div> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          {timeslots.slice(-1).map((data, indx) => (
                            <>
                              {data.doctorDateId === DoctorDateId ? (
                                <>
                                  {data.doctorDateId === DoctorDateId &&
                                  data.startTime <= CurrentTime ? (
                                    // <div className="container">
                                    //   <div className=" justify-content-md-center">
                                    //     <div className="col-md-12">
                                    //       <RequestCall />
                                    //     </div>
                                    //   </div>
                                    // </div>

                                    <div className="row justify-content-md-center">
                                      {/* <div className="col-md-6"> */}
                                      <RequestCall />
                                      {/* </div> */}
                                    </div>
                                  ) : (
                                    <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                                      {timeslots?.map((data, index) => (
                                        <>
                                          {data.doctorDateId ===
                                          DoctorDateId ? (
                                            <>
                                              {data.doctorDateId ===
                                                DoctorDateId &&
                                                data.startTime >=
                                                  CurrentTime && (
                                                  <div class="col my-2">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        shippingDetail(data)
                                                      }
                                                      className="btn btn-outline-primary"
                                                    >
                                                      {moment(data.startTime, [
                                                        "HH.mm",
                                                      ]).format("hh:mm a")}
                                                    </button>
                                                  </div>
                                                )}
                                            </>
                                          ) : (
                                            <div class="col my-2">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  shippingDetail(data)
                                                }
                                                className="btn btn-outline-primary"
                                              >
                                                {/* {data.startTime} */}

                                                {moment(data.startTime, [
                                                  "HH.mm",
                                                ]).format("hh:mm a")}
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                                  {timeslots?.map((data, index) => (
                                    <>
                                      {data.doctorDateId === DoctorDateId ? (
                                        <>
                                          {data.doctorDateId === DoctorDateId &&
                                            data.startTime >= CurrentTime && (
                                              <div class="col my-2">
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    shippingDetail(data)
                                                  }
                                                  className="btn btn-outline-primary"
                                                >
                                                  {/* {data.startTime} */}
                                                  {moment(data.startTime, [
                                                    "HH.mm",
                                                  ]).format("hh:mm a")}
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <div class="col my-2">
                                          <button
                                            type="button"
                                            onClick={() => shippingDetail(data)}
                                            className="btn btn-outline-primary"
                                          >
                                            {/* {data.startTime} */}

                                            {moment(data.startTime, [
                                              "HH.mm",
                                            ]).format("hh:mm a")}
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                            </>
                          ))}

                          {/* {data.startTime >= CurrentTime && ( */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="card p-3">
                  <p className="fw-bold">About</p>
                  <p>
                    Provide vaccinations, perform routine health screenings,and
                    offer guidance on maintaining a healthy lifestyle
                  </p>
                  <p className="fw-bold">Availability</p>
                  {/* <p>Monday - Friday 8am -9pm </p> */}
                  <p>
                    {availablityDays}
                    {/* {moment(timeslots[0].startTime, "HH:mm").format(
                      " h:mm A"
                    )}
                    {moment(timeslots[timeslots.length - 1].startTime, "HH:mm")
                      .endOf("minutes")
                      .add(differenceTime, "minutes")
                      .format("h:mm A")} */}
                  </p>
                  <p className="fw-bold">Languges speaks</p>
                  <small>English </small> <small>Hindi </small>
                  <small>Marathi </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="card rounded-top p-3 d-none d-md-block">
              {slots?.length === 0 ? (
                // <div class="d-flex justify-content-center m-5">
                //   <div className=" text-center">
                //     <img src={notAvailable} style={{ width: "18rem" }} />

                //   </div>
                // </div>
                <div className="container">
                  <div className="row justify-content-md-center">
                    <RequestCall />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex flex-row flex-nowrap overflow-auto pb-4">
                    {Loader ? (
                      <div
                        className="spinner-border text-primary mx-2"
                        style={{ width: 20, height: 20 }}
                        role="status"
                      ></div>
                    ) : (
                      slots.map((item, index) => (
                        <div class="col  me-3 ">
                          <button
                            type="button"
                            className={`btn btn-outline-primary bg-${
                              Buttonactiv === index && "primary text-light"
                            }`}
                            // className={"btn btn-outline-primary   mb-2 bg-${}"}
                            onClick={(e) => {
                              // handleClick(e);
                              setButtonActiv(index);
                              setTimeSlots(item?.slotList);
                              localStorage.setItem("bookingDate", item.date);
                            }}
                            style={{ width: 120, height: 55 }}
                          >
                            {/* {moment(item.date).format("DD-MM-YYYY")}
                            <br></br> */}
                            {/* {moment(item.date).format("dddd")} */}
                            {moment(item.date).format("DD MMM")}
                          </button>

                          {/* <div
                          className="btn-group "
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check "
                            name="btnradio"
                            id={item.date}
                            autoComplete="off"
                            defaultChecked=""
                            value={moment(item.date).format("DD-MM-YYYY")}
                            onClick={(e) => {
                              setTimeSlots(item?.slotList);
                              localStorage.setItem("bookingDate", item.date);
                            }}
                          />
                          <label
                            className="btn btn-outline-primary mb-2  "
                            htmlFor={item.date}
                          
                          >
                            {moment(item.date).format("DD-MM-YY")}
                           
                          </label>
                        </div> */}

                          <br></br>
                          {showEdit ? (
                            <Link
                              to={"/addtimeslot"}
                              type="button"
                              onClick={() =>
                                navigate(
                                  localStorage.setItem(
                                    "dateItem",
                                    JSON.stringify(item)
                                  )
                                )
                              }
                              class="btn btn-outline-primary  ms-2"
                            >
                              Time Solot
                            </Link>
                          ) : null}
                        </div>
                      ))
                    )}
                  </div>

                  <h4 className="mt-4"> Available slots</h4>
                  <div class="row justify-content-between">
                    <div class="col-4">
                      <small> Jivdani hospital </small>
                    </div>
                    <div class="col-3">
                      <small> For: {differenceTime} Min </small>
                    </div>
                  </div>
                  {timeslots.length == 0 ? (
                    <>
                      {/* <div className=" text-center">
                        <h4 className=" text-primary ">
                          Time is not available for booking
                        </h4>

                       
                      </div> */}

                      {/* <RequestCall /> */}

                      <div className="container">
                        <div className="row justify-content-md-center">
                          {/* <div className="col-md-6"> */}
                          <RequestCall />
                          {/* </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      {timeslots.slice(-1).map((data, indx) => (
                        <>
                          {data.doctorDateId === DoctorDateId ? (
                            <>
                              {data.doctorDateId === DoctorDateId &&
                              data.startTime <= CurrentTime ? (
                                // <div className="container">
                                //   <div className=" justify-content-md-center">
                                //     <div className="col-md-12">
                                //       <RequestCall />
                                //     </div>
                                //   </div>
                                // </div>

                                <div className="row justify-content-md-center">
                                  <div className="col-md-6">
                                    <RequestCall />
                                  </div>
                                </div>
                              ) : (
                                <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                                  {timeslots?.map((data, index) => (
                                    <>
                                      {data.doctorDateId === DoctorDateId ? (
                                        <>
                                          {data.doctorDateId === DoctorDateId &&
                                            data.startTime >= CurrentTime && (
                                              <div class="col my-2">
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    shippingDetail(data)
                                                  }
                                                  className="btn btn-outline-primary"
                                                >
                                                  {moment(data.startTime, [
                                                    "HH.mm",
                                                  ]).format("hh:mm a")}
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <div class="col my-2">
                                          <button
                                            type="button"
                                            onClick={() => shippingDetail(data)}
                                            className="btn btn-outline-primary"
                                          >
                                            {/* {data.startTime} */}

                                            {moment(data.startTime, [
                                              "HH.mm",
                                            ]).format("hh:mm a")}
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (
                            <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                              {timeslots?.map((data, index) => (
                                <>
                                  {data.doctorDateId === DoctorDateId ? (
                                    <>
                                      {data.doctorDateId === DoctorDateId &&
                                        data.startTime >= CurrentTime && (
                                          <div class="col my-2">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                shippingDetail(data)
                                              }
                                              className="btn btn-outline-primary"
                                            >
                                              {/* {data.startTime} */}
                                              {moment(data.startTime, [
                                                "HH.mm",
                                              ]).format("hh:mm a")}
                                            </button>
                                          </div>
                                        )}
                                    </>
                                  ) : (
                                    <div class="col my-2">
                                      <button
                                        type="button"
                                        onClick={() => shippingDetail(data)}
                                        className="btn btn-outline-primary"
                                      >
                                        {/* {data.startTime} */}

                                        {moment(data.startTime, [
                                          "HH.mm",
                                        ]).format("hh:mm a")}
                                      </button>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          )}
                        </>
                      ))}

                      {/* {data.startTime >= CurrentTime && ( */}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="col-12 ">
              <div
                className="card py-1 px-3 d-block d-md-none  fixed-bottom "
                style={{ marginBottom: 65, marginRight: 20, marginLeft: 20 }}
              >
                <div className="row ">
                  <div className="col">
                    <small style={{ fontSize: 20 }} className="fw-bold">
                      {product.price}
                    </small>

                    <br></br>
                    <small> For 10 Mins </small>
                  </div>
                  <div className="col mt-2">
                    <div className="d-grid ">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          BookingButton();
                        }}
                      >
                        {timeslots.length == 0
                          ? "Request call"
                          : "Book Appointment"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-none d-md-block">
              <div
                className="card py-1 px-3  "
                //style={{ marginBottom: 65, marginRight: 20, marginLeft: 20 }}
              >
                <div className="row ">
                  <div className="col">
                    <small style={{ fontSize: 20 }} className="fw-bold">
                      {product.price}
                    </small>
                    <br></br>
                    <small> For 10 Mins </small>
                  </div>
                  <div className="col mt-2">
                    <div className="d-grid ">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          BookingButton();
                        }}
                      >
                        {timeslots.length == 0
                          ? "Request call"
                          : "Book Appointment"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className=" flex-column  align-items-center">
          <form className="  rounded-top bg-primary card-body">
            <div className="card bg-primary">
              <div className="text-light ps-4 mt-4 ">Book appointment </div>
              <div class="row p-4">
                <div class="col-3">
                  {product?.iconURL ? (
                    <img
                      src={IMG_BASE_URL + product?.iconURL}
                      style={{ maxHeight: "80px", width: "80px" }}
                      class="rounded-circle "
                      alt="Cinque Terre"
                      onError={(event) => {
                        event.target.src = DefaultImage;
                      }}
                    ></img>
                  ) : (
                    <img
                      src={unavailableImage}
                      style={{ maxHeight: "80px", width: "80px" }}
                      class="rounded-circle"
                      alt="Cinque Terre"
                    ></img>
                  )}
                </div>
                <div class="col align-self-center ">
                  <h4 className=" text-light ">{product.title}</h4>
                </div>
              </div>
            </div>
            <div class="card rounded-top p-3">
              {slots?.length === 0 ? (
                <div class="d-flex justify-content-center m-5">
                  <div className=" text-center">
                    <img src={notAvailable} style={{ width: "18rem" }} />

                   
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex flex-row flex-nowrap overflow-auto pb-4">
                    {Loader ? (
                      <div
                        className="spinner-border text-primary mx-2"
                        style={{ width: 20, height: 20 }}
                        role="status"
                      ></div>
                    ) : (
                      slots.map((item, index) => (
                        <div class="col  me-3 ">
                          <button
                            type="button"
                            className={`btn btn-outline-primary bg-${
                              Buttonactiv === index && "primary text-light"
                            }`}
                            onClick={(e) => {
                             
                              setButtonActiv(index);
                              setTimeSlots(item?.slotList);
                              localStorage.setItem("bookingDate", item.date);
                            }}
                            style={{ width: 120, height: 55 }}
                          >
                            {moment(item.date).format("DD-MM-YYYY")}
                            <br></br>
                            {moment(item.date).format("dddd")}
                          </button>

                          

                          <br></br>
                          {showEdit ? (
                            <Link
                              to={"/addtimeslot"}
                              type="button"
                              onClick={() =>
                                navigate(
                                  localStorage.setItem(
                                    "dateItem",
                                    JSON.stringify(item)
                                  )
                                )
                              }
                              class="btn btn-outline-primary  ms-2"
                            >
                              Time Solot
                            </Link>
                          ) : null}
                        </div>
                      ))
                    )}
                  </div>

                  <h4 className="mt-4"> Available slots</h4>
                  <div class="row justify-content-between">
                    <div class="col-4">
                      <small> Jivdani hospital </small>
                    </div>
                    <div class="col-3">
                      <small> Fees: {product.price} </small>
                    </div>
                  </div>
                  {timeslots.length == 0 ? (
                    <>
                      

                      <div className="container">
                        <div className="row justify-content-md-center">
                          <div className="col-md-6">
                            <RequestCall />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      {timeslots.slice(-1).map((data, indx) => (
                        <>
                          {data.doctorDateId === DoctorDateId ? (
                            <>
                              {data.doctorDateId === DoctorDateId &&
                              data.startTime <= CurrentTime ? (
                                

                                <div className="row justify-content-md-center">
                                  <div className="col-md-6">
                                    <RequestCall />
                                  </div>
                                </div>
                              ) : (
                                <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                                  {timeslots?.map((data, index) => (
                                    <>
                                      {data.doctorDateId === DoctorDateId ? (
                                        <>
                                          {data.doctorDateId === DoctorDateId &&
                                            data.startTime >= CurrentTime && (
                                              <div class="col my-2">
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    shippingDetail(data)
                                                  }
                                                  className="btn btn-outline-primary"
                                                >
                                               
                                                  {moment(data.startTime, [
                                                    "HH.mm",
                                                  ]).format("hh:mm a")}
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <div class="col my-2">
                                          <button
                                            type="button"
                                            onClick={() => shippingDetail(data)}
                                            className="btn btn-outline-primary"
                                          >
                                          

                                            {moment(data.startTime, [
                                              "HH.mm",
                                            ]).format("hh:mm a")}
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (
                            <div class="row row-cols-4 row-cols-sm-5 row-cols-md-8">
                              {timeslots?.map((data, index) => (
                                <>
                                  {data.doctorDateId === DoctorDateId ? (
                                    <>
                                      {data.doctorDateId === DoctorDateId &&
                                        data.startTime >= CurrentTime && (
                                          <div class="col my-2">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                shippingDetail(data)
                                              }
                                              className="btn btn-outline-primary"
                                            >
                                            
                                              {moment(data.startTime, [
                                                "HH.mm",
                                              ]).format("hh:mm a")}
                                            </button>
                                          </div>
                                        )}
                                    </>
                                  ) : (
                                    <div class="col my-2">
                                      <button
                                        type="button"
                                        onClick={() => shippingDetail(data)}
                                        className="btn btn-outline-primary"
                                      >
                                      

                                        {moment(data.startTime, [
                                          "HH.mm",
                                        ]).format("hh:mm a")}
                                      </button>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          )}
                        </>
                      ))}

                     
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div> */}
      </div>
    </>
  );
}
export default SlotBooking;

export const isConsecutive = ({ dates, value }) => {
  for (let i = 1; i < (value ? dates.length : dates.length - 1); i++) {
    const current = moment(dates[i], "DD-MM-YYYY");
    const prev = moment(dates[i - 1], "DD-MM-YYYY");
    if (!current.isSame(prev.clone().add(1, "days"))) {
      return false;
    }
  }
  return true;
};
export const isConsecutiveDays = (sortedDays) => {
  for (let i = 1; i < sortedDays.length; i++) {
    const current = sortedDays[i];
    const prev = sortedDays[i - 1];
    const currentIdx = moment().day(current).day();
    const prevIdx = moment().day(prev).day();
    if (currentIdx - prevIdx !== 1 && currentIdx - prevIdx !== -6) {
      return false;
    }
  }
  return true;
};

import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import customStyles, {
  SinglecustomSelectStyles,
} from "../../ui/CSS/customStyles.js";
import PlusButton from "../../ui/PlusButton.js";
import {
  ConvertObj,
  convertSelectedDataToServerFormat,
  DataConverte,
  DataConvertecheck,
  isValidData,
} from "../../DataConverte/DataConverte.js";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi.js";
import IconFunctionality from "../../IconFunctionality.js";
import Categories from "../../visitConstant/Categories.js";

import CreatableSelect from "react-select/creatable";

const Menstrual = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  // console.log(visitPadData?.regularities, "visitPadData--->");

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const initialFormData = {
    ageAtMenarche: "",
    lmp: "",
    regularityOfMenstrualCycle: "",
    durationForMenstrualBleeding: "",
    periodOfMenstrualCycle: "",
    detailsOfMenstrualIssues: "",
    note: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [serverData, setServerData] = useState({});

  // console.log("formData: ", formData)
  const DropdownIndicator = () => null;
  const IndicatorSeparator = () => null;
  const CustomDropdownIndicator = () => null;

  // Handler for Select changes
  const handleSelectChange = (field, selectedOption) => {
    if (!selectedOption) {
      // console.log("print log")
    } else {
      // console.log("notprint log")

      // console.log(field, selectedOption ,"------------")
      const updatedFormData = { ...formData, [field]: selectedOption };
      let updatedServerData;
      if (field == "regularityOfMenstrualCycle") {
        // console.log({ ...formData, [field]: selectedOption })
        const NewData = {
          id: selectedOption.value,
          name: selectedOption.label,
        };
        updatedServerData = { ...serverData, [field]: NewData };
      } else {
        updatedServerData = { ...serverData, [field]: selectedOption?.value };
      }

      setFormData(updatedFormData);
      // setServerData(updatedServerData);
      setServerData(updatedServerData);

      dispatch(updateField("menstrualInfoData", updatedServerData));
    }
    // console.log(updatedServerData ,"------00");
  };

  // Handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    const updatedServerData = { ...serverData, [name]: value };

    setFormData(updatedFormData);
    setServerData(updatedServerData);

    dispatch(updateField("menstrualInfoData", updatedServerData));
  };

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state.ClearAllData?.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setFormData(initialFormData);
    setServerData();
    dispatch(updateField("menstrualInfoData"));
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  const [TempletDataReserve, setTempletDataReserve] = useState();
  useEffect(() => {
    if (isValidData(patientData?.menstrualInfoData)) {
      setTempletDataReserve(patientData?.menstrualInfoData);
    } else {
      handleClearData();
    }
  }, [patientData]);

  useEffect(() => {
    // Extract menstrualInfoData only if it exists
    const menstrualInfo = patientData?.menstrualInfoData;

    if (TempletDataReserve) {
      setIsOpen(true);
      const formattedData = {
        ageAtMenarche: ConvertObj({
          id: TempletDataReserve?.ageAtMenarche,
          name: TempletDataReserve?.ageAtMenarche,
        }),
        regularityOfMenstrualCycle: ConvertObj(
          TempletDataReserve?.regularityOfMenstrualCycle
        ),
        durationForMenstrualBleeding: ConvertObj({
          id: TempletDataReserve?.durationForMenstrualBleeding,
          name: TempletDataReserve?.durationForMenstrualBleeding,
        }),
        periodOfMenstrualCycle: ConvertObj({
          id: TempletDataReserve?.periodOfMenstrualCycle,
          name: TempletDataReserve?.periodOfMenstrualCycle,
        }),
        detailsOfMenstrualIssues: TempletDataReserve?.detailsOfMenstrualIssues,
        note: TempletDataReserve?.note,
        lmp: TempletDataReserve?.lmp,
      };

      const serverFormat = {
        ageAtMenarche: TempletDataReserve?.ageAtMenarche,
        regularityOfMenstrualCycle:
          TempletDataReserve?.regularityOfMenstrualCycle,
        durationForMenstrualBleeding:
          TempletDataReserve?.durationForMenstrualBleeding,
        periodOfMenstrualCycle: TempletDataReserve?.periodOfMenstrualCycle,
        detailsOfMenstrualIssues: TempletDataReserve?.detailsOfMenstrualIssues,
        note: TempletDataReserve?.note,
        lmp: TempletDataReserve?.lmp,
      };

      // Update state and dispatch actions
      setFormData(formattedData);
      setServerData(serverFormat);
      dispatch(updateField("menstrualInfoData", serverFormat));
    }
  }, [TempletDataReserve]);

  const TempletDataTransfer = (TempletData) => {
    // console.log("TempletData", TempletData);
    setTempletDataReserve(TempletData);
  };

  const [duration, setDuration] = useState();

  const durationOptions = duration
    ? [
        { value: `${duration} hours`, label: `${duration} hours` },
        { value: `${duration} days`, label: `${duration} days` },
        { value: `${duration} weeks`, label: `${duration} weeks` },
        { value: `${duration} months`, label: `${duration} months` },
        { value: `${duration} years`, label: `${duration} years` },
      ]
    : [];

  // const handleInput = ( field, value) => {
  //   setDuration(value);
  //   const uppercasedValue = value.toUpperCase();
  //   const newInputValue = [...inputValues];
  //   newInputValue[index][field] = uppercasedValue;
  //   setInputValues(newInputValue);
  //   if (index === inputValues?.length - 1) {
  //     addRowforInput();
  //   }
  //   return uppercasedValue;
  // };
  // console.log("formData", formData)

  return (
    <>
      <div className="d-flex">
        <IconFunctionality
          catID={Categories?.MENSTRUAL_INFO?.catID}
          fieldName={Categories?.MENSTRUAL_INFO?.name}
          name="Menstrual Info"
          iconSave
          iconRefresh
          iconErase
          onClick={handleClearData}
          setTempData={TempletDataTransfer}
        />

        <PlusButton setOpen={setIsOpen} open={isOpen} />
      </div>

      {/* <div className="col-12  col-xxl-9 mx-4"> */}

      <div
        className="col-12 col-xxl-8   mx-xxl-auto"
        style={{ paddingLeft: 40 }}
      >
        {isOpen && (
          <table
            className="table table-bordered table-xs"
            style={{ maxWidth: 750, height: 10, fontSize: 15 }}
          >
            <thead className="table-primary" style={{ fontSize: 13 }}>
              <tr>
                <th scope="col" style={{ width: 30 }}>
                  #
                </th>
                <th scope="col">Menstrual Info</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody
              style={{
                verticalAlign: "middle",
                fontSize: 13,
              }}
            >
              <tr>
                <th scope="row">1</th>
                <td>Age at Menarche</td>
                <td>
                  <Select
                    // isDisabled={isLoading}
                    // isLoading={isLoading}

                    isClearable
                    value={formData?.ageAtMenarche}
                    closeMenuOnSelect={true}
                    options={[
                      {
                        value: `${duration ? duration : ""} years`,
                        label: `${duration ? duration : ""} years`,
                      },
                    ]}
                    placeholder="Select..."
                    onChange={(selectedOption) =>
                      handleSelectChange("ageAtMenarche", selectedOption)
                    }
                    onFocus={() => {
                      setDuration(
                        formData?.ageAtMenarche?.label?.split(" ")[0]
                      );
                    }}
                    onInputChange={(item) => {
                      setDuration(item);
                    }}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    styles={SinglecustomSelectStyles}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>LMP</td>
                <td>
                  <input
                    className="form-control"
                    type="date"
                    name="lmp"
                    value={formData?.lmp}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Regularity of Menstrual Cycle</td>
                <td>
                  <CreatableSelect
                    isClearable
                    //  value={formData.regularityOfMenstrualCycle}

                    value={formData?.regularityOfMenstrualCycle}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    options={DataConvertecheck(visitPadData?.regularities)}
                    placeholder="Select..."
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        "regularityOfMenstrualCycle",
                        selectedOption
                      )
                    }
                    components={{ DropdownIndicator, IndicatorSeparator }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Duration for Menstrual Bleeding</td>
                <td>
                  <Select
                    isClearable
                    value={formData?.durationForMenstrualBleeding}
                    closeMenuOnSelect={true}
                    options={[
                      {
                        value: `${duration ? duration : ""} days`,
                        label: `${duration ? duration : ""} days`,
                      },
                    ]}
                    placeholder="Select..."
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        "durationForMenstrualBleeding",
                        selectedOption
                      )
                    }
                    onFocus={() => {
                      setDuration(
                        formData?.durationForMenstrualBleeding?.label?.split(
                          " "
                        )[0]
                      );
                      // console.log("Current value on focus:", formData?.durationForMenstrualBleeding?.label?.split(" ")[0] || "No value selected");
                    }}
                    onInputChange={(item) => {
                      setDuration(item);
                    }}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    styles={SinglecustomSelectStyles}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Period of Menstrual Cycle</td>
                <td>
                  <Select
                    isClearable
                    value={formData?.periodOfMenstrualCycle}
                    closeMenuOnSelect={true}
                    options={durationOptions}
                    placeholder="Select..."
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        "periodOfMenstrualCycle",
                        selectedOption
                      )
                    }
                    onFocus={() => {
                      setDuration(
                        formData?.periodOfMenstrualCycle?.label?.split(" ")[0]
                      );
                    }}
                    onInputChange={(item) => {
                      setDuration(item);
                    }}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    styles={SinglecustomSelectStyles}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Details of Menstrual Issues</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    name="detailsOfMenstrualIssues"
                    placeholder="Enter details..."
                    value={formData?.detailsOfMenstrualIssues}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td colSpan={2} className="text-center">
                  <textarea
                    className="form-control"
                    name="note"
                    rows="2"
                    placeholder="Add Notes..."
                    value={formData?.note}
                    onChange={handleInputChange}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Menstrual;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Headline from "./ Headline";

import { Link } from "react-router-dom";

const YouTubecard = ({ item, navigate, showEdit, nama}) => {
  //const [viewType ,setViewtype]=useState(item?.viewType)
  const viewType = item?.viewType;
  return (
    <div>
      {/* <div class="mb-3 fw-semibold">
        <table>
          <td>
            <h3>{item.title}</h3>
          </td>

          <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
            <div className="">
              {showEdit ? (
                <div>
                  <button
                    onClick={() =>
                      navigate(
                        "products",

                        localStorage.setItem("myData", JSON.stringify(item))
                      )
                    }
                    type="button"
                    class="btn btn-primary me-md-2"
                  >
                    View all
                  </button>
                  <button
                    className="btn btn-primary "
                    onClick={() =>
                      navigate(
                        "addcat",
                        localStorage.setItem("catData", JSON.stringify(item))
                      )
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    Edit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </table>
      </div> */}
      <Headline
        item={item}
        navigate={navigate}
        showEdit={showEdit}
        nama={nama}
      />
      <div className="d-flex flex-row flex-nowrap overflow-auto">
        {/* <div className="row row-cols-md-2  row-cols-lg-3  row-cols-xl-4 g-1  mb-2"> */}
        {item?.items?.map((it) => (
          <div>
            <Link
              to={"/productdetail/" + it?.itemId}
              className="text-decoration-none"
              onClick={() =>
                localStorage.setItem("myProduct", JSON.stringify(it))
              }
            >
              <div
                class="card rounded-4 border border-dark  me-3  "
                style={{
                  minWidth: 250,
                  minHeight: 200,
                  width: 330,
                  height: 415,
                }}
              >
                {/* {viewType === you_tube_view01 ? (
              <img height="220" src={it?.emailId} alt=" Images" />
            ) : (
              <iframe
                title="{it?.iconURL}"
                height="220"
                src={it?.iconURL}
                // src={it?.emailId}
                // src="https://drive.google.com/file/d/1pBUsRGvPxIpEjWjY3q12bUwPQZgQ5TfA/preview"

                allow="autoplay"
                allowFullScreen
                className="rounded-4 d-block"
                loading="lazy"
              ></iframe>
            )} */}

                {/* <div
                  className="card  text-white rounded-4"
                  style={{ maxWidth: 600 }}
                >
                  <img
                    src={it?.emailId}
                    className="rounded-4 d-block "
                    alt="Stony Beach"
                    style={{ width: "100%", height: 200 }}
                  />
                  <div
                    className="card-img-overlay rounded-4"
                   // style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-play"
                      style={{ width: 50, height: 50, alignSelf: "center" }}
                      className="rounded-4 position-absolute top-50 start-50 translate-middle"
                    />
                  </div>
                </div> */}

                {it.viewType === "video" ? (
                  <div
                    className="card bg-dark text-white"
                    style={{ maxWidth: 600, height: 220 }}
                  >
                    <img
                     src={it?.emailId}
                      className="rounded-4 d-block"
                      style={{ maxWidth: 600, height: 220 }}
                      alt="Stony Beach"
                    />
                    <div
                      className="card-img-overlay"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-circle-play"
                        style={{ width: 50, height: 50, alignSelf: "center" }}
                        className="position-absolute top-50 start-50 translate-middle"
                      />
                    </div>
                  </div>
                ) : (
                  <iframe
                    title="videos"
                    height="220"
                    // height="220"
                    src={it?.iconURL}
                    allowFullScreen
                    className="rounded-4 d-block"
                  ></iframe>
                )}

                <div class="card-body ">
                  <h5 class="card-title">{it?.title}</h5>
                  <p class="card-text">
                    {it.subTitle.length > 100
                      ? it.subTitle.substring(0, 100) + "......."
                      : it.subTitle}
                  </p>
                  {showEdit ? (
                    <Link
                      to={"/productdetail/" + it?.itemId}
                      onClick={() =>
                        localStorage.setItem("myProduct", JSON.stringify(it))
                      }
                    >
                      <button class="btn btn-outline-primary ">Video Edit</button>
                    </Link>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default YouTubecard;

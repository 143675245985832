import React, { useState, useRef, useEffect } from "react";
import CustomTooltip from "./CustomTooltip";
import Select from "react-select";
import DropdownComponent from "./ui/CustomDropdown";
import AddNewDataForAll from "./modal/AddNewDataForAll";
import { useDispatch, useSelector } from "react-redux";
import { saveTemplate } from "./ReduxApi/SaveTemplateApi";
import Categories from "./visitConstant/Categories";
import { RootFetchTemplateData } from "./ReduxApi/RootTemplateData";
import {
  convertCmedicinToServerFormat,
  ConvertObj,
  DataConverte,
  removeEmptyValues,
} from "./DataConverte/DataConverte";
import Medication from "./modal/AddMedication";
import { updateField } from "./ReduxApi/ReduxApi";
import ToastFist from "./Toast/ToastFist";
import { DATA_SAVED, ERROR_MSG } from "../Constant";

const IconFunctionalityForMedicine = ({
  catID,
  currentRows,
  fieldName,
  iconSave,
  iconRefresh,
  iconErase,
  onClick,
  setTempData,
}) => {
  const reduxApiData = useSelector((state) => state.reduxApiData);
  const [templateName, setTemplateName] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");


  const dispatch = useDispatch();
  const handleOnChange = (text) => {
    setTemplateName(text);
  };
  const handleOptionChange = async (selectedOption) => {
    // console.log(selectedOption, " kkdkd")
    if (selectedOption) {
      // console.log("dd", selectedOption);

      let res = await dispatch(RootFetchTemplateData(catID, selectedOption.value));
      // console.log(res, "rexs");
      if (res == 'done') {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(ERROR_MSG)
        return;
      }
      if (res?.currentMedicationData?.length == 0) {
        setColorStatus(0)
        setToastMsg("Data not available")
        setShowToast(true);
      }
      if (res?.currentMedicationData?.length > 0) {
        const newRows = res?.currentMedicationData?.map((med) => {
          const value = med?.medicineId;
          return {
            id: med?.currentMedicationDataTemplateId,
            medicineId: value,
            unit: { value: med?.unit?.id, label: med?.unit?.name },
            medicationScheduleList: med?.medicationScheduleList?.map((schedul) => ({
              dosages: {
                value: schedul?.dosages?.id,
                label: schedul?.dosages?.name
              },
              whens: {
                value: schedul?.whens?.id,
                label: schedul?.whens?.name
              },
              frequency: {
                value: schedul?.frequency?.id,
                label: schedul?.frequency?.name
              },
              duration: schedul?.duration,
              notes: schedul?.notes
            }))
          };
        });

        setTempData((currentRows) => {
          let updatedRows = currentRows.filter((row) => row.medicineId?.id);

          newRows.forEach((newRow) => {
            const matchIndex = updatedRows?.findIndex(
              (row) => row.medicineId?.id === newRow.medicineId?.id
            );

            if (matchIndex !== -1) {
              // If a matching row is found, replace it
              updatedRows[matchIndex] = newRow;
            } else {
              // If no matching row is found, append the new row
              updatedRows.push(newRow);
            }
          });

          // Add the empty row at the end after all insertions are done
          updatedRows.push({
            id: Date.now(), // Unique id for the empty row
            medicineId: "",
            unit: { value: "", label: "" },
            medicationScheduleList: [
              {
                dosages: { value: "", label: "" },
                whens: { value: "", label: "" },
                frequency: { value: "", label: "" },
                duration: "",
                notes: ""
              }
            ]
          });

          const transformedDataServer =
            convertCmedicinToServerFormat(updatedRows);

          dispatch(updateField("currentMedicationData", transformedDataServer));

          return updatedRows;
        });
      }
    }
  };

  const onSubmitName = async () => {
    if (!templateName) {
      setColorStatus(3)
      setToastMsg("Enter template name")
      setShowToast(true);
      return false;
    }
    let data = reduxApiData?.request?.busiParams?.[fieldName];
    if (catID === Categories.DIAGNOSIS.catID && Array.isArray(data)) {
      const modifiedData = data.map((item) => {
        const { id, ...rest } = item; // Destructure to remove `id`
        return rest; // Return the object without `id`
      });

      data = modifiedData;
    }
    if (catID === Categories.PAST_MEDICATION.catID) {
      // Assuming data is a comma-separated string like "1,3,4"
      const pastMedicationData = { pastMedicationData: data };
      data = pastMedicationData;
    }

    const sendableData = removeEmptyValues(data);

    // console.log('after', data);
    let res = await saveTemplate(
      catID,
      templateName,
      sendableData,
      fieldName,
      dispatch
    );

    if (res?.respData?.respMsg == "Template name already exists. Please use a different name.") {
      setColorStatus(3)
      setToastMsg("Template name already exists")
      setShowToast(true);
      return false;
    } else if (res?.respData?.respMsg == "done") {
      setColorStatus(0)
      setToastMsg("Template not saved")
      setShowToast(true);
      setTemplateName("");
      return true;
    }
    else if (res) {
      setColorStatus(1)
      setToastMsg(DATA_SAVED)
      setShowToast(true);
      setTemplateName("");
      return true;

    } else {
      setColorStatus(0)
      setToastMsg(ERROR_MSG)
      setShowToast(true);
      setTemplateName("");
      return true;
    }

  };

  const { loading, templateNames, error } = useSelector(
    (state) => state?.templateNames
  );

  // const DropdownData = templateNames?.find(item => item?.category === catID);
  const DropdownData = Array.isArray(templateNames)
    ? templateNames?.find((item) => item?.category === catID)
    : null;

  const Templetdropdown = DropdownData?.names?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  // console.log(saveTemplate?.id, saveTemplate?.templateName, "ss");

  return (
    <>
      <div className="d-flex justify-content-center  gap-4 ">
        <div className="m-0">
          <Medication customeKey={false} />
        </div>
        {iconErase && (
          <div className="d-flex " onClick={onClick}>
            <CustomTooltip
              icon="fa-solid fa-eraser"
              tooltipText="Erase"
              mainText="Clear All"
            />
            {/* <p className=""></p> */}
          </div>
        )}
        {iconRefresh && (
          <DropdownComponent
            icon="fa-solid fa-magnifying-glass"
            tooltipText="Load templates"
            options={Templetdropdown}
            handleOnChange={handleOptionChange}
            medicineText={"Search Template"}
            catID={catID}
          />
        )}
        {iconSave && (
          <div style={{ marginRight: 50 }}>
            <DropdownComponent
              icon="fa-solid fa-floppy-disk"
              tooltipText="Save"
              options={null}
              handleOnChange={handleOnChange}
              onSubmit={onSubmitName}
              templateName={templateName?.toUpperCase()}
              medicineText={"Save As Template"}
            />
          </div>
        )}

        {/* <CustomTooltip
          icon="fa-solid fa-square-plus"
          tooltipText="Add Medicine"
        /> */}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default IconFunctionalityForMedicine;

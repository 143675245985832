import React, { useCallback, useEffect, useState } from "react";
import {
  convertOnlyValue,
  convertSelectedDataToServerFormat,
  DataConverte,
  DataConvertecheck,
  isValidData,
  removeMatchingItems,
} from "../../DataConverte/DataConverte";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";
import Select from "react-select";
import customStyles, { customSelectStyles } from "../../ui/CSS/customStyles";
import Categories from "../../visitConstant/Categories";
import CreatableSelect from "react-select/creatable";
import useDebounce from "../../useDebounce/useDebounce";
import {
  ADDNEWDATAFORALL,
  DROPDOWNSARCHYNAME,
} from "../../visitConstant/ApiConstant";
import apiHelper from "../../ApiHelpar/apiHelper";
import { handleNewOptions } from "../../Utils/handleNewOptions";
import ToastFist from "../../Toast/ToastFist";

const TestsRequested = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  const [TestsRequesteds, setTestsRequesteds] = useState([]);
  const [byWhen, setByWhen] = useState("");
  const [byWhendropdown, setByWhendropdown] = useState([]);
  const [serverData, setServerData] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [searchTerm, setSearchTerm] = useState(
    visitPadData?.testsRequesteds || []
  );

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const AddbyWhendropdown = async (selectedOptions, actionMeta) => {
    try {
      const updatedOptions = await handleNewOptions(
        selectedOptions,
        Categories?.WHENS.catID,
        showToast,
        setShowToast,
        setToastMsg,
        setColorStatus
      );

      const dataCheck =
        updatedOptions.label == updatedOptions.value ? null : updatedOptions;
     // console.log(dataCheck);
      // const idsString = updatedOptions?.map((option) => option?.value).join(",");

      setByWhendropdown(dataCheck);
    } catch (error) {
      // console.log(error);
    }
  };

  const debouncedInput = useDebounce(serverData, 500);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();

  const handleTestsRequestedChange = async (selectedOptions) => {
    // Convert selected options to a comma-separated string of IDs
    // console.log("byWhen", byWhen);
    try {
      // console.log("Selected Options:", selectedOptions);
      const updatedOptions = await handleNewOptions(
        selectedOptions,
        Categories?.TESTS_REQUESTED.catID,
        showToast,
        setShowToast,
        setToastMsg,
        setColorStatus
      );
      // // console.log("Updated Options:---->>", updatedOptions);

      const idsString = updatedOptions
        ?.map((option) => option?.value)
        .join(",");
      // Update state
      setTestsRequesteds(removeMatchingItems(updatedOptions));

      // console.log( removeMatchingItems(updatedOptions ), "updatedOptions");

      setSelectedOptions(removeMatchingItems(updatedOptions));
      // Prepare the server data
      const combinedDate = `${byWhen} - ${
        byWhendropdown?.value === " - undefined" ? null : byWhendropdown?.label
      }`;
      const serverData = {
        testsRequesteds: idsString,
        whenBy: combinedDate === "undefined" ? null : combinedDate,
      };

      // console.log(combinedDate, "  TESTREQUESTED");
      // Dispatch and log
    //  console.log("testsRequestedData", serverData);
      dispatch(updateField("testsRequestedData", serverData));
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle updates when selectedOptions, byWhen, or byWhendropdown changes
  useEffect(() => {
    if (selectedOptions?.length > 0) {
      handleTestsRequestedChange(selectedOptions);
    }
    // console.log("data d", selectedOptions)
  }, [byWhen, byWhendropdown]);

  // // console.log(byWhendropdown, "kfkfk");

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setTestsRequesteds([]);
    setByWhen("");
    setByWhendropdown([]);
    setSelectedOptions([]);
    setTempletDataReserve([]);
    setServerData("");
    dispatch(updateField("testsRequestedData", ""));
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  const [TempletDataReserve, setTempletDataReserve] = useState([]);

  useEffect(() => {
    if (isValidData(patientData?.testsRequestedData)) {
      setTempletDataReserve(patientData?.testsRequestedData);
      // console.log("Valid diagnosisData:", patientData?.testsRequestedData);
    } else {
    handleClearData()
    }
  }, [patientData, dispatch]);

  useEffect(() => {
    if (TempletDataReserve) {
      const localData = DataConverte(TempletDataReserve?.testsRequesteds);

      // Handle 'whenBy' part logic
      if (TempletDataReserve?.whenBy) {
        let [part1, part2] = (TempletDataReserve?.whenBy)
          .split(" - ")
          .map((part) => part.trim());

        // Initialize part1 and part2 with empty strings if they are not defined
        part1 =
          part1.includes("null") || part1.includes("undefined") ? "" : part1;
        part2 =
          part2.includes("null") || part2.includes("undefined") ? "" : part2;

        // console.log(part1, "dd>>", part2);
        setByWhen(part1);
        setByWhendropdown(part2 ? { value: part2, label: part2 } : []);
      }

      // Handle case when localData is undefined
      if (!localData) {
        setTestsRequesteds([]);
        setSelectedOptions([]);
      } else {
        // Flatten localData if necessary and combine with TestsRequesteds
        const combinedData = [
          ...TestsRequesteds,
          ...(Array.isArray(localData) ? localData : [localData]),
        ];

        // Create unique data by 'value'
        const uniqueData = Array.from(
          new Set(combinedData.map((item) => item.value))
        ).map((value) => combinedData?.find((item) => item.value === value));

        // Update the state with the unique data
        setTestsRequesteds(uniqueData);
        setSelectedOptions(uniqueData);

        // Prepare server data
        const serverData = {
          testsRequesteds: convertOnlyValue(uniqueData),
          whenBy:
            TempletDataReserve?.whenBy === " - undefined" ||
            TempletDataReserve?.whenBy === "null - undefined"
              ? null
              : TempletDataReserve.whenBy,
        };

        // Dispatch the updated data
        dispatch(updateField("testsRequestedData", serverData));

        // Optional: log the server data
        // console.log("testsRequestedData", serverData);
      }
    }
  }, [TempletDataReserve]);

  const TempletDataTransfer = (TempletData) => {
    setTempletDataReserve(TempletData);
  };
  const DropdownIndicator = () => {
    return null; // Completely disables the dropdown indicator
  };

  const IndicatorSeparator = () => {
    return null; // Completely disables the separator
  };

  useEffect(() => {
    if (debouncedInput && debouncedInput.length >= 1) {
      fetchData(debouncedInput);
    }
  }, [debouncedInput]);

  // const AddItem = async (items) => {
  //   // alert("calling");
  //   if (items) {
  //     try {
  //       const endpoint = ADDNEWDATAFORALL;
  //       const data = {
  //         categoryId: Categories?.TESTS_REQUESTED.catID,
  //         name: items,
  //       };

  //       const response = await apiHelper(endpoint, data);
  //       const dataConvert = response?.respData?.respMsg;
  //       // // console.log("dataConvert",dataConvert)
  //       return { value: dataConvert.id, label: dataConvert.name };
  //     } catch (error) {
  //      // console.log("Error fetching data:", error);
  //     } finally {
  //      // console.log("ok");
  //     }
  //   }
  // };

  const fetchData = async (debouncedInput) => {
    if (debouncedInput) {
      setIsLoading(true); // Show loader
      try {
        const endpoint = DROPDOWNSARCHYNAME;
        const data = {
          categoryId: Categories?.TESTS_REQUESTED.catID,
          name: debouncedInput,
        };
        const response = await apiHelper(endpoint, data);
        const dataConvert = DataConvertecheck(response?.respData?.respMsg);

        if (response?.respData?.respMsg?.length) {
          setSearchTerm(dataConvert);
        }
      } catch (error) {
        // console.log("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  };

  return (
    <>
      <IconFunctionality
        catID={Categories?.TESTS_REQUESTED?.catID}
        fieldName={Categories?.TESTS_REQUESTED?.name}
        name="Tests Requested"
        iconSave
        iconRefresh
        iconErase
        onClick={() => handleClearData()}
        setTempData={TempletDataTransfer}
        iconPlus
        InputField={1}
      />

      <div className="col-12 col-xxl-9 px-4  ">
        <div className="row">
          <div className="col-8">
            <label></label>
            {/* <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={DataConverte(visitPadData?.testsRequesteds)}
                    value={TestsRequesteds}
                    placeholder="Select options..."
                    onChange={(selectedOptions) =>
                      handleSelectChange(
                        selectedOptions,
                        "testsRequesteds",
                        "testsRequestedData"
                      )
                    }
                  /> */}
            {/* <Select
              closeMenuOnSelect={false}
              isMulti
              options={DataConverte(visitPadData?.testsRequesteds)}
              value={TestsRequesteds}
              placeholder="Select options..."
              onChange={(selectedOptions) =>
                handleTestsRequestedChange(selectedOptions)
              }
            /> */}

            <CreatableSelect
              closeMenuOnSelect={false}
              key="select-complaints"
              isMulti
              options={
                // searchTerm.length > 0
                //   ? DataConvertecheck(searchTerm)
                //   : visitPadData?.testsRequesteds
                searchTerm?.length > 0
                  ? DataConvertecheck(searchTerm)
                  : DataConvertecheck(visitPadData?.testsRequesteds)
              }
              placeholder="Tests..."
              onChange={handleTestsRequestedChange}
              value={TestsRequesteds}
              onInputChange={(item) => setServerData(item.toUpperCase())}
              inputValue={serverData}
              // menuIsOpen={true} // Keep the menu open to show loader
              isLoading={isLoading} // Show loading state
              loadingMessage={() => "Loading options..."} // Custom loader message
              components={{ DropdownIndicator, IndicatorSeparator }}
              styles={customSelectStyles}
            />
          </div>
          <div className="col-4">
            <label>By When</label>
            <div class="input-group mb-3">
              <input
                type={byWhendropdown === "Calendar" ? "date" : "text"}
                class="form-control"
                placeholder=""
                value={byWhen}
                name="whenBy"
                onChange={(e) => setByWhen(e.target.value)}
              />
              <span class="input-group-text ps-1 p-0" id="basic-addon2">
                <CreatableSelect
                  options={DataConverte(visitPadData?.periods)}
                  styles={customStyles}
                  placeholder="Select options..."
                  value={
                    byWhendropdown === " - undefined" ? null : byWhendropdown
                  }
                  //  onChange={(ByWhen) => setByWhendropdown(ByWhen)}

                  onChange={AddbyWhendropdown}
                  closeMenuOnSelect={true}
                  isLoading={isLoading} // Show loading state
                  loadingMessage={() => "Loading options..."} // Custom loader message
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};
export default TestsRequested;


// Action creator to set visit count
export const setLastHistory = (object) => ({
    type: "SET_LAST_HISTORY",
    payload: object || null,
});

export const setVisitCount = (count) => ({
    type: "SET_VISIT_COUNT",
    payload: count,
});

// Initial State
const initialState = {
    visitCount: 0,
};

// Reducer
const visitReducerCount = (state = initialState, action) => {
    switch (action.type) {
        case "SET_VISIT_COUNT":
            return {
                ...state,
                visitCount: action.payload, // Update visitCount
            };
        case "SET_LAST_HISTORY":
            return {
                ...state,
                lastHistory: action.payload,
            };
        default:
            return state;
    }
};

export default visitReducerCount;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SESSION_ID,
  CUSTOMER_ID,
  SetOrders,
  BASE_URL,
  ACCESS_TOKEN,
  RAZORPAYKEYID,
  startPhonePePayment,
  ROLE,
  ROLEDOCTOR,
  USER_ROLE,
  IMG_BASE_URL,
} from "../Constant";
import { useState, useEffect } from "react";

import { useLoaderData, useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import CheckoutStepper from "../components/CheckoutStepper";
import moment from "moment";
import axios from "axios";
import Placeholder from "../../src/default_img/placeholder.png";
import {
  GOOGLE_DRIVE_URL,
  DRIVE_LOGO_URL,
  USER_INFO,
  DEBUG_LOG_ENABLED,
  STARTRAZORPAYPAYMENT,
} from "../Constant";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import DefaultImage from "../Images/doctor.png"

function OrderReview() {
  const [sdkReady, setSdkReady] = useState(false);
  const dispatch = useDispatch();
  const [addressId, setAddressId] = useState();
  const customerId = localStorage.getItem(CUSTOMER_ID);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerAddress, setCustomerAddress] = useState([]);
  const userRole = localStorage.getItem(USER_ROLE);
  const AddToCart = localStorage.getItem("AddToCart");

  //  const AddToCartAmount=useLocation();
  //  const amount = AddToCartAmount.state.AddToCartAmount

  const [paypalAmount, setpaypalAmount] = useState(0);
  const [catItemList, setItemData] = useState([]);
  const [currencyCode, setCurrency] = useState("INR");
  const [timeSlotDetail, setTimeSlotDetail] = useState("");

  const [PayHospital, setPayHospital] = useState(false);
  // localStorage.setItem("AddtoCarbooking", AddtoCarbooking);

  const AddtoCarbooking = localStorage.getItem("AddtoCarbooking");

  const addressData = localStorage.getItem("addressData");
  const addressList = JSON.parse(addressData);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const [orderID, setOrderID] = useState("");

  const current_date = new Date();
  const userData = localStorage.getItem(USER_INFO);
  const profiledata = JSON.parse(userData);

  const cash_payment = 0;
  const online_payment = 1;

     const PaymentSuccessorderStatus = 1;
     const CashPaymentSuccessorderStatus = 0;

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async () => {
    // console.log("paypalAmount", paypalAmount);
    // console.log(localStorage.getItem(CUSTOMER_ID));
    // console.log(currencyCode);
    // alert(localStorage.getItem(RAZORPAYKEYID));
    // if (DEBUG_LOG_ENABLED) {
    //   console.log("here...");
    // }
    const res = await initializeRazorpay();
    // const order = await createOrder(params);

    const razorpayOrder = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          customerId: localStorage.getItem(CUSTOMER_ID),
          amount: (paypalAmount * 100).toString(),
          currency: currencyCode,
          //currency: "INR", //currencyCode,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    let response = await axios.post(
      BASE_URL + STARTRAZORPAYPAYMENT,
      razorpayOrder,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      }
    );

    var mOrderId = response?.data?.respData?.respMsg?.orderId;
    // if (DEBUG_LOG_ENABLED) {
    //   console.log(mOrderId);
    // }

    if (!res) {
      swal("Razorpay SDK Failed to load");
      return;
    }
    //   const data = await fetch("/api/razorpay", { method: "POST" }).then((t) =>
    //   t.json()
    // );
    // console.log(data);
    //console.log("RAZORPAYKEYID", localStorage.getItem(RAZORPAYKEYID));
    var options = {
      key: localStorage.getItem(RAZORPAYKEYID), // Enter the Key ID generated from the Dashboard
      name: "Jivdani hospital",
      currency: currencyCode,
      amount: (paypalAmount * 100).toString(),

      order_id: mOrderId,
      description: catItemList?.title,
      image: GOOGLE_DRIVE_URL + DRIVE_LOGO_URL,
     // callback_url: "https://jivdanihospital.com/jivdani-v1/razorPayCallback",
      handler: function (response) {
        // Validate payment at server - using webhooks is a better idea.
        // swal(response.razorpay_payment_id);
        console.log("response", response);
        if (response.razorpay_payment_id !== "") {

          
          // console.log("razorpay_payment_id", response.razorpay_payment_id);
          //  console.log("razorpay_order_id", response.razorpay_order_id);
          //   console.log("razorpay_signature", response.razorpay_signature);


          paymentObject.close();
          setOrder(
            response.razorpay_payment_id,
            online_payment,
            PaymentSuccessorderStatus,
            "RAZORPAY"
          );
        } else {
          //alert("pament faild");
          navigate("/OrderCancel");
        }

        // swal(response.razorpay_order_id);
        // swal(response.razorpay_signature);
      },
      prefill: {
        name: addressList?.name,
        email: profiledata?.emailId,
        contact: addressList?.mobileNo,
      },
      notes: {
        address: "navi mumbai",
      },
      theme: {
        color: "#5406a1",
      },
    };

    const paymentObject = new window.Razorpay(options);
   paymentObject.open();
 // RazorpayCheckout.open(options)
  // paymentObject.open()
  //   .then((data) => {
  //     // handle success
  //   alert(`Success: ${data.razorpay_payment_id}`);
  //     if (data.razorpay_payment_id !== "") {
  //       // Alert.alert('  handle success ');
  //       // console.log('razorpay_payment_id', data.razorpay_payment_id);
  //       // console.log('razorpay_order_id', data.razorpay_order_id);
  //       // console.log('razorpay_signature', data.razorpay_signature);
       
  //       setOrder(data.razorpay_payment_id, online_payment);
  //     }
  //   })
  //   .catch((error) => {
  //     // handle failure
     
  //   // navigate("/");
  //     alert("payment failure");
  //     // Alert.alert(`Error: ${error.code} | ${error.description}`);
  //     // console.error(`Error: ${error.code} | ${error.description}`);
  //   });
  
  };

  const cashPayment = async () => {
    if (DEBUG_LOG_ENABLED) {
      console.log("here...");
    }
    const oderId = Math.floor(100000 + Math.random() * 900000);
    setOrder(oderId, cash_payment,CashPaymentSuccessorderStatus, 'CASH');
  };

 const location = useLocation();
 const [paymentDetails, setPaymentDetails] = useState({
   orderId: "",
   paymentId: "",
   signature: "",
 });

;

  useEffect(() => {
    const multipleAmount = localStorage.getItem("multipleprice");
    const multipleczrtCode = localStorage.getItem("multiplecurrency");
    const cartData = localStorage.getItem("multiplecartListData");

    // const currencyCode = localStorage.getItem("currency");
    const myData = localStorage.getItem("cartListData");


 
      console.log("addressData",localStorage.getItem("addressData"));

    if (multipleAmount === "") {
      const catItem = JSON.parse(myData);
      // const cashlAmount = catItem.price;
      const cashlAmount = localStorage.getItem("myProductDiscountPrice");
      setpaypalAmount(catItem.price);
      setItemData(catItem);
      //  setCurrency(currencyCode);
      const timeslotItem = localStorage.getItem("timeslot");
      setTimeSlotDetail(JSON.parse(timeslotItem));
    } else {
      const multiplecatItem = JSON.parse(cartData);
      setpaypalAmount(multipleAmount);
      setItemData(multiplecatItem);
      // setCurrency(multipleczrtCode);
    }
    // if (success) {
    //   // swal("Payment successful!!" + orderID);
    //   setOrder(orderID);
    // } else {
    //   if (DEBUG_LOG_ENABLED) {
    //     console.log(1, orderID);
    //   }
    //   if (orderID !== "") {
    //     swal("Payment Fail!!" + ErrorMessage);
    //   }
    //   if (DEBUG_LOG_ENABLED) {
    //     console.log(2, success);
    //     console.log(3, ErrorMessage);
    //   }
    // }
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      setAddressId(addressList?.id);
    }
  }, [success]);

  const setOrder = async (
    orderID,
    paymentType,
    PaymentSuccessorderStatus,
    paymentGateway,
    dataUrl
  ) => {
    try {
      const setOrderItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: orderID,
            amount: paypalAmount,
            customerId: localStorage.getItem(CUSTOMER_ID),
            paymentId: orderID,
            addressId: addressId,
            date: moment().format("YYYY-MM-dd HH:mm:ss.SSS"),
            paymentType: paymentType,
            currency: currencyCode,
            customerPhone: addressList.mobileNo,
            doctorId: catItemList?.doctorId,
            slotId: timeSlotDetail?.slotId,
            catType: catItemList?.catType,
            doctorName: catItemList?.title,
            address: localStorage.getItem("addressData"),
            bookingDate: localStorage.getItem("bookingDate"),
            startTime: localStorage.getItem("bookingTime"),
            orderStatus: PaymentSuccessorderStatus,
            paymentGateway: paymentGateway, /// PHONEPE;
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + SetOrders, setOrderItem, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      localStorage.setItem("orderID", orderID);


       if (dataUrl) {
        navigate("/Phonpe", { state: { dataUrl } });

       } else {
        


          if (response.data.respData.message === "Slot booking is not placed") {
            alert(response.data.respData.message);
          } else {
            navigate("/ordersuccess");
          }
       }


     
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };
  let totalCartPrice = 0;

  const Phonpe = async () => {
    try {
      const PhonpeData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            amount: paypalAmount * 100,
            currency: "INR",
            customerId: localStorage.getItem(CUSTOMER_ID),
            deviceOS: "IOS",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + startPhonePePayment,
        // "https://jivdanihospital.com/jivdani-v1/startPhonePePayment",
        PhonpeData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // const oderId = Math.floor(100000 + Math.random() * 900000);

    


      setOrder(
        response.data.respData.respMsg.merchantTransactionId,
        online_payment,
        PaymentSuccessorderStatus,
        "PHONPE",
        response.data.respData.respMsg.instrumentResponse.redirectInfo.url
      );

      

    } catch (err) {
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const solotTime = localStorage.getItem("bookingTime");

  const MultipalCartItemList = () => {
    return (
      <div>
        <ScrollToTopOnMount />
        {timeSlotDetail !== "" ? (
          <div>
            <div
              class="row align-items-center "
              style={{ justifyContent: "center" }}
            >
              <div class="col-md-5 p-3">
                <Link
                  to={"/doctordetail/" + catItemList?.itemId}
                  onClick={() =>
                    navigate(
                      localStorage.setItem(
                        "myProduct",
                        JSON.stringify(catItemList)
                      )
                    )
                  }
                >
                  <img
                    style={{ width: 150, height: 150, alignSelf: "center" }}
                    src={
                      catItemList?.iconURL
                        ? IMG_BASE_URL + catItemList?.iconURL
                        : DefaultImage
                    }
                    alt="Images"
                    onError={(event) => {
                      event.target.src = DefaultImage;
                    }}
                  />
                </Link>
              </div>
              <div className="col-md-7 card-title text-left text-dark text-truncate">
                <div>
                  <h6 className="h5">{catItemList?.title}</h6>
                  <h6 className="h5">
                    Appointment Date : {localStorage.getItem("bookingDate")}
                  </h6>
                  <h6 className="h5">
                    Appointment Time : {localStorage.getItem("bookingTime")}
                  </h6>
                  <h5>
                    {/* {currencyCode === "USD"
                                            ? "$" + catItemList.priceInDollar
                                            : " ₹" + catItemList.price} */}
                    {" ₹" + paypalAmount}
                  </h5>
                </div>
              </div>
            </div>

            <div class="clearfix bg-secondary ">
              <div class="float-start">
                <p class="mb-0 me-5 d-flex align-items-center">
                  <span class="h4 text-white p-2"> Subtotal: </span>
                </p>
              </div>
              <div class="float-end">
                <p class="mb-0 me-5 d-flex align-items-center p-2">
                  <span class="h4 text-white ">
                    {currencyCode === "USD" ? " $" : " ₹"}
                  </span>
                  <span class="h4 text-white ">{paypalAmount}</span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {catItemList?.map((item) => (
              <div
                class="row align-items-center "
                style={{ justifyContent: "center" }}
              >
                <div class=" col-md-4 ">
                  <Link
                    to={"/productdetail/" + item?.itemId}
                    onClick={() =>
                      localStorage.setItem("myProduct", JSON.stringify(item))
                    }
                  >
                    <img
                      style={{ width: 200, alignSelf: "center" }}
                      // onError={({ currentTarget }) => {
                      //   currentTarget.onerror = null; // prevents looping
                      //   currentTarget.src = {
                      //     Placeholder,
                      //   };
                      // }}
                      onError={(event) => {
                        event.target.src = DefaultImage;
                      }}
                      // height="120"
                      src={
                        item?.iconURL
                          ? IMG_BASE_URL + item?.iconURL
                          : "https://drive.google.com/uc?id=1k66Wm_oUbyiaNXd25RDWtf_NdSSjB783"
                      }
                      alt="iconURL"
                    />
                  </Link>
                </div>
                <div className="  col-md-7 card-title text-left text-dark text-truncate">
                  <div>
                    <h6 className="h5 text-truncate">{item?.title}</h6>
                    <h6 className="h5">
                      Appointment Date : {localStorage.getItem("bookingDate")}
                    </h6>
                    <h6 className="h5">
                      Appointment Time : {localStorage.getItem("bookingTime")}
                    </h6>
                    <h5>
                      {/* {currencyCode === "USD"
                                            ? "$" + catItemList.priceInDollar
                                            : " ₹" + catItemList.price} */}
                      {" ₹" + item?.price}
                    </h5>
                  </div>
                </div>
              </div>
            ))}{" "}
            <div class="clearfix bg-secondary">
              <div class="float-start">
                <p class="mb-0 me-5 d-flex align-items-center">
                  <span class="h4 text-white p-2"> Subtotal : </span>
                </p>
              </div>
              <div class="float-end">
                <p class="mb-0 me-5 d-flex align-items-center p-2">
                  <span class="h4 text-white ">
                    {currencyCode === "USD" ? " $" : " ₹"}
                  </span>
                  <span class="h4 text-white ">{paypalAmount}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": localStorage.getItem("clientId"),
      }}
    >
      <div
        className="  align-items-center container "
        style={{ marginTop: 110 }}
      >
        <div className="row">
          <div className="col-md-12">
            <CheckoutStepper step={2} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div className="d-flex flex-column ">
              <ScrollToTopOnMount />
              <div className="card p-4  bg-white">
                <p>
                  <span class="h4"> Address </span>
                </p>
                <hr />
                <h5>{addressList?.name}</h5>
                <label class="form-check-label" for="flexRadioDefault1">
                  {addressList?.addressLine1 + " " + addressList?.addressLine2}
                </label>
                <p class="form-check-label">
                  {addressList?.city +
                    " " +
                    addressList?.state +
                    " " +
                    addressList?.country +
                    " " +
                    addressList?.pincode}
                </p>
                <p class="form-check-label">
                  {"Mobile no : " + addressList?.mobileNo}
                </p>
              </div>

              <div class="card mb-5 ">
                <div class="card-body p-4">
                  <p>
                    <span class="h4"> Booking details </span>
                  </p>

                  <MultipalCartItemList />
                  <div class="card p-4">
                    <div class="row g-2">
                      <h2>Safety measures followed at the hospital</h2>
                      <div class="col-6">
                        <div
                          class="row g-2 border bg-light "
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div class="col ps-3">
                            <img
                              src={IMG_BASE_URL + "oderDetails/masks.png"}
                              style={{ width: 50 }}
                            />
                          </div>
                          <div class="col" style={{ alignSelf: "start" }}>
                            <div class="py-2 ">Mask Mandatory</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          class="row g-2 border bg-light "
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div class="col ps-3">
                            <img
                              src={
                                IMG_BASE_URL +
                                "oderDetails/social-distancing.png"
                              }
                              style={{ width: 50 }}
                            />
                          </div>
                          <div class="col" style={{ alignSelf: "start" }}>
                            <div class="py-2 ">Social Distancing</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          class="row g-2 border bg-light "
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div class="col ps-3">
                            <img
                              className="text-Primary"
                              src={IMG_BASE_URL + "oderDetails/temp.png"}
                              style={{ width: 50 }}
                            />
                          </div>
                          <div class="col" style={{ alignSelf: "start" }}>
                            <div class="py-2 ">Temprature Check</div>
                          </div>
                        </div>
                        {/* <div class="p-3 border bg-light">Temprature Check</div> */}
                      </div>
                      <div class="col-6">
                        <div
                          class="row g-2 border bg-light "
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div class="col ps-3">
                            <img
                              src={IMG_BASE_URL + "oderDetails/hand-wash.png"}
                              style={{ width: 50 }}
                            />
                          </div>
                          <div class="col" style={{ alignSelf: "start" }}>
                            <div class="py-2 ">Regular Sanitization</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border border-5  m-3 p-1">
                    <div className="form-check">
                      <input
                        className="form-check-input p-2 "
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckDefault"
                        onClick={() => setPayHospital(!PayHospital)}
                      />
                      <label
                        className="form-check-label fw-bold ps-2 "
                        htmlFor="flexCheckDefault"
                      >
                        Pay at Hospital<br></br>
                        <p>
                          Make payment by sannig the hospital pay QR code or pay
                          in cash
                        </p>
                      </label>
                    </div>
                  </div>

                  <div class=" content-center pt-3">
                    {
                      <div>
                        {userRole?.includes(ROLEDOCTOR) ? (
                          <div>
                            {userRole?.includes(ROLE) ? (
                              <div>
                                {PayHospital ? (
                                  <button
                                    class="btn btn-primary btn-lg w-100 mt-5"
                                    onClick={cashPayment}
                                  >
                                    payment at Hospital
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      class="btn btn-primary btn-lg w-100 mt-3"
                                      // onClick={Phonpe}
                                      onClick={makePayment}
                                    >
                                      Pay now
                                    </button>
                                    <br></br>
                                    <button
                                      class="btn btn-outline-primary btn-lg w-100 mt-3"
                                      onClick={Phonpe}
                                      // onClick={makePayment}
                                    >
                                      Pay now Phonpe
                                    </button>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div>
                                {" "}
                                {
                                  "The doctor is not allowed to make bookings for appointments due to a recent policy change in the clinic."
                                }
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {PayHospital ? (
                              <button
                                class="btn btn-primary btn-lg w-100 mt-5"
                                onClick={cashPayment}
                              >
                                payment at Hospital
                              </button>
                            ) : (
                              <button
                                class="btn btn-primary btn-lg w-100 mt-3"
                                // onClick={Phonpe}
                                onClick={makePayment}
                              >
                                Pay now
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    }

                    {/* <button
                      style={{ layout: "vertical" }}
                      class="btn btn-primary btn-lg w-100 mt-3"
                      onClick={makePayment}
                    >
                      Razorpay Now
                    </button> */}

                    {/* <button
                     // style={{ layout: "vertical" }}
                      class="btn btn-primary btn-lg w-100 mt-3"
                      onClick={Phonpe}
                    //  onClick={() => {
                    //   window.open("http://trustseal.enamad.ir/Verify.aspx?id=15288&p=nbpdyncrwkynaqgwaqgw", "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
                    // }}
                    >
                       Pay now
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4 "> */}
        </div>
      </div>
      {/* </div> */}
    </PayPalScriptProvider>
  );
}

export default OrderReview;

import  { useState } from "react";
// import { useNavigate } from "react-router-dom";


import password from "./password.css";

import {
  SESSION_ID,
  BASE_URL,
  DEBUG_LOG_ENABLED,
  ACCESS_TOKEN,
  AddPassword,
} from "../Constant";
import axios from "axios";
import swal from "sweetalert";
export default function ForgotPassword(props) {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [forgotpassword, setForgotPassword] = useState({
    Password: "",
  });

  const handleChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    setForgotPassword({ ...forgotpassword, [e.target.name]: value });
  };

  const saveForgotPassword = (e) => {
    e.preventDefault();

    const forgotpasswordmData = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          Password: forgotpasswordmData.Password,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

  
    axios
      .post(BASE_URL + AddPassword, forgotpasswordmData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      })

      .then((response) => {
        swal("successful : " + response.data);
        // if (DEBUG_LOG_ENABLED) {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        swal("error : " + error);
        // if (DEBUG_LOG_ENABLED) {
        //   console.log(error);
        // }
      });
  };

  return (
    <div className="align-items-center container  py-5 px-xl-5 mx-auto mt-5 ">
      <div className="wrapper">
        <div className="col-lg-9">
          <div className="d-flex flex-column  align-items-center">
            <form className="Auth-form ">
              <div className="Auth-form-content  ">
                <h3 className="Auth-form-title">ForgotPassword ?</h3>
                <div className="text-center"></div>
                <div className="form-group text-center mt-3">
                  <p>You can reset your password here.</p>
                </div>
                <div className="form-group mt-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="emailId"
                    //  value={customer.emailId}
                    className="form-control mt-1"
                    placeholder="Email Address"
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  {/* <button 
            onClick = {saveForgotPassword} 
            type="Reset Password" className="btn btn-primary " >
              Reset Password
            </button> */}
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Reset Password
                  </button>
                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header bg-primary  ">
                          <h4
                            class="modal-title text-white  "
                            id="staticBackdropLabel"
                          >
                            Entet OTP
                          </h4>
                          <button
                            type="button"
                            class="btn-close  "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="d-flex justify-content-center h5">
                          Please enter tne code we just srnt to
                        </div>
                        <div class="d-flex justify-content-center ">
                          88888888888 to procced
                        </div>
                        <div class="d-flex justify-content-center mx-5 pt-5">
                          {/* <input
                            type="text"
                            name="Otp"
                            //  value={customer.emailId}
                            className="form-control text-center mx-5"
                            
                            style={{outline: "none" }}
                            maxLength="4"
                            placeholder=" 0      0      0        0 "
                          /> */}
                          <div className="divOuter">
                            <div className="divInner">
                              <input
                                className="partitioned"
                                type="text"
                                maxlength="4"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center mt-2  pb-5">
                          <a class="text-decoration-none">Resend OTP</a>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" class="btn bg-primary">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

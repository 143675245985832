import React, { useState, useEffect } from "react";
import "./CustomDropdown.css";
import "../ui/CSS/focusinput.css";
import { filterNewOptions } from "../DataConverte/DataConverte";

const CustomDropdownSingleValue = ({
  inputRef = null,
  options,
  value,
  onChange,
  onBlur,
  onAddNewItem,
  onSearch
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setHighlightedIndex(0); // Reset highlighted index on open
    }
  }, [isOpen]);

  useEffect(() => {
    // Set initial value if available
    if (value) {
      const selectedOption = options?.find((option) => option.value === value);
      if (selectedOption) {
        setSearchTerm(selectedOption.label);
      }
    }
  }, [value, options]);

  const handleOptionClick = (option) => {
    setSearchTerm(option.label);
    setIsOpen(false);
    onChange(option); // Trigger onChange with selected option
  };

  const handleInputFocus = (e) => {
    setIsOpen(true);
    // console.log(e ,"dropdwopn single valu" ,value)
  };

  const handleInputBlur = (e) => {
    if (onBlur) onBlur(e.target.value)
    // setTimeout(() =>
    setIsOpen(false)
    //  150);
  }

  const handleInputChange = (e) => {
    const inputValu = e.target.value;
    setSearchTerm(e.target.value);
    if (onSearch) onSearch(inputValu);
    setIsOpen(true);
  };

  const filteredOptions = options?.filter((option) =>
    option.label?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === filteredOptions?.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === 0 ? filteredOptions?.length - 1 : prevIndex - 1
      );
    } else if (e.key === "Enter") {
      const selected = filteredOptions[highlightedIndex];
      if (selected) {
        handleOptionClick(selected);
      } else {
        handleAddNewItem();
      }
      //  handleOptionClick(filteredOptions[highlightedIndex]);
    }
  };

  // Handle adding a new item
  const handleAddNewItem = () => {
    //  alert(searchTerm)
    if (searchTerm.trim()) {
      onAddNewItem(searchTerm.trim());
      onChange({ value: searchTerm, label: searchTerm });

      setIsOpen(false); // Close dropdown after adding
    }
  };

  return (
    <div
      className="dropdown"
      onKeyDown={handleKeyDown}
      tabIndex="0"
      style={{ position: "relative" }}
    >
      <div className="dropdown-header">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className="prompt form-control border-0"
          style={{ fontSize: 13, borderRadius: 0 }}
        />
      </div>
      {isOpen && (
        <div className="dropdown-list w-auto mr-4">
          {filteredOptions?.length > 0 ? (
            filteredOptions?.map((option, index) => (
              <div
                key={option?.value}
                className={`dropdown-item ${index === highlightedIndex ? "highlighted" : ""
                  }`}
                onMouseDown={() => handleOptionClick(option)}
              >
                {option?.label}
              </div>
            ))
          ) : // <div className="dropdown-item">No options found</div>
            searchTerm ? (
              <div
                className="dropdown-item"
                onMouseDown={handleAddNewItem}
                onKeyDown={handleKeyDown}
              >
                Create "{searchTerm}"
              </div>
            ) : (
              <div className="dropdown-item">Search...</div>
            )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdownSingleValue;

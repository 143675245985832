// import { Link } from "react-router-dom";
// import parse from "html-react-parser";
// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import FeatureProduct from "./FeatureProduct";
// import { DEBUG_LOG_ENABLE } from "../Constant";
import Banner from "./Banner";
import Headline from "./ Headline";
import { OPD_COUNT } from "../Constant";

const BannerCard = ({ item, navigate, showEdit }) => {
  // console.log(
  //   item?.viewMoreTitle,
  //   " -- ",
  //   item?.viewMoreTitle ? item?.viewMoreTitle : "99"
  // );
  localStorage?.setItem(
    OPD_COUNT,
    item?.viewMoreTitle ? item?.viewMoreTitle : 4
  );

  return (
    <div>
      {/* {showEdit ? (
        <div class="mb-3 fw-semibold">
          <table>
            <td>
              <h3>{item.title}</h3>
            </td>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5">
              <div className="">
                {showEdit ? (
                  <div>
                    <button
                      onClick={() =>
                        navigate(
                          "products ",
                          localStorage.setItem("myData", JSON.stringify(item))
                        )
                      }
                      type="button"
                      class="btn btn-primary me-md-2"
                    >
                      View all
                    </button>
                    <button
                      className="btn btn-primary "
                      onClick={() =>
                        navigate(
                          "addcat",
                          localStorage.setItem("catData", JSON.stringify(item))
                        )
                      }
                      type="submit"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                      Edit
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </table>
        </div>
      ) : null} */}
      {showEdit ? (
        <Headline item={item} navigate={navigate} showEdit={showEdit} />
      ) : null}

      <div className="row justify-contet-center ">
        <Banner product={item} showEdit={showEdit} navigate={navigate} />
      </div>
    </div>
  );
};
export default BannerCard;

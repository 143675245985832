import React from 'react'


const reactnatviedata = (state=false, action) =>{
    switch (action.type) {
      case "DATASHOW":
        return action.payload;
      default:
        return state;
    }
}
export default reactnatviedata;
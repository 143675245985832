import React from "react";
import { IMG_BASE_URL, article_view_01 } from "../../Constant";
import { Link } from "react-router-dom";
import { Alert } from "bootstrap";

function ProductListdetail({ onArticalClick }) {
  // localStorage.getItem("myProduct");

  const myData = localStorage.getItem("itemslist");
  const itemList = JSON.parse(myData);

  // var artical = (product) => {
  //   alert(product);
  //   localStorage.setItem("myProduct", JSON.stringify(product));
  //   return product;
  // };

  return (
    <>
      {itemList && (
        <>
          {itemList?.items.map((product) => (
            // <div>{product?.title}</div>
            // <Link
            //   to={"/productdetail/" + product?.itemId}
            //   onClick={() => onArticalClick("raja rahul ")}
            // >
            <div
              className="card mb-3"
              //  style={{ maxWidth: 420 }}
              onClick={
                () => onArticalClick(product)
                // alert(product.title)
              }
            >
              <div className="row g-0">
                <div className="col-4 " >
                  <img
                    src={IMG_BASE_URL+product.iconURL}
                    className="img-fluid rounded-start"
                   // style={{ maxHeight:200}}
                    alt="..."
                  />
                </div>
                <div className="col-8">
                  <div className="card-body">
                    <h5 className="card-title">{product.title}</h5>
                    <p className="card-text">
                      {product.titleID.length > 100
                        ? product.titleID.substring(0, 100) + "......."
                        : product.titleID}
                    </p>
                    {/* <p className="card-text">
                      <small className="text-muted">
                        Last updated 3 mins ago
                      </small>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            // </Link>
          ))}
        </>
      )}
    </>
  );
}

export default ProductListdetail;

import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import LoginScrollpages from "./LoginScrollpages";
import { useEffect } from "react";
import {
  ACCESS_TOKEN,
  USER_ROLE,
  SESSION_ID,
  USER_INFO,
  CUSTOMER_ID,
  BASE_URL,
  GET_CART_COUNT,
  DEBUG_LOG_ENABLED,
  DOCTOR_ID,
  generateSmsOtp,
  PASSWORD,
  ROLE,
  generateSmsLocalOtp,
  CART_COUNT,
  USER_ROLE_CHANGES_BUTTON,
  ERROR_MSG,
} from "../Constant";
// import { UserContext } from "../App";
import axios from "axios";
import { increment, handleLoggin } from "../actions";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
export default function Login() {
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);

  const dispatch = useDispatch();
  //const {state, dispatch} = useContext(UserContext);
  //const history = useHistory();
  // test

  // let [authMode, setAuthMode] = useState("signin");
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin");
  // };
  const navigate = useNavigate();
  // const [data, setData] = useState({});
  const [customer, setCustomer] = useState({
    username: "",
    password: "",
    idToken: "",
    mobileNo: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const loginCustomer = (e) => {
    e.preventDefault();
    if (
      // customer.username === "" ,
      //  customer.password === ""
      customer.mobileNo === ""
    ) {
      swal("Please entery mobail no.");
      // swal(" Please entery mobail no. ");
    } else {
      //  loginData(customer.username, customer.password, customer.idToken);
      generateOtp(customer.mobileNo);
    }
  };

  const sessionId = localStorage.getItem(SESSION_ID);

  const loginData = async (username, password, idToken) => {
    localStorage.setItem(PASSWORD, password);

    try {
      const loginData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            username: username,
            password: password,
            idToken: idToken,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // if (DEBUG_LOG_ENABLED) {
      //   console.log("LoginService : " + loginData);
      // }

      let response = await axios.post(BASE_URL + "login", loginData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          // Authorization: "Bearer " + access_token,
        },
      });

      // setData(response.data.respData.respMsg);

      localStorage.setItem(
        USER_INFO,
        JSON.stringify(response.data.respData.respMsg)
      );

      localStorage.setItem(
        ACCESS_TOKEN,
        response.data.respData.respMsg.accessToken
      );

      localStorage.setItem(
        CUSTOMER_ID,
        response.data.respData.respMsg.customerId
      );
      localStorage.setItem(DOCTOR_ID, response.data.respData.respMsg.doctorId);
      localStorage.setItem(USER_ROLE, response.data.respData.respMsg.role);
      localStorage.setItem(USER_ROLE_CHANGES_BUTTON, response.data.respData.respMsg.role);
      getCartCountData(
        response.data.respData.respMsg.customerId,
        response.data.respData.respMsg.accessToken
      );

      dispatch(handleLoggin(true));
      // if (DEBUG_LOG_ENABLED) {
      //   console.log(response.data);
      // }
      navigate("/");
      // history.push("/")
    } catch (err) {
      swal("Something went wrong!");
      // alert(err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };
  const getCartCountData = async (cstId, access_token) => {
    try {
      const getCartCountData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            customerId: cstId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      //const response = await LoginService.getCartCount(getCartCountData,access_token);
      // dispatch(increment(response.data.respData.respMsg.cartCount));
      localStorage.setItem(ACCESS_TOKEN, access_token);
      let res = await axios.post(BASE_URL + GET_CART_COUNT, getCartCountData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      });

      // const data = res.data;
      dispatch(increment(res.data.respData.respMsg.cartCount));
      localStorage.setItem(CART_COUNT, res.data.respData.respMsg.cartCount);
    } catch (err) {
      swal(err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const generateOtp = async (mobileNo) => {
    try {
      const DataOtp = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            username: mobileNo,
            // password: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const res = await axios.post(
        BASE_URL + generateSmsLocalOtp,
        //  "   https://jivdanihospital.com/jivdani-v1/generateSmsLocalOtp",
        DataOtp,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            //  Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // OtpSuccess();
      res.data.respData.code === 0
        ? swal("OTP has been not sent ")
        : OtpSuccess();
    } catch (error) {
      if (error.response.status === 404) {
        OtpSuccess();
      } else {
        // alert(error);
        swal(ERROR_MSG);
      }
    }
  };
  const OtpSuccess = () => {
    let mobile = customer.mobileNo;
    navigate("/Otp", { state: { mobile: mobile } });

    // swal(res.data.respData.message);
  };

  return (

    <section className=" py-5 px-2 mt-5" >
      <ScrollToTopOnMount />
      <div className="container  " style={{ marginTop: 45 }}>
        <div className="card rounded-3 text-black">
          <div className="row  ">
            <LoginScrollpages />
            <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1">
              <div className="card-body ">
                <div className="text-center">
                  <h4 className="mt-1 mb-3pb-1 fw-bold "> OTP Sign In </h4>
                </div>
                <form className="">
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="form2Example11">
                      Mobile number
                    </label>
                    <input
                      name="mobileNo"
                      onChange={(e) => handleChange(e)}
                      value={customer.mobileNo}
                      className="form-control border border-primary my-3"
                      placeholder=" Mobile No"
                      required
                      aria-required="true"
                      type="tel"
                      size="10"
                      minlength="10"
                      maxlength="10"
                    />
                  </div>

                  <div className="d-grid gap-2 mt-3">
                    {/* <Link to="otp" type="submit" className="btn btn-primary">
                        OTP Login
                      </Link> */}
                    <button
                      // onClick={() => navigate("/signup")}
                      onClick={loginCustomer}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Login
                    </button>
                  </div>
                </form>
                {/* Username & Password */}
                {/* <form>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="form2Example11">
                      Username
                    </label>
                    <input
                      type="username"
                      name="username"
                      value={customer.username}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                      placeholder="Phone number or email address"
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="form2Example22">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={customer.password}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                      placeholder="Enter password"
                    />
                  </div>

                  <div className="d-grid gap-2 mt-3">
                    <button
                      onClick={loginCustomer}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Login
                    </button>
                  </div>
                  <div className="text-center pt-2  ">
                    <Link
                      className="text-center "
                      // onClick={() => navigate("/forgotpassword")}
                      to="/forgotpassword"
                    >
                      Forgot password?
                    </Link>
                  </div>

                  <div className="d-flex align-items-center justify-content-center pt-4">
                    <p className="mb-0 me-2">Don't have an account?</p>
                    <button
                      type="button"
                      onClick={() => navigate("/signup")}
                      className="btn btn-outline-primary"
                    >
                      Create new
                    </button>
                  </div>
                </form>  */}
                <>
                  <div className="py-4">
                    <div style={{ float: "left", width: "44%" }}>
                      <hr />
                    </div>
                    <div style={{ float: "right", width: "44%" }}>
                      <hr />
                    </div>
                    OR
                  </div>
                </>

                <div className=" d-flex justify-content-center  pt-4">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      loginData("", "", credentialResponse.credential);
                      // if (DEBUG_LOG_ENABLED) {
                      //   console.log(credentialResponse.credential);
                      // }
                      var decoded = jwt_decode(credentialResponse.credential);
                      // if (DEBUG_LOG_ENABLED) {
                      //   console.log(decoded);
                      // }
                    }}
                    onError={() => {
                      // if (DEBUG_LOG_ENABLED) {
                      //   console.log("Login Failed");
                      // }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
const UnauthorisedPage = () => {
  return (
    <div className="col-md-12 text-center">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default UnauthorisedPage;

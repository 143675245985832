


import Headline from "../landing/ Headline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerReviewCart from "./CustomerReviewCart";


const CustomerReview = ({ item, navigate, showEdit }) => {
  return (
    <>
      <div>
        {/* {showEdit ? (
          <div class="mb-3 fw-semibold">
            <table>
              <td>
                <h3>{item.title}</h3>
              </td>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5">
                <div className="">
                  {showEdit ? (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            "products",
                            localStorage.setItem("myData", JSON.stringify(item))
                          )
                        }
                        type="button"
                        class="btn btn-primary me-md-2"
                      >
                        View all
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          navigate(
                            "addcat",
                            localStorage.setItem(
                              "catData",
                              JSON.stringify(item)
                            )
                          )
                        }
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        Edit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </table>
          </div>
        ) : null} */}
        {showEdit?  <Headline item={item} navigate={navigate} showEdit={showEdit} />:null}
       

        <div className="row justify-contet-center ">
          <CustomerReviewCart product={item} showEdit={showEdit} />
        </div>
      </div>
    </>
  );
};

export default CustomerReview;




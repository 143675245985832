// import { useEffect, useState } from "react";
// import {
//   BASE_URL,
//   CARD_VIEW,
//   FULL_BODY_CHEAKUP,
//   TOP_BANNER,
//   CARD_VIEW_WITH_TEXT,
//   USER_ROLE,
//   ROLE
// } from "../../Constant";
// import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// const Ourservices = () => {
//   const [Categorydata, setCategoryData] = useState([]);
//   const [showEdit, setShowEdit] = useState(false);
// const navigate =useNavigate()
//   const myData = localStorage.getItem("cat_ascending_data");
//   const catItemList = JSON.parse(myData);
//   const [catListData, setCatListData] = useState([]);

//   useEffect(() => {
//   const userRole = localStorage.getItem(USER_ROLE);
//   if (userRole?.includes(ROLE)) {
//     setShowEdit(true);
//   }

//     const filteredSearchList = catItemList.filter((item) => {
//       return (
//         item.viewType === CARD_VIEW ||
//         item?.viewType === "FullBodyCheakup" ||
//         item.viewType === CARD_VIEW_WITH_TEXT ||
//         item?.viewType === "you_tube_view" ||
//         item?.viewType === "Digital X-ray" ||
//         item?.viewType === "Specialised"
//       );
//     });
//     setCatListData(filteredSearchList);

//   }, []);

//   const data = (item, nama) => {
//     //  navigate("/itemsviewmore");

//     localStorage.setItem("myData", JSON.stringify(item));
//     localStorage.setItem("myDataview", nama);
//   };
//   return (
//     <div className="container mt-4">
//       <h1 className="mb-5 text-center fw-semibold text-primary">
//         Our Services
//       </h1>

//       <div className="row">
//         {catListData.map((item, index) => (
//           <div key={index} className="col-4  col-sm-4 col-lg-2">
//             <div className="service-card text-center ">
//               <Link
//                 to={
//                   item?.viewType === "Digital X-ray"
//                     ? "/Xray"
//                     : "/itemsviewmore"
//                 }
//                 className="btn  btn-light   text-primary text-truncate"
//                 onClick={() => data(item, item.viewType)}
//               >
//                 <img
//                   style={{ width: "6vh", height: "6vh" }}
//                   src={item.iconURL}
//                   alt={item.subTitle}
//                 />

//                 <p className=" text-break text-center fw-bold mt-3">
//                   {item.subTitle}
//                 </p>
//               </Link>
//               <br></br>
//               {showEdit ? (
//                 <Link
//                   to={"/addcat"}
//                   className="btn btn-primary "
//                   onClick={() =>
//                     navigate(
//                       localStorage.setItem("catData", JSON.stringify(item))
//                     )
//                   }
//                   type="submit"
//                 >
//                   <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
//                   Edit
//                 </Link>
//               ) : null}
//             </div>

//             {/* )} */}
//           </div>
//         ))}
//       </div>
//     </div>

//   );
// };

// export default Ourservices;

import { useEffect, useState } from "react";
import {
  BASE_URL,
  CARD_VIEW,
  CARD_VIEW_WITH_TEXT,
  USER_ROLE,
  ROLE,
  IMG_BASE_URL,
} from "../../Constant";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OurServices.css"; // Create a CSS file for your styles
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import { useSelector } from "react-redux";

const Ourservices = () => {
  const editMode = useSelector((state) => state.editMode.editMode);
  const navigate = useNavigate();
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  useEffect(() => {
    const filteredSearchList = catItemList?.filter((item) => {
      return (
        item.viewType === CARD_VIEW ||
        item?.viewType === "FullBodyCheakup" ||
        item.viewType === CARD_VIEW_WITH_TEXT ||
        // item?.viewType === "you_tube_view" ||
        item?.viewType === "Digital X-ray" ||
        item?.viewType === "Specialised" ||
        item?.viewType === "PanchakarmaUnit" ||
        item?.viewType === "neurologist"
      );
    });
    setCatListData(filteredSearchList);
  }, []);

  const data = (item, nama) => {
    //  navigate("/itemsviewmore");

    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };

  {
    /* <div class="container">
    <div class="row row-cols-auto">
      <div class="col">Column</div>
      <div class="col">Column</div>
      <div class="col">Column</div>
      <div class="col">Column</div>
    </div>
  </div> */
  }

  //const primaryColor = "#664ea8"; // Your desired color code

  const imageStyle = {
    // width: "6vh",
    // height: "6vh",
    filter: `brightness(0) saturate(100%) invert(22%) sepia(42%) saturate(2414%) hue-rotate(260deg) brightness(97%) contrast(102%)`,
  };

  return (
    // <div className="container mt-4">

    //   <h1 className="mb-5 text-center fw-semibold text-primary">
    //     Our Services
    //   </h1>

    //   <div className="row row-cols-auto">
    //     {catListData.map((item, index) => (
    //       <div key={index} className="col">
    //         {/* Updated the column sizes for responsiveness */}
    //         <div className="service-card text-center">
    //           <Link
    //             to={
    //               item?.viewType === "Digital X-ray"
    //                 ? "/Xray"
    //                 : item?.viewType === "Specialised"
    //                 ? "/Dialysis"
    //                 : item?.viewType === "neurologist"
    //                 ? "/neurologist"
    //                 : "/itemsviewmore"
    //             }
    //             className="btn btn-light text-primary text-truncate"
    //             onClick={() => data(item, item.viewType)}
    //           >
    //             <img
    //               style={{ width: "6vh", height: "6vh" }}
    //               src={item.iconURL}
    //               alt={item.subTitle}
    //             />
    //             <p className="text-break text-center fw-bold mt-3">

    //               {/* {item.subTitle} */}
    //               {/* {item.subTitle.length > 10
    //                 ? item.subTitle.substring(0, 10) + ".."
    //                 : item.subTitle} */}
    //             </p>
    //           </Link>
    //           <br></br>
    //           {showEdit ? (
    //             <Link
    //               to={"/addcat"}
    //               className="btn btn-primary "
    //               onClick={() =>
    //                 navigate(
    //                   localStorage.setItem("catData", JSON.stringify(item))
    //                 )
    //               }
    //               type="submit"
    //             >
    //               <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
    //               Edit
    //             </Link>
    //           ) : null}
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    <div className="container mt-4">
      <div class="d-flex bd-highlight mt-4 ">
        <div class="container overflow-hidden">
          <div class="row ">
            <div class="col">
              <h1 className="mb-5 fs-3 fw-semibold ">Our Services</h1>
            </div>
          </div>
        </div>

        <div>
          <Link
            className="btn  btn-light d-block d-sm-none text-primary text-truncate"
            style={{
              fontWeight: 700,
            }}
            onClick={() => data()}
          >
            See all
            <FontAwesomeIcon
              icon="fa-solid fa-greater-than  "
              className="ps-2"
              fade
            />
          </Link>
        </div>
      </div>
      <div class="row  ">
        {Array.isArray(catListData) &&
          catListData?.map((item, index) => (
            <div class="col-4 col-md-3 col-lg-2  text-center">
              <Link
                to={
                  item?.viewType === "Digital X-ray"
                    ? "/Xray"
                    : item?.viewType === "Specialised"
                    ? "/Dialysis"
                    : item?.viewType === "neurologist"
                    ? "/neurologist"
                    : "/itemsviewmore"
                }
                className="btn btn-light text-primary text-truncate "
                onClick={() => data(item, item.viewType)}
              >
                <img
                  style={{
                    filter: `brightness(0) saturate(100%) invert(22%) sepia(42%) saturate(2414%) hue-rotate(260deg) brightness(97%) contrast(102%)`,
                    maxWidth: "90px" /* Set your desired width */,
                    height: "50px" /* Set your desired height */,
                    objectFit:
                      "contain" /* Maintains aspect ratio and crops to fill */,
                    objectPosition: "center" /* Centers the image */,
                  }}
                  src={IMG_BASE_URL + item.iconURL}
                  alt={item.subTitle}
                />
              </Link>
              <br></br>
              <small className=" fw-bold"> {item.subTitle}</small>
              <br></br>
              {editMode ? (
                <Link
                  to={"/addcat"}
                  className="btn btn-outline-primary "
                  onClick={() =>
                    navigate(
                      localStorage.setItem("catData", JSON.stringify(item))
                    )
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                  Edit
                </Link>
              ) : null}
            </div>
          ))}
      </div>

      {/* <div class="d-flex bd-highlight mt-4 ">
        <div class="container overflow-hidden">
          <div class="row ">
            <div class="col">
              <h1 className="mb-5 fs-3 fw-semibold text-primary">
                Our Services
              </h1>
            </div>
          </div>
        </div>

        <div>
          <Link
            className="btn  btn-light d-block d-sm-none text-primary text-truncate"
            style={{
              fontWeight: 700,
            }}
            onClick={() => data()}
          >
            See all
            <FontAwesomeIcon
              icon="fa-solid fa-greater-than  "
              className="ps-2"
              fade
            />
          </Link>
        </div>
      </div>

      <div className="row row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5">
        {catListData.map((item, index) => (
          <div key={index} className="">
            <Link
              to={
                item?.viewType === "Digital X-ray"
                  ? "/Xray"
                  : item?.viewType === "Specialised"
                  ? "/Dialysis"
                  : item?.viewType === "neurologist"
                  ? "/neurologist"
                  : "/itemsviewmore"
              }
              className="btn btn-light text-primary text-truncate"
              onClick={() => data(item, item.viewType)}
            >
              <img
                style={{
                  filter: `brightness(0) saturate(100%) invert(22%) sepia(42%) saturate(2414%) hue-rotate(260deg) brightness(97%) contrast(102%)`,
                }}
                src={IMG_BASE_URL + item.iconURL}
                alt={item.subTitle}
              />

              <p className="text-center fw-bold mt-3 d-none d-sm-block">
                {item.subTitle}
              </p>
              <p className="text-center fw-bold mt-3 d-block d-sm-none">
                {item.subTitle.length > 5
                  ? item.subTitle.substring(0, 5) + ".."
                  : item.subTitle}
             
              </p>
            </Link>
            <br></br>
            <p className="text-center fw-bold mt-3   text-primary">
              {item.subTitle}
            </p>
            <br></br>
            {showEdit ? (
              <Link
                to={"/addcat"}
                className="btn btn-primary "
                onClick={() =>
                  navigate(
                    localStorage.setItem("catData", JSON.stringify(item))
                  )
                }
                type="submit"
              >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                Edit
              </Link>
            ) : null}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Ourservices;

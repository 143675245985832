import moment from "moment";

// Utility function to transform API response to react-select format
export const DataConverte = (apiResponse) => {
  return apiResponse?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
};

export const ConvertObj = (obj) => {
  return {
    value: obj?.id,
    label: obj?.name,
  };
};

export const ConvertMenstrual = (obj) => {
  return {
    value: obj?.id,
    label: obj?.name,
  };
};

export const ReversObj = (obj) => {
  return {
    id: obj?.value,
    name: obj?.label,
  };
};

export const InvestServerConverter = (data) => {
  return data
    .filter((item) => item?.id)
    .map((item) => ({
      testsOrInvestigations: {
        id: item?.id,
        name: item?.testsOrInvestigations,
        type: {
          id: item?.type?.id,
          name: item?.type?.name,
        },
        units: {
          id: item?.unit?.id,
          name: item?.unit?.name ? item?.unit?.name : "",
        },
      },
      investigationsDates:
        item?.investigationsDates?.length > 0
          ? item?.investigationsDates.map((dates) => ({
              investigationsDate: dates.investigationsDate || "",
              value: dates.value || "",
            }))
          : [],
    }));
};

// export const convertSelectedDataToServerFormat = (name, data) => {
//   if (!Array.isArray(data)) {
//     console.error('Expected an array but got:', data);
//     return { [name]: '' };
//   }
//   return {
//     [name]: data?.map((item) => item?.value).join(',') || '',
//   };
// };

export const convertSelectedDataToServerFormat = (name, data) => {
  // Helper function to filter only numeric values
  const filterNumericValues = (inputString) => {
    const items = inputString.split(",");
    const numericValues = items.filter(
      (item) => !isNaN(item.trim()) && item.trim() !== ""
    );
    return numericValues.join(",");
  };

  // Ensure data is an array
  if (!Array.isArray(data)) {
    // console.error("Expected an array but got:", data);
    return { [name]: "" };
  }

  // Map the data array to a comma-separated string of values
  const valueString = data?.map((item) => item?.value).join(",") || "";

  // Apply the filterNumericValues condition
  const filteredValueString = filterNumericValues(valueString);

  // Return the final formatted object
  return {
    [name]: filteredValueString || "",
  };
};

export const labServerFormate = (name, data) => {
  if (!Array.isArray(data)) {
    //  console.error("Expected an array but got:", data);
    return { [name]: [] };
  }
  return data.map((item) => ({
    [name]: {
      id: item?.value || null,
      name: item?.label || null,
    },
    labTestRecord: { other: null, upi: 0 },
  }));
};

export const calculateTimeDifference = (startTime, endTime = null, date) => {
  const start = moment(startTime, "HH:mm");
  const currentDate = moment(); // Get current date and time
  const providedDate = moment(date, "YYYY-MM-DD"); // Parse provided date

  if (endTime === null) {
    if (providedDate.isSame(currentDate, "day")) {
      // If the date is today, calculate time difference from now
      const now = moment();
      const duration = moment.duration(now.diff(start));
      const hours = duration.hours();
      const minutes = duration.minutes();
      if (hours === 0 && minutes === 0) {
        return "Just now";
      }
      return `${hours ? hours + "h " : ""}${
        minutes ? minutes + "m" : "0m"
      }`.trim();
    } else {
      // If the date is not today, return startTime formatted as HH:mm
      return start.format("HH:mm");
    }
  } else {
    // If endTime is provided, calculate difference between startTime and endTime
    const end = moment(endTime, "HH:mm");
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    if (hours === 0 && minutes === 0) {
      return "Just now";
    }
    return `${hours ? hours + "h " : ""}${
      minutes ? minutes + "m" : "0m"
    }`.trim();
  }
};

export const getGenFormate = (gender) => {
  const genders = gender
    ? gender == "M"
      ? "Male"
      : gender == "F"
      ? "Female"
      : "Other"
    : "";
  return genders;
};
export const removeMatchingItems = (updatedOptions) => {
  const updatedItems = updatedOptions?.filter(
    (item) => item?.label !== item?.value
  );

  return updatedItems;
};

export const filterNumericValues = (inputString) => {
  const numericValues = inputString
    .split(",")
    .filter((item) => !isNaN(item.trim()) && item.trim() !== "");
  return numericValues.length < inputString.split(",").length
    ? numericValues.join(",")
    : inputString;
};

export const removeEmptyValues = (obj) => {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    // Handle arrays separately
    return obj
      .map((item) => removeEmptyValues(item)) // Recursively clean array elements
      .filter(
        (item) =>
          item != null && // Exclude null and undefined values
          item !== "" && // Exclude empty strings
          !(typeof item === "object" && Object.keys(item).length === 0) // Exclude empty objects
      );
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(
        ([_, v]) =>
          v != null && // Exclude null and undefined values
          v !== "" && // Exclude empty strings
          !(
            typeof v === "object" &&
            Object.keys(removeEmptyValues(v)).length === 0
          ) // Exclude empty objects
      )
      .map(
        ([k, v]) => [k, typeof v === "object" ? removeEmptyValues(v) : v] // Recursively clean nested objects
      )
  );
};
export const convertCmedicinToServerFormat = (newRows) => {
  return newRows
    .filter((row) => row?.medicineId?.id)
    .map((row) => {
      const medicineId = row.medicineId || null;

      const updatedMedicationScheduleList = row?.medicationScheduleList?.map(
        (schedule) => {
          return {
            dosages: {
              id: schedule.dosages.value,
              name: schedule.dosages.label,
            },
            whens: {
              id: schedule.whens.value,
              name: schedule.whens.label,
            },
            frequency: {
              id: schedule.frequency.value,
              name: schedule.frequency.label,
            },
            duration: schedule.duration,
            notes: schedule.notes,
          };
        }
      );

      return {
        medicineId: { ...medicineId },
        unit: { id: row.unit?.value, name: row?.unit?.label } || null,
        medicationScheduleList: updatedMedicationScheduleList,
        newEntry: row.newEntry,
      };
    });
};

export const filterNewOptions = (rows, options) => {
  const existingRowIds = rows?.map((row) => row.id);
  return options?.filter((item) => !existingRowIds?.includes(item.id)) || [];
};
export const filterNewOptionsD = (rows, options) => {
  const existingRowIds = rows?.map((row) => row?.diagnosis?.value);
  return options?.filter((item) => !existingRowIds?.includes(item.id)) || [];
};

export const convertOnlyValue = (data) => {
  return data?.map((item) => item?.value).join(",") || "";
};

// export const ArrayuniqueData = ( data) => {

//   if (!Array.isArray(data)) {
//     return   Array.from(
//       new Set(data.map((item) => item.id))
//     ).map((value) => data.find((item) => item.id === value));
//   }

// };

export const ArrayuniqueData = (data) => {
  // Create a Map to store unique items based on the 'value' field
  const uniqueMap = new Map();

  data.forEach((item) => {
    if (item?.value !== undefined) {
      uniqueMap.set(item.value, item);
    }
  });

  // Convert the Map back to an array with unique items
  return Array.from(uniqueMap.values());
};

export const DataConvertecheck = (apiResponse) => {
  const ApiResponse = apiResponse || [];
  return ApiResponse?.map((item) => {
    // Check if the item already has 'value' and 'label' fields
    if (item?.value && item?.label) {
      return item;
    }
    // Otherwise, convert 'id' and 'name' to 'value' and 'label'
    return {
      value: item?.id,
      label: item?.name,
    };
  });
};

export const removeDuplicateIds = (data) => {
  const uniqueData = data.filter(
    (item, index, self) =>
      item?.testsOrInvestigations?.id === undefined ||
      index ===
        self?.findIndex(
          (t) =>
            t?.testsOrInvestigations?.id === item?.testsOrInvestigations?.id
        )
  );
  return uniqueData;
};

export const isValidData = (data) => {
  return data !== undefined && data !== null && data !== "";
};

//   // Example usage
//   const apiResponse = [
//     { id: 1, name: "WEAKNESS" },
//     { id: 2, name: "FEVER" },
//     { id: 3, name: "COUGH" },
//     { id: 4, name: "HEADACHE" },
//     { id: 5, name: "COLD" },
//     { id: 6, name: "THROAT IRRITATION" },
//     { id: 7, name: "BODYACHE" },
//     { id: 8, name: "CONSTIPATION" },
//   ];

// Output:
// [
//   { value: 1, label: 'WEAKNESS' },
//   { value: 2, label: 'FEVER' },
//   { value: 3, label: 'COUGH' },
//   { value: 4, label: 'HEADACHE' },
//   { value: 5, label: 'COLD' },
//   { value: 6, label: 'THROAT IRRITATION' },
//   { value: 7, label: 'BODYACHE' },
//   { value: 8, label: 'CONSTIPATION' }
// ]

import unavailable from "../Images/unavailable.png";

function ReviewCartItem({ product, id }) {
  return (
    <>
      <div className="d-flex">
        <div className="flex-shink-0">
          <img
            className="rounded"
            src={product?.iconURL ? product?.iconURL : unavailable}
            // onError={({ currentTarget }) => {
            //   currentTarget.onerror = null; // prevents looping
            //   currentTarget.src = { Placeholder };
            // }}
            width={80}
            height={80}
            alt="Product image."
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="flex-grow-1 ms-3 h-100">
          <div className="vstack">


            
            <a className="text-dark text-decoration-none">{product?.title}</a>

            <h6 className="mb-0">{"₹ " + product?.price}</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewCartItem;

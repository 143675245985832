import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  CALLLINK_LINK_ADD_TO_CART,
} from "../Constant";
import swal from "sweetalert";

import { useSelector, useDispatch } from "react-redux";
import { increment } from "../actions";
import axios from "axios";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

function TextCardItemView({ product, showEdit, discountPrice }) {
  const dispatch = useDispatch();
  // const {state, dispatch} = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const isLogged = useSelector((state) => state.isLogged);
  const [Discount, setDiscount] = useState(0);
  const DiscountPrice = product.price - (product.price * Discount) / 100;

  useEffect(() => {
    if (    product.discountPrice) {
      setDiscount(discountPrice);
    } else if (discountPrice) {
      setDiscount(discountPrice);
    } else {
      setDiscount(0);
    }
  }, []);

  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);
    // if (DEBUG_LOG_ENABLED) {
    //   console.log("CartService : id:" + id + "  token:" + token);
    // }
    if (isLogged && id != null && id != "" && token != "") {
      addToCart();
    } else {
      swal("Please log in and try again ");
      navigate("/login");
    }
  };
  const addToCart = async () => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: product.itemId,
            price: DiscountPrice,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // if (DEBUG_LOG_ENABLED) {
      //   console.log("CartService : " + itemData);
      // }

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      dispatch(increment(response.data.respData.respMsg.cartCount));
      //dispatch(increment(response.data.respData.respMsg.cartCount));
      swal(response.data.respData.message);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const bookingclick = () => {
    if (product?.callingURL === CALLLINK_LINK_ADD_TO_CART) {
      addToMycart();
    } else {
      localStorage.setItem("myProduct", JSON.stringify(product));
      localStorage.setItem(
        "myProductDiscountPrice",
        JSON.stringify(DiscountPrice)
      );

      navigate(
        //product.callingURL
        "/slotbooking" + "/" + product?.itemId
      );
    }
  };

  const DisountUI = ({ Discount }) => {
    return (
      <div
        className="ribbon-wrap "
        style={{
          width: 70,
          height: 90,
          position: "absolute",
          top: "-10px",
          left: "-10px",
          pointerEvents: "none",
        }}
      >
        <div
          className="ribbon rounded-pill"
          style={{
            position: "absolute",
            width: 70,
            height: 20,
            fontSize: "0.918rem",
            textAlign: "center",
            padding: "1px 0",
            background: "	#ff0505",
            color: "#fff",
            transform: "rotate(-45deg)",
            right: "-1px",
            top: "29%",
            borderRadius: "0 0 5px 5px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >

          
          {Discount + "% Off"}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <Link
        // to={"/productdetail"}

        onClick={() =>
          localStorage.setItem("myProduct", JSON.stringify(product))
        }
      >
        <LazyLoadImage
          className="rounded-circle  "
          effect="blur card-img-top cover "
          key={product.iconURL}
          // onError={({ currentTarget }) => {
          //   currentTarget.onerror = null; // prevents looping
          //   currentTarget.src = { Placeholder };
          // }}

          alt=""
          src={product.iconURL}
        />
      </Link> */}
      {/* <h5 class="card-title">{parse(`${product.title}`)}</h5> */}
      {/* <div class=" "> */}
      {/* <p class="card-text">{parse(`${product.subTitle}`)}</p>

        <Link
          className="text-primary"
          to={
            product?.callingURL === "" || product?.callingURL === null
              ? "/booking/" + product?.itemId
              : product.callingURL + "/" + product?.itemId
          }
        >
          Book appointment
        </Link> */}

      {/* <div class=""> */}

      <div
        class="card  rounded-4 me-2  mb-2"
        style={{ minWidth: 250, minHeight: 180, width: 300, height: 210 }}
      >
        <div class="card-body ">
          <div class="card-header bg-white">
            <h5
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                lineClamp: 2,
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                fontWeight: 700,
              }}
            >
              {parse(`${product.title}`)}
            </h5>
          </div>

          <p class="card-text">{parse(`${product.subTitle}`)}</p>
          {product.price == product.price - (product.price * Discount) / 100 ? (
            <div className="row">
              <div className="col-6 fw-bold">
                {"₹"}
                {parse(`${product.price - (product.price * Discount) / 100}`)}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-6 fw-lighter  text-decoration-line-through">
                {parse(`${"₹" + "   " + product.price}`)}
              </div>
              <div className="col-6 fw-bold">
                {"₹"}
                {parse(`${product.price - (product.price * Discount) / 100}`)}
              </div>
            </div>
          )}

          <button
            class="btn btn-primary mb-3  me-4 position-absolute bottom-0 end-0"
            onClick={bookingclick}
          >
            {product?.callingURL === CALLLINK_LINK_ADD_TO_CART
              ? "Add to cart"
              : "Book now"}
          </button>
        </div>

        {showEdit ? (
          <Link
            to={"/productdetail/" + product?.itemId}
            class="btn btn-outline-primary mb-3  m-4 position-absolute bottom-0 start-0"
            onClick={() =>
              localStorage.setItem("myProduct", JSON.stringify(product))
            }
          >
            Edit Now
          </Link>
        ) : null}
        {product.discountPrice ? (
          <DisountUI Discount={product.discountPrice} />
        ) : Discount ? (
           <DisountUI Discount={Discount} />
          
        ) : (
          ""
        )}
      </div>

      {/* <div
        className="container text-center"
        style={{
          width: 460,
          padding: 40,
          background: "#ffffff",
          textAlign: "center",
          borderRadius: 12,
          overflow: "hidden",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.05)",
          position: "relative",
        }}
      >
        <h2 className="title" style={{ fontSize: "2rem", color: "#333" }}>
          Unlock Exclusive <br /> Content
        </h2>
        <h3
          className="price"
          style={{
            color: "#FF6B6B",
            fontWeight: 700,
            fontSize: "2.2rem",
            margin: "15px 0",
          }}
        >
          $29<span>/month</span>
        </h3>
        <p
          className="description"
          style={{
            color: "#3b3b3b",
            fontSize: "1.1rem",
            margin: "20px 0 20px",
          }}
        >
          Gain exclusive access to our premium content library. Explore and
          enjoy high-quality videos on your preferred devices.
        </p>
        <b
          className="offer"
          style={{
            display: "block",
            color: "#555",
            fontSize: "1rem",
            marginTop: 25,
          }}
        >
          Act fast! Offer ends on September 20, 2023.
        </b>
        <a
          className="btn btn-primary subscribe-button"
          style={{
            display: "inline-block",
            padding: "15px 0",
            backgroundColor: "#FF6B6B",
            color: "#fff",
            textDecoration: "none",
            borderRadius: 30,
            fontSize: "1.2rem",
            marginTop: 40,
            width: "100%",
            fontWeight: 500,
            transition: "0.2s ease",
          }}
          href="#"
        >
          Subscribe Now
        </a>
        <div
          className="ribbon-wrap"
          style={{
            width: 150,
            height: 150,
            position: "absolute",
            top: "-10px",
            left: "-10px",
            pointerEvents: "none",
          }}
        >
          <div
            className="ribbon"
            style={{
              position: "absolute",
              width: 200,
              fontSize: "0.918rem",
              textAlign: "center",
              padding: "8px 0",
              background: "#FF6B6B",
              color: "#fff",
              transform: "rotate(-45deg)",
              right: "-17px",
              top: "29%",
              borderRadius: "0 0 5px 5px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "0 20px 20px 0",
                borderColor: "transparent #FF6B6B transparent transparent",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            Special Offer!
            <br />
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>-20%</span>
          </div>
        </div>
      </div> */}

      {/* </div> */}

      {/* <Link
          className="text-primary stretched-link  p-1"
          to={
            product?.callingURL === "" || product?.callingURL === null
              ? "/booking"
              : product.callingURL
          }
          // to={"booking"}
          onClick={() =>
            localStorage.setItem("myProduct", JSON.stringify(product, showEdit))
          }
          replace
        >
          Book appointment
        </Link> */}

      {/* </div> */}
    </>
  );
}

export default TextCardItemView;

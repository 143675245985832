import React, { useEffect, useState } from "react";
import UserSearchDropdown from "../report/CustomDropdown/UserSearchDropdown";
import apiHelper from "../report/ApiHelpar/apiHelper";
import useDebounce from "../report/useDebounce/useDebounce";
import { GET_DATA_BYNAME_MOBILE } from "../report/visitConstant/ApiConstant";
import moment from "moment";
import { OPD_COUNT, SUPER_ADNIM, USER_ROLE } from "../Constant";

const TokenForm = ({
  user,
  handleChange,
  saveUser,
  onSelect,
  inputValue,
  setInputValue,
  setInputMobailValue,
  currentDate,
  setCurrentDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [nameOptions, setNameOptions] = useState([]); // For name dropdown
  const [mobileOptions, setMobileOptions] = useState([]); // For mobile dropdown
  const [nameInput, setNameInput] = useState(); // Separate state for name input
  const [mobileInput, setMobileInput] = useState(); // Separate state for mobile input
  const [dob, setDOB] = useState("");
  const [selectedGender, setSelectedGender] = useState(
    user.customerId?.gender || "M"
  ); // default to 'M'

  const [SuprAdmin, setSuprAdmin] = useState(false);
  const userRole = localStorage.getItem(USER_ROLE);
  const [opdLength, setOpdLength] = useState(() => {
    const storedOpdCount = localStorage.getItem("opd_count");
    return storedOpdCount !== null && !isNaN(storedOpdCount)
      ? Number(storedOpdCount)
      : 4;
  });

  useEffect(() => {
    const opdcount = localStorage.getItem("opd_count");
    const validOpdCount =
      opdcount !== null && !isNaN(opdcount) ? Number(opdcount) : 4;
    setOpdLength(validOpdCount);

    if (userRole?.includes(SUPER_ADNIM)) {
      setSuprAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (user.customerId.name || user.customerId.mobileNo) {
      setMobileInput(user.customerId.mobileNo || "");
      setNameInput(user.customerId?.name || "");
      setSelectedGender(user.customerId?.gender || "");
    }
  }, [user.customerId?.name, user.customerId?.mobileNo]);

  // Debounced input values for name and mobile
  const debouncedNameInput = useDebounce(nameInput, 500);
  const debouncedMobileInput = useDebounce(mobileInput, 700);

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  // Effect to handle name search
  useEffect(() => {
    if (debouncedNameInput?.trim() !== "") {
      setInputValue(debouncedNameInput);
      fetchData({ name: debouncedNameInput }, setNameOptions); // Call fetchData with name input
    }
  }, [debouncedNameInput]);

  // Effect to handle mobile search
  useEffect(() => {
    if (debouncedMobileInput?.trim() !== "") {
      setInputMobailValue(debouncedMobileInput);
      fetchData({ mobileNo: debouncedMobileInput }, setMobileOptions); // Call fetchData with mobile input
    }
  }, [debouncedMobileInput]);

  // Input change handler
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Update state with input value
  };

  // const fetchData = async (searchValue, setOptionsCallback) => {
  //   console.log(searchValue);
  //   setLoading(true);
  //   try {
  //     const endpoint = GET_DATA_BYNAME_MOBILE;
  //     const response = await apiHelper(endpoint, searchValue);
  //     console.log("API Response:", response);
  //     // console.log("API Response:", response?.respData?.respMsg);
  //     // setOptionsCallback(response?.respData?.respMsg);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async (searchValue, setOptionsCallback) => {
    setLoading(true);
    try {
      const endpoint = GET_DATA_BYNAME_MOBILE;
      const response = await apiHelper(endpoint, searchValue);
      console.log("API Response:", response);

      // Check if the response structure is correct
      if (
        response &&
        response.respData &&
        Array.isArray(response.respData.respMsg)
      ) {
        if (response.respData.respMsg.length > 0) {
          setOptionsCallback(response.respData.respMsg); // Set options if data is found
        } else {
          // console.log("No results found.");
          setOptionsCallback([]); // Clear options if no data
        }
      } else {
        console.error(
          "API Response is missing the expected structure (respData or respMsg)"
        );
        setOptionsCallback([]); // Clear options if response is invalid
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOptionsCallback([]); // Clear options if an error occurs
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewItem = (fieldName, value) => {
    const fakeEvent = {
      target: {
        name: fieldName,
        value: value,
      },
    };
    handleChange(fakeEvent);
  };

  useEffect(() => {
    const fakeEvent = {
      target: {
        name: "customerId.name",
        value: nameInput,
      },
    };
    handleChange(fakeEvent);
  }, [nameInput]);

  useEffect(() => {
    const fakeEvent = {
      target: {
        name: "customerId.mobileNo",
        value: mobileInput,
      },
    };
    handleChange(fakeEvent);
  }, [mobileInput]);

  // Function to handle form submission
  const handleSubmit = (e, newEntry) => {
    e.preventDefault(); // Prevent default form submission behavior
    user.customerId.customerDetails.newEntry = showMoreDetails;
    user.customerId.gender = selectedGender;
    saveUser(newEntry); // Call the saveUser function to submit the form

    // Clear input fields after submitting the form

    setNameInput("");
    setMobileInput("");
  };

  const [isOpen, setIsOpen] = useState(false);

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [selectedOption, setSelectedOption] = useState("Open this select menu");

  const selectOptions = [
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    // You can handle additional logic here if needed, like updating form state
  };

  const handleGenderChange = (event) => {
    const value = event.target.value;
    setSelectedGender(value);
    if (value == "M") {
      user.customerId.salutation = "Mr";
    } else if (value == "F") {
      user.customerId.salutation = "Mrs";
    } else {
      user.customerId.salutation = "";
    }
  };

  const handleDOBCalulet = (event) => {
    setSelectedGender(event.target.value);
  };

  useEffect(() => {
    if (user.customerId.age) {
      // console.log(
      //   user.customerId.age +
      //     (user.customerId.ageUnit ? user.customerId.ageUnit : "years")
      // );

      const dobDate = moment()
        .subtract(
          user.customerId.age,
          user.customerId.ageUnit ? user.customerId.ageUnit : "years"
        )
        .format("YYYY-MM-DD");
      setDOB(dobDate);
      const fakeEvent = {
        target: {
          name: "customerId.dob",
          value: dobDate,
        },
      };
      handleChange(fakeEvent);
    } else {
      setDOB("");
    }
  }, [user.customerId.age, user.customerId.ageUnit]);

  // useEffect(() => {
  //   if (!dob) return; // Exit early if dob is not provided

  //   // Calculate age based on the date of birth
  //   const calculatedAge = moment().diff(moment(dob, 'YYYY/MM/DD'), 'years');

  //   // Check if the calculated age matches the stored age in user
  //   if (user?.customerId?.age !== calculatedAge) {
  //     // Only update if the calculated age is different from the current value
  //     const fakeEvent = {
  //       target: {
  //         name: 'customerId.age',
  //         value: calculatedAge,
  //       },
  //     };
  //     handleChange(fakeEvent);
  //   }
  // }, [dob]);

  // console.log(user   ,"--------000---")

  return (
    <>
      <div>
        {/* <h2 className="text-center mb-4">Add New Patient</h2> */}
        <form>
          <div className="d-flex justify-content-between align-items-center mb-3 pb-1">
            <span className="h3 fw-bold mb-0 text-center ">
              Add New Patient
            </span>
            {SuprAdmin && (
              <input
                type="date"
                name="currentDate"
                onChange={(e) => setCurrentDate(e.target.value)}
                value={currentDate}
                className="form-control mt-1 me-3"
                style={{ maxWidth: "20%" }}
                required
              />
            )}
          </div>

          {/* Basic Information */}
          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="patientName" className="form-label">
                Patient Name*
              </label>
              <div className="row g-2">
                <div className="col-3">
                  <select
                    className="form-select"
                    name="customerId.salutation"
                    onChange={(e) => {
                      handleChange(e);

                      if (e.target.value == "Mr") {
                        user.customerId.gender = "M";
                        user.gender = "M";
                        setSelectedGender("M");
                      } else if (
                        e.target.value == "Mrs" ||
                        e.target.value == "Ms" ||
                        e.target.value == "Smt"
                      ) {
                        user.customerId.gender = "F";
                        user.gender = "F";
                        setSelectedGender("F");
                      }
                    }}
                    value={user.customerId.salutation}
                  >
                    <option value="" disabled>
                      Title
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                    <option value="md">Md</option>
                    <option value="Smt">Smt</option>
                    <option value="Baby">Baby</option>
                    <option value="Sri">Sri</option>
                  </select>
                </div>

                <div className="col-9">
                  <UserSearchDropdown
                    options={nameOptions}
                    handleInputChange={setNameInput}
                    onSelect={onSelect}
                    value={user.customerId.name}
                    rowIndex={1}
                    loading={loading}
                    Placeholder="Enter Name"
                    onAddNewItem={(name) =>
                      handleAddNewItem("customerId.name", name)
                    }
                    searchTerm={nameInput}
                    newEntry={true}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <label htmlFor="patientName" className="form-label">
                Patient Name*
              </label>
              <UserSearchDropdown
                options={nameOptions}
                handleInputChange={setNameInput}
                onSelect={onSelect}
                value={user.customerId.name}
                rowIndex={1}
                loading={loading}
                Placeholder="Enter Name"
                onAddNewItem={(name) =>
                  handleAddNewItem("customerId.name", name)
                }
                searchTerm={nameInput}
                newEntry={true}
              />
            </div> */}

            <div className="col-md-6">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number*
              </label>
              <UserSearchDropdown
                options={mobileOptions}
                handleInputChange={setMobileInput}
                onSelect={onSelect}
                value={user.customerId.mobileNo}
                rowIndex={1}
                loading={loading}
                Placeholder="Enter Number"
                onAddNewItem={(mobileNo) =>
                  handleAddNewItem("customerId.mobileNo", mobileNo)
                }
                searchTerm={mobileInput}
                newEntry={true}
                inputType="tel"
              />
            </div>
          </div>

          {/* Gender and Age Information */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Gender*</label>
              <div className="btn-group w-100" role="group">
                <input
                  type="radio"
                  className="btn-check"
                  name="customerId.gender"
                  id="genderMale"
                  value="M"
                  checked={selectedGender === "M"}
                  onChange={handleGenderChange}
                />
                <label className="btn btn-outline-primary" htmlFor="genderMale">
                  M
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="customerId.gender"
                  id="genderFemale"
                  value="F"
                  checked={selectedGender === "F"}
                  onChange={handleGenderChange}
                />
                <label
                  className="btn btn-outline-primary"
                  htmlFor="genderFemale"
                >
                  F
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="customerId.gender"
                  id="genderOther"
                  value="T"
                  checked={selectedGender === "T"}
                  onChange={handleGenderChange}
                />
                <label
                  className="btn btn-outline-primary"
                  htmlFor="genderOther"
                >
                  Other
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="age" className="form-label">
                Age
              </label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="age"
                  placeholder="Age"
                  name="customerId.age"
                  onChange={handleChange}
                  // value={
                  //   user?.customerId?.age
                  //     ? user?.customerId?.age?.split(" ")[0]
                  //     : user?.customerId?.age
                  // }
                  value={user?.customerId?.age}
                  min="1"
                  max="120"
                />
                <select
                  className="form-select"
                  name="customerId.ageUnit"
                  onChange={handleChange}
                  value={user.customerId.ageUnit}
                  // value={
                  //   user.customerId.age
                  //     ? user?.customerId?.age?.split(" ")[1]
                  //     : user?.customerId?.ageUnit
                  // }
                >
                  <option value="years">Years</option>
                  <option value="months">Months</option>
                  <option value="day">Days</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="dob" className="form-label">
                DOB
              </label>
              <input
                type="date"
                className="form-control"
                id="dob"
                value={dob}
                onChange={(e) => setDOB(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">OPD</label>
              <select
                className="form-select"
                name="opd"
                onChange={handleChange}
                value={user?.opd}
              >
                {Array.from({ length: opdLength ? opdLength : 4 }).map(
                  (_, index) => (
                    <option
                      key={index}
                      value={index + 1}
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      OPD-{index + 1}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {/* Address Information */}
          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                name="customerId.city"
                className="form-control"
                id="city"
                placeholder="Enter City"
                onChange={handleChange}
                value={user.customerId?.city}
              />
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="pin" className="form-label">
                    Pin
                  </label>
                  <input
                    name="customerId.pin"
                    type="text"
                    className="form-control"
                    id="pin"
                    placeholder="Enter Pin"
                    onChange={handleChange}
                    value={user.customerId?.pin}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="preferredLanguage" className="form-label">
                    Preferred Language
                  </label>
                  <select
                    className="form-select"
                    id="preferredLanguage"
                    name="customerId.preferredLanguage"
                    onChange={handleChange}
                    value={user.customerId.preferredLanguage}
                  >
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Marathi">Marathi</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <textarea
                name="customerId.address"
                className="form-control"
                id="address"
                placeholder="Enter Address"
                onChange={handleChange}
                value={user.customerId?.address}
              />
            </div>
          </div>

          {/* Toggle Additional Details */}
          <div className="mb-3 text-center">
            <button
              type="button"
              className="btn  text-body" // text-body for neutral text color
              onClick={() => setShowMoreDetails(!showMoreDetails)}
            >
              If you want to add more details,{" "}
              <span className="text-primary">Click Here</span>
            </button>
          </div>

          {user.customerId?.name && (
            <div className="card w-100" style={{ background: "#fffbfa" }}>
              <div className="card-body d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
                <p className="card-title mb-0 text-center text-danger fw-semibold">
                  Are you updating
                </p>

                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => handleSubmit(e, true)}
                >
                  Existing Patient
                </button>

                <p className="card-text mb-0 text-center text-danger fw-semibold">
                  Or creating a
                </p>

                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => handleSubmit(e, false)}
                >
                  New Patient
                </button>
              </div>
            </div>
          )}

          {showMoreDetails && (
            <div className="additional-fields ">
              <div class="d-flex w-90 align-items-center">
                <p class="mb-0 text-center opacity-25 flex-shrink-0 px-3 ">
                  Marital Status
                </p>
                <hr class="flex-grow-1 border border-primary" />
              </div>
              {/* Additional Form Fields */}
              <div className="row mb-3">
                <div className="col-md-4">
                  <label htmlFor="maritalStatus" className="form-label">
                    Marital Status
                  </label>
                  <select
                    name="customerId.customerDetails.maritalStatus"
                    className="form-select"
                    id="maritalStatus"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.maritalStatus}
                  >
                    <option value="">Select</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="since" className="form-label">
                    Since
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="customerId.customerDetails.since" // Added name attribute
                    id="since"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.since}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="spouseBloodGroup" className="form-label">
                    Blood Group
                  </label>
                  <select
                    name="customerId.customerDetails.bloodGroup" // Added name attribute
                    className="form-select"
                    id="spouseBloodGroup"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.bloodGroup}
                  >
                    <option value="">Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="spouseName" className="form-label">
                    Spouse Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.spouseName" // Added name attribute
                    id="spouseName"
                    placeholder="Enter Spouse Name"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.spouseName}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="spouseBloodGroup" className="form-label">
                    Spouse Blood Group
                  </label>
                  <select
                    name="customerId.customerDetails.spouseBloodGroup" // Added name attribute
                    className="form-select"
                    id="spouseBloodGroup"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.spouseBloodGroup}
                  >
                    <option value="">Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
              </div>

              <div class="d-flex w-90 align-items-center">
                <hr class="flex-grow-1 border border-primary" />
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="referredBy" className="form-label">
                    Referred By
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.referredBy" // Added name attribute
                    id="referredBy"
                    placeholder="Doctor Name"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.referredBy}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="existingID" className="form-label">
                    Existing ID (if any)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.existingId" // Added name attribute
                    id="existingID"
                    placeholder="Enter ID"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.existingId}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="customerId.customerDetails.email" // Added name attribute
                    id="email"
                    placeholder="Enter Email"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.email}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="channel" className="form-label">
                    Channel (How did the patient hear about you?)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.channel" // Added name attribute
                    id="channel"
                    placeholder="Enter Channel"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.channel}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="coAddress" className="form-label">
                    C/O
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.co" // Added name attribute
                    id="coAddress"
                    placeholder="Enter C/O"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.co}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="mobile2" className="form-label">
                    Occupation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.occupation" // Added name attribute
                    id="Occupation"
                    placeholder="Enter Secondary Number"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.occupation}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="aadharNumber" className="form-label">
                    Tag
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.tag" // Added name attribute
                    id="tag"
                    placeholder="Enter Tag"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.tag}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="mobile2" className="form-label">
                    Mobile 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.mobile2" // Added name attribute
                    id="customerId.customerDetails.mobileNo2"
                    placeholder="Enter Secondary Number"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.mobile2}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="aadharNumber" className="form-label">
                    Aadhar Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="customerId.customerDetails.aadharNumber" // Added name attribute
                    id="aadharNumber"
                    placeholder="Aadhar Card Number"
                    onChange={handleChange}
                    value={user.customerId.customerDetails.aadharNumber}
                  />
                </div>
              </div>
              {/* Submit Button */}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e, false)}
                >
                  Add & Create Rx
                </button>
                {/* <button type="button" className="btn btn-outline-primary">
            Add & Create Bill
          </button> */}
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => handleSubmit(e, true)}
                >
                  {/* Add & Create Appointment */}
                  Existing Patient
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default TokenForm;

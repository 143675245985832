import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CLIENT_ID,
  ACCESS_TOKEN,
  BASE_URL,
  getCustomerAddressByUserId,
  addCustomerAddress,
  removeCustomerAddress,
  DEBUG_LOG_ENABLED,
  PAYPALCLIENTID,
  RAZORPAYKEYIDSECRET,
  SANDBOXRAZORPAYKEYID,
  RAZORPAYKEYID,
} from "../Constant";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";

import axios from "axios";
import swal from "sweetalert";
import CheckoutStepper from "../components/CheckoutStepper";

import { useLocation } from "react-router-dom";
import Loader from "../loader/Loader";
function AddAddress() {
  // const dispatch = useDispatch();
  const [customerAddress, setCustomerAddress] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [show, setShow] = useState(true);
  const [clientID, setClientID] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [Activ, setActiv] = useState(true);
  const [addressShow, setAddressShow] = useState(false);
  // const Location = useLocation();
  // const AddToCartAmount = Location.state.totalCartPrice;

  const getAddressLists = async () => {
    try {
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: customerId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCustomerAddressByUserId,
        customerData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      if (response?.data?.respData?.respMsg?.length === 0) {
        setAddressShow(true);
      } else {
        setAddressShow(false);
      }

      setCustomerAddress(response?.data?.respData?.respMsg);
      localStorage.setItem(
        "addressList",
        JSON.stringify(response?.data?.respData?.respMsg)
      );
      setActiv(false);
    } catch (err) {
      setActiv(false);
      if (err?.response?.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  const handleAddressIdchang = (e, item) => {
    const value = e?.target?.value;

    localStorage.setItem("addressData", JSON.stringify(item));

    setAddressId(value);
  };

  const customerId = localStorage.getItem(CUSTOMER_ID);

  const [customer, setCustomer] = useState({
    customerId: customerId,
    name: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    purpose: "",
    pincode: "",
    country: "",
    mobileNo: "",
    defaultAddress: isSubscribed,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  // const [catItemList, setCartItemList] = useState([]);

  const reviewOrder = (e) => {
    e.preventDefault();
    if (
      addressId === "" ||
      (addressId === null && clientID === "") ||
      clientID === null
    ) {
      swal("Please select your address");
    } else {
      navigate("/orderreview");
    }
  };

  const saveAddress = async (e) => {
    e.preventDefault();
    try {
      if (
        (customer.name === "" ||
          customer.mobileNo === "" ||
          customer.addressLine1 === "" ||
          customer.addressLine2 === "" ||
          customer.city === "" ||
          customer.state === "",
        customer.country === "",
        customer.pincode === "")
      ) {
        swal(" Please enter all fields ");
      } else {
        const customerData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              customerId: customerId,
              name: customer.name,
              addressLine1: customer.addressLine1,
              addressLine2: customer.addressLine2,
              city: customer.city,
              state: customer.state,
              purpose: customer.purpose,
              country: customer.country,
              pincode: customer.pincode,
              landmark: customer.landmark,
              mobileNo: customer.mobileNo,
              defaultAddress: customer.defaultAddress,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });

        let response = await axios.post(
          BASE_URL + addCustomerAddress,
          customerData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        );

        swal(" " + response.data.respData.message);
        // if (DEBUG_LOG_ENABLED) {
        //   console.log(response.data);
        // }
        getAddressLists();
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeAddress = async (item) => {
    try {
      const removeAddressItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            addressId: item.id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + removeCustomerAddress,
        removeAddressItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      swal(response.data.respData.message);
      getAddressLists();
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    removeAddress(item);
  };

  const getClient = async () => {
    try {
      const getClientId = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + GET_CLIENT_ID, getClientId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setClientID(response.data.respData.respMsg.razorpayKeyId);
      localStorage.setItem(
        SANDBOXRAZORPAYKEYID,
        response.data.respData.respMsg.sandboxRazorpayKeyId
      );

      localStorage.setItem(
        RAZORPAYKEYID,
        response.data.respData.respMsg.razorpayKeyId
      );

      localStorage.setItem(
        RAZORPAYKEYIDSECRET,
        response.data.respData.respMsg.razorpayKeySecret
      );

      localStorage.setItem(
        PAYPALCLIENTID,
        response.data.respData.respMsg.paypalClientId
      );

      localStorage.setItem(
        "clientId",
        response.data.respData.respMsg.razorpayKeyId
      );

      // response.data.respData.respMsg.paypalClientId;
      // response.data.respData.respMsg.razorpayKeyId;
      // response.data.respData.respMsg.razorpayKeySecret;
      // response.data.respData.respMsg.sandboxRazorpayKeyId;
    } catch (err) {
      // swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      getClient();
      getAddressLists();
    }
  }, []);
  const handleCheck = () => {
    setIsSubscribed((current) => !current);
  };
  //   const [selectedGender, setSelectedGender] = useState("");

  //   const handleGenderChange = (event) => {
  //     const selectedValue = event.target.value;

  //     // Update the state with the selected value
  //     setSelectedGender(selectedValue);

  //     // Log the selected value to the console
  // alert("Selected Gender:", event.target.value);
  //   };

  return (
    <div
      className="  align-items-center container  "
      style={{ marginTop: 110, marginBottom: 20 }}
    >
      <div className="wrapper">
        <div className="col-lg-9">
          <div className="d-flex flex-column ">
            <ScrollToTopOnMount />

            <div className="row">
              <div className="col-md-12">
                <CheckoutStepper />
              </div>
            </div>

            <div class="card mb-4 ">
              <div class="card-body p-3">
                <form>
                  <div class="form-group">
                    <label className="h4">Select Address</label>
                    {Activ ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {customerAddress.map((item) => (
                          <div class="alert alert-primary clearfix">
                            <div class="form-check" id="form-check1">
                              <input
                                class="form-check-input"
                                value={item.id}
                                onChange={(e) => handleAddressIdchang(e, item)}
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <>
                                <h5>
                                  {`${item.name} ${
                                    item.purpose ? `(${item.purpose})` : ""
                                  } `}{" "}
                                </h5>

                                <h6>{item.country}</h6>
                                <p>{"B.O.D - " + item.landmark}</p>
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  {item.addressLine1 + " " + item.addressLine2}
                                </label>

                                <p class="form-check-label">
                                  {" " +
                                    item.city +
                                    " " +
                                    item.state +
                                    " " +
                                    // item.country +
                                    " " +
                                    item.pincode}
                                </p>
                                <p class="form-check-label">
                                  {"Mobile no : " + item.mobileNo}
                                </p>
                              </>
                            </div>

                            <div>
                              <button
                                class="btn btn-transparent btn-lg float-end"
                                for="form-check1"
                                onClick={() => removeAddress(item)}
                                type="submit"
                              >
                                <FontAwesomeIcon icon="fa-trash" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>

            {show ? (
              <form class="row g-3">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <span class="h1"> Add new address </span>
                      </button>
                    </h2>

                    <div
                      id="flush-collapseOne"
                      className={
                        addressShow
                          ? "accordion-collapse collapse show"
                          : "accordion-collapse collapse"
                      }
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="card p-4">
                        {/* <div>
                          <p>
                            <span class="h1"> Add new address </span>
                          </p>
                        </div> */}
                        <div class="row g-3">
                          <div class="col-md-4">
                            <label for="validationCustom01" class="form-label">
                              Patient name
                            </label>
                            <input
                              class="form-control"
                              id="validationServer01"
                              type="text"
                              name="name"
                              required
                              value={customer.name}
                              onChange={(e) => handleChange(e)}
                              placeholder="Full Name"
                              size="20"
                              maxlength="20"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Father/Husband name</label>
                            <input
                              type="text"
                              name="country"
                              required="true"
                              value={customer.country}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Father/Husband name"
                              maxlength="50"
                            />
                          </div>

                          <div class="col-md-4">
                            <label>Date of Birth</label>
                            <input
                              type="Date"
                              name="landmark"
                              required="true"
                              value={customer.landmark}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                            />
                          </div>
                        </div>
                        <div class="row g-3">
                          <div class="col-md-4">
                            <label className="m-1">Gender</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="purpose"
                              // onChange={handleGenderChange}
                              // value={selectedGender}
                              onChange={(e) => handleChange(e)}
                              value={customer.purpose}
                              //customer.purpose
                            >
                              <option value="" disabled>
                                Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label>Flat No & Building name </label>
                            <input
                              type="text"
                              name="addressLine1"
                              required="true"
                              value={customer.addressLine1}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Flat No & Building name"
                              size="100"
                              maxlength="100"
                            />
                          </div>
                          {/* <div class="col-md-4">
                            <label>Locality</label>
                            <input
                              type="text"
                              name="addressLine2"
                              aria-required
                              value={customer.addressLine2}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Locality"
                              maxlength="70"
                            />
                          </div> */}
                          <div class="col-md-4">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              name="mobileNo"
                              required="true"
                              value={customer.mobileNo}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Mobile Number"
                              size="10"
                              minlength="10"
                              maxlength="12"
                            />
                          </div>
                        </div>

                        <div class="row g-3">
                          <div class="col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              required="true"
                              value={customer.city}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="City"
                              maxlength="15"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              required="true"
                              value={customer.state}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="state"
                              maxlength="10"
                            />
                          </div>

                          <div class="col-md-4">
                            <label>Zip</label>
                            <input
                              type="numbar"
                              name="pincode"
                              required="true"
                              value={customer.pincode}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="pincode"
                              maxlength="7"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          {/* <label>Sankalp (puja ka Uddesh)</label>
                    <textarea
                      class="form-control "
                      name="purpose"
                      required="true"
                      value={customer.purpose}
                      onChange={(e) => handleChange(e)}
                      placeholder="sankalp"
                      rows="2"
                    ></textarea> */}
                          <div class="col-12 pt-1">
                            <div class="form-check">
                              <input
                                class="form-check-input is-valid"
                                type="checkbox"
                                value={isSubscribed}
                                id="invalidCheck3"
                                defaultChecked={true}
                                onChange={handleCheck}
                                aria-describedby="validCheck3Feedback"
                                required
                                maxlength="100"
                              />
                              <label
                                class="form-check-label"
                                for="invalidCheck3"
                              >
                                Mark as default address
                              </label>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end ">
                            <button
                              className="btn btn-outline-dark mt-3"
                              for="form-check1"
                              onClick={saveAddress}
                              type="submit"
                            >
                              <FontAwesomeIcon icon="fa-floppy-disk" />
                              {" " + "save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="card p-4">
                  <div>
                    <p>
                      <span class="h1"> Add new address </span>
                    </p>
                  </div>
                  <div class="row g-3">
                    <div class="col-md-4">
                      <label for="validationCustom01" class="form-label">
                        Full name
                      </label>
                      <input
                        class="form-control"
                        id="validationServer01"
                        type="text"
                        name="name"
                        required
                        value={customer.name}
                        onChange={(e) => handleChange(e)}
                        placeholder="Full Name"
                        size="20"
                        maxlength="20"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        name="mobileNo"
                        required="true"
                        value={customer.mobileNo}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Mobile Number"
                        size="10"
                        minlength="10"
                        maxlength="12"
                      />
                    </div>
                  </div>
                  <div class="row g-3">
                    <div class="col-md-4">
                      <label>Flat No & Building name </label>
                      <input
                        type="text"
                        name="addressLine1"
                        required="true"
                        value={customer.addressLine1}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Flat No & Building name"
                        size="100"
                        maxlength="120"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Locality</label>
                      <input
                        type="text"
                        name="addressLine2"
                        aria-required
                        value={customer.addressLine2}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Locality"
                        maxlength="100"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        required="true"
                        value={customer.city}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="City"
                        maxlength="50"
                      />
                    </div>
                  </div>

                  <div class="row g-3">
                    <div class="col-md-4">
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        required="true"
                        value={customer.state}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="state"
                        maxlength="50"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        required="true"
                        value={customer.country}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="country"
                        maxlength="10"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Zip</label>
                      <input
                        type="numbar"
                        name="pincode"
                        required="true"
                        value={customer.pincode}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="pincode"
                        maxlength="20"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                 
                    <div class="col-12 pt-1">
                      <div class="form-check">
                        <input
                          class="form-check-input is-valid"
                          type="checkbox"
                          value={isSubscribed}
                          id="invalidCheck3"
                          defaultChecked={true}
                          onChange={handleCheck}
                          aria-describedby="validCheck3Feedback"
                          required
                          maxlength="100"
                        />
                        <label class="form-check-label" for="invalidCheck3">
                          Mark as default address
                        </label>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end ">
                      <button
                        className="btn btn-outline-dark mt-3"
                        for="form-check1"
                        onClick={saveAddress}
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-floppy-disk" />
                        {" " + "save"}
                      </button>
                    </div>
                  </div>
                </div> */}
              </form>
            ) : null}
          </div>
          <div class="d-flex justify-content-end pt-5 ">
            <button
              type="button"
              onClick={reviewOrder}
              class="btn btn-primary btn-lg"
              style={{ width: 300 }}
            >
              Review Order
              <span> </span>
              <FontAwesomeIcon icon="fa-solid fa-angles-right" fade />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;

export const Color = {
    header: "rgba(0, 0, 0, 0.1)",
    graydark: "rgba(0, 0, 0, 0.4)",
    primary: "rgba(130, 80, 255, 1)",
    focusColor: "rgba(130, 80, 255, 0.1)",
    subTextColor: "rgba(0, 0, 0, 0.8)",
    pendingBg: "#FFD700", //"rgba(247, 166, 23, 0.3)",
    pendingText: "#F7A617",
    completeBG: "#04CB00",//"rgba(75, 181, 67, 0.2)",
    completeText: "rgba(75, 181, 67, 1)",
    sentBg: "#FF8250",//"rgba(255, 87, 87, 0.4)",
    sentText: "rgba(255, 87, 87, 9)",
    lightGray: "#EEEEEE",
    lightPrimary: "rgba(130, 80, 255, 0.1)",
    disabled: "#D9D9D9"
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import CheckoutStepper from "../components/CheckoutStepper";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { increment } from "../actions";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

function OrderSuccess() {
  const [cartList, setcartList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const BookingTime = localStorage.getItem("bookingTime");
    if (!BookingTime) {
      dispatch(increment(0));
    }

 

    localStorage.setItem("price", " ");
    localStorage.setItem("bookingTime", "");
     localStorage.setItem("currency", "");
      localStorage.setItem("bookingDate", "");
 //localStorage.setItem("cartListData", "");
     localStorage.setItem("cartListData", "");

    localStorage.setItem("clientId", "");
    localStorage.setItem("razorpayKeyId", "");

    localStorage.setItem("myProductDiscountPrice", "");
    localStorage.setItem("multipleprice", "");

    
  }, []);

  return (
    <div className="container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
      <ScrollToTopOnMount/>
      <div className="col-md-12">
        <CheckoutStepper step={3} />
      </div>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <div className="card border-0 shadow-sm h-100">
            <div className="card-body mt-5">
              <div className="d-flex justify-content-center mb-3">
                <FontAwesomeIcon
                  icon={("fas", "check-circle")}
                  size="5x"
                  className="text-success"
                />
              </div>
              <h3 className="text-center">Thank you for order</h3>
              <h6 className="text-center h-5">
                We&apos;ve received your order. You can track order status in
                your&nbsp; payment reference no:
                {localStorage.getItem("orderID")}
                <span> </span>
                <Link to={"/orderhistory"}>Booking History</Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccess;

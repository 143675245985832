import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomTooltip from "./CustomTooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState, submitData } from "./ReduxApi/ReduxApi";

import swal from "sweetalert";
import ToastFist from "./Toast/ToastFist";
import icons from "./Utils/icons";
import {
  ENTER_DATA,
  NO_DATA_ENTERED,
  SESSION_ID,
  VISIT_ERROR_MSG,
  VISIT_SUCCESS_MSG,
} from "../Constant";

const ReportFooter = ({ receivedDatas, patientData }) => {
  const [receivedData, setReceiveData] = useState(receivedDatas);

  useEffect(() => {
    // Update the state whenever the receivedDatas prop changes
    setReceiveData(receivedDatas);
  }, [receivedDatas]); // Dependency array includes receivedDatas
  const reduxApiData = useSelector((state) => state.reduxApiData);
  const lastHistory = useSelector((state) => state?.visit?.lastHistory);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const [selectedLanguages, setSelectedLanguage] = useState("en");

  // const handleSubmit = async (loderId) => {
  //   setLoading(loderId); // Start loading

  //   console.log(reduxApiData);
  //   console.log(lastHistory, "lastHistory");

  //   function updateBusiParams(respMsg, currentHistory) {
  //     if (!currentHistory) return {}; // Return empty if there's no current history data

  //     return {
  //       // Manually update specific IDs
  //       complaintsData: currentHistory?.complaintsData
  //         ? {
  //             complaintsDataId:
  //               respMsg?.complaintsData?.complaintsDataId || null,
  //             ...currentHistory.complaintsData,
  //           }
  //         : null,

  //       historyData: currentHistory?.historyData
  //         ? {
  //             historyDataid: respMsg?.historyData?.historyDataid || null,
  //             ...currentHistory.historyData,
  //           }
  //         : null,

  //       systemicExaminationData: currentHistory?.systemicExaminationData
  //         ? {
  //             systemicExaminationDataId:
  //               respMsg?.systemicExaminationData?.systemicExaminationDataId ||
  //               null,
  //             ...currentHistory.systemicExaminationData,
  //           }
  //         : null,

  //       diagnosisData: currentHistory?.diagnosisData
  //         ? currentHistory.diagnosisData.map((item) => {
  //             const match = respMsg?.diagnosisData?.find(
  //               (diag) => diag?.diagnosis?.id === item?.diagnosis?.id
  //             );
  //             return {
  //               diagnosisDataId: match ? match.diagnosisDataId : null,
  //               ...item,
  //             };
  //           })
  //         : null,

  //       menstrualInfoData: currentHistory?.menstrualInfoData
  //         ? {
  //             menstrualInfoId:
  //               respMsg?.menstrualInfoData?.menstrualInfoId || null,
  //             ...currentHistory.menstrualInfoData,
  //           }
  //         : null,

  //       obstetricHistoryData: currentHistory?.obstetricHistoryData
  //         ? {
  //             obstetricHistoryId:
  //               respMsg?.obstetricHistoryData?.obstetricHistoryId || null,
  //             ...currentHistory.obstetricHistoryData,
  //           }
  //         : null,

  //       obstetricAdditionalDatas: currentHistory?.obstetricAdditionalDatas
  //         ? currentHistory.obstetricAdditionalDatas.map((item) => {
  //             const match = respMsg?.obstetricAdditionalDatas?.find(
  //               (obs) =>
  //                 obs?.pregnancyOutcome?.id === item?.pregnancyOutcome?.id
  //             );
  //             return {
  //               obstetricAdditionalDataId: match
  //                 ? match.obstetricAdditionalDataId
  //                 : null,
  //               ...item,
  //             };
  //           })
  //         : null,

  //       physicalExamination: currentHistory?.physicalExamination
  //         ? {
  //             physicalExaminationDataId:
  //               respMsg?.physicalExamination?.physicalExaminationDataId || null,
  //             ...currentHistory.physicalExamination,
  //           }
  //         : null,

  //       investigationsData: currentHistory?.investigationsData
  //         ? currentHistory.investigationsData.map((item) => {
  //             const match = respMsg?.investigationsData?.find(
  //               (diag) =>
  //                 diag?.testsOrInvestigations?.id ===
  //                 item?.testsOrInvestigations?.id
  //             );
  //             return {
  //               investigationsDataId: match
  //                 ? match.investigationsDataId
  //                 : item.investigationsDataId || null,
  //               ...item,
  //             };
  //           })
  //         : null,

  //       labTestsAndImagingData: currentHistory?.labTestsAndImagingData
  //         ? currentHistory.labTestsAndImagingData.map((item) => {
  //             const match = respMsg?.labTestsAndImagingData?.find(
  //               (diag) =>
  //                 diag?.labTestsAndImaging?.id === item?.labTestsAndImaging?.id
  //             );
  //             return {
  //               labTestsAndImagingDataID: match
  //                 ? match.labTestsAndImagingDataID
  //                 : item.labTestsAndImagingDataID || null,
  //               ...item,
  //             };
  //           })
  //         : null,

  //       testsRequestedData: currentHistory?.testsRequestedData
  //         ? {
  //             testsRequestedDataId:
  //               respMsg?.testsRequestedData?.testsRequestedDataId || null,
  //             ...currentHistory.testsRequestedData,
  //           }
  //         : null,

  //       currentMedicationData: currentHistory?.currentMedicationData
  //         ? currentHistory.currentMedicationData.map((item) => {
  //             const match = respMsg?.currentMedicationData?.find(
  //               (diag) => diag?.medicineId?.id === item?.medicineId?.id
  //             );
  //             return {
  //               currentMedicationDataId: match
  //                 ? match.currentMedicationDataId
  //                 : item.currentMedicationDataId || null,
  //               ...item,
  //             };
  //           })
  //         : null,

  //       // Add all other sections from currentHistory except specified ones
  //       ...Object.keys(currentHistory).reduce((acc, key) => {
  //         if (
  //           ![
  //             "complaintsData",
  //             "historyData",
  //             "systemicExaminationData",
  //             "physicalExamination",
  //             "obstetricAdditionalDatas",
  //             "obstetricHistoryData",
  //             "menstrualInfoData",
  //             "diagnosisData",
  //             "historyData",
  //             "investigationsData",
  //             "labTestsAndImagingData",
  //             "testsRequestedData",
  //             "currentMedicationData",

  //             // "pastMedicationData",

  //             // 'referredTo',
  //             // 'vitalsData'
  //           ].includes(key)
  //         ) {
  //           acc[key] = currentHistory[key];
  //         }
  //         return acc;
  //       }, {}),
  //     };
  //   }

  //   // Usage
  //   let busiParams = updateBusiParams(
  //     lastHistory,
  //     reduxApiData.request.busiParams
  //   );
  //   const hist = {
  //     pubInfo: {
  //       sessionId: localStorage.getItem(SESSION_ID),
  //     },
  //     request: {
  //       busiParams,
  //       isEncrypt: false,
  //       transactionId: "897987987989",
  //     },
  //   };

  //   try {
  //     var res;
  //     if (lastHistory.date == reduxApiData.request.busiParams.date) {
  //       res = await dispatch(submitData(hist, receivedData));
  //       console.log(hist, "hist");

  //       console.log();
  //     } else {
  //       res = await dispatch(submitData(reduxApiData, receivedData));
  //       console.log(reduxApiData, "Updated reduxApiData");
  //     }

  //     if (res == NO_DATA_ENTERED) {
  //       setShowToast(true);
  //       setColorStatus(3);
  //       setToastMsg(ENTER_DATA);
  //     } else if (res) {
  //       setShowToast(true);
  //       setColorStatus(1);
  //       setToastMsg(VISIT_SUCCESS_MSG);
  //     } else {
  //       setShowToast(true);
  //       setColorStatus(0);
  //       setToastMsg(VISIT_ERROR_MSG);
  //     }
  //   } catch (error) {
  //     setShowToast(true);
  //     setColorStatus(0);
  //     setToastMsg(VISIT_ERROR_MSG);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (loaderId) => {
    setLoading(loaderId); // Start loading
  //  console.log(reduxApiData);
   // console.log(lastHistory, "lastHistory");

    // Function to update business parameters with current history and response data
    const updateBusiParams = (respMsg, currentHistory = {}) => {
      const mapDataWithId = (items, respItems, idKey) =>
        items?.map((item) => {
          const match = respItems?.find(
            (respItem) => respItem[idKey]?.id === item[idKey]?.id
          );
          // console.log( {
          //   [`${idKey}Id`]: match
          //     ? match[`${idKey}Id`]
          //     : item[`${idKey}Id`] || null,
          //   ...item,
          // })
          return {
            [`${idKey}Id`]: match
              ? match[`${idKey}Id`]
              : item[`${idKey}Id`] || null,
            ...item,
          };
        }) || null;

      const newmapDataWithId = (items, respItems, idKey, AddID) =>
        (Array.isArray(items) &&
          items?.map((item) => {
            const match = respItems?.find(
              (respItem) => respItem[idKey]?.id === item[idKey]?.id
            );
            // console.log({
            //   [`${AddID}`]: match
            //     ? match[`${AddID}`]
            //     : item[`${AddID}`] || null,
            //   ...item,
            // });
            return {
              [`${AddID}`]: match
                ? match[`${AddID}`]
                : item[`${AddID}`] || null,
              ...item,
            };
          })) ||
        null;

      return {
        complaintsData: currentHistory.complaintsData
          ? {
              complaintsDataId:
                respMsg?.complaintsData?.complaintsDataId || null,
              ...currentHistory.complaintsData,
            }
          : null,
        historyData: currentHistory.historyData
          ? {
              historyDataid: respMsg?.historyData?.historyDataid || null,
              ...currentHistory.historyData,
            }
          : null,
        systemicExaminationData: currentHistory.systemicExaminationData
          ? {
              systemicExaminationDataId:
                respMsg?.systemicExaminationData?.systemicExaminationDataId ||
                null,
              ...currentHistory.systemicExaminationData,
            }
          : null,
        diagnosisData: newmapDataWithId(
          currentHistory.diagnosisData,
          respMsg?.diagnosisData,
          "diagnosis",
          "diagnosisDataId"
        ),
        menstrualInfoData: currentHistory.menstrualInfoData
          ? {
              menstrualInfoId:
                respMsg?.menstrualInfoData?.menstrualInfoId || null,
              ...currentHistory.menstrualInfoData,
            }
          : null,
        obstetricHistoryData: currentHistory.obstetricHistoryData
          ? {
              obstetricHistoryId:
                respMsg?.obstetricHistoryData?.obstetricHistoryId || null,
              ...currentHistory.obstetricHistoryData,
            }
          : null,

        obstetricAdditionalDatas: newmapDataWithId(
          currentHistory.obstetricAdditionalDatas,
          respMsg?.obstetricAdditionalData,
          "pregnancyOutcome",
          "obstetricAdditionalDataID"
        ),

        // obstetricAdditionalDatas: mapDataWithId(
        //   currentHistory.obstetricAdditionalDatas || [],
        //   respMsg?.obstetricAdditionalDatas || [],
        //   "pregnancyOutcome"
        // ),

        // obstetricHistoryData: currentHistory.obstetricHistoryData
        //   ? {
        //       obstetricHistoryId:
        //         respMsg?.obstetricHistoryData?.obstetricHistoryId || null,
        //       ...currentHistory.obstetricHistoryData,
        //     }
        //   : { obstetricHistoryId: null },

        physicalExamination: currentHistory.physicalExamination
          ? {
              physicalExaminationDataID:
                respMsg?.physicalExamination?.physicalExaminationDataID || null,
              ...currentHistory.physicalExamination,
            }
          : null,
        investigationsData: newmapDataWithId(
          currentHistory.investigationsData,
          respMsg?.investigationsData,
          "testsOrInvestigations",
          "investigationsDataId"
        ),
        labTestsAndImagingData: newmapDataWithId(
          currentHistory.labTestsAndImagingData,
          respMsg?.labTestsAndImagingData,
          "labTestsAndImaging",
          "labTestsAndImagingDataID"
        ),
        testsRequestedData: currentHistory.testsRequestedData
          ? {
              testsRequestedDataId:
                respMsg?.testsRequestedData?.testsRequestedDataId || null,
              ...currentHistory.testsRequestedData,
            }
          : null,
        currentMedicationData: newmapDataWithId(
          currentHistory.currentMedicationData,
          respMsg?.currentMedicationData,
          "medicineId",
          "currentMedicationDataId"
        ),
        // Include other sections of currentHistory
        ...Object.fromEntries(
          Object.entries(currentHistory).filter(
            ([key]) =>
              ![
                "complaintsData",
                "historyData",
                "systemicExaminationData",
                "physicalExamination",
                "obstetricAdditionalDatas",
                "obstetricAdditionalData",
                "obstetricHistoryData",
                "menstrualInfoData",
                "diagnosisData",
                "investigationsData",
                "labTestsAndImagingData",
                "testsRequestedData",
                "currentMedicationData",
              ].includes(key)
          )
        ),
      };
    };

    // Create the request payload
    const createRequestPayload = (busiParams) => ({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams,
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    // Main processing and submission logic
    try {
      var requestPayload;
      if (lastHistory) {
        const busiParams = updateBusiParams(
          lastHistory,
          reduxApiData.request.busiParams
        );
        requestPayload = createRequestPayload(busiParams);
      }

      const payload =
        lastHistory?.date === reduxApiData?.request?.busiParams?.date
          ? requestPayload
          : reduxApiData;

     // console.log(payload, "payload");

      const res = await dispatch(submitData(payload, receivedData));
      const toastMsg =
        res === NO_DATA_ENTERED
          ? ENTER_DATA
          : res
          ? VISIT_SUCCESS_MSG
          : VISIT_ERROR_MSG;
      const colorStatus = res === NO_DATA_ENTERED ? 3 : res ? 1 : 0;

      setShowToast(true);
      setColorStatus(colorStatus);
      setToastMsg(toastMsg);
    } catch (error) {
      console.log("errr 1", error);
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async (loderId) => {
    setLoading(loderId); // Start loading
    try {
      const res = await dispatch(submitData(reduxApiData, receivedData));

      if (res == NO_DATA_ENTERED) {
        setShowToast(true);
        setColorStatus(3);
        setToastMsg(ENTER_DATA);
      } else if (res) {
        setShowToast(true);
        // console.log("LOG DD1", res)
        setColorStatus(1);
        setToastMsg(VISIT_SUCCESS_MSG);
        navigate("/Report", {
          state: {
            receivedData,
            selectedLanguages,
            ageGender: {
              age: receivedData?.customerId?.age,
              gender: receivedData?.customerId?.gender,
            },
          },
        });
      } else {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(VISIT_ERROR_MSG);
      }
      setLoading(false); // Stop loading
    } catch (error) {
      setLoading(false); // Stop loading
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    }
  };

  const ViewAllTokanButton = async (loderId) => {
    setLoading(loderId); // Start loading
    try {
      const res = await dispatch(submitData(reduxApiData, receivedData));

      if (res == NO_DATA_ENTERED) {
        setShowToast(true);
        setColorStatus(3);
        setToastMsg(ENTER_DATA);
      } else if (res) {
        setShowToast(true);
        setColorStatus(1);
        setToastMsg(VISIT_SUCCESS_MSG);
        navigate("/tokenbooking");
      } else {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(VISIT_ERROR_MSG);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false); // Stop loading
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    }
  };

  const footerStyle = {
    backgroundColor: "#fffff",
    // color: "white",
    padding: "20px",
    position: "sticky",
    bottom: "0",
    width: "100%",
    height: 6,
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  return (
    <>
      <footer
        className=" d-flex justify-content-end border  rounded p-4 bg-light "
        style={footerStyle}
      >
        <div class="align-self-center ">
          <select
            className="form-select"
            id="inputGroupSelect02"
            defaultValue=""
            style={{ maxHeight: 38 }}
            value={selectedLanguages}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="hi">Hindi</option>
            <option value="mar">Marathi</option>
          </select>
        </div>

        <div class="align-self-center ms-4  " onClick={() => handlePrint("6")}>
          <img
            src={icons.print}
            alt={"print"}
            style={{ width: 25, height: 25, cursor: "pointer" }}
          />
        </div>
        <div class="align-self-center ms-4 " onClick={() => handleSubmit("5")}>
          <img
            src={icons.whatsapp}
            alt={"whatsapp"}
            style={{ width: 25, height: 25 }}
          />
          {/* <FontAwesomeIcon icon="fa-brands fa-whatsapp" style={{color: "#63E6BE",height:22}} /> */}
        </div>
        <div class="align-self-center ms-4 " onClick={() => handleSubmit("4")}>
          <img
            src={icons.gmail}
            alt={"gmail"}
            style={{ width: 25, height: 25 }}
          />
        </div>
        <div class="align-self-center ms-4 flex ">
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => ViewAllTokanButton("3")}
            style={{ width: 130, height: 38 }}
          >
            {loading == "3" && (
              <span
                class="spinner-border spinner-border-sm text-primary"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            View Tokens
          </button>
        </div>

        <div class="align-self-center ms-4 flex ">
          <button
            type="button"
            class="btn btn-light  border border-primary  text-centar "
            onClick={() => handleSubmit("2")}
            style={{ width: 130, height: 38 }}
          >
            {loading == "2" ? (
              <span
                class="spinner-border spinner-border-sm text-primary"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <CustomTooltip
                icon="fa-regular fa-floppy-disk"
                tooltipText="Save"
              />
            )}
            <span className="ms-2 md:fs-5 text-center text-primary"> Save</span>
          </button>
        </div>

        <div
          class="align-self-center ms-4 flex"
          // onClick={() => screenNavigtion()}
        >
          <button
            type="button"
            class="btn btn-danger  border   text-centar "
            style={{ width: 130, height: 38 }}
            //  onClick={ViewAllTokanButton}
            onClick={() => handlePrint("1")}
          >
            <span className="ms-2 md:fs-5 text-center "> End constant</span>
          </button>
        </div>
      </footer>

      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};
export default ReportFooter;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconColor } from "./visitConstant/repotOtherConstant";

const CustomTooltip = ({ icon, tooltipText, mainText }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <div
        className="tooltip-container"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        style={{ cursor: "pointer" }}
      >
        <FontAwesomeIcon
          icon={icon}
          className=""
          style={{ fontSize: 19, color: iconColor }}
        />


        <span className="ms-1">{mainText ? mainText : null}</span>

        {visible && (
          <div
            className="tooltip-text p-2"
            style={{
              visibility: visible ? "visible" : "hidden",
              width: "120px",
              backgroundColor: "#fff",
              fontSize: "15px",
              color: "#03030f",
              textAlign: "center",
              borderRadius: "9px",
              padding: "5px 0",
              position: "absolute",
              zIndex: 1,
              bottom: "125%",
              left: "50%",
              marginLeft: "-60px",
              opacity: visible ? 1 : 0,
              transition: "opacity 0.3s",
            }}
          >
            {tooltipText}
            <div
              style={{
                content: '""',
                position: "absolute",
                top: "100%",
                left: "50%",
                marginLeft: "-5px",
                borderWidth: "5px",
                borderStyle: "solid",
                borderColor: "#fff transparent transparent transparent",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;

 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
// import FeatureProduct from "../landing/FeatureProduct";
import  { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { increment } from "../actions";
import moment from "moment";
import Placeholder from "../../src/default_img/placeholder.png";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CART_ITEM,
  RemoveFromCart,
  ACCESS_TOKEN,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  IMG_BASE_URL,
  CART_COUNT,
} from "../Constant";
import axios from "axios";
import CaritemMT  from "../Images/CaritemMT.jpg"
import swal from "sweetalert";
import DefaultImage from "../Images/doctor.png"

function AddToCart() {
  const dispatch = useDispatch();
  const [date, setDate] = useState("");

  var totalCartPrice = 0;
  var totalCartPriceDollar = 0;

  const [multipleprice, setMultipleprice] = useState(0);
  const [multiplecurrency, setMultiplecurrency] = useState("₹");

  // const handleChange = (e) => {
    
  //   const value = e.target.value;
  //   var dataArray = value.split(" ");
  //   if (dataArray[0] === "₹") {
  //     setMultiplecurrency("INR");
  //   } else if (dataArray[0] === "$") {
  //     setMultiplecurrency("USD");
  //   }

  //   setMultipleprice(parseFloat(dataArray[1]));
  // };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  const [multiplecartListData, setmultiplecartListData] = useState([]);

  const getCartLists = async () => {
    
    try {
      const cartListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
   
      let response = await axios.post(BASE_URL + GET_CART_ITEM, cartListData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setmultiplecartListData(response?.data?.respData?.respMsg?.cartList);
      // if (DEBUG_LOG_ENABLED) {
      //   console.log("multiplecartListData : " + multiplecartListData);
      // }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 404) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  useEffect(() => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null || customerId === "") {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      getCartLists();
    }
  }, []);

  const removeItemFromCart = async (item) => {
     
    try {
      const removeCartItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + RemoveFromCart,
        removeCartItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
localStorage.setItem(CART_COUNT,response?.data?.respData?.respMsg?.cartCount)
      dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
      swal(response?.data?.respData?.message);
      getCartLists();
    } catch (err) {
      if (err?.response?.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    removeItemFromCart(item);
  };

  const handleDateChange = (e, item) => {
    // e.stopProgtion ();
    // e.preventDefault();
    const value = e.target.value;
    const date = moment(Date(value)).format("YYYY-MM-DD HH:mm:ss.SSS");
    setDate(date);
    addToCart(value, item);
    // e.preventDefault();
  };
  const addToCart = async (value, item) => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,
            date: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // if (DEBUG_LOG_ENABLED) {
      //   console.log("CartService : " + itemData);
      // }

 

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
      swal(response?.data?.respData?.message);
      getCartLists();
    } catch (err) {
      if (err?.response?.status === 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const shippingDetail = (multipleprice) => {
    setMultipleprice(multipleprice);
    localStorage.setItem(
      "multiplecartListData",
      JSON.stringify(multiplecartListData)
    );

    localStorage.setItem("multipleprice", multipleprice);
    localStorage.setItem("multiplecurrency", multiplecurrency);

    localStorage.setItem("cartListData", "");
    localStorage.setItem("currency", "");
    localStorage.setItem("timeslot", "");
    localStorage.setItem("bookingTime", "");

    if (multipleprice === 0) {
      swal("Please select the given amount to pay");
    } else if (multiplecartListData[0].date === "") {
      swal("Please select the given date of pujan to processed");
    } else {
      navigate("/addaddress");
    }
  };

  return (
    <div>
      {multiplecartListData?.length === 0 ? (
        <div class="d-flex justify-content-center mt-5 pt-5  ">
          <div className=" text-center">
            <FontAwesomeIcon
              icon="fa-solid fa-cart-shopping"
              style={{ color: "#6750A4", height: 200 }}
            />
            {/* <img src={CaritemMT} style={{ width: "18rem" }} /> */}
            <h5 className="card-title fw-bold text-primary mt-5">
              Item not found, please try another way
            </h5>
          </div>
        </div>
      ) : (
      
        <div className="container mt-5 py-5 px-xl-5">
          <div className="col-lg-9">
            <div className="d-flex flex-column h-100">
              <ScrollToTopOnMount />
              <p>
                <span class="h4"> Cart Item </span>
              </p>
              {multiplecartListData?.map((item) => {
                const dollarResult = parseFloat(item.priceInDollar);
                const result = parseFloat(item.price);
                totalCartPrice += result;
                totalCartPriceDollar += dollarResult;

                return (
                  <div>
                    <section>
                      <div class="container h-100">
                        <div class="row d-flex justify-content-center align-items-center h-100">
                          <div class="col">
                            <div class="card mb-4">
                              <div class="card-body p-4">
                                <div class="row align-items-center">
                                  <div class="col-md-2">
                                    <Link
                                      to={"/productdetail"}
                                      onClick={() =>
                                        localStorage.setItem(
                                          "myProduct",
                                          JSON.stringify(item)
                                        )
                                      }
                                    >
                                      <img
                                        width="120"
                                       // height="150"
                                        src={
                                          item?.iconURL
                                            ? IMG_BASE_URL + item?.iconURL
                                            : DefaultImage
                                        }
                                        alt=" Images"
                                        onError={(event) => {
                                          event.target.src = DefaultImage;
                                        }}
                                        style={{objectfit: "contain"}}
                                      />
                                    </Link>
                                  </div>
                                  <div className="col-md-10 card-title text-left text-dark text-truncate">
                                    <div>
                                      <h6>{item.title}</h6>
                                      <h5>
                                        <span>₹</span>
                                        {item.price}
                                      </h5>

                                      <label for="birthdaytime">
                                        Booking date{" "}
                                      </label>
                                      <span> </span>
                                      <input
                                        type="datetime-local"
                                        id="Date"
                                        name="date"
                                        value={item.date}
                                        onChange={(e) =>
                                          handleDateChange(e, item)
                                        }
                                      ></input>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                      <button
                                        height="50"
                                        onClick={() => removeItem(item)}
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>

                                  {/* <div class="col-md-2 d-flex justify-content-center">
                                <span class="input-group-btn">
                                  <div>
                                    <span class="input-group-btn">
                                      <button
                                        type="button"
                                        class="quantity-left-minus btn btn-danger btn-number"
                                        // onclick={() =>
                                        //   decreaseValue(item.itemId)
                                        // }
                                        // value="Decrease Value"
                                      >
                                        -
                                      </button>
                                    </span>
                                  </div>
                                </span>
                                <input
                                  type="number"
                                  id="number"
                                  name="quantity"
                                  class="form-control input-number"
                                  value="1"
                                  min="1"
                                  max="100"
                                />

                                <span class="input-group-btn">
                                  <div>
                                    <span class="input-group-btn">
                                      <button
                                        type="button"
                                        class="quantity-right-plus btn btn-success btn-number"
                                        // onclick={() =>
                                        //   increaseValue(item.itemId)
                                        // }
                                      >
                                        +
                                      </button>
                                    </span>
                                  </div>
                                </span>
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                );
              })}









              <div>
                <div class="card p-4">
                  <div class="float-end">
                    <p>
                      {/* <input
                        class="form-check-input"
                        value={"₹" + " " + totalCartPrice}
                        onChange={(e) => handleChange(e)}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      /> */}
                      <span class="h5"> </span>
                      <span class="h4">Order total:</span>
                      <span class="h4 ">{"₹" + "      " + totalCartPrice}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                {/* <Link
                  replace
                  className="btn btn-outline-dark mt-3"
                  class="btn btn-outline-dark btn-lg me-2 text-truncate"
                >
                  Continue
                </Link> */}
                <button
                  onClick={(e) => shippingDetail(totalCartPrice)}
                  class="btn btn-primary btn-lg  mt-2"
                  style={{ width: 300 }}
                >
                  Continue
                 <span>   </span> <FontAwesomeIcon icon="fa-solid fa-angles-right" fade />
                </button>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddToCart;
